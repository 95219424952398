import { CalendarControl } from 'components/calendar/Calendar';
import { ToggleSelector } from 'components/ToggleSelector';
import { capitalize } from 'helpers';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Page from './Page';

export default function CalendarPage() {
	let { type, year, month } = useParams();
	const [calType, setType] = React.useState<string>(type ? capitalize(type) : 'Festivals');

	return (
		<Page title='Calendar' includeBottomNavigation dark>
			<ToggleSelector values={['Festivals', 'Calendar']} type={calType!} setType={setType} />
			<CalendarControl
				calType={calType}
				year={year ? parseInt(year) : undefined}
				month={month ? parseInt(month) : undefined}
			/>
		</Page>
	);
}
