import { Typography } from '@mui/material';
import ArticleList from 'components/articles/ArticleList';
import { ToggleSelector } from 'components/ToggleSelector';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getUserPlaylists } from 'store/data/user/selectors';
import { updateAppBarTitle } from 'store/temp/actions';
import { ApplicationState, Article, DownloadStatus, UserPlaylist } from 'types';

interface TracksListProps {
	playlist?: UserPlaylist;
	articles: Article[];
	downloadingMedia: Article[];
	queuedMedia: Article[];
	// fetchArticle: (href: string) => Promise<Article>;
	updateAppBarTitle: (title: string) => void;
}

class TracksList extends React.Component<TracksListProps, any> {
	constructor(props: any) {
		super(props);

		props.updateAppBarTitle(`Playlist [${props.playlist?.title ?? ''}]`);

		this.state = { type: 'All' };
	}

	render() {
		let { articles, playlist, downloadingMedia, queuedMedia } = this.props;
		if (!playlist) {
			return null;
		}

		let isDownloadingMedia = playlist.id === '0' && (downloadingMedia.length > 0 || queuedMedia.length > 0);

		let downloading: any[] = [];
		let queued: any[] = [];
		if (isDownloadingMedia) {
			if (articles.length > 0) {
				(articles as any[]).unshift({
					articleType: 'component',
					__component: (
						<Typography variant='body2' color='textSecondary' style={{ marginLeft: 8, marginTop: 16 }}>
							Downloaded [{articles.length}]:
						</Typography>
					),
				});
			}
			if (queuedMedia.length > 0) {
				queued = [
					{
						articleType: 'component',
						__component: (
							<Typography variant='body2' color='textSecondary' style={{ marginLeft: 8, marginTop: 16 }}>
								Queued for Download [{queuedMedia.length}]:
							</Typography>
						),
					},
					...queuedMedia,
				];
				(articles as any[]).unshift(...queued);
			}
			if (downloadingMedia.length > 0) {
				downloading = [
					{
						articleType: 'component',
						__component: (
							<Typography variant='body2' color='textSecondary' style={{ marginLeft: 8, marginTop: 16 }}>
								Currently Downloading [{downloadingMedia.length}]:
							</Typography>
						),
					},
					...downloadingMedia,
				];
				(articles as any[]).unshift(...downloading);
			}
		} else {
			articles = articles?.filter(
				(article) =>
					this.state.type === 'All' || article.articleType.toLowerCase() === this.state.type.toLowerCase()
			);
		}

		return (
			<>
				{!isDownloadingMedia && (
					<ToggleSelector
						values={['All', 'Bhajan', 'Satsang']}
						type={this.state.type}
						setType={(type) => {
							this.setState({ type });
						}}
					/>
				)}
				<ArticleList recordType='' avoidVirtual records={articles} />
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState, { playlistId }) {
	let playlists = getUserPlaylists(state);
	let playlist = playlists[playlistId];
	if (!playlist) {
		return {};
	}

	let tracks = Object.values(playlist.tracks).sort((a, b) => a.order - b.order);

	let articles: Article[] = [];
	let articlesById = state.dataState.articles.byId;

	let downloadProgress = state.offlineState.audio;
	let downloadingMedia = Object.keys(downloadProgress)
		.filter((id) => downloadProgress[id]?.progress?.status === DownloadStatus.InProgress)
		.map((id) => articlesById[id])
		.sort((a, b) => a.mediaLength - b.mediaLength);
	let queuedMedia = Object.keys(downloadProgress)
		.filter((id) => downloadProgress[id]?.progress?.status === DownloadStatus.Queued)
		.map((id) => articlesById[id])
		.sort((a, b) => a.mediaLength - b.mediaLength);

	for (let i = 0; i < tracks.length; i++) {
		let article = articlesById[tracks[i].articleId];
		if (article) {
			if (playlist.id === '0') {
				if (
					downloadProgress[article.id]?.progress?.status === DownloadStatus.Complete ||
					!downloadProgress[article.id]
				) {
					articles.push(article);
				}
			} else {
				articles.push(article);
			}
		}
	}
	return { playlist, articles, downloadingMedia, queuedMedia };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, unknown, AnyAction>) {
	return {
		// fetchArticle: async (href: string) => {
		// 	return await dispatch(
		// 		fetchArticleData({ href: href }, { cacheStrategy: CacheStrategy.CacheFallingBackToNetwork })
		// 	);
		// },
		updateAppBarTitle: (title: string) => {
			dispatch(updateAppBarTitle(title));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TracksList);
