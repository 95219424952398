import { combineReducers } from 'redux';
import { Feature, FeaturesById, FeatureStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateFeatures, UpdateFeaturesFiltered, UPDATE_FEATURES, UPDATE_FEATURES_FILTERED } from './actions';

const featuresByIdReducer = (state: FeaturesById = {}, action: UpdateFeatures) => {
	switch (action.type) {
		case UPDATE_FEATURES:
			return updateData('Features', state, action.changes, action.overwrite);
		default:
			return state;
	}
};

export const filteredFeaturesReducer = (state: Feature[] = [], action: UpdateFeaturesFiltered) => {
	switch (action.type) {
		case UPDATE_FEATURES_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const featuresReducer = combineReducers<FeatureStore>({
	byId: featuresByIdReducer,
});
