import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { Icons } from 'config/icons';
import { EmailAuthProvider, reauthenticateWithCredential, User } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { getWebAppAssetPath, timeout } from 'helpers';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { appInterface } from 'store';
import { EventType, onEvent } from 'store/temp/actions';
import { ApplicationState } from 'types';
import * as Yup from 'yup';
import { PhoneSignInControl } from './PhoneSignInControl';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	gsignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		// border: '1px solid ' + theme.palette.secondary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	}),
	asignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		// border: '1px solid ' + theme.palette.secondary.main,

		'& .a-logo': {
			width: '100%',
			minWidth: 140,
			minHeight: 30,
			maxWidth: 300,
		},
	}),
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -1.25,
		alignSelf: 'center',
	},
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(4),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& .MuiTextField-root': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),

		// '&:first-of-type': {
		// 	marginTop: theme.spacing(5),
		// },
	}),
};

export interface Props {
	onPasswordLogin: (currentPass: string) => Promise<void>;
	onGoogleorAppleSignIn: () => Promise<void>;
	onPhoneSign: () => Promise<void>;
	showMessage: (...payload) => void;
	user?: User;
	confirmText?: string;
	onSubmittingChange: (isSubmitting: boolean) => void;
}

class ReAuthenticate extends React.Component<Props, any> {
	context!: React.ContextType<typeof DialogContext>;

	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
		};
	}

	render() {
		let { onSubmittingChange, onGoogleorAppleSignIn, onPasswordLogin, user, onPhoneSign, confirmText, showMessage } = this.props;
		let { showPassword } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		let divider = (
			<>
				<Divider />
				<Box sx={classes.or}>or</Box>
			</>
		);

		let isGoogle = user && user.providerData.map((provider) => provider?.providerId).indexOf('google.com') >= 0;
		let isPass = user && user.providerData.map((provider) => provider?.providerId).indexOf('password') >= 0;
		let isPhone = user && user.providerData.map((provider) => provider?.providerId).indexOf('phone') >= 0;
		let isApple = user && user.providerData.map((provider) => provider?.providerId).indexOf('apple.com') >= 0;

		return (
			<>
				{isGoogle ? (
					<Button
						sx={classes.gsignin}
						onClick={async () => {
							try {
								onSubmittingChange(true);
								if (user) {
									await appInterface.initiateGoogleSignIn();
								}

								await onGoogleorAppleSignIn();
								this.context.popScreen();
							} catch (e) {
								onSubmittingChange(false);
							}
						}}
						variant="contained"
						color="inherit"
					>
						<img src={getWebAppAssetPath('g-logo.png')} className="g-logo" alt="Google Logo" />
						<span className="g-msg">{confirmText ?? 'Confirm'}</span>
					</Button>
				) : null}

				{isApple ? (
					<Button
						sx={classes.asignin}
						onClick={async () => {
							try {
								onSubmittingChange(true);
								if (user) {
									await appInterface.initiateAppleSignIn(true);
									await timeout(2000);
								}

								await onGoogleorAppleSignIn();
								this.context.popScreen();
							} catch (e) {
								onSubmittingChange(false);
							}
						}}
					>
						<img src={getWebAppAssetPath('continue-with-apple.png')} className="a-logo" />
					</Button>
				) : null}

				{isPass ? (
					<>
						{isGoogle && divider}
						<Formik
							initialValues={{
								current: '',
							}}
							validationSchema={() =>
								Yup.object({
									current: Yup.string().required('Required'),
								})
							}
							onSubmit={async (values, { setSubmitting }) => {
								try {
									onSubmittingChange(true);
									let curUser = user; //firebase.auth().currentUser;
									if (curUser && curUser.email) {
										// await firebase.auth().signInWithEmailAndPassword(curUser.email, values.current);

										let credential = EmailAuthProvider.credential(curUser.email, values.current);
										await reauthenticateWithCredential(curUser, credential);
									}

									await onPasswordLogin(values.current);
									this.context.popScreen();
								} catch (e) {
									onSubmittingChange(false);
									console.error(e);
									showMessage(new Context(), EventType.Information, 'signin', {
										success: false,
										message: 'Wrong Credentials!',
									});
								}
							}}
						>
							{({ submitForm, isSubmitting }) => (
								<Box component={Form} sx={classes.form} translate="yes">
									<Field
										component={TextField}
										type={showPassword ? 'text' : 'password'}
										label="Current Password"
										name="current"
										autoComplete="off"
										autoCapitalize="off"
										autoCorrect="off"
										spellCheck="false"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														tabIndex={-1}
														size="large"
													>
														{showPassword ? Icons.Visibility : Icons.VisibilityOff}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<Button sx={classes.signinbtn} variant="contained" color="inherit" onClick={submitForm} disabled={isSubmitting}>
										{confirmText ?? 'Confirm'}
									</Button>
								</Box>
							)}
						</Formik>
					</>
				) : null}
				{isPhone ? (
					<>
						{(isGoogle || isPass) && divider}

						<PhoneSignInControl
							style={{ width: '90%', margin: 'auto', marginTop: 16 }}
							forDelete
							onSignInComplete={async () => {
								await onPhoneSign();
							}}
						/>
					</>
				) : null}
			</>
		);
	}
}

function mapStateToProps({ userState }: ApplicationState, props) {
	let { userStore } = userState;

	return {
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

ReAuthenticate.contextType = DialogContext;
export default connect(mapStateToProps, mapDispatchToProps)(ReAuthenticate);
