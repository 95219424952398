import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';
import { updateQuotes } from 'store/data/quotes/actions';

export class QuotesFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'quotes';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'quotes');
	public getChangeProcessAction = (changes) => updateQuotes(changes);
}
