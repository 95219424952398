import AdminPage from "components/pages/AdminPage";
import AdminSetRecordPage from "components/pages/AdminSetRecordPage";
import ArticlePage from "components/pages/ArticlePage";
import CalendarPage from "components/pages/CalendarPage";
import ContactPage from "components/pages/ContactPage";
import DialogPage from "components/pages/DialogPage";
import EditAddressPage from "components/pages/EditAddressPage";
import EmailLinkSignupPage from "components/pages/EmailLinkSignupPage";
import EmailLinkUpdatePage from "components/pages/EmailLinkUpdatePage";
import FeaturePage from "components/pages/FeaturePage";
import FeedbackPage from "components/pages/FeedbackPage";
import MediaPage from "components/pages/MediaPage";
import NityaNiyamPage from "components/pages/NityaNiyamPage";
import NoticesPage from "components/pages/NoticesPage";
import Page from "components/pages/Page";
import PhoneSignInPage from "components/pages/PhoneSignInPage";
import PkpPage from "components/pages/PkpPage";
import PlaylistPage from "components/pages/PlaylistPage";
import PlaylistsPage from "components/pages/PlaylistsPage";
import PublicationPage from "components/pages/PublicationPage";
import ReorderPlaylistPage from "components/pages/ReorderPlaylistPage";
import RhymeListPage from "components/pages/RhymeListPage";
import RhymePage from "components/pages/RhymePage";
import SSNRequestPage from "components/pages/SSNRequestPage";
import SandeshListPage from "components/pages/SandeshListPage";
import SignInPage from "components/pages/SignInPage";
import TermsPage from "components/pages/TermsPage";
import UserOptionsPage from "components/pages/UserOptionsPage";
import { AccountMode, AccountScreenManager } from "components/profile/AccountScreenManager";
import { SignOut } from "components/profile/SignOut";
import { logEvent } from "components/telemetry";
import { IsMinimalRhymeSite } from "config";
import { AdminRoutes, AppRoutes, MinimalRoutes, WebRoutes } from "config/route";
import { User } from "firebase/auth";
import { isAdmin, isFakeWebViewEnv } from "helpers";
import React from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { updateAppBarTitle } from "store/temp/actions";
import { ApplicationState } from "types";
import BhajanPage from "../pages/BhajanPage";
import HomePage from "../pages/HomePage";
import IntroPage from "../pages/IntroPage";
import PublicationsPage from "../pages/PublicationsPage";
import QuotePage from "../pages/QuotePage";
import SatsangPage from "../pages/SatsangPage";
import ShriAmarVaniPage from "../pages/ShriAmarVaniPage";
import SubscribePage from "../pages/SubscribePage";
import { withRouter } from "./WithRouter";

const pageComponentMap = {
    Home: HomePage,
    Intro: IntroPage,
    Quote: QuotePage,
    Bhajan: BhajanPage,
    Satsang: SatsangPage,
    Publications: PublicationsPage,
    ShriAmarVani: ShriAmarVaniPage,
    RhymeList: RhymeListPage,
    Calendar: CalendarPage,
    Notices: NoticesPage,
    Article: ArticlePage,
    Media: MediaPage,
    Playlists: PlaylistsPage,
    Playlist: PlaylistPage,
    ReorderPlaylist: ReorderPlaylistPage,
    SignIn: SignInPage,
    PhoneSignIn: PhoneSignInPage,
    UserOptions: UserOptionsPage,
    Admin: AdminPage,
    AdminSetRecord: AdminSetRecordPage,
    PkP: PkpPage,
    Rhyme: RhymePage,
    EmailLinkSignup: EmailLinkSignupPage,
    EmailLinkUpdate: EmailLinkUpdatePage,
    Contact: ContactPage,
    Feedback: FeedbackPage,
    Terms: TermsPage,
    Subscribe: SubscribePage,
    EditAddress: EditAddressPage,
    Cart: SubscribePage,
    Feature: FeaturePage,
    Sandesh: SandeshListPage,
    Publication: PublicationPage,
    SSNRequest: SSNRequestPage,
    NityaNiyam: NityaNiyamPage,
};

interface PageRouterProps {
    updateAppBarTitle: (string) => void;
    history: any;
    isAdmin: boolean;
    user?: User;
}

export class PageRouter extends React.Component<PageRouterProps> {
    private previousLocation: any;

    rectifyPath(location) {
        if (location.search.startsWith("?r=")) {
            location.pathname = location.search.replace("?r=", "");
            location.search = "";
        }
    }

    constructor(props: PageRouterProps) {
        super(props);

        this.rectifyPath(this.props.history.location);
        this.previousLocation = this.props.history.location;

        logEvent("page_view", { location: this.props.history.location.pathname });
    }

    UNSAFE_componentWillUpdate() {
        const { history } = this.props;
        let location = history.location;

        this.rectifyPath(history.location);

        if (this.previousLocation.pathname !== location.pathname) {
            logEvent("page_view", { path: location.pathname });
        }

        if (!(location.state && location.state.isModal)) {
            this.previousLocation = location;
        }
    }

    render() {
        const { history, isAdmin, user } = this.props;
        let location = history.location;
        this.rectifyPath(location);

        const isModal = location.state && location.state.isModal && this.previousLocation.pathname !== location.pathname;

        let appRoutes;
        if (isAdmin) {
            appRoutes = [...AdminRoutes, ...AppRoutes];
        } else if (isFakeWebViewEnv()) {
            appRoutes = AppRoutes;
        } else {
            appRoutes = WebRoutes;
        }

        if (IsMinimalRhymeSite) {
            if (!user) {
                return (
                    <DialogPage
                        title='Sign In'
                        transition={true}
                        maxWidth='xs'
                        style={{ height: "100%" }}
                        goBackOnClose={!!isModal}
                        goBackTo='/'
                        onClose={() => !!user}
                    >
                        <AccountScreenManager rootOnInit initScreen={AccountMode.SignIn} />
                    </DialogPage>
                );
            } else if (!isAdmin) {
                return (
                    <Page dark style={{ maxWidth: 500, margin: "0 auto" }} title='Sign In'>
                        <h4 style={{ textAlign: "center" }}>Email: {user.email}</h4>
                        <h1 style={{ textAlign: "center" }}>You are not authorized! Contact site administrator!</h1>
                        <SignOut />
                    </Page>
                );
            } else {
                appRoutes = MinimalRoutes;
            }
        } else if (!isModal && (!user || !user.phoneNumber) && location.pathname.indexOf("phonesignin") < 0) {
            // this.previousLocation = { pathname: '/phonesignin' };
            // location.pathname = '/phonesignin';
            // return <NewPhoneSignIn />;
        }

        let loc = isModal ? this.previousLocation : location;
        return (
            <>
                <Routes location={loc}>
                    {appRoutes.map((route, index) => {
                        let PageComponent = pageComponentMap[route.page || "Home"] || HomePage;
                        return (
                            <Route path={route.to} key={"route-key-" + route.to} element={<PageComponent routeState={{ ...route.state, ...loc.state }} />} />
                        );
                    })}
                    <Route path={"/"} element={<HomePage />} />
                </Routes>

                {isModal ? (
                    <Routes>
                        {appRoutes.map((route, index) => {
                            let PageComponent = pageComponentMap[route.page || "Home"] || HomePage;
                            return route.state?.isModal ? (
                                <Route
                                    path={route.to}
                                    key={"modal-route-key-" + route.to}
                                    element={<PageComponent isModal={true} routeState={{ ...route.state, ...location.state }} />}
                                />
                            ) : null;
                        })}
                    </Routes>
                ) : null}
            </>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.userState.userStore.user,
        isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateAppBarTitle: (title: string) => {
            dispatch(updateAppBarTitle(title));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageRouter));
