import { combineReducers } from 'redux';
import { OrderStore } from 'types';
import { Order } from 'types/orders';
import { updateData } from '../generic/reducers';
import {
	ClearOrders,
	CLEAR_ORDERS,
	UpdateOrders,
	UpdateOrdersFiltered,
	UPDATE_ORDERS,
	UPDATE_ORDERS_FILTERED,
} from './actions';

export const ordersByIdReducer = (state = {}, action: UpdateOrders | ClearOrders) => {
	switch (action.type) {
		case UPDATE_ORDERS:
			return updateData('Orders', state, action.changes);
		case CLEAR_ORDERS:
			return {};
		default:
			return state;
	}
};

export const filteredOrdersReducer = (state: Order[] = [], action: UpdateOrdersFiltered | ClearOrders) => {
	switch (action.type) {
		case UPDATE_ORDERS_FILTERED:
			return action.payload;
		case CLEAR_ORDERS:
			return [];
		default:
			return state;
	}
};

export const ordersReducer = combineReducers<OrderStore>({
	byId: ordersByIdReducer,
});
