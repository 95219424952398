import { NotificationsControl } from 'components/notifications/Notifications';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

function NoticesPage(props) {
	let { isModal } = props;
	let { id } = useParams();

	return (
		<>
			{!isModal ? <HomePage /> : null}
			<DialogPage title='Important Notices' transition={true} dark goBackOnClose={!!isModal} goBackTo='/'>
				<NotificationsControl id={id} />
			</DialogPage>
		</>
	);
}

export default NoticesPage;
