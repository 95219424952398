import { getWebAppAssetPath } from 'helpers';
import React from 'react';

export const EndContent = (props) => {
	return (
		<>
			{props.isb ? (
				props.locale === 'hi' ? (
					<div className={'locale-hi'}>{React.createElement('heading-three', {}, 'इति शुभम् भवतु')}</div>
				) : (
					<div className={'locale-en'}>
						{React.createElement('heading-three', { style: { textTransform: 'capitalize' } }, 'Om Shanti Shanti Shanti')}
					</div>
				)
			) : (
				<img src={getWebAppAssetPath('bg/dark/end-content.png')} style={{ width: '50%', display: 'block', margin: '24px auto' }} alt="" />
			)}
		</>
	);
};
