import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Edition, Publication } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_PUBLICATIONS = '@@pubs/UPDATE_PUBLICATIONS';
export type T_UPDATE_PUBLICATIONS = typeof UPDATE_PUBLICATIONS;

export interface UpdatePublications extends Action {
	type: T_UPDATE_PUBLICATIONS;
	changes: FBChanges;
	overwrite?: boolean;
}

export const updatePublications = (changes: FBChanges, overwrite?: boolean): UpdatePublications => {
	return {
		type: UPDATE_PUBLICATIONS,
		changes,
		overwrite,
	};
};

export const UPDATE_PUBLICATIONS_FILTERED = UPDATE_PUBLICATIONS + '_FILTERED';
export type T_UPDATE_PUBLICATIONS_FILTERED = typeof UPDATE_PUBLICATIONS_FILTERED;

export interface UpdatePublicationsFiltered extends Action {
	type: T_UPDATE_PUBLICATIONS_FILTERED;
	payload: Publication[];
}

export const UPDATE_EDITIONS = '@@pubs/UPDATE_EDITIONS';
export type T_UPDATE_EDITIONS = typeof UPDATE_EDITIONS;

export interface UpdateEditions extends Action {
	type: T_UPDATE_EDITIONS;
	changes: FBChanges;
	overwrite?: boolean;
}

export const updateEditions = (changes: FBChanges, overwrite?: boolean): UpdateEditions => {
	return {
		type: UPDATE_EDITIONS,
		changes,
		overwrite,
	};
};

export const UPDATE_EDITIONS_FILTERED = UPDATE_EDITIONS + '_FILTERED';
export type T_UPDATE_EDITIONS_FILTERED = typeof UPDATE_EDITIONS_FILTERED;

export interface UpdateEditionsFiltered extends Action {
	type: T_UPDATE_EDITIONS_FILTERED;
	payload: Edition[];
}
