import { createSelector } from 'reselect';
import { ApplicationState, Quote } from 'types';

const getQuotes = (state: ApplicationState) => state.filteredDataState.quotes;

export const getQuotesByDate = createSelector([getQuotes], (quotes) => {
	let quotesByDate: { [date: string]: Quote } = {};
	quotes.forEach((quote) => {
		quotesByDate[quote.forDate] = quote;
	});
	return quotesByDate;
});

const getQuotesById = (state: ApplicationState) => state.dataState.quotes.byId;

export const getQuoteTypes = createSelector([getQuotesById], (quotesById) => {
	let quotes = Object.values(quotesById);
	let types = quotes.reduce((types, quote) => {
		if (quote.type) {
			types[quote.type] = true;
		}
		return types;
	}, {});
	return Object.keys(types);
});
