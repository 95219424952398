import { Button, CardActions } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserPlaylists } from 'store/data/user/selectors';
import { AppDataState, ApplicationState, Article, UserPlaylist } from 'types';
import { ArticleTextContent } from './articles/ArticleTextContent';
import { ArticleTile } from './articles/ArticleTile';
import UserPlaylistTile from './playlist/UserPlaylistTile';
import PublicationTile from './publications/PublicationTile';
import { GoldenRhyme } from './rhymes/GoldenRhyme';
import { RhymePageContent } from './rhymes/RhymePageContent';

interface Props {
	collectionType: string;
	refId: string;
	dataState: AppDataState;
	state: ApplicationState;
	tileType?: { [key: string]: any };
	index?: number;
	tileProps?: any;
}

const Component = (props: Props) => {
	let { collectionType, refId, dataState, state, tileType, index, tileProps } = props;
	let navigate = useNavigate();

	switch (collectionType) {
		case 'publications':
			let publications = dataState.publications.byId;
			let publication = publications[refId];

			return <PublicationTile record={publication} />;
		case 'editions':
			let editions = dataState.editions.byId;
			let edition = editions[refId];

			let pubById = dataState.publications.byId;
			let pub = pubById[edition.publicationId];

			return <PublicationTile concise={tileType && tileType['editions'] === 'concise'} record={pub} curEditionId={refId} />;
		case 'articles':
			let articles = dataState.articles.byId;
			let article = articles[refId];

			if (article?.mediaType === 'audio') {
				let Tile = tileType && tileType['articles'] ? tileType['articles'] : ArticleTile;
				return <Tile index={index} record={article} />;
			} else if (article?.mediaType === 'text') {
				return <ArticleTextContent href={article.id} articleType={article.articleType} isEmbed={true} />;
			}
			break;
		case 'articlespin':
			let Tile = tileType && tileType['articles'] ? tileType['articles'] : ArticleTile;
			return <Tile style={{ marginLeft: 0, marginRight: 0 }} index={index} record={dataState.articles.byId[refId]} {...tileProps} />;
		case 'playlists':
			let playlists = getUserPlaylists(state);
			return (
				<div style={{ margin: 0 - 8 }}>
					<UserPlaylistTile
						getTracks={(playlist: UserPlaylist) => {
							let allArticles = dataState.articles.byId;

							let articles: Article[] = [];
							let tracks = Object.values(playlist.tracks).sort((a, b) => a.order - b.order);

							for (let i = 0; i < tracks.length; i++) {
								let href = tracks[i].articleId;
								if (allArticles[href]) {
									articles.push(allArticles[href]);
								}
							}

							return articles;
						}}
						record={playlists[refId]}
					/>
				</div>
			);
		case 'rhymes':
			return <RhymePageContent id={refId} isEmbed={true} />;
		case 'rhymespin':
			let rhymes = dataState.rhymes.byId;
			let rhyme = rhymes[refId];

			return (
				<div
					style={{ margin: '8px 0' }}
					onClick={() => {
						navigate('/rhyme/' + refId, {
							state: {
								isModal: true,
							},
						});
					}}
				>
					<GoldenRhyme
						bottomMsg={
							<CardActions>
								<Button style={{ marginLeft: 'auto' }} color="primary" variant="text" size="small">
									View Meaning
								</Button>
							</CardActions>
						}
						rhyme={rhyme.rhyme}
					/>
				</div>
			);
	}

	return null;
};

function mapStateToProps(state: ApplicationState) {
	return {
		state,
		dataState: state.dataState,
	};
}

export const ReferencedComponent = connect(mapStateToProps)(Component);
