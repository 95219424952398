import { AccountScreenManager, getAccountScreenTitle } from 'components/profile/AccountScreenManager';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function UserOptionsPage(props) {
	let { isModal } = props;
	let { key, path1, path2, path3 } = useParams();
	let query = useQuery();
	let screen = key ?? query.get('screen');

	return (
		<>
			{!isModal ? <HomePage /> : null}
			<DialogPage
				key={key! + path1! + path2 + path3}
				title={getAccountScreenTitle(screen!)}
				transition={false}
				maxWidth='xs'
				dark
				goBackOnClose={!!isModal}
				goBackTo='/'
				screenKey={screen!}
			>
				<AccountScreenManager
					initScreen={screen!}
					rootOnInit={true}
					query={query!}
					path={[path1!, path2!, path3!]}
				/>
			</DialogPage>
		</>
	);
}
