import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { alignRhymes } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

interface TextProps {
	width: string;
	children?: React.ReactNode;
	locale?: string;
	alignRhymes?: boolean;
	useFontSize?: boolean;
	fontSize: number;
	style?: any;
}

const classes = {
	root: (props) => (theme) => ({
		lineHeight: 1.8,
		textAlign: 'justify',
		// color: '#555555',

		[theme.breakpoints.up('xl')]: {
			fontSize: '2.4rem',
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: '2.3rem',
		},
		[theme.breakpoints.only('md')]: {
			fontSize: '2.1rem',
		},
		[theme.breakpoints.only('sm')]: {
			fontSize: '2rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.9rem',
		},

		'& p': {
			textIndent: 0,

			'&[style*=justify]': {
				textIndent: props.locale === 'hi' ? '3em' : 0,
			},

			'&.ql-align-justify': {
				textIndent: props.locale === 'hi' ? '3em' : 0,
				textAlign: 'justify' as 'justify',

				'& img': {
					width: '100%',
				},
			},

			'&.ql-align-center': {
				textAlign: 'center' as 'center',

				'& img': {
					width: '100%',
				},
			},
		},
	}),
	inline: {
		display: 'inline',
	},
};

class TextContent extends React.Component<TextProps, any> {
	private rootRef;

	constructor(props: any) {
		super(props);
		this.rootRef = React.createRef();
	}

	componentDidMount() {
		if (this.props.alignRhymes) {
			alignRhymes(this.rootRef.current as HTMLElement);

			try {
				(document as any).fonts.ready.then(() => {
					alignRhymes(this.rootRef.current as HTMLElement);
				});
			} catch (e) {}
		}
	}

	componentDidUpdate() {
		if (this.props.alignRhymes) {
			alignRhymes(this.rootRef.current as HTMLElement);
		}
	}

	render() {
		let { children, locale, fontSize, useFontSize, style } = this.props;

		return children ? (
			<Box sx={classes.root(this.props)} className={'locale-' + (locale ?? 'en')} ref={this.rootRef} style={style}>
				{useFontSize !== false && fontSize !== 100 ? <div style={{ fontSize: fontSize / 100.0 + 'em' }}>{children}</div> : children}
			</Box>
		) : (
			<>
				<Skeleton animation="wave" />
				<Skeleton animation="wave" />
				<Skeleton animation="wave" />
				<Skeleton animation="wave" width="60%" />
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		fontSize: state.uxState.settings.font ?? 100,
	};
}

export default connect(mapStateToProps)(withWidth()(TextContent));
