import { Avatar, Box, Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { alpha, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Icons } from 'config/icons';
import { WebGroup, WebRightGroup } from 'config/route';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { ApplicationState, RouteProps } from 'types';

const classes = {
	root: {
		color: 'white',
		transform: 'rotate(0)',
		backgroundImage: `url(${getWebAppAssetPath('icons/dark/top_arch.png')})`,
		backgroundSize: '100% 100%',
		height: '20vw',
		filter: 'drop-shadow(5px 5px 5px grey)',
	},
	appbar: {
		backgroundColor: 'transparent',
		boxShadow: 'none',

		'& .MuiButton-text': {
			fontWeight: 'bold',
			textShadow: '2px 2px 5px green',
		},

		'& .MuiIconButton-root': {
			filter: 'drop-shadow(2px 2px 5px green)',
		},
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: (theme: Theme) => ({
		marginRight: theme.spacing(1),
	}),
	title: {
		flexGrow: 1,
	},
	search: (theme: Theme) => ({
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	}),
	searchIcon: (theme: Theme) => ({
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	inputRoot: {
		color: 'inherit',
	},
	inputInput: (theme: Theme) => ({
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	}),
	sectionDesktop: (theme: Theme) => ({
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	}),
	sectionMobile: (theme: Theme) => ({
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	}),
	userpic: (theme: Theme) => ({
		width: theme.spacing(3),
		height: theme.spacing(3),
	}),
};

const Link = (props) => {
	const { icon, primary, to, state, isIcon } = props;

	const RenderLink = React.useMemo(
		() => React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => <RouterLink to={to} state={state} ref={ref} {...itemProps} />),
		[to, state]
	);

	return isIcon ? (
		<IconButton key={primary} color="inherit" component={RenderLink} size="large">
			{icon}
		</IconButton>
	) : (
		<Button key={primary} target={state?.target} color="inherit" component={RenderLink}>
			{primary}
		</Button>
	);
};

function WebAppBar(props) {
	let photoUrl =
		(props.userData?.photoUrl && props.userData?.photoUrl.length > 1 ? props.userData?.photoUrl : undefined) ||
		props.user?.photoURL ||
		(props.user && getWebAppAssetPath('app_icon.png'));

	return (
		<Box sx={[classes.grow, classes.root]}>
			<AppBar position="relative" sx={classes.appbar}>
				<Toolbar>
					{WebGroup.routes.map((props: RouteProps) => {
						return <Link key={props.to} {...props} isIcon={false} />;
					})}
					<Box sx={classes.grow} />
					{WebRightGroup.routes.map((props: RouteProps) => {
						return <Link key={props.to} {...props} isIcon={true} />;
					})}
					<Link
						to="/account"
						page="UserOptions"
						primary="Account"
						title="Account"
						state={{ isModal: true }}
						icon={photoUrl ? <Avatar src={photoUrl} variant="circular" sx={classes.userpic} /> : Icons.Account}
						isIcon={true}
					/>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
		userData: state.userState.userStore.userData,
	};
}

export default connect(mapStateToProps)(WebAppBar);
