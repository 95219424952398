// Object.values(articles)
// 	.sort((b, a) => a.publishTime.seconds - b.publishTime.seconds)
// 	.filter((a) => {
// 		return (a.articleType === 'satsang' || a.articleType === 'bhajan') && a.mediaType === 'audio';
// 	})
// 	.slice(0, 20)
// 	.map((a) => {
// 		if (a.publishTime.seconds) a.publishTime = new Date(a.publishTime.seconds * 1000);
// 		return a;
// 	});

// top20 = [...top20satsang, ...top20bhajan, ...top20dhyan, ...top20nitya, ...top20sa]
// top20sa = Object.values(articles).sort((b, a) => a.publishTime.seconds - b.publishTime.seconds).filter(a => {return (a.articleType === "shriaarti" && a.mediaType !== "text")}).slice(0, 20)
// top20map = {};top20.forEach(a => top20map[a.id] = a)

import { ArticlesById } from 'types';

export const ArticlesDump = {
	Tzey6ruuRjJ1L43phYt4: {
		id: 'Tzey6ruuRjJ1L43phYt4',
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		author: 'Mh. Yog Dharam Anand Ji',
		mediaUri: 'satsang/Tzey6ruuRjJ1L43phYt4.mp3',
		active: true,
		mediaLength: 251.98925,
		mediaType: 'audio',
		lang: 'hi',
		title: {
			en: 'Khushiyon Ke Daata',
		},
		group: 'Satsang',
		createdAt: {
			seconds: 1608903584,
			nanoseconds: 756000000,
		},
		publishTime: {
			seconds: 3217783178,
			nanoseconds: 259000000,
		},
		deleted: true,
		updatedAt: {
			seconds: 1608905442,
			nanoseconds: 375000000,
		},
	},
	t50SpqWk8wSidZDtonDt: {
		id: 't50SpqWk8wSidZDtonDt',
		publishTime: {
			seconds: 1722922200,
			nanoseconds: 0,
		},
		author: 'Mh. Nirbhay Nirlep Anand Ji',
		mediaUri: 'satsang/t50SpqWk8wSidZDtonDt.mp3',
		active: true,
		mediaLength: 513.5477708333333,
		mediaType: 'audio',
		title: {
			hi: 'मन का स्वभाव',
			en: 'Mann Ka Swabhaav',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722922348,
			nanoseconds: 664000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-Shri Mandir',
		lang: 'hi',
		group: 'Sant-Samaagam',
		updatedAt: {
			seconds: 1722922348,
			nanoseconds: 664000000,
		},
		__playbackState: 'Not Started',
	},
	XXTYOowWwEsH3EI7bkJx: {
		id: 'XXTYOowWwEsH3EI7bkJx',
		publishTime: {
			seconds: 1722864600,
			nanoseconds: 0,
		},
		author: 'Mh. Vichar Nirlep Anand Ji',
		mediaUri: 'satsang/XXTYOowWwEsH3EI7bkJx.mp3',
		active: true,
		mediaLength: 879.9804375,
		mediaType: 'audio',
		title: {
			hi: 'सतगुरु-महिमा',
			en: 'Satguru-Mahima',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722859688,
			nanoseconds: 706000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-DSB',
		lang: 'hi',
		group: 'Alaukik Anand',
		updatedAt: {
			seconds: 1722859688,
			nanoseconds: 706000000,
		},
		__playbackState: 'Not Started',
	},
	BNzgq2PqaAl0vjHXIVv3: {
		id: 'BNzgq2PqaAl0vjHXIVv3',
		publishTime: {
			seconds: 1722835800,
			nanoseconds: 0,
		},
		author: 'Mh. Achal Param Anand Ji',
		mediaUri: 'satsang/BNzgq2PqaAl0vjHXIVv3.mp3',
		active: true,
		mediaLength: 398.98445833333335,
		mediaType: 'audio',
		title: {
			hi: 'सतगुरु से मिलन ',
			en: 'Satguru Se Milan',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722849000,
			nanoseconds: 595000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-Shri Mandir',
		lang: 'hi',
		group: 'Sant-Samaagam',
		updatedAt: {
			seconds: 1722849000,
			nanoseconds: 595000000,
		},
		__playbackState: 'Not Started',
	},
	'6OHpkQqpuTjeJzr1RwqW': {
		id: '6OHpkQqpuTjeJzr1RwqW',
		publishTime: {
			seconds: 1722778200,
			nanoseconds: 0,
		},
		author: 'Mh. Vishuddh Prakash Anand Ji',
		mediaUri: 'satsang/6OHpkQqpuTjeJzr1RwqW.mp3',
		active: true,
		mediaLength: 1203.0908333333334,
		mediaType: 'audio',
		title: {
			hi: 'श्री आनन्दपुर की रचना',
			en: 'Shri Anandpur Kee Rachna',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722792166,
			nanoseconds: 271000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-DSB',
		lang: 'hi',
		group: 'Alaukik Anand',
		updatedAt: {
			seconds: 1722792166,
			nanoseconds: 271000000,
		},
		__playbackState: 'Not Started',
	},
	z8g3o0i5a0iuTaGFkAwe: {
		id: 'z8g3o0i5a0iuTaGFkAwe',
		publishTime: {
			seconds: 1722742200,
			nanoseconds: 0,
		},
		author: 'Mh. Dhyan Amar Anand Ji',
		mediaUri: 'satsang/z8g3o0i5a0iuTaGFkAwe.mp3',
		active: true,
		mediaLength: 763.7455833333333,
		mediaType: 'audio',
		title: {
			hi: 'शुभ स्थापना दिवस',
			en: 'Shubh Sthaapana Divas',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722741161,
			nanoseconds: 116000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-Shri Mandir',
		lang: 'hi',
		group: 'Sant-Samaagam',
		updatedAt: {
			seconds: 1722741161,
			nanoseconds: 116000000,
		},
	},
	UqNnvhXJDapor50toUas: {
		id: 'UqNnvhXJDapor50toUas',
		publishTime: {
			seconds: 1722691800,
			nanoseconds: 0,
		},
		author: 'Mh. Anmol Vivek Anand Ji',
		mediaUri: 'satsang/UqNnvhXJDapor50toUas.mp3',
		active: true,
		mediaLength: 1115.7286875,
		mediaType: 'audio',
		title: {
			hi: 'कठिन परिश्रम',
			en: 'Kathin Parishram',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722687376,
			nanoseconds: 65000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-DSB',
		lang: 'hi',
		group: 'Alaukik Anand',
		updatedAt: {
			seconds: 1722687376,
			nanoseconds: 65000000,
		},
	},
	YAOv9cnGPwadN2tffHFT: {
		id: 'YAOv9cnGPwadN2tffHFT',
		publishTime: {
			seconds: 1722663000,
			nanoseconds: 0,
		},
		author: 'Mh. Nij Amar Anand Ji',
		mediaUri: 'satsang/YAOv9cnGPwadN2tffHFT.mp3',
		active: true,
		mediaLength: 331.2308541666667,
		mediaType: 'audio',
		title: {
			hi: 'श्री वचन की सम्भाल',
			en: 'Shri Vachan Kee Sambhaal',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722687745,
			nanoseconds: 546000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-Shri Mandir',
		lang: 'hi',
		group: 'Sant-Samaagam',
		updatedAt: {
			seconds: 1722687745,
			nanoseconds: 546000000,
		},
	},
	JlwWQo8MyhnWWL8wcsWE: {
		id: 'JlwWQo8MyhnWWL8wcsWE',
		publishTime: {
			seconds: 1722605400,
			nanoseconds: 0,
		},
		author: 'Mh. Aad Amar Anand Ji',
		mediaUri: 'satsang/JlwWQo8MyhnWWL8wcsWE.mp3',
		active: true,
		mediaLength: 742.4181666666667,
		mediaType: 'audio',
		title: {
			hi: 'इष्ट-कृपा',
			en: 'Easht-Kripa',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722688020,
			nanoseconds: 348000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-DSB',
		lang: 'hi',
		group: 'Alaukik Anand',
		updatedAt: {
			seconds: 1722688020,
			nanoseconds: 348000000,
		},
	},
	IDWB6L9d981qKdnwNY05: {
		id: 'IDWB6L9d981qKdnwNY05',
		publishTime: {
			seconds: 1722576600,
			nanoseconds: 0,
		},
		author: 'Mh. Nirbhay Dharam Anand Ji',
		mediaUri: 'satsang/IDWB6L9d981qKdnwNY05.mp3',
		active: true,
		mediaLength: 411.78877083333333,
		mediaType: 'audio',
		title: {
			hi: 'सच्चे रक्षक',
			en: 'Sacche Rakshak',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1722591823,
			nanoseconds: 160000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'satsang',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD-Shri Mandir',
		lang: 'hi',
		group: 'Sant-Samaagam',
		updatedAt: {
			seconds: 1722591823,
			nanoseconds: 160000000,
		},
	},
	AZqVfgV6jXSyuh2ZCLzS: {
		id: 'AZqVfgV6jXSyuh2ZCLzS',
		publishTime: {
			seconds: 1721413800,
			nanoseconds: 0,
		},
		author: 'Bh. Gajendra Ji - Hyderabad',
		mediaUri: 'bhajan/AZqVfgV6jXSyuh2ZCLzS.mp3',
		active: true,
		mediaLength: 544.1480208333334,
		mediaType: 'audio',
		diffCreation: false,
		createdAt: {
			seconds: 1717745189,
			nanoseconds: 389000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD',
		lang: 'hi',
		group: 'Teerth-Mahima',
		title: {
			hi: 'गुरु चरण इक तीरथ भारी',
			en: 'Guru Charan Ik Teerath Bhaari',
		},
		updatedAt: {
			seconds: 1717745511,
			nanoseconds: 980000000,
		},
		__playbackState: 'Not Started',
	},
	TlNzEcGkjkk5iK44eF9T: {
		id: 'TlNzEcGkjkk5iK44eF9T',
		author: 'Mh. Chetan Sukh Anand Ji',
		mediaUri: 'bhajan/TlNzEcGkjkk5iK44eF9T.mp3',
		active: true,
		mediaLength: 365.42316666666665,
		mediaType: 'audio',
		title: {
			hi: 'जाग जाग इन्सान जाग',
			en: 'Jaag Jaag Insaan Jaag',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1716820160,
			nanoseconds: 784000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SPD',
		lang: 'hi',
		group: 'Updesh',
		publishTime: {
			seconds: 1719772200,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1716820187,
			nanoseconds: 305000000,
		},
		__playbackState: 'Not Started',
	},
	uJAv8kUQc24Op6hlJlFy: {
		id: 'uJAv8kUQc24Op6hlJlFy',
		active: true,
		mediaType: 'audio',
		title: {
			hi: 'विराजो आन कर भगवन',
			en: 'Viraajo Aan Kar Bhagwan',
		},
		diffCreation: false,
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		lang: 'hi',
		group: 'Vinay',
		publishTime: {
			seconds: 1717957800,
			nanoseconds: 0,
		},
		author: 'Bh. Gajendra Ji - Hyderabad',
		place: 'SPD',
		createdAt: {
			seconds: 1717947936,
			nanoseconds: 442000000,
		},
		mediaUri: 'bhajan/uJAv8kUQc24Op6hlJlFy1.mp3',
		mediaLength: 478.498375,
		updatedAt: {
			seconds: 1717993927,
			nanoseconds: 610000000,
		},
	},
	pvQsSgl1L2UCaC4lWEXw: {
		id: 'pvQsSgl1L2UCaC4lWEXw',
		publishTime: {
			seconds: 1717727400,
			nanoseconds: 0,
		},
		author: 'Puja Ji - SPD',
		mediaUri: 'bhajan/pvQsSgl1L2UCaC4lWEXw.mp3',
		active: true,
		mediaLength: 229.3920625,
		mediaType: 'audio',
		title: {
			hi: 'ओ प्रेम के भंडारी',
			en: 'O Prem Ke Bhandaari',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1717726830,
			nanoseconds: 410000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SPD-Shri Mandir',
		lang: 'hi',
		group: 'Vinay',
		updatedAt: {
			seconds: 1717726830,
			nanoseconds: 410000000,
		},
	},
	'6I6nFvS4Q3lVnuF2X64A': {
		id: '6I6nFvS4Q3lVnuF2X64A',
		publishTime: {
			seconds: 1716085800,
			nanoseconds: 0,
		},
		author: 'Puja Ji - SPD',
		mediaUri: 'bhajan/6I6nFvS4Q3lVnuF2X64A.mp3',
		active: true,
		mediaLength: 235.03364583333334,
		mediaType: 'audio',
		title: {
			hi: 'चरणों की बख़्शो भक्ति',
			en: 'Charnon Kee Bakhsho Bhakti',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1716124740,
			nanoseconds: 749000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SPD',
		lang: 'hi',
		group: 'Vinay',
		updatedAt: {
			seconds: 1716124740,
			nanoseconds: 749000000,
		},
	},
	ZXuSog2dTXRiT5oIxYz9: {
		id: 'ZXuSog2dTXRiT5oIxYz9',
		publishTime: {
			seconds: 1713573000,
			nanoseconds: 0,
		},
		author: 'Mh. Aghat Hira Anand Ji',
		mediaUri: 'bhajan/ZXuSog2dTXRiT5oIxYz9.mp3',
		active: true,
		mediaLength: 315.95227083333333,
		mediaType: 'audio',
		title: {
			hi: 'धर युग-युग में अवतार',
			en: 'Dhar Yug-Yug Mein Avtaar',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1713628644,
			nanoseconds: 74000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD',
		lang: 'hi',
		group: 'Festival Special',
		updatedAt: {
			seconds: 1713628644,
			nanoseconds: 74000000,
		},
	},
	'72OLs4BhVjIP2oyYR2YS': {
		id: '72OLs4BhVjIP2oyYR2YS',
		publishTime: {
			seconds: 1713292200,
			nanoseconds: 0,
		},
		author: 'Bh. Ramdev Ji & Bh. Gajendra Ji - Hyderabad',
		mediaUri: 'bhajan/72OLs4BhVjIP2oyYR2YS.mp3',
		active: true,
		mediaLength: 630.211,
		mediaType: 'audio',
		title: {
			hi: 'ऐ मेरे सतगुरु जी',
			en: 'Ae Mere Satguru Ji',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1695524679,
			nanoseconds: 701000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD',
		lang: 'hi',
		group: 'Satguru Mahima',
		updatedAt: {
			seconds: 1695524679,
			nanoseconds: 701000000,
		},
	},
	kiA9MTOp4IWBlZbGLOsO: {
		id: 'kiA9MTOp4IWBlZbGLOsO',
		publishTime: {
			seconds: 1712601000,
			nanoseconds: 0,
		},
		author: 'Bh. Gajendra Ji - Hyderabad',
		mediaUri: 'bhajan/kiA9MTOp4IWBlZbGLOsO.mp3',
		active: true,
		mediaLength: 408.2956458333333,
		mediaType: 'audio',
		title: {
			hi: 'इस नये वर्ष में दाता',
			en: 'Is Naye Varsh Mein Daata',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1712152510,
			nanoseconds: 534000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SAPD',
		lang: 'hi',
		group: 'Festival Special',
		updatedAt: {
			seconds: 1712152510,
			nanoseconds: 534000000,
		},
	},
	AKfOLG6V06FxIQ9fwVDW: {
		id: 'AKfOLG6V06FxIQ9fwVDW',
		publishTime: {
			seconds: 1709605800,
			nanoseconds: 0,
		},
		author: 'SSN',
		mediaUri: 'bhajan/AKfOLG6V06FxIQ9fwVDW.mp3',
		active: true,
		mediaLength: 339.25360416666666,
		mediaType: 'audio',
		title: {
			hi: 'मुँहों मंगियाँ मुरादां पा लो',
			en: 'Muhon Mangiyaan Muraadaan Paa Lo',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1709605886,
			nanoseconds: 842000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: 'SSN',
		lang: 'pu',
		group: 'Satguru Mahima',
		updatedAt: {
			seconds: 1709605886,
			nanoseconds: 842000000,
		},
	},
	bqn6rdXYl09YYcw77eOS: {
		id: 'bqn6rdXYl09YYcw77eOS',
		publishTime: {
			seconds: 1707867000,
			nanoseconds: 0,
		},
		author: 'Bh. Ramdev Ji & Bh. Gajendra Ji - Hyderabad',
		mediaUri: 'bhajan/mgIuiiUyFUX4KMQNJyjy1.mp3',
		active: true,
		mediaLength: 531.0355782312926,
		mediaType: 'audio',
		title: {
			hi: 'ख़ुशियाँ ले करके',
			en: 'Khushiyan Le Karke',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1707876569,
			nanoseconds: 526000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'bhajan',
		by: '16',
		showon: {
			sap: true,
			spd: true,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Incarnation Day Special',
		updatedAt: {
			seconds: 1707876569,
			nanoseconds: 526000000,
		},
	},
	hKxuvxDxigC7RDJ0KvRi: {
		id: 'hKxuvxDxigC7RDJ0KvRi',
		publishTime: {
			seconds: 1704047400,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/hKxuvxDxigC7RDJ0KvRi.mp3',
		active: true,
		mediaLength: 3608.968625,
		mediaType: 'audio',
		title: {
			hi: 'ध्यान',
			en: 'Dhyan',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1704096674,
			nanoseconds: 882000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Dhyan',
		articleType: 'dhyan',
		updatedAt: {
			seconds: 1704104268,
			nanoseconds: 389000000,
		},
	},
	IKz6tolnrSyOd4R7jqHQ: {
		id: 'IKz6tolnrSyOd4R7jqHQ',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/IKz6tolnrSyOd4R7jqHQ.mp3',
		active: true,
		mediaLength: 405.898875,
		mediaType: 'audio',
		title: {
			hi: 'श्री आरती',
			en: 'Shri Aarti',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697983919,
			nanoseconds: 320000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Morning Nitya Niyam',
		updatedAt: {
			seconds: 1697983919,
			nanoseconds: 320000000,
		},
	},
	'5wOOUhi61St4YqRCmmzO': {
		id: '5wOOUhi61St4YqRCmmzO',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/5wOOUhi61St4YqRCmmzO.mp3',
		active: true,
		mediaLength: 346.21654166666667,
		mediaType: 'audio',
		title: {
			hi: 'श्री विनती',
			en: 'Shri Vinati',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697984247,
			nanoseconds: 782000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Morning Nitya Niyam',
		updatedAt: {
			seconds: 1697984247,
			nanoseconds: 782000000,
		},
	},
	GIVT8d4wMJ4eSruTHJNb: {
		id: 'GIVT8d4wMJ4eSruTHJNb',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/GIVT8d4wMJ4eSruTHJNb.mp3',
		active: true,
		mediaLength: 311.60183333333333,
		mediaType: 'audio',
		title: {
			hi: 'श्री स्तोत्र',
			en: 'Shri Stotr',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697984338,
			nanoseconds: 73000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Morning Nitya Niyam',
		updatedAt: {
			seconds: 1697984338,
			nanoseconds: 73000000,
		},
	},
	FbM4U8zgoYgygzyGr30M: {
		id: 'FbM4U8zgoYgygzyGr30M',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/FbM4U8zgoYgygzyGr30M.mp3',
		active: true,
		mediaLength: 548.66475,
		mediaType: 'audio',
		title: {
			hi: 'श्री छंद',
			en: 'Shri Chhand',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697984427,
			nanoseconds: 933000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Morning Nitya Niyam',
		updatedAt: {
			seconds: 1697984427,
			nanoseconds: 933000000,
		},
	},
	Rvr2ahUDqB0DOq38Xmq7: {
		id: 'Rvr2ahUDqB0DOq38Xmq7',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/Rvr2ahUDqB0DOq38Xmq7.mp3',
		active: true,
		mediaLength: 405.8277708333333,
		mediaType: 'audio',
		title: {
			hi: 'श्री आरती',
			en: 'Shri Aarti',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697985058,
			nanoseconds: 861000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Evening Nitya Niyam',
		updatedAt: {
			seconds: 1697985058,
			nanoseconds: 861000000,
		},
	},
	jjdmEvb11Fdqtrd6zk2o: {
		id: 'jjdmEvb11Fdqtrd6zk2o',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/jjdmEvb11Fdqtrd6zk2o.mp3',
		active: true,
		mediaLength: 346.23108333333334,
		mediaType: 'audio',
		title: {
			hi: 'श्री विनती',
			en: 'Shri Vinati',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697985112,
			nanoseconds: 96000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Evening Nitya Niyam',
		updatedAt: {
			seconds: 1697985112,
			nanoseconds: 96000000,
		},
	},
	wKiwXTfhHNKFhZg4lbWu: {
		id: 'wKiwXTfhHNKFhZg4lbWu',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/wKiwXTfhHNKFhZg4lbWu.mp3',
		active: true,
		mediaLength: 311.47516666666667,
		mediaType: 'audio',
		title: {
			hi: 'श्री स्तोत्र',
			en: 'Shri Stotr',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697985168,
			nanoseconds: 333000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Evening Nitya Niyam',
		updatedAt: {
			seconds: 1697985168,
			nanoseconds: 333000000,
		},
	},
	x3zcoKnVzuOCgdWGDhTH: {
		id: 'x3zcoKnVzuOCgdWGDhTH',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'nityaniyam/x3zcoKnVzuOCgdWGDhTH.mp3',
		active: true,
		mediaLength: 484.3516666666667,
		mediaType: 'audio',
		title: {
			hi: 'श्री स्तुति',
			en: 'Shri Stuti',
		},
		diffCreation: false,
		createdAt: {
			seconds: 1697985218,
			nanoseconds: 128000000,
		},
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		by: '3',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		group: 'Evening Nitya Niyam',
		updatedAt: {
			seconds: 1697985218,
			nanoseconds: 128000000,
		},
	},
	POwDvI5aY7qAPNjORfIG: {
		id: 'POwDvI5aY7qAPNjORfIG',
		publishTime: {
			seconds: 1697913000,
			nanoseconds: 0,
		},
		author: '',
		active: true,
		mediaType: 'audio',
		diffCreation: false,
		minimal: false,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'nityaniyam',
		showon: {
			sap: true,
			spd: false,
		},
		context: {
			hi: '',
			en: '',
		},
		files: {},
		place: '',
		lang: 'hi',
		createdAt: {
			seconds: 1697987141,
			nanoseconds: 752000000,
		},
		title: {
			hi: 'ध्यान',
			en: 'Dhyan',
		},
		group: 'Dhyan',
		mediaUri: 'nityaniyam/POwDvI5aY7qAPNjORfIG1.mp3',
		by: '3',
		mediaLength: 310.4698125,
		updatedAt: {
			seconds: 1699079829,
			nanoseconds: 672000000,
		},
	},
	UDd3MOAAftvjTNaldx0b: {
		id: 'UDd3MOAAftvjTNaldx0b',
		mediaType: 'audio',
		deleted: false,
		articleType: 'shriaarti',
		prevId: 1108,
		lang: 'hi',
		group: 'Pooja Ke Phool',
		createdAt: {
			seconds: 1538502555,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'shriaarti/UDd3MOAAftvjTNaldx0b1.mp3',
		mediaLength: 825.8612083333334,
		publishTime: {
			seconds: 1532648100,
			nanoseconds: 0,
		},
		by: '3',
		active: true,
		title: {
			en: 'Aaradhan',
			hi: '',
		},
		allowExport: true,
		updatedAt: {
			seconds: 1609565939,
			nanoseconds: 0,
		},
	},
	zttqoZ8uXivTFmv7WjR5: {
		id: 'zttqoZ8uXivTFmv7WjR5',
		mediaType: 'audio',
		deleted: false,
		articleType: 'shriaarti',
		prevId: 1041,
		lang: 'hi',
		group: 'Pooja Ke Phool',
		createdAt: {
			seconds: 1532626965,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'shriaarti/zttqoZ8uXivTFmv7WjR51.mp3',
		mediaLength: 1165.104,
		publishTime: {
			seconds: 1532647802,
			nanoseconds: 0,
		},
		by: '3',
		active: true,
		title: {
			en: 'Shri Guru Mahima',
			hi: '',
		},
		allowExport: true,
		updatedAt: {
			seconds: 1609566197,
			nanoseconds: 0,
		},
		__playbackState: 'Not Started',
	},
	JSAbPtmmAoMIZrMiEYKL: {
		id: 'JSAbPtmmAoMIZrMiEYKL',
		mediaType: 'audio',
		deleted: false,
		articleType: 'shriaarti',
		prevId: 1105,
		lang: 'hi',
		group: 'Pooja Ke Phool',
		createdAt: {
			seconds: 1538485832,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'shriaarti/JSAbPtmmAoMIZrMiEYKL2.mp3',
		mediaLength: 1612.3820208333334,
		publishTime: {
			seconds: 1532647801,
			nanoseconds: 0,
		},
		by: '3',
		active: true,
		title: {
			en: 'Shri Aarti Stotr - Morning',
			hi: '',
		},
		allowExport: true,
		updatedAt: {
			seconds: 1609566032,
			nanoseconds: 0,
		},
	},
	v8X3COp68cPdPWQs0DjI: {
		id: 'v8X3COp68cPdPWQs0DjI',
		mediaType: 'audio',
		deleted: false,
		articleType: 'shriaarti',
		prevId: 1106,
		lang: 'hi',
		group: 'Pooja Ke Phool',
		createdAt: {
			seconds: 1538486030,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		author: '',
		mediaUri: 'shriaarti/v8X3COp68cPdPWQs0DjI2.mp3',
		mediaLength: 1547.8857083333332,
		publishTime: {
			seconds: 1532647800,
			nanoseconds: 0,
		},
		by: '3',
		active: true,
		title: {
			en: 'Shri Aarti Stotr - Evening',
			hi: '',
		},
		allowExport: true,
		updatedAt: {
			seconds: 1609566168,
			nanoseconds: 0,
		},
		__playbackState: 'Not Started',
	},
	DKPiv1j10dStc3mx0xFO: {
		id: 'DKPiv1j10dStc3mx0xFO',
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'shriaarti',
		author: '',
		mediaLength: 825.834,
		mediaType: 'video',
		lang: 'hi',
		title: {
			en: 'Aaradhan',
		},
		group: 'Pooja Ke Phool',
		publishTime: {
			seconds: 1532629980,
			nanoseconds: 0,
		},
		allowExport: true,
		createdAt: {
			seconds: 1606630179,
			nanoseconds: 300000000,
		},
		active: true,
		mediaUri: 'shriaarti/DKPiv1j10dStc3mx0xFO2.mp4',
		updatedAt: {
			seconds: 1608459132,
			nanoseconds: 133000000,
		},
	},
	c3qWKDBuyrgoanYfWcE2: {
		id: 'c3qWKDBuyrgoanYfWcE2',
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'shriaarti',
		author: '',
		active: true,
		mediaLength: 1163.333,
		mediaType: 'video',
		lang: 'hi',
		title: {
			en: 'Shri Guru Mahima',
		},
		group: 'Pooja Ke Phool',
		publishTime: {
			seconds: 1532629920,
			nanoseconds: 0,
		},
		allowExport: true,
		createdAt: {
			seconds: 1606629982,
			nanoseconds: 544000000,
		},
		mediaUri: 'shriaarti/c3qWKDBuyrgoanYfWcE22.mp4',
		updatedAt: {
			seconds: 1608459101,
			nanoseconds: 776000000,
		},
	},
	YSfmZYXFwN9OBCjkxmpE: {
		id: 'YSfmZYXFwN9OBCjkxmpE',
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'shriaarti',
		author: '',
		active: true,
		mediaLength: 1612.352,
		mediaType: 'video',
		lang: 'hi',
		title: {
			en: 'Shri Aarti Stotr - Morning',
		},
		group: 'Pooja Ke Phool',
		publishTime: {
			seconds: 1532629860,
			nanoseconds: 0,
		},
		allowExport: true,
		createdAt: {
			seconds: 1606587102,
			nanoseconds: 456000000,
		},
		mediaUri: 'shriaarti/YSfmZYXFwN9OBCjkxmpE1.mp4',
		updatedAt: {
			seconds: 1608459052,
			nanoseconds: 951000000,
		},
	},
	PO2gU8SZbbq40mEKXDdN: {
		id: 'PO2gU8SZbbq40mEKXDdN',
		publishTime: {
			seconds: 1532629800,
			nanoseconds: 0,
		},
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		articleType: 'shriaarti',
		author: '',
		active: true,
		mediaLength: 1547.84,
		mediaType: 'video',
		lang: 'hi',
		title: {
			en: 'Shri Aarti Stotr - Evening',
		},
		group: 'Pooja Ke Phool',
		allowExport: true,
		createdAt: {
			seconds: 1606629373,
			nanoseconds: 254000000,
		},
		mediaUri: 'shriaarti/PO2gU8SZbbq40mEKXDdN2.mp4',
		updatedAt: {
			seconds: 1608459004,
			nanoseconds: 802000000,
		},
	},
} as unknown as ArticlesById;
