import { Button, Card, CardHeader } from '@mui/material';
import RecordList from 'components/RecordList';
import { withRouter } from 'components/shell/WithRouter';
import { User } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { getUserPlaylists } from 'store/data/user/selectors';
import { ApplicationState, Article, ArticlesById, UserPlaylist, UserPlaylists } from 'types';
import UserPlaylistTile from './UserPlaylistTile';

interface PlayListsProps {
	playlists: UserPlaylists;
	allArticles: ArticlesById;
	user?: User;
	history: any;
}

class UserPlaylistsControl extends React.Component<PlayListsProps, any> {
	private getItemSize = (index) => {
		return 93;
	};

	private getTracks = (playlist: UserPlaylist) => {
		let { allArticles } = this.props;

		let articles: Article[] = [];
		let tracks = Object.values(playlist.tracks).sort((a, b) => a.order - b.order);

		for (let i = 0; i < tracks.length; i++) {
			let href = tracks[i].articleId;
			if (allArticles[href]) {
				articles.push(allArticles[href]);
			}
		}

		return articles;
	};

	render() {
		let { playlists, user, history } = this.props;
		let navigate = history.navigate;

		if (!user) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader='Please signin to manage playlists'
						action={
							<Button
								style={{ marginTop: 8 }}
								size='small'
								onClick={() => {
									navigate('/account?screen=signin', { state: { isModal: true } });
								}}
							>
								Sign In
							</Button>
						}
					/>
				</Card>
			);
		}

		// delete playlists[0];
		let playlistsArray = Object.values(playlists);

		return (
			<RecordList
				records={playlistsArray}
				fetching={false}
				RecordTile={UserPlaylistTile}
				getItemSize={this.getItemSize}
				RecordTileProps={{ getTracks: this.getTracks }}
			></RecordList>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		playlists: getUserPlaylists(state),
		allArticles: state.dataState.articles.byId,
		user: state.userState.userStore.user,
	};
}

export default connect(mapStateToProps)(withRouter(UserPlaylistsControl));
