import { ToggleSelector } from "components/ToggleSelector";
import PublicationList from "components/publications/PublicationList";
import { IsOwnerSAPD } from "config";
import React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import Page from "./Page";

export default function PublicationsPage() {
    const [type, setType] = React.useState<string>("Journals");

    let location = useLocation();

    const match = matchPath(
        {
            path: "/publications/:owner",
            caseSensitive: true,
            end: false,
        },
        location.pathname
    );

    let owner: string = IsOwnerSAPD ? match?.params.owner ?? "sapd" : "spd";

    return (
        <Page key={owner} title={"Publications " + (match?.params.owner ? owner.toUpperCase() : "")} includeBottomNavigation dark>
            <ToggleSelector values={["Journals", "Books"]} type={type} setType={setType} />
            <PublicationList
                filter={(pub) =>
                    pub.owner.toLowerCase() === owner.toLowerCase() &&
                    (!pub.type || pub.type !== "shriaarti") &&
                    (type === "Books" ? pub.frequency === 0 : pub.frequency !== 0)
                }
            />
        </Page>
    );
}
