import { Box, Card } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { Field, Form, Formik } from 'formik';
import { TextField, ToggleButtonGroup } from 'formik-mui';
import { Context } from 'helpers/context';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { updateProfile } from 'services/api/auth';
import { ApplicationState, UserData } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(8),
	}),
	label: (theme: Theme) => ({
		color: theme.palette.grey[500],
		marginBottom: theme.spacing(-1) + 'px !important',
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& .MuiTextField-root': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: (theme: Theme) => ({
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	}),
};

export interface ProfileProps extends ScreenManagerProps {
	userData?: UserData;
	updateProfile: (fullName?: string, gender?: string, dob?: string, rahbar?: string) => Promise<void>;
}

class ProfileDetails extends React.Component<ProfileProps, any> {
	render() {
		let { userData, updateProfile, popScreen } = this.props;

		return (
			<Box sx={classes.root}>
				<UserPicInfo allowChange />
				<Card sx={classes.card}>
					<Formik
						initialValues={{
							fullname: userData?.fullName || '',
							gender: userData?.gender || '',
							dob: userData?.dob ? moment(userData?.dob) : null,
							rahbar: userData?.rahbar || '',
						}}
						validationSchema={() => {
							return Yup.object({
								fullname: Yup.string().required('Full Name Required'),
								gender: Yup.string().oneOf(['male', 'female']),
								dob: Yup.date().nullable(),
								rahbar: Yup.string(),
							});
						}}
						onSubmit={async (values, { setSubmitting }) => {
							await updateProfile(
								values.fullname,
								values.gender,
								values.dob && (values.dob as any).format
									? (values.dob as any).format('YYYY-MM-DD')
									: '',
								values.rahbar
							);

							setSubmitting(false);
							popScreen();
						}}
					>
						{({ submitForm, isSubmitting, values, setFieldValue }) => (
							<Box component={Form} sx={classes.form} translate='yes'>
								<Field
									component={TextField}
									autoComplete='off'
									name='fullname'
									type='text'
									label='Full Name'
								/>
								<Typography sx={classes.label}>Gender</Typography>
								<Field
									component={ToggleButtonGroup}
									type='checkbox'
									style={{ width: '100%' }}
									name='gender'
									exclusive
								>
									<ToggleButton color='primary' style={{ width: '50%' }} value='male'>
										Male
									</ToggleButton>
									<ToggleButton color='primary' style={{ width: '50%' }} value='female'>
										Female
									</ToggleButton>
								</Field>
								<MobileDatePicker
									views={['year', 'month', 'day']}
									openTo={'year'}
									value={values['dob']}
									label='Date of Birth'
									format='ddd, Do MMM YYYY'
									onAccept={(date) => {
										if (date) {
											setFieldValue('dob', date);
										}
									}}
								/>
								<Field
									component={TextField}
									autoComplete='off'
									name='rahbar'
									type='text'
									label='Rahbar'
								/>
								<Button
									sx={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}
								>
									Save Changes
								</Button>
							</Box>
						)}
					</Formik>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps({ userState }: ApplicationState) {
	let { userStore } = userState;

	return {
		userData: userStore.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateProfile: async (fullName?: string, gender?: string, dob?: string, rahbar?: string) => {
			await dispatch(updateProfile({ fullName, gender, dob, rahbar }, new Context()));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
