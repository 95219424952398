import { Box, Drawer } from '@mui/material';
import { isMobile } from 'helpers';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { openDrawer, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';

const classes = {
	root: {
		zIndex: 1300,

		'& .MuiDrawer-paperAnchorBottom': {
			width: isMobile() ? 'auto' : 500,
			left: isMobile() ? 0 : 'calc(50% - 250px)',
		},
	},
	fullList: {
		width: 'auto',
	},
};

function mapStateToProps({ tempState }: ApplicationState, props: { content?; open? }) {
	let { bottomDrawer } = tempState;

	return {
		isDrawerOpen: props.open || bottomDrawer.open,
		content: props.content || bottomDrawer.content,
		styles: bottomDrawer.styles,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (open: boolean) => {
			dispatch(toggleBottomDrawer(undefined, open));
		},
		onDrawerOpen: () => {
			dispatch(openDrawer());
		},
	};
}

let connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface BottomDrawerProps extends PropsFromRedux {}

function BottomDrawer(props: BottomDrawerProps) {
	const closeDrawer = () => (e: React.MouseEvent) => {
		props.toggleDrawer(false);
	};

	const content = () => <Box sx={classes.fullList}>{props.content ?? ''}</Box>;

	return (
		<div>
			<Drawer sx={[classes.root, props.styles]} anchor='bottom' open={props.isDrawerOpen} onClose={closeDrawer()}>
				{content()}
			</Drawer>
		</div>
	);
}

export default connector(BottomDrawer);
