import { Box, Paper } from '@mui/material';
import TextContent from 'components/TextContent';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';

const classes = {
	root: {
		'&::before, &::after': {
			display: 'block',
			content: '""',
			backgroundImage: `url(${getWebAppAssetPath('bg/golden-border.jpg')})`,
			backgroundRepeat: 'repeat-x',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			height: '2rem',
		},
	},
	rhyme: {
		color: '#bd3b0b',
		fontWeight: 'bold',
		textAlign: 'center',
		padding: 2,
		// width: '90%',
		fontSize: '1.1em',
	},
};

interface Props {
	rhyme: string;
	bottomMsg?: any;
}

export const GoldenRhyme = (props: Props) => {
	let { rhyme, bottomMsg } = props;

	return (
		<Paper elevation={3}>
			<Box sx={classes.root}>
				<TextContent alignRhymes={true} locale='hi' useFontSize={false}>
					<Box sx={classes.rhyme} dangerouslySetInnerHTML={{ __html: rhyme }} />
				</TextContent>
				{bottomMsg}
			</Box>
		</Paper>
	);
};
