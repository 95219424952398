import ArticleList from 'components/articles/ArticleList';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { withRouter } from 'components/shell/WithRouter';
import { ArticleTypeMap } from 'config';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HomePage from './HomePage';
import Page from './Page';

function MediaPage(props) {
	let { routeState, isModal } = props;
	let { href } = useParams();

	useEffect(() => {
		if (isModal) {
			MediaPlayer.instance().playAllAudio(href!, routeState.type);
		}
	}, [href, isModal, routeState]);

	return (
		<React.Fragment key={'media-player-' + href}>
			{!isModal ? (
				<Page
					title={ArticleTypeMap[routeState.type]}
					includeBottomNavigation
					fullscreen
					dark
					onLoad={() => {
						MediaPlayer.instance().playAudio(href!, routeState.type, 0);
					}}
				>
					{routeState.type === 'dhyan' ? <HomePage /> : <ArticleList recordType={routeState.type} />}
				</Page>
			) : (
				<span></span>
			)}
		</React.Fragment>
	);
}

export default withRouter(MediaPage);
