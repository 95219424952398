import { FireStoreModule } from 'fb';
import 'firebase/firestore';
import { collection, Firestore } from 'firebase/firestore';
import { updateBooklet } from 'store/data/booklets/actions';

export class BookletsFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'booklets';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'booklets');
	public getChangeProcessAction = (changes) => updateBooklet(changes);
}
