import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, IconButton, SvgIcon, Typography } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { MobileDatePicker } from '@mui/x-date-pickers';
import App from 'AppInterface/App';
import clsx from 'clsx';
import { BottomDrawerOption, BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { logEvent } from 'components/telemetry';
import { LangMap } from 'config';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import { getContentStorageUrl, isWebViewEnv } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { Context } from 'helpers/context';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleRecordPin } from 'services/api/auth';
import { addToCartThunk } from 'services/api/orders';
import { removeFromCart, updateCartItem } from 'store/data/cart/actions';
import { getEditionsByPubId } from 'store/data/publications/selectors';
import { EventType, onEvent, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, CartItem, ContentType, Edition, PinnedRecord, Publication, UserData } from 'types';
import { OrderItemType } from 'types/orders';

const classes = {
	root: {
		// margin: theme.spacing(1),
	},
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			// fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	content: {
		flexDirection: 'row',
		display: 'flex',
		position: 'relative',
	},
	media: (theme) => ({
		width: 'auto',
		height: 200,
		WebkitBoxReflect:
			'below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, transparent), to(rgba(255, 255, 255, 0.5)))',
		marginBottom: '1.5em',
		objectFit: 'contain',

		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			background: theme.palette.grey[100],
			top: 2,
			left: 2,
			height: '80%',
			width: 'calc(35% - 16px)',
			WebkitBoxReflect:
				'below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, transparent), to(rgba(255, 255, 255, 0.5)))',
		},
	}),
	desc: {
		paddingLeft: 2,
		height: 200,
		width: '65%',
		marginLeft: 'auto',
		marginBottom: '1.5em',
		color: 'text.secondary',
	},
	actions: {
		backgroundColor: 'primary.main',
		justifyContent: 'center',
		color: 'white',
		borderColor: 'white',

		'& > a': {
			color: 'white',
			borderColor: 'white',
			padding: '8px 16px',
			border: '1px solid white',
			borderRadius: 4,
			fontSize: '1.6em',
			textTransform: 'uppercase',
		},
		'& > button': {
			color: 'white',
			borderColor: 'white',
		},
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
};

interface TileProps {
	record: Publication;
	editions: Edition[];
	curEditionId?: string;
	toggleDrawer: (content?: any) => void;
	showMessage: (...payload) => void;
	addToCart: (pubId: string) => void;
	showOnlyPubDetails?: boolean;
	concise?: boolean;
	userData?: UserData;
	user?: User;
	cartItem: CartItem;
	pinnedRecords: { [id: string]: PinnedRecord };
	updateCartItem: (pubId: string, quantity: number) => void;
	removeFromCart: (pubId: string) => void;
	toggleRecordPin: (pubId: string, edId: string) => void;
}

const PublicationTile = (props: TileProps) => {
	let navigate = useNavigate();

	let { record: publication, editions, curEditionId, toggleDrawer, showOnlyPubDetails: basic, pinnedRecords, toggleRecordPin, concise } = props;

	let latestEdition = editions.length
		? editions.reduce((max, edition) => {
				return max.publishTime.seconds > edition.publishTime.seconds ? max : edition;
		  })
		: null;

	let [currentEdition, setCurrentEdition] = React.useState(curEditionId ? editions.filter((ed) => ed.id === curEditionId)[0] : latestEdition);

	if (!publication) {
		return null;
	}

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let minDate = editions.reduce((minDate, edition) => {
			if (edition.publishTime.seconds * 1000 < minDate.getTime()) {
				return new AnandDate(edition.publishTime).getDateObj();
			} else {
				return minDate;
			}
		}, new Date('2100-01-01'));

		let maxDate = editions.reduce((maxDate, edition) => {
			if (edition.publishTime.seconds * 1000 > maxDate.getTime()) {
				return new AnandDate(edition.publishTime).getDateObj();
			} else {
				return maxDate;
			}
		}, new Date('2000-01-01'));

		let curPublishDate = new AnandDate(currentEdition?.publishTime).getDateObj();

		let optionsList: BottomDrawerOption[] = [
			{
				title: pinnedRecords[currentEdition!.id] ? 'Unpin from Home' : 'Pin to Home',
				icon: pinnedRecords[currentEdition!.id] ? Icons.BookmarkRemove : Icons.BookmarkAdd,
				onClick: () => {
					toggleRecordPin(publication.id, currentEdition!.id);
					toggleDrawer();
				},
			},
		];

		if (!(publication.frequency === 0 || basic)) {
			optionsList = [
				{
					element: (
						<MobileDatePicker
							views={['year', 'month']}
							openTo="year"
							minDate={moment(minDate)}
							maxDate={moment(maxDate)}
							value={moment(curPublishDate)}
							label="Select Previous Edition"
							onAccept={(date: Moment | null) => {
								if (date) {
									let edition = editions.filter((edition) => {
										let pubDate = new AnandDate(edition?.publishTime).getDateObj();

										return pubDate.getFullYear() === date.year() && pubDate.getMonth() === date.month();
									});
									if (edition && edition.length) {
										setCurrentEdition(edition[0]);
										toggleDrawer();
									} else {
										props.showMessage(new Context(), EventType.Information, 'edition', {
											success: false,
											message: `Edition for this month is not available!`,
										});
									}
								}
							}}
						/>
					),
					icon: (
						<SvgIcon>
							<path
								fill="currentColor"
								d="M4,21H19A2,2 0 0,0 21,19V13H17V15L13,12L17,9V11H21V5A2,2 0 0,0 19,3H4A2,2 0 0,0 2,5V19A2,2 0 0,0 4,21M4,15H8V17H4V15M4,11H11V13H4V11M4,7H11V9H4V7M21,11H24V13H21V11Z"
							/>
						</SvgIcon>
					),
				},
				...optionsList,
			];
		}

		let options = BottomDrawerOptions({
			options: optionsList,
			actionHandler: () => {
				toggleDrawer();
			},
		});
		toggleDrawer(options);
	};

	const onDownloadFile = () => {
		let url: string = getContentStorageUrl(ContentType.Edition, 'file', currentEdition);

		let filename = publication.title + (publication.frequency > 0 ? ' - ' + new AnandDate(currentEdition?.publishTime).format('DD MMM YYYY') : '');
		let extension = url.substring(url.lastIndexOf('.'));

		logEvent('downloadPublication', {
			pubId: publication.id,
			edId: currentEdition?.id,
			title: publication.title,
			filename: filename + extension,
			url: url,
		});
		App.downloadFile(url, filename + extension);
	};

	if (concise) {
		return (
			<>
				<Card elevation={2} sx={classes.root} style={{ position: 'relative' }}>
					<CardActionArea
						onClick={() => {
							if (currentEdition) {
								if (isWebViewEnv()) {
									onDownloadFile();
								} else {
									navigate('/publication/' + currentEdition.id, {
										state: {
											isModal: true,
										},
									});
								}
							}
						}}
					>
						<CardMedia
							sx={{ ...classes.media, width: '100%', height: 'auto' }}
							component="img"
							image={getContentStorageUrl(ContentType.Edition, 'cover', currentEdition)}
							onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
								event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
							}}
						/>
					</CardActionArea>
					<IconButton sx={{ position: 'absolute', top: 0, right: 0, borderRadius: 0, background: '#ffffff77' }} onClick={onMoreIconClick}>
						{Icons.MoreVert}
					</IconButton>
				</Card>
			</>
		);
	}

	return (
		<>
			<Card elevation={2} sx={classes.root}>
				<CardHeader
					sx={classes.header}
					avatar={<Avatar sx={classes.avatar}>{Icons.Publication}</Avatar>}
					action={<IconButton onClick={onMoreIconClick}>{Icons.MoreVert}</IconButton>}
					title={
						<Typography noWrap className={publication.lang === 'hi' ? 'locale-hi' : ''}>
							{publication.title}
						</Typography>
					}
					subheader={
						basic
							? null
							: publication.frequency === 0
							? LangMap[publication.lang]
							: currentEdition
							? new AnandDate(currentEdition.publishTime).format('MMM, YYYY')
							: 'No editions found'
					}
				/>
				<CardContent sx={classes.content}>
					<CardMedia
						sx={classes.media}
						component="img"
						image={getContentStorageUrl(ContentType.Edition, 'cover', currentEdition)} //{process.env.REACT_APP_CDN_HOST + 'img/ebook_thumbs/' + latestEdition?.coverpageUri}
						onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
							event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
							// event.currentTarget.style.height = '0px'; // = process.env.REACT_APP_CDN_HOST + 'img/ebook_thumbs/null.jpg';
						}}
					/>

					<Typography
						variant="body1"
						sx={classes.desc}
						className={clsx({ 'locale-hi': publication.lang === 'hi' })}
						dangerouslySetInnerHTML={{ __html: publication.desc }}
					></Typography>
				</CardContent>
				{basic ? null : (
					<CardActions sx={classes.actions}>
						<Button
							variant="outlined"
							color="inherit"
							// size='small'
							disabled={!currentEdition?.fileUri}
							onClick={onDownloadFile}
						>
							Download
						</Button>
						<Button
							variant="outlined"
							color="inherit"
							// size='small'
							disabled={!currentEdition?.fileUri}
							onClick={() => {
								if (currentEdition) {
									navigate('/publication/' + currentEdition.id, {
										state: {
											isModal: true,
										},
									});
								}
							}}
						>
							Read
						</Button>
						{/* {(isAdmin(user, userData) || userData?.email === 'digital.arya@gmail.com') &&
						publication.sellHardcopy ? (
							!cartItem ? (
								<Button
									variant='outlined'
									// size='small'
									// disabled={!currentEdition?.fileUri}
									onClick={() => {
										if (publication.frequency === 0) {
											addToCart(publication.id);
											props.showMessage(new Context(), EventType.Action, 'cart', {
												action: {
													label: 'View Cart',
													color: 'white',
													callback: () => {
														navigate('/cart', {state: {
															isModal: true,
														}});
													},
												},
												variant: 'success',
												message: `Book added to the Cart. You may continue to add more books to place a combined order.`,
											});
										} else {
											navigate('/subscribe/' + publication.id, {state: {
												isModal: true,
											}});
										}
									}}
								>
									{publication.frequency === 0 ? 'Purchase' : 'Subscribe'} Hard Copy: ₹{' '}
									{(publication.price ?? 0).toFixed(2)}
								</Button>
							) : (
								<NumberInput
									white
									min={1}
									allowDelete
									number={cartItem.quantity}
									setNumber={(number) => {
										if (number === 0) {
											removeFromCart(cartItem.itemId);
										} else {
											updateCartItem(cartItem.itemId, number);
										}
									}}
								/>
							)
						) : null} */}
					</CardActions>
				)}
			</Card>
		</>
	);
};

function mapStateToProps(state: ApplicationState, { record }) {
	let editions = getEditionsByPubId(state) || {};
	let pubEditions = editions[record?.id] || [];
	let pinnedRecords = state.userState.userStore.userData?.pinnedRecords ?? {};

	return {
		pinnedRecords,
		editions: pubEditions,
		userData: state.userState.userStore.userData,
		user: state.userState.userStore.user,
		cartItem: state.dataState.cart.items[record?.id] || undefined,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		addToCart: (pubId: string) => {
			dispatch(addToCartThunk({ itemId: pubId, itemType: OrderItemType.Publication, quantity: 1 }));
		},
		updateCartItem: (pubId: string, quantity: number) => {
			dispatch(updateCartItem({ itemId: pubId, itemType: OrderItemType.Publication, quantity }));
		},
		removeFromCart: (pubId: string) => {
			dispatch(removeFromCart(pubId));
		},
		toggleRecordPin: (pubId: string, edId: string) => {
			dispatch(toggleRecordPin(edId, 'editions'));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicationTile);
