import FinishEmailLinkSignUp from 'components/profile/FinishEmailLinkSignUp';
import React from 'react';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function EmailLinkSignupPage(props) {
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<DialogPage title='Finish SignUp' transition={true} maxWidth='xs' goBackOnClose={!!isModal} goBackTo='/'>
				<FinishEmailLinkSignUp />
			</DialogPage>
		</>
	);
}
