import { PlayedMedia } from 'types';
import { UpdateAllPlayedMedia, UpdatePlayedMedia, UPDATE_ALL_PLAYED_MEDIA, UPDATE_PLAYED_MEDIA } from './actions';

export function playedMediaReducer(curState: PlayedMedia = {}, action: UpdatePlayedMedia | UpdateAllPlayedMedia) {
	switch (action.type) {
		case UPDATE_PLAYED_MEDIA:
			if (!action.force && action.time - (curState[action.articleId]?.time ?? 0) <= 10) {
				return curState;
			}

			let time = action.force ? action.time : Math.max(curState[action.articleId]?.time ?? 0, action.time);
			if (time === curState[action.articleId]?.time) {
				return curState;
			}

			var state = { ...curState };

			state[action.articleId] = {
				time: time,
				timestamp: new Date().getTime(),
			};

			return state;
		case UPDATE_ALL_PLAYED_MEDIA:
			return action.playedMedia;
		default:
			return curState;
	}
}
