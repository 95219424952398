import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Sandesh } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_SANDESH = '@@sandesh/UPDATE_SANDESH';
export type T_UPDATE_SANDESH = typeof UPDATE_SANDESH;

export interface UpdateSandesh extends Action {
	type: T_UPDATE_SANDESH;
	changes: FBChanges;
}

export function updateSandesh(changes: FBChanges): UpdateSandesh {
	return {
		type: UPDATE_SANDESH,
		changes,
	};
}

export const UPDATE_SANDESH_FILTERED = UPDATE_SANDESH + '_FILTERED';
export type T_UPDATE_SANDESH_FILTERED = typeof UPDATE_SANDESH_FILTERED;

export interface UpdateSandeshFiltered extends Action {
	type: T_UPDATE_SANDESH_FILTERED;
	payload: Sandesh[];
}

export const MARK_SANDESH_SHARED = '@@sandesh/MARK_SANDESH_SHARED';
export type T_MARK_SANDESH_SHARED = typeof MARK_SANDESH_SHARED;

export interface MarkSandeshShared extends Action {
	type: T_MARK_SANDESH_SHARED;
	id: string;
}

export function markSandeshShared(id: string): MarkSandeshShared {
	return {
		type: MARK_SANDESH_SHARED,
		id,
	};
}
