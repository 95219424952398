import { Box, Theme } from '@mui/material';
import { Image } from 'components/Image';
import { OrgLogoSrc } from 'config';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import { BannerRhymeCtrl } from './BannerRhyme';
import { SSDNCtrl } from './SSDNCtrl';

const brandBoxClasses = {
	brandBox: (theme: Theme) => ({
		maxWidth: '60em',
		margin: '0 auto',
		[theme.breakpoints.up('xl')]: {
			marginTop: '-20em',
			marginBottom: '-20em',
		},
		[theme.breakpoints.only('lg')]: {
			marginTop: '-18em',
			marginBottom: '-18em',
		},
		[theme.breakpoints.only('md')]: {
			marginTop: '-15em',
			marginBottom: '-15em',
		},
		[theme.breakpoints.only('sm')]: {
			marginTop: '-15em',
			marginBottom: '-15em',
			width: '90%',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10em',
			marginBottom: '-10em',
			width: '80%',
		},
	}),
	glowImg: {
		display: 'block',
		width: '100%',
		animationName: 'sphamadjust-hue',
		animationDuration: '40000ms',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear',
	},
	brandImg: {
		display: 'block',
		width: '100%',
		transform: 'rotate(0)',
		marginTop: '-100%',
	},
};

class BrandBoxComponent extends React.Component<{ glowImgSrc: string; brandImgSrc: string }> {
	render() {
		return (
			<Box sx={brandBoxClasses.brandBox}>
				<Image sx={brandBoxClasses.glowImg} src={this.props.glowImgSrc} />
				<Image sx={brandBoxClasses.brandImg} src={this.props.brandImgSrc} />
			</Box>
		);
	}
}

const BrandBox = BrandBoxComponent;

const classes = {
	background: (theme: Theme) => ({
		// position: 'initial !important' as 'initial',
		backgroundImage: `url(${getWebAppAssetPath('bg/new-banner-double.jpg')})`,
		backgroundSize: '100% 100%',
		overflow: 'hidden',
		marginTop: '-4px',
		[theme.breakpoints.up('xl')]: {
			fontSize: 17,
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: 15,
		},
		[theme.breakpoints.only('md')]: {
			fontSize: 14,
		},
		[theme.breakpoints.down('md')]: {
			fontSize: 13,
		},
	}),
};

class Component extends React.Component<{ classes?: any }> {
	render() {
		return (
			<Box sx={classes.background}>
				<SSDNCtrl />
				<BrandBox brandImgSrc={getWebAppAssetPath(OrgLogoSrc)} glowImgSrc={getWebAppAssetPath('glow.png')} />
				<BannerRhymeCtrl />
			</Box>
		);
	}
}

export const Banner = Component;
