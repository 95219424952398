import { grey } from '@mui/material/colors';
import { createTheme, darken, Theme } from '@mui/material/styles';
import { PrimaryMainColor } from 'config';
import { getWebAppAssetPath, isFakeWebViewEnv } from 'helpers';
import './fonts.css';

export const PrimaryMainColorDark = darken(PrimaryMainColor, 0.2);

let theme = createTheme({
	palette: {
		primary: {
			// main: '#32b78d',
			main: PrimaryMainColor,
			contrastText: '#ffffff',
		},
	},
	typography: {
		htmlFontSize: 10,
		fontFamily: 'Roboto',
		body1: {
			fontSize: '1.9rem',
		},
		body2: {
			letterSpacing: 0,
			fontSize: '1.7rem',
		},
	},
});

let htmlBody = {
	margin: 0,
	fontFamily: 'Roboto',
	letterSpacing: '0.05em',
	wordSpacing: 0,
	WebkitFontSmoothing: 'antialiased',
	MozOsxFontSmoothing: 'grayscale',

	position: 'relative',
	height: '100vh',
	fontSize: '10px',
};

let commonTheme = createTheme(theme, {
	typography: {
		body1: {
			fontSize: '1.9rem',

			[theme.breakpoints.down(380)]: {
				fontSize: '1.7rem',
			},

			[theme.breakpoints.down(340)]: {
				fontSize: '1.5rem',
			},
		},
		body2: {
			letterSpacing: 0,
			fontSize: '1.7rem',

			[theme.breakpoints.down(380)]: {
				fontSize: '1.5rem',
			},

			[theme.breakpoints.down(340)]: {
				fontSize: '1.3rem',
			},
		},
		subtitle1: {
			letterSpacing: 0,
			fontSize: '1.8rem',

			[theme.breakpoints.down(380)]: {
				fontSize: '1.6rem',
			},

			[theme.breakpoints.down(340)]: {
				fontSize: '1.4rem',
			},
		},

		[theme.breakpoints.down(340)]: {
			htmlFontSize: 9,
		},
	},
	components: {
		MuiBackdrop: {
			root: {
				top: isFakeWebViewEnv() ? 56 : 0,
			},
		},
		MuiPaper: {
			root: {
				color: 'rgba(0, 0, 0, 0.65)',
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '1.6rem',
				},
			},
		},
		MuiIconButton: {
			root: {
				'&:hover': {
					[theme.breakpoints.down('sm')]: {
						backgroundColor: 'inherit',
					},
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: (theme: Theme) => ({
				html: {
					...htmlBody,

					[theme.breakpoints.down(340)]: {
						fontSize: 9,
					},
					[theme.breakpoints.down(315)]: {
						fontSize: 8,
					},
				},

				body: {
					// ...htmlBody,

					color: 'rgba(0, 0, 0, 0.75)',
					backgroundColor: grey[200],
				},

				'heading-one, heading-two, heading-three': {
					display: 'block',
					color: theme.palette.primary.main,
					fontSize: '1.1em',
					fontWeight: 'bold',
					textAlign: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					marginBottom: '1.5em',
					marginTop: '1em',
				},

				'heading-one': {
					backgroundImage: `url(${getWebAppAssetPath('bg/dark/heading-bg.png')})`,
					padding: '2em 0',

					[theme.breakpoints.down('sm')]: {
						fontSize: '5.2vw',
						padding: '1.5em 0',
						marginBottom: '1em',
						marginTop: '1em',
					},
				},

				'heading-two': {
					backgroundImage: `url(${getWebAppAssetPath('bg/dark/the-spiritual-master.png')})`,
					padding: '4em 0 0.45em 0',

					[theme.breakpoints.down('sm')]: {
						fontSize: '5.2vw',
						paddingBottom: '1.15em',
						marginBottom: 0,
						marginTop: 0,
					},
				},

				'heading-three': {
					backgroundImage: `url(${getWebAppAssetPath('bg/dark/holy-teachings.png')})`,
					padding: '5em 0 0.6em 0',

					[theme.breakpoints.down('sm')]: {
						fontSize: '5vw',
						padding: '3.5em 0 0.2em 0',
						marginBottom: '1em',
						marginTop: 0,
					},
				},

				'.end-content': {
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',

					width: '20vw',
					height: '3.5vw',
					maxWidth: 350,
					maxHeight: 80,
					margin: '0 auto',
					backgroundImage: `url(${getWebAppAssetPath('bg/dark/end-content.png')})`,
				},

				'.pp-hide-right': {
					marginLeft: '-0.12em',
				},

				'.locale-hi': {
					'& .pp-hide-right': {
						marginLeft: '-0.16em',
					},
				},

				'.locale-en': {
					'& heading-one, & heading-two, & heading-three': {
						textTransform: 'uppercase',
						// letterSpacing: 0,
						whiteSpace: 'nowrap',
						fontSize: '1em',
					},

					'& heading-one': {
						[theme.breakpoints.down('sm')]: {
							fontSize: '5vw',
							padding: '1.8em 0',
						},
					},

					'& haeding-two': {
						[theme.breakpoints.down('sm')]: {
							fontSize: '5vw',
							paddingBottom: '0.9em',
						},
					},

					'& .pp-hide-right': {
						marginLeft: '-0.12em',
					},
				},
			}),
		},
	},
});

export const enUsTheme = commonTheme;
export const hiTheme = commonTheme;
