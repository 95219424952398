import { RhymeList } from 'components/rhymes/RhymeList';
import { IsMinimalRhymeSite } from 'config';
import React from 'react';
import Page from './Page';

export default function RhymeListPage() {
	return (
		<Page title={IsMinimalRhymeSite ? 'Rhymes List' : 'Scroll of Wisdom'} includeBottomNavigation fullscreen dark>
			<RhymeList />
		</Page>
	);
}
