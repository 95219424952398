import { RhymePageContent } from 'components/rhymes/RhymePageContent';
import { IsMinimalRhymeSite } from 'config';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import Page from './Page';

export default function RhymePage(props) {
	let { isModal } = props;
	let { id } = useParams();

	return isModal ? (
		<DialogPage disableBackdropClick title={IsMinimalRhymeSite ? 'Rhyme' : 'Scroll of Wisdom'} transition='left'>
			<RhymePageContent id={id!} />
		</DialogPage>
	) : (
		<Page title={IsMinimalRhymeSite ? 'Rhyme' : 'Scroll of Wisdom'} includeBottomNavigation>
			<RhymePageContent id={id!} />
		</Page>
	);
}
