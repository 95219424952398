import { FireStoreModule } from 'fb';
import { collection, Firestore, query, where } from 'firebase/firestore';
import { updateFBArticles } from 'store/data/articles/actions';

export class ArticlesFireStoreModule implements FireStoreModule {
	private forWeb: boolean;

	constructor(forWeb?: boolean) {
		this.forWeb = forWeb ?? false;
	}

	public getModuleName = () => 'articles';
	public getOpsKeySuffix = () => (this.forWeb ? 'forWeb' : 'global');
	public getFBWatchQuery = (db: Firestore) => {
		if (this.forWeb) {
			return query(collection(db, 'articles'), where('articleType', 'in', ['introduction', 'policies', 'shriamarvani']));
		}
		return collection(db, 'articles');
	};
	public getChangeProcessAction = (changes) => updateFBArticles(changes);
}
