import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import blueGrey from '@mui/material/colors/blueGrey';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { getArticleIconImg } from 'components/articles/ArticleOptions';
import { Bull } from 'components/Bull';
import { ArticleTypeMap, LangMap } from 'config';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Article, AudioCurrentState } from 'types';
import { AudioPlaybackInstance } from './AudioPlayback';

const classes = {
	root: (props) => ({
		display: props.enabled ? 'block' : 'none',
		backgroundColor: 'rgba(50, 183, 140, 0.25)',
	}),
	header: {
		px: 2,
		py: 1.5,

		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			// float: 'right'
		},

		'& .MuiCardHeader-content': {
			minWidth: 0,
		},

		'& .MuiCardHeader-avatar': {
			marginLeft: -2,
			marginTop: -2,
			marginBottom: -2,
		},
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	imgavatar: {
		width: 77,
		height: 77,
		borderRadius: 0,
		transform: 'scale(1)',

		'& img': {
			width: '100%',
		},
	},
	slider: {
		'&.MuiSlider-root': {
			padding: 0,
			height: 4,
		},

		'&.MuiSlider-root.Mui-disabled': {
			color: 'primary.main',
		},

		'& .MuiSlider-track': {
			height: 2,
			zIndex: 1,
		},

		'& .MuiSlider-thumb': {
			display: 'none',
		},

		'& .MuiSlider-rail': {
			height: 4,
		},
	},
};

// const MiniAudioSlider = styled(Slider)({
// 	root: {
// 		padding: 0,
// 		height: 4,
// 	},
// 	track: {
// 		height: 4,
// 	},
// 	rail: {
// 		height: 4,
// 	},
// 	thumb: {
// 		display: 'none',
// 	},
// });

interface MiniPlayerProps {
	enabled: boolean;
	currentTime?: number;
	duration: number;
	article?: Article;
	currentState?: AudioCurrentState;
	stopMusicPlayer: (e: React.MouseEvent) => void;
	playPauseAudio: (e: React.MouseEvent) => void;

	locale: string;
}

function miniPlayer(props: MiniPlayerProps) {
	let { currentTime = 0, duration, article, currentState, playPauseAudio, stopMusicPlayer, locale } = props;

	let AvatarIcon = getArticleIconImg(article);

	// if (article?.articleType === 'bhajan') {
	// 	AvatarIcon = Icons.Bhajan;
	// } else if (article?.articleType === 'satsang') {
	// 	AvatarIcon = Icons.Satsang;
	// } else {
	// 	AvatarIcon = Icons.Bhajan;
	// }

	let isReady =
		article && (currentState === AudioCurrentState.Playing || currentState === AudioCurrentState.Paused || currentState === AudioCurrentState.Ended);

	let localeClass = '';
	if (article?.title[locale]) {
		if (locale === 'hi') {
			localeClass = 'locale-hi';
		}
	} else if (article?.title.hi) {
		localeClass = 'locale-hi';
	}

	return (
		<Box component="div" sx={classes.root(props)}>
			{
				<Slider
					sx={classes.slider}
					disabled
					value={!isReady ? 0 : currentTime}
					onChange={(e, newValue) => {
						AudioPlaybackInstance().seekTo(newValue as number);
					}}
					min={0}
					max={!isReady ? 1 : duration}
				/>
			}
			<CardHeader
				sx={classes.header}
				avatar={
					!article ? <Skeleton animation="wave" variant="rectangular" width={40} height={40} /> : <Avatar sx={classes.imgavatar}>{AvatarIcon}</Avatar>
				}
				action={
					<>
						<IconButton onClick={playPauseAudio} disabled={!isReady}>
							{currentState === AudioCurrentState.Playing ? Icons.Pause : Icons.Play}
						</IconButton>
						<IconButton onClick={stopMusicPlayer}>{Icons.Close}</IconButton>
					</>
				}
				title={
					!article ? (
						<Skeleton animation="wave" height={12} width="80%" style={{ marginBottom: 10 }} />
					) : (
						<Typography noWrap className={localeClass}>
							{article?.title[locale] || article?.title.hi || article?.title.en}
						</Typography>
					)
				}
				subheader={
					!article ? (
						<Skeleton animation="wave" height={12} width="40%" style={{ marginBottom: 10 }} />
					) : (
						<span>
							{ArticleTypeMap[article!.articleType]}
							<Bull />
							{LangMap[article!.lang]}
						</span>
					)
				}
			></CardHeader>
		</Box>
	);
}

function mapStateToProps(state: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = state.tempState;
	let article = audioPlaybackDetails?.articleList[audioPlaybackDetails?.currentIndex];

	return {
		currentTime: audioPlaybackState.currentTime,
		duration: audioPlaybackState.duration || article?.mediaLength || 0,
		currentState: audioPlaybackState.currentState,
		article,
		locale: state.uxState.locale,
	};
}

export default connect(mapStateToProps)(miniPlayer);
