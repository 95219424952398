import { Box, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DBConfig, OrgName } from 'config';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	}),
};

const Component = (props) => {
	return (
		<Box sx={classes.root}>
			<UserPicInfo />

			<Card sx={classes.card}>
				<Typography>You can contact us at following postal address:</Typography>
				<Typography style={{ paddingTop: 24 }} variant='subtitle1'>
					<span dangerouslySetInnerHTML={{ __html: props.postalContact }} />
				</Typography>
			</Card>
		</Box>
	);
};

function mapStateToProps({ dataState }: ApplicationState) {
	return {
		postalContact: dataState.configs.byId[DBConfig.PostalContact]?.value ?? `<strong>${OrgName}</strong>`,
	};
}

export const PostalContact = connect(mapStateToProps)(Component);
