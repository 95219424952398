import { Action } from 'redux';
import { Article, DownloadProgress } from 'types';

export const ADD_OFFLINE_AUDIO = '@@offline/ADD_OFFLINE_AUDIO';
export type T_ADD_OFFLINE_AUDIO = typeof ADD_OFFLINE_AUDIO;

export interface AddOfflineAudio extends Action {
	type: T_ADD_OFFLINE_AUDIO;
	article: Article;
	forExport: boolean;
}

export const addOfflineAudio = (article: Article, forExport: boolean): AddOfflineAudio => {
	return {
		type: ADD_OFFLINE_AUDIO,
		article,
		forExport: forExport,
	};
};

export const REMOVE_OFFLINE_AUDIO = '@@offline/REMOVE_OFFLINE_AUDIO';
export type T_REMOVE_OFFLINE_AUDIO = typeof REMOVE_OFFLINE_AUDIO;

export interface RemoveOfflineAudio extends Action {
	type: T_REMOVE_OFFLINE_AUDIO;
	articleId: string;
	localOnly: boolean;
}

export const removeOfflineAudio = (articleId: string, localOnly: boolean): RemoveOfflineAudio => {
	return {
		type: REMOVE_OFFLINE_AUDIO,
		articleId,
		localOnly,
	};
};

export const UPDATE_DOWNLOAD_STATE = '@@offline/UPDATE_DOWNLOAD_STATE';
export type T_UPDATE_DOWNLOAD_STATE = typeof UPDATE_DOWNLOAD_STATE;

export interface UpdateDownloadState extends Action {
	type: T_UPDATE_DOWNLOAD_STATE;
	articleId: string;
	progress: DownloadProgress | null;
}

export const updateDownloadState = (articleId: string, progress: DownloadProgress | null): UpdateDownloadState => {
	return {
		type: UPDATE_DOWNLOAD_STATE,
		articleId,
		progress,
	};
};
