import { Card } from '@mui/material';
import TextContent from 'components/TextContent';
import { ToggleSelector } from 'components/ToggleSelector';
import { DBConfig } from 'config';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Article } from 'types';

interface Props {
	termsRecord: Article;
	ppRecord: Article;
}

const Component = (props: Props) => {
	const [type, setType] = React.useState<string>('Privacy');

	return (
		<>
			<ToggleSelector values={['Privacy', 'Terms']} type={type} setType={setType} />
			<Card
				style={{
					margin: 8,
					marginTop: 16,
					marginBottom: 32,
					padding: 16,
					display: 'flex',
					flexDirection: 'column' as 'column',
					backgroundColor: 'white',
				}}
			>
				<TextContent>
					<div
						style={{ fontSize: '0.9em' }}
						dangerouslySetInnerHTML={{
							__html: (type === 'Terms' ? props.termsRecord?.text?.en : props.ppRecord?.text?.en) || '',
						}}
					/>
				</TextContent>
			</Card>
		</>
	);
};

function mapStateToProps(state: ApplicationState) {
	let terms = state.dataState.configs.byId[DBConfig.Terms];
	let termsRecord = state.dataState.articles.byId[terms?.value];

	let pp = state.dataState.configs.byId[DBConfig.Privacy];
	let ppRecord = state.dataState.articles.byId[pp?.value];

	return {
		termsRecord,
		ppRecord,
	};
}

export default connect(mapStateToProps)(Component);
