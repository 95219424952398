import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';
import React from 'react';

const classes = {
	root: (theme: Theme) => ({
		width: '100%',
		position: 'fixed',
		top: '56px',
		left: 0,

		'& > * + *': {
			marginTop: 0,
		},

		[theme.breakpoints.up('sm')]: {
			top: 64,
		},
	}),
};

export default function LinearIndeterminate() {
	return (
		<Box sx={classes.root}>
			<LinearProgress />
		</Box>
	);
}
