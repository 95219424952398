import { applyFilters } from 'components/FilterBar';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { ApplicationState, Article, ArticlesByType } from 'types';

const getFilteredArticles = (state: ApplicationState) => state.filteredDataState.articles;

export const getArticlesByType = createSelector([getFilteredArticles], (articles) => {
	let result: ArticlesByType = {};
	for (let i = 0; i < articles.length; i++) {
		let article = articles[i];
		result[article.articleType] = result[article.articleType] || [];
		result[article.articleType].push(article);
	}

	return result;
});

const getArticlesById = (state: ApplicationState) => state.dataState.articles.byId;

export const getArticleTypes = createSelector([getArticlesById], (articlesById) => {
	let articles = Object.values(articlesById);
	let types =
		articles?.reduce((types, article) => {
			types[article.articleType] = true;
			return types;
		}, {}) || {};
	return Object.keys(types).sort();
});

export const getAllArticlesByType = createSelector([getArticlesById], (articlesById) => {
	let articles = Object.values(articlesById);
	let result: ArticlesByType = {};
	for (let i = 0; i < articles.length; i++) {
		let article = articles[i];
		result[article.articleType] = result[article.articleType] || [];
		result[article.articleType].push(article);
	}

	return result;
});

export const getArticleGroups = createSelector([getArticlesById], (articlesById) => {
	let articles = Object.values(articlesById);
	let groups =
		articles?.reduce((groups, article) => {
			groups[article.group] = true;
			return groups;
		}, {}) || {};
	return Object.keys(groups).sort();
});

export const getArticleAuthors = createSelector([getArticlesById], (articlesById) => {
	let articles = Object.values(articlesById);
	let authors =
		articles?.reduce((authors, article) => {
			if (article.author) {
				authors[article.author] = true;
			}
			return authors;
		}, {}) || {};
	return Object.keys(authors).sort();
});

export const getArticlePlaces = createSelector([getArticlesById], (articlesById) => {
	let articles = Object.values(articlesById);
	let places =
		articles?.reduce((places, article) => {
			if (article.place) {
				places[article.place] = true;
			}
			return places;
		}, {}) || {};
	return Object.keys(places).sort();
});

export const getArticlesOfType = createCachedSelector(
	[getArticlesByType, (state, articleType) => articleType],
	(articles, articleType) => {
		return articles[articleType];
	}
)((_state_, articleType) => articleType);

export const getArticleGroupsForType = createSelector([getArticlesOfType], (articles) => {
	let groups =
		articles?.reduce((groups, article) => {
			groups[article.group] = true;
			return groups;
		}, {}) || {};
	return Object.keys(groups).sort();
});

export const getArticleLangsForType = createSelector([getArticlesOfType], (articles) => {
	let langs =
		articles?.reduce((langs, article) => {
			langs[article.lang] = true;
			return langs;
		}, {}) || {};
	return Object.keys(langs).sort();
});

export const getRecordsWithPlaybackState = createSelector(
	[(state: ApplicationState) => state.offlineState.playedMedia, (state, records: Article[]) => records],
	(playedMedia, articles) => {
		for (let i = 0; i < articles.length; i++) {
			let article = articles[i];

			let playedTime = playedMedia[article.id]?.time ?? 0;
			if (playedTime <= 0) {
				article.__playbackState = 'Not Started';
			} else if (article.mediaLength - playedTime < 5) {
				article.__playbackState = 'Completed';
			} else {
				article.__playbackState = 'Partial';
			}
		}

		return articles;
		// articles.map((article) => {
		// 	let result = { ...article };
		// 	let playedTime = playedMedia[article.id] ?? 0;
		// 	if (playedTime <= 0) {
		// 		result.playbackState = 'Not Started';
		// 	} else if (article.mediaLength - playedTime < 5) {
		// 		result.playbackState = 'Completed';
		// 	} else {
		// 		result.playbackState = 'Partial';
		// 	}
		// 	return result;
		// });
	}
);

export const getArticlesFiltered = createSelector(
	[
		(state, records) => getRecordsWithPlaybackState(state, records),
		(state, records, filters) => filters,
		(state, records, filters, filtersMeta) => filtersMeta,
	],
	(records, filters, filtersMeta) => {
		return applyFilters(records, filters, filtersMeta);
	}
);

export const getArticlesFilteredSecond = createSelector(
	[
		(state, records) => getRecordsWithPlaybackState(state, records),
		(state, records, filters) => filters,
		(state, records, filters, filtersMeta) => filtersMeta,
	],
	(records, filters, filtersMeta) => {
		return applyFilters(records, filters, filtersMeta);
	}
);

// export const getArticleMediaTypesFilter = createCachedSelector([getArticlesOfType], (articles) => {
// 	let types = articles.reduce((types, article) => {
// 		types[article.mediaType] = true;
// 		return types;
// 	}, {});
// 	return Object.keys(types).sort();
// })((_state_, articleType) => articleType);

// export const getArticlesOfMediaType = createCachedSelector(
// 	[getArticlesOfType, (state, articleType, mediaType) => mediaType],
// 	(articles, mediaType) => {
// 		return articles.filter((article) => article.mediaType === mediaType);
// 	}
// )((_state_, articleType, mediaType) => articleType + mediaType);

// export const getArticleLanguagesFilter = createCachedSelector([getArticlesOfMediaType], (articles) => {
// 	let types = articles.reduce((types, article) => {
// 		types[article.lang] = true;
// 		return types;
// 	}, {});
// 	return Object.keys(types).sort();
// })((_state_, articleType, mediaType) => articleType + mediaType);
