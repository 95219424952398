import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

const classes = {
	title: {
		flexGrow: 1,
		fontSize: '1.75rem',
	},
};

interface AppBarTitleProps {
	title: string;
}

export function AppBarTitle(props: AppBarTitleProps) {
	return (
		<Typography sx={classes.title} variant='h6' noWrap>
			{props.title}
		</Typography>
	);
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { appBarTitle } = tempState;

	return {
		title: appBarTitle,
	};
}

export default connect(mapStateToProps)(AppBarTitle);
