import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Icons } from 'config/icons';
import { isAdmin, isWebEnv } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { createPlaylist, modifyPlaylistTracks } from 'services/api/playlists';
import { getUserPlaylists } from 'store/data/user/selectors';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Article, UserPlaylist, UserPlaylists } from 'types';
import PlaylistName from './PlaylistName';

interface PlayListsProps {
	playlists: UserPlaylists;
	article: Article;
	userIsAdmin: boolean;
	toggleDrawer: (content?: any) => void;
	createPlaylist: (title: string) => Promise<UserPlaylist>;
	modifyPlaylistTracks: (playlistId: string, articleId: string, action: 'Add' | 'Remove') => Promise<UserPlaylist>;
}

class AddToPlaylistsControl extends React.Component<PlayListsProps, any> {
	constructor(props: PlayListsProps) {
		super(props);

		let state = { newPlaylistOpen: false, newPlaylistName: '' };
		for (let key in props.playlists) {
			let isAdded = !!props.playlists[key].tracks[props.article.id];

			state['checked-' + key] = isAdded;
		}

		this.state = state;
	}

	private onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};

	render() {
		let { playlists, toggleDrawer, createPlaylist, article, modifyPlaylistTracks, userIsAdmin } = this.props;
		let playlistsArray = Object.values(playlists);

		return (
			<>
				{this.state.newPlaylistOpen ? (
					<PlaylistName
						open={this.state.newPlaylistOpen}
						actionText='CREATE'
						action={async (name: string) => {
							await createPlaylist(name);
						}}
					/>
				) : null}

				<ListItem>
					<ListItemText primary='Add to Playlist...' />
					<Button
						size='small'
						color='inherit'
						onClick={() => {
							this.setState({ newPlaylistOpen: true });
						}}
					>
						+ New Playlist
					</Button>
				</ListItem>
				<Divider />
				<FormGroup style={{ margin: '16px' }}>
					{playlistsArray.map((element) => {
						return element.id !== '0' || (isWebEnv() && userIsAdmin) ? (
							<FormControlLabel
								key={'playlist-' + element.id}
								control={
									<Checkbox
										color='primary'
										checked={this.state['checked-' + element.id] ?? false}
										onChange={this.onCheck}
										name={'checked-' + element.id}
									/>
								}
								label={element.title}
							/>
						) : null;
					})}
				</FormGroup>
				<Divider />

				<ListItem
					button
					onClick={() => {
						for (let key in playlists) {
							let wasAdded = !!playlists[key].tracks[article.id];
							let isAdded = this.state['checked-' + key];
							let modified = isAdded !== wasAdded;
							if (modified) {
								modifyPlaylistTracks(key, article.id, isAdded ? 'Add' : 'Remove');
							}
						}

						toggleDrawer();
					}}
				>
					<ListItemIcon>{Icons.Done}</ListItemIcon>
					<ListItemText primary='Done' />
				</ListItem>
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		playlists: getUserPlaylists(state),
		userIsAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		createPlaylist: async (title: string) => {
			return dispatch(createPlaylist({ title }));
		},
		modifyPlaylistTracks: async (playlistId: string, articleId: string, action: 'Add' | 'Remove') => {
			return dispatch(modifyPlaylistTracks({ playlistId, articleId, action }));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToPlaylistsControl);
