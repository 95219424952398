import { CircularProgress } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, DownloadState, DownloadStatus } from 'types';

const Component = (props: { downloadState?: DownloadState }) => {
	let { downloadState } = props;

	let isDownloaded =
		(downloadState?.exportProgress?.status !== DownloadStatus.InProgress &&
			downloadState?.exportProgress?.status !== DownloadStatus.Queued &&
			downloadState?.progress?.status === DownloadStatus.Complete) ??
		false;

	let isDownloadQueued: boolean = downloadState?.exportProgress?.status === DownloadStatus.Queued ?? false;
	isDownloadQueued = isDownloadQueued || (downloadState?.progress?.status === DownloadStatus.Queued ?? false);

	let isDownloadInProgress: boolean = downloadState?.exportProgress?.status === DownloadStatus.InProgress ?? false;
	isDownloadInProgress = isDownloadInProgress || (downloadState?.progress?.status === DownloadStatus.InProgress ?? false);

	let downloadProgressValue; // = downloadProgress?.value ?? 0;
	if (downloadState?.exportProgress?.status === DownloadStatus.InProgress) {
		downloadProgressValue = downloadState?.exportProgress?.value ?? 0;
	} else {
		downloadProgressValue = downloadState?.progress?.value ?? 0;
	}

	let downloadIndicator = <Icons.DownloadIcon style={{ color: blueGrey[300] }} fontSize="small" />;
	let downloadSuccessIndicator = <Icons.OfflinePinIcon color="primary" fontSize="small" />;

	return (
		<span style={{ marginRight: 8, height: 20 }}>
			{isDownloaded ? (
				downloadSuccessIndicator
			) : isDownloadQueued || isDownloadInProgress ? (
				<span style={{ position: 'relative' }}>
					{downloadState?.progress?.status === DownloadStatus.Complete ? downloadSuccessIndicator : downloadIndicator}
					<CircularProgress
						size={28}
						variant={isDownloadQueued ? 'indeterminate' : 'determinate'}
						value={downloadProgressValue}
						style={{ position: 'absolute', left: -4, top: -8 }}
					/>
				</span>
			) : null}
		</span>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let downloadState = props.record ? state.offlineState.audio[props.record.id] : undefined;

	return {
		downloadState,
	};
}

export const DownloadIndicator = connect(mapStateToProps)(Component);
