import { combineReducers } from 'redux';
import { ConfigsById, ConfigStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateConfigs, UPDATE_CONFIG } from './actions';

export const configsByIdReducer = (state: ConfigsById = {}, action: UpdateConfigs) => {
	switch (action.type) {
		case UPDATE_CONFIG:
			return updateData('Configs', state, action.changes);
		default:
			return state;
	}
};

export const configsReducer = combineReducers<ConfigStore>({
	byId: configsByIdReducer,
});
