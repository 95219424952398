import {
	Box,
	Button,
	ButtonGroup,
	CardActions,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Paper,
	Radio,
	Switch,
} from '@mui/material';
import Accordian from '@mui/material/Accordion';
import AccordianDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { Icons } from 'config/icons';
import AnandDate from 'helpers/AnandDate';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { toggleBottomDrawer, toggleOnDemandPage } from 'store/temp/actions';
import { updateFilters, updateSortOrder } from 'store/ux/actions';
import { ApplicationState } from 'types';
import DialogPage from './pages/DialogPage';
import { BottomDrawerOptions } from './shell/BottomDrawerOptions';
import { ToggleSelector } from './ToggleSelector';

const classes = {
	paper: {
		mb: 0.5,
	},
	button: {
		flex: '1 1 auto',
		borderRadius: 0,
		borderRight: '1px solid darkgrey',

		'&:last-of-type': {
			borderRight: 'none',
		},
	},
	sortoptions: {
		'& .MuiButtonBase-root': {
			paddingTop: 0.5,
			paddingBottom: 0.5,
		},
	},
	filterRoot: (theme) => ({
		// width: '100%',
		margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
	}),
	filterHeading: (theme) => ({
		fontSize: theme.typography.pxToRem(15),
		// fontWeight: theme.typography.fontWeightBold,
	}),
};

interface Props {
	sortBy: string[];
	filters: any;
	filtersMeta: any;
	records: any[];
	recordType: string;
	state: ApplicationState;

	toggleDrawer: (content?: any) => void;
	sortValue: string;
	onSortChange?: (sortValue: string) => void;
	updateSortOrder: (recordType: string, sortValue: string) => void;
	toggleOnDemandPage: (component?: any) => void;
	updateFilters: (recordType: string, filters: any) => void;
}

interface FilterProps {
	filters: any;
	state: ApplicationState;
	filtersMeta: any;
	records: any[];
	onClose: () => void;
	onApply: (filters: any) => void;
}

export const applyFilters = (records, filters, filtersMeta) => {
	return records.filter((record) => {
		let include = true;
		for (let field in filtersMeta) {
			let fieldInclude = true;
			if (filtersMeta[field].type === 'checkbox' || filtersMeta[field].type === 'custom') {
				let fieldFilterValues = filters[field] ?? filtersMeta[field].default ?? {};
				let fieldValue =
					filtersMeta[field].type === 'custom' ? filtersMeta[field].value(record) : record[field];

				let allFalse = true;
				for (let key in fieldFilterValues) {
					let value = fieldFilterValues[key];

					if (value) {
						allFalse = false;
						break;
					}
				}

				for (let key in fieldFilterValues) {
					let value = allFalse ? true : fieldFilterValues[key];

					if (
						(value &&
							// eslint-disable-next-line
							(fieldValue == key ||
								(key === 'undefined' && fieldValue === undefined) ||
								(key === 'null' && fieldValue === null) ||
								(key === 'true' && fieldValue === true) ||
								(key === 'false' && fieldValue === false))) ||
						fieldFilterValues[fieldValue] === undefined
					) {
						fieldInclude = true;
						break;
					} else if (typeof value !== 'number') {
						fieldInclude = false;
					}
				}
			} else if (filtersMeta[field].type === 'daterange') {
				let fieldFilterValues = filters[field];
				if (fieldFilterValues && !(fieldFilterValues.all ?? true)) {
					let from = fieldFilterValues.from;
					let to = fieldFilterValues.to;
					if (from && to) {
						let date = new AnandDate(record[field]);

						if (
							!(date.isSameOrAfterDate(new AnandDate(from)) && date.isSameOrBeforeDate(new AnandDate(to)))
						) {
							fieldInclude = false;
						}
					}
				}
			}

			include = include && fieldInclude;
			if (!include) {
				break;
			}
		}
		return include;
	});
};

class FilterPage extends React.Component<FilterProps, any> {
	constructor(props) {
		super(props);

		let minMaxDates = this.calculateMinMaxDates(props.filtersMeta, props.records);

		let filters = props.filters || {};
		for (let key in minMaxDates.minDates) {
			filters[key] = filters[key] || {};
			filters[key]['from'] =
				filters[key]['from'] ?? new AnandDate().setDateObj(minMaxDates.minDates[key]).format('YYYY-MM-DD');
			filters[key]['to'] =
				filters[key]['to'] ?? new AnandDate().setDateObj(minMaxDates.maxDates[key]).format('YYYY-MM-DD');
		}

		this.state = {
			filters: this.getApplicableFilters(props.records, filters),
			expandedPanel: Object.keys(props.filtersMeta)[0],
			minDates: minMaxDates.minDates,
			maxDates: minMaxDates.maxDates,
		};
	}

	private calculateMinMaxDates(filtersMeta, records) {
		let minDates = {};
		let maxDates = {};

		if (!records) {
			return { minDates, maxDates };
		}

		for (let field in filtersMeta) {
			let filter = filtersMeta[field];

			if (filter.type === 'daterange') {
				let minDateSeconds = records.reduce(
					(minDateSeconds, record) =>
						Math.min(record[field].seconds ?? new Date(record[field]).getTime() / 1000, minDateSeconds),
					Number.MAX_VALUE
				);
				minDates[field] = new Date(minDateSeconds * 1000);
				let maxDateSeconds = records.reduce(
					(minDateSeconds, record) =>
						Math.max(record[field].seconds ?? new Date(record[field]).getTime() / 1000, minDateSeconds),
					0
				);
				maxDates[field] = new Date(maxDateSeconds * 1000);
			}
		}
		return { minDates, maxDates };
	}

	private getApplicableFieldFilters = (records, filters, field) => {
		let fieldMeta = this.props.filtersMeta[field];
		let depends = fieldMeta?.dependsOn || [];
		let partialFilters = {};
		Object.keys(filters).forEach((key) => depends.indexOf(key) > -1 && (partialFilters[key] = filters[key]));
		partialFilters[field] = {};

		let filteredRecords = applyFilters(records, partialFilters, this.props.filtersMeta);

		let values =
			filteredRecords?.reduce((values, record) => {
				let fieldValue = fieldMeta?.type === 'custom' ? fieldMeta.value(record) : record[field];
				values[fieldValue] = true;
				return values;
			}, {}) ?? {};

		let sorted = {};
		Object.keys(values)
			.sort()
			.forEach(
				(value) =>
					(sorted[value] =
						(filters[field] && (filters[field][value] ? true : false)) ??
						(!fieldMeta.default || fieldMeta.default[value] === undefined
							? false
							: !!fieldMeta.default[value]))
			);

		for (let key in filters[field]) {
			if (sorted[key] === undefined) {
				sorted[key] = filters[field][key] ? 1 : 0;
			}
		}

		return sorted;
	};

	private getApplicableFilters = (records, filters) => {
		let result = {};
		for (let field in this.props.filtersMeta) {
			let type = this.props.filtersMeta[field].type;

			if (type === 'checkbox' || type === 'custom') {
				result[field] = this.getApplicableFieldFilters(records, filters, field);
			} else if (type === 'daterange') {
				result[field] = filters[field];
			}
		}
		return result;
	};

	private handleChange = (filterKey, itemKey, isToggle?, selectAll?) => () => {
		let filters = this.state.filters;
		if (isToggle === true) {
			let keyFilters = filters[filterKey];
			Object.keys(keyFilters).forEach((key) => (keyFilters[key] = selectAll || key === itemKey));

			filters = {
				...filters,
				[filterKey]: { ...keyFilters },
			};
		} else {
			filters = {
				...filters,
				[filterKey]: { ...filters[filterKey], [itemKey]: !filters[filterKey][itemKey] },
			};
		}

		this.setState({
			filters: this.getApplicableFilters(this.props.records, filters),
		});
	};

	private handleDateChange = (filterKey, itemKey) => (date: Moment | null) => {
		let filters = this.state.filters;
		filters = {
			...filters,
			[filterKey]: { ...filters[filterKey], [itemKey]: date ? date.format('YYYY-MM-DD') : null },
		};

		this.setState({
			filters: this.getApplicableFilters(this.props.records, filters),
		});
	};

	private handleDateAllChange = (filterKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let filters = this.state.filters;
		filters = {
			...filters,
			[filterKey]: { ...filters[filterKey], all: event.target.checked },
		};

		this.setState({
			filters: this.getApplicableFilters(this.props.records, filters),
		});
	};

	private handlePanelChange = (panel) => (event, isExpanded) => {
		this.setState({
			expandedPanel: isExpanded ? panel : false,
		});
	};

	private applyAll = (field, value) => (event) => {
		let filters = { ...this.state.filters };
		let fieldFilters = filters[field];

		for (let key in fieldFilters) {
			fieldFilters[key] = value;
		}

		this.setState({
			filters: this.getApplicableFilters(this.props.records, filters),
		});
	};

	private filterKeySortMap = {
		audio: 0,
		text: 1,
		hi: 2,
		pu: 3,
		si: 4,
		en: 5,
		'Not Started': 6,
		Partial: 7,
		Completed: 8,
	};

	render() {
		let { onClose, onApply, filtersMeta } = this.props;
		let { expandedPanel, filters } = this.state;

		let filterContent = (
			<DialogPage
				goBackOnClose={false}
				title='Filter'
				transition={true}
				onClose={onClose}
				dark
				actionButtons={
					<Button
						variant='contained'
						color='primary'
						startIcon={Icons.DoneAll}
						onClick={() => onApply(filters)}
					>
						Apply
					</Button>
				}
			>
				<Box sx={classes.filterRoot}>
					{Object.keys(filtersMeta).map((filterKey) => {
						let filterMeta = filtersMeta[filterKey];
						let filter = filters[filterKey];

						let filterKeys = Object.keys(filter);
						let isBool = filterMeta.renderType === 'switch';
						let isToggleBar = filterMeta.renderType === 'togglebar';

						if (isBool) {
							return (
								<div key={filterKey}>
									<FormControlLabel
										style={{}}
										control={
											<Switch
												checked={!!filter['true']}
												onChange={() => {
													this.handleChange(filterKey, 'true', true)();
												}}
												color='primary'
											/>
										}
										label={filterMeta.label}
									/>
								</div>
							);
						}

						if (isToggleBar) {
							let itemKeys = filterKeys
								.filter((key) => typeof filter[key] !== 'number')
								.sort((a, b) => {
									return this.filterKeySortMap[a] > this.filterKeySortMap[b] ? 1 : -1;
								});

							let selected = itemKeys.filter((itemKey) => !!filter[itemKey]);
							let labels = itemKeys.map((key) => filterMeta.optionLabel(key, this.props.state));

							if (selected.length === 0) {
								setTimeout(() => {
									this.handleChange(filterKey, 'all', true, true)();
								}, 100);
							}

							return (
								<div key={filterKey} style={{ paddingBottom: 16 }}>
									<Typography sx={classes.filterHeading} style={{ paddingLeft: 8 }}>
										{filterMeta.label}
									</Typography>
									<ToggleSelector
										values={['all', ...itemKeys]}
										labels={['All', ...labels]}
										type={selected.length === itemKeys.length ? 'all' : selected[0]}
										setType={(type) => {
											this.handleChange(filterKey, type, true, type === 'all')();
										}}
									/>
								</div>
							);
						}

						return (
							<Accordian
								expanded={expandedPanel === filterKey}
								// defaultExpanded={true}
								key={filterKey}
								onChange={this.handlePanelChange(filterKey)}
							>
								<AccordionSummary expandIcon={Icons.ExpandMore}>
									<Typography sx={classes.filterHeading}>{filterMeta.label}</Typography>
								</AccordionSummary>
								<AccordianDetails
									style={{
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: 8,
									}}
								>
									{filterMeta.type === 'checkbox' || filterMeta.type === 'custom' ? (
										<>
											<FormGroup>
												{Object.keys(filter).map((itemKey) => {
													let item = filter[itemKey];
													// if (typeof item === 'number') {
													// 	return null;
													// }
													return (
														<FormControlLabel
															key={itemKey}
															control={
																<Checkbox
																	checked={item ? true : false}
																	onChange={this.handleChange(filterKey, itemKey)}
																	color='primary'
																/>
															}
															label={filterMeta.optionLabel(itemKey, this.props.state)}
															disabled={typeof item === 'number'}
														/>
													);
												})}
											</FormGroup>
											<ButtonGroup variant='text' size='small' sx={{ marginLeft: 'auto' }}>
												<Button size='small' onClick={this.applyAll(filterKey, true)}>
													All
												</Button>
												<Button size='small' onClick={this.applyAll(filterKey, false)}>
													None
												</Button>
											</ButtonGroup>
										</>
									) : null}
									{filterMeta.type === 'daterange' ? (
										<>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<DatePicker
													sx={{ flex: 'auto', mx: 1 }}
													openTo='year'
													minDate={moment(this.state.minDates[filterKey])}
													label='From'
													maxDate={moment(this.state.maxDates[filterKey])}
													value={moment(filter?.from || this.state.minDates[filterKey])} //{this.state.date}
													format='Do MMM, YYYY'
													onAccept={this.handleDateChange(filterKey, 'from')}
													disabled={filter?.all ?? true}
												/>
												<DatePicker
													sx={{ flex: 'auto', mx: 1 }}
													minDate={moment(this.state.minDates[filterKey])}
													label='To'
													maxDate={moment(this.state.maxDates[filterKey])}
													value={moment(filter?.to || this.state.maxDates[filterKey])} //{this.state.date}
													format='Do MMM, YYYY'
													onAccept={this.handleDateChange(filterKey, 'to')}
													disabled={filter?.all ?? true}
												/>
											</div>
											<FormControlLabel
												sx={{ marginLeft: 'auto' }}
												control={
													<Switch
														checked={filter?.all ?? true}
														onChange={this.handleDateAllChange(filterKey)}
														color='primary'
													/>
												}
												label='All'
											/>
										</>
									) : null}
								</AccordianDetails>
							</Accordian>
						);
					})}
				</Box>
			</DialogPage>
		);

		return filterContent;
	}
}

class Component extends React.Component<Props, any> {
	private onFilterBtnClick = () => {
		let { toggleOnDemandPage, filters, updateFilters, recordType, records, filtersMeta } = this.props;

		let filterPage = (
			<FilterPage
				state={this.props.state}
				filters={filters || {}}
				filtersMeta={filtersMeta}
				records={records}
				onClose={() => {
					toggleOnDemandPage();
				}}
				onApply={(filters) => {
					updateFilters(recordType, filters);
					toggleOnDemandPage();
				}}
			/>
		);

		toggleOnDemandPage(filterPage);
	};

	private onSortBtnClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let optionsList = BottomDrawerOptions({
			drawerTitle: 'Sort By',
			sx: classes.sortoptions,
			options: this.props.sortBy.map((sortValue) => {
				return {
					title: sortValue,
					icon: <Radio color='primary' checked={this.props.sortValue === sortValue} />,
					onClick: async () => {
						this.props.toggleDrawer();
						this.props.updateSortOrder(this.props.recordType, sortValue);
					},
				};
			}),
			actionHandler: () => {
				this.props.toggleDrawer();
			},
		});

		this.props.toggleDrawer(optionsList);
	};

	render() {
		return (
			<Box>
				<Paper square sx={classes.paper}>
					<CardActions>
						{/* <Button
							className={classes.button}
							startIcon={<FilterListRoundedIcon />}
							// onClick={this.onSortBtnClick}
						>
							Hindi Audio
						</Button> */}
						<Button
							sx={classes.button}
							color='inherit'
							startIcon={Icons.Sort}
							onClick={this.onSortBtnClick}
						>
							Sort
						</Button>
						<Button
							sx={classes.button}
							color='inherit'
							onClick={this.onFilterBtnClick}
							startIcon={
								// <Badge variant='dot' color='secondary'>
								Icons.FilterList
								// </Badge>
							}
						>
							Filter
						</Button>
					</CardActions>
				</Paper>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState, props) {
	return {
		sortValue: state.uxState.sort[props.recordType] || props.sortValue,
		filters: state.uxState.filters[props.recordType] || {},
		state,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		updateSortOrder: (recordType: string, sortValue: string) => {
			dispatch(updateSortOrder(recordType, sortValue));
		},
		updateFilters: (recordType: string, filters: any) => {
			dispatch(updateFilters(recordType, filters));
		},
		toggleOnDemandPage: (component?: any) => {
			dispatch(toggleOnDemandPage(component));
		},
	};
}

export const FilterBar = connect(mapStateToProps, mapDispatchToProps)(Component);
