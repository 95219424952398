import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Booklet } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_BOOKLETS = '@@booklets/UPDATE_BOOKLETS';
export type T_UPDATE_BOOKLETS = typeof UPDATE_BOOKLETS;

export interface UpdateBooklets extends Action {
	type: T_UPDATE_BOOKLETS;
	changes: FBChanges;
	overwrite?: boolean;
}

export function updateBooklet(changes: FBChanges, overwrite?: boolean): UpdateBooklets {
	return {
		type: UPDATE_BOOKLETS,
		changes,
		overwrite,
	};
}

export const UPDATE_BOOKLETS_FILTERED = UPDATE_BOOKLETS + '_FILTERED';
export type T_UPDATE_BOOKLETS_FILTERED = typeof UPDATE_BOOKLETS_FILTERED;

export interface UpdateBookletsFiltered extends Action {
	type: T_UPDATE_BOOKLETS_FILTERED;
	payload: Booklet[];
}
