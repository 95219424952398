import { Box, Theme } from '@mui/material';
import { OrgName, OrgNameHi } from 'config';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

const classes = {
	root: (theme: Theme) => ({
		fontSize: '3.5vw',
		textAlign: 'center',
		padding: '0.4em 0px 0.8em',
		color: 'primary.main',
		marginTop: '-2.5em',
		fontWeight: 'bold',
		filter: 'drop-shadow(0.1em 0.1em 0.03em lightgrey)',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',

		'&::after, &::before': {
			content: '""',
			display: 'inline-block',
			backgroundImage: `url(${getWebAppAssetPath('icons/dark/main-flower.png')})`,
			width: '1.8em',
			height: '1.2em',
			backgroundSize: 'contain',
			margin: '0 0.7em',
			verticalAlign: 'middle',
		},

		'&::after': {
			transform: 'scaleX(-1)',
		},

		[theme.breakpoints.up('xl')]: {
			fontSize: '3vw',
			marginTop: '-2.8em',
			paddingBottom: '1em',
		},
		[theme.breakpoints.only('md')]: {
			marginTop: '-2.4em',
			paddingBottom: '0.8em',
			// fontSize: '4vw',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '-2.2em',
			paddingBottom: '0.5em',
		},
	}),

	title: {
		wordSpacing: 0,
		display: 'inline',
		fontSize: 'inherit',
		fontWeight: 'inherit',
	},
};

const Component = (props) => {
	return (
		<Box sx={classes.root}>
			<Box component="h1" sx={classes.title} className={'locale-' + props.locale}>
				{props.locale === 'hi' ? OrgNameHi : OrgName}
			</Box>
		</Box>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
	};
}

export const OrgTitle = connect(mapStateToProps)(Component);
