import { Card, CardHeader } from '@mui/material';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { ToggleSelector } from 'components/ToggleSelector';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { onEvent, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';
import { Order, OrderStatusValue, OrderType, SubscriptionStatus } from 'types/orders';
import OrderTile from './OrderTile';

interface Props extends ScreenManagerProps {
	orders: Order[];
	toggleDrawer: (content?: any) => void;
	isSubscription?: boolean;
}

interface State {
	type: string;
}

class OrderList extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {
			type: 'Active',
		};
	}

	render() {
		let { orders, isSubscription, ...smProps } = this.props;

		let orderList: Order[] = [];
		let activeList: any = [];

		orders
			.sort((b, a) => (a.purchaseTime ?? a.createdAt.seconds) - (b.purchaseTime ?? b.createdAt.seconds))
			.forEach((order) => {
				let isSame = isSubscription ? order.type === OrderType.Subscription : order.type === OrderType.OneTime;
				if (isSame) {
					orderList.push(order);
				} else {
					return;
				}

				let isActive =
					order.status.status === OrderStatusValue.Confirmed &&
					(!order.subscription || order.subscription.status === SubscriptionStatus.Active);
				if (this.state.type === 'Active') {
					if (order.subscription?.startDate) {
						let isExpired = new AnandDate()
							.setEpoch(order.subscription?.startDate)
							.add(order.subscription.period, 'year')
							.subtract(1, 'day')
							.isBeforeDate(new AnandDate());
						if (isExpired) {
							isActive = false;
						}
					}
				}

				if ((this.state.type === 'Active' && !isActive) || (this.state.type !== 'Active' && isActive)) {
					return;
				}

				activeList.push(
					<OrderTile key={order.id} isSubscription={isSubscription} orderId={order.id} {...smProps} />
				);
				// let orderItems = Object.values(order.items);
				// orderItems.forEach((item) => {
				// 	activeList.push(
				// 		isSubscription ? (
				// 			<SubscriptionItemTile
				// 				key={order.id + item.itemId}
				// 				orderId={order.id}
				// 				orderItemId={item.itemId}
				// 				withDetail={false}
				// 				{...smProps}
				// 			/>
				// 		) : (
				// 			<OrderItemTile
				// 				key={order.id + item.itemId}
				// 				orderId={order.id}
				// 				orderItemId={item.itemId}
				// 				withDetail={false}
				// 				{...smProps}
				// 			/>
				// 		)
				// 	);
				// });
			});
		if (!orderList.length) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader={
							(isSubscription
								? `You have not subscribed to hard copy of any publication.`
								: `You have not purchased hard copy of any publication.`) +
							' You may visit Publications page to place your order.'
						}
					/>
				</Card>
			);
		}
		return (
			<div>
				{this.state.type !== 'Active' || activeList.length !== orderList.length ? (
					<ToggleSelector
						type={this.state.type}
						setType={(type) => this.setState({ type })}
						values={['Active', 'InActive']}
						labels={[isSubscription ? 'Active' : 'Confirmed', 'Inactive']}
					/>
				) : null}
				{activeList}
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	let orders = state.filteredDataState.orders;

	return {
		orders,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
