import { Box, Theme } from '@mui/material';
import TextContent from 'components/TextContent';
import { MainRhyme } from 'config';
import React from 'react';

const classes = {
	rhymes: (theme: Theme) => ({
		[theme.breakpoints.up('xl')]: {
			'& > .locale-hi': {
				fontSize: '2.9vw',

				'& .rhyme-line-justified': {
					width: '55%',
				},
			},
		},
		[theme.breakpoints.only('lg')]: {
			'& > .locale-hi': {
				fontSize: '3.1vw',

				'& .rhyme-line-justified': {
					width: '60%',
				},
			},
		},
		[theme.breakpoints.only('md')]: {
			'& > .locale-hi': {
				fontSize: '3.6vw',

				'& .rhyme-line-justified': {
					width: '70%',
				},
			},
		},
		[theme.breakpoints.down('md')]: {
			'& > .locale-hi': {
				fontSize: '4.1vw',

				'& .rhyme-line-justified': {
					width: '80%',
				},
			},
		},

		color: 'white',
		textAlign: 'center' as 'center',
		padding: '2em 0em 0.5em 0',
		textShadow: '0.1em 0.1em 0.2em blue',
		lineHeight: 1,
		fontWeight: 'bold' as 'bold',

		'& .rhyme-line': {
			'&.rhyme-line-justified': {
				marginBottom: '-0.6em',
			},
		},

		'& .locale-hi': {
			color: 'white',
		},
	}),
};

export const BannerRhymeCtrl = (props) => {
	let { className, ...others } = props;
	return (
		<Box {...others} className={className} sx={classes.rhymes}>
			<TextContent locale='hi' alignRhymes={true} useFontSize={false}>
				<span dangerouslySetInnerHTML={{ __html: props.rhyme || MainRhyme }} />
			</TextContent>
		</Box>
	);
};
