import { Avatar, Button, Card, CardHeader, IconButton, Paper, SvgIcon, Typography } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import App from 'AppInterface/App';
import { Image } from 'components/Image';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { AppName, DBConfig, SHARE_ORIGIN } from 'config';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, ContentType } from 'types';

let CardRoot = styled(Card)(({ theme }) => ({
	margin: theme.spacing(1),
}));

let StyledAvatar = styled(Avatar)({
	color: '#fff',
	backgroundColor: blueGrey[300],
});

const styles = {
	media: {
		display: 'block',
		width: '100%',
		objectFit: 'contain' as 'contain',
		// '&::before': {
		// 	content: '""',
		// 	display: 'block',
		// 	position: 'absolute',
		// 	background: theme.palette.grey[100],
		// 	top: theme.spacing(2),
		// 	left: theme.spacing(2),
		// 	width: '100%',
		// },
	},
	header: {
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	ib: {
		position: 'absolute' as 'absolute',
		top: '90%',
		right: 8,
		color: 'white',
		background: 'rgb(0 0 0 / 40%)',
		padding: 8,
		boxShadow: '1px 1px 2px grey',
		borderRadius: 4,

		'&:hover': {
			backgroundColor: 'rgb(0 0 0 / 40%)',
		},

		'&.Mui-disabled': {
			backgroundColor: 'rgb(0 0 0 / 10%)',
		},
	},
	stepper: { maxWidth: 400, flexGrow: 1, background: 'white', margin: 'auto' },
};

function mapStateToProps(state: ApplicationState) {
	let festivals = {};
	let calendars = {};

	let configs = state.dataState.configs.byId; //getDataById(state, 'configs') || {};
	let allYears = configs[DBConfig.Calendars]?.value || [];

	allYears.forEach((year) => {
		festivals[year] = configs[DBConfig.Festivals + year]?.value;
		calendars[year] = configs[DBConfig.Calendar + year]?.value;
	});

	return {
		festivals,
		calendars,
		locale: state.uxState.locale,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface FestivalsProps extends PropsFromRedux {
	year?: number;
	month?: number;
	calType: string;
}

interface State {
	year: number;
	month: number;
}

class Component extends React.Component<FestivalsProps, State> {
	constructor(props: FestivalsProps) {
		super(props);

		this.state = {
			year: props.year ?? new Date().getFullYear(),
			month: props.month ?? new Date().getMonth() + 1,
		};
	}

	public download() {
		let { festival, calendar } = this.curCalendar();

		if (this.props.calType === 'Festivals') {
			App.downloadFile(
				getContentStorageUrl(ContentType.Calendar, festival.festival),
				`Festivals_${festival.festivalYear}.jpg`
			);
		} else {
			let calendarUri = calendar.fullCalendar['calendar'];
			App.downloadFile(
				getContentStorageUrl(ContentType.Calendar, calendarUri),
				`Calendar_${calendar.calendarYear}.pdf`
			);
		}
		this.props.toggleDrawer();
	}

	private optionsObj = (subpath) => {
		let curPublishDate = new Date(this.state.year, this.state.month - 1, 1);
		let allYears = Object.keys(this.props.calendars).sort();

		let minDate = new Date(parseInt(allYears[0]), 0, 1);
		let maxDate = new Date(parseInt(allYears[allYears.length - 1]), 11, 1);

		return {
			options: [
				{
					element: (
						<MobileDatePicker
							key={this.props.calType}
							views={this.props.calType === 'Festivals' ? ['year'] : ['year', 'month']}
							openTo={'year'}
							minDate={moment(minDate)}
							maxDate={moment(maxDate)}
							value={moment(curPublishDate)}
							label='Select Previous Calendar'
							onAccept={(date: Moment | null) => {
								if (date) {
									this.setState({
										year: date.year(),
										month: date.month() + 1,
									});
									this.props.toggleDrawer();
								}
							}}
						/>
					),
					icon: (
						<SvgIcon>
							<path
								fill='currentColor'
								d='M4,21H19A2,2 0 0,0 21,19V13H17V15L13,12L17,9V11H21V5A2,2 0 0,0 19,3H4A2,2 0 0,0 2,5V19A2,2 0 0,0 4,21M4,15H8V17H4V15M4,11H11V13H4V11M4,7H11V9H4V7M21,11H24V13H21V11Z'
							/>
						</SvgIcon>
					),
				},
				{
					title: 'Download',
					icon: Icons.CloudDownload,
					onClick: () => {
						this.download();
					},
				},
				{
					title: 'Share',
					icon: Icons.Share,
					onClick: () => {
						try {
							App.shareLink(
								'Calendar, ' + AppName + ': ',
								SHARE_ORIGIN + '/calendar/' + subpath,
								'Calendar',
								subpath
							);
						} catch (error) {
							(App as any).shareLink(
								'Calendar, ' + AppName + ': ',
								SHARE_ORIGIN + '/calendar/' + subpath
							);
						}
						this.props.toggleDrawer();
					},
				},
			],
			actionHandler: () => {
				this.props.toggleDrawer();
			},
		};
	};

	private optionsList = (subpath) => BottomDrawerOptions(this.optionsObj(subpath));

	private onMoreIconClick = (subpath) => (e: React.MouseEvent) => {
		e.stopPropagation();

		this.props.toggleDrawer(this.optionsList(subpath));
	};

	private curCalendar() {
		let { festivals, calendars, locale } = this.props;
		let { year, month } = this.state;
		let yearStr = year.toString();
		let monthStr = month.toString();

		let allYears = Object.keys(festivals).sort();
		let latestYear = allYears[allYears.length - 1];
		let festivalYear = festivals[yearStr] ? yearStr : latestYear;
		let curYearFestivals = festivals[festivalYear];
		let festival = curYearFestivals[locale] || curYearFestivals['hi'] || curYearFestivals['en'];

		let allCalYear = Object.keys(calendars).sort();
		let latestCalYear = allCalYear[allCalYear.length - 1];
		let calendarYear = calendars[yearStr] ? yearStr : latestCalYear;
		let curYearCalendar = calendars[calendarYear];
		let fullCalendar = curYearCalendar[locale] || curYearCalendar['hi'] || curYearCalendar['en'];
		let allCalMonths = Object.keys(fullCalendar).sort();
		let latestCalMonth = allCalMonths[allCalMonths.length - 1];
		let calMonth = fullCalendar[monthStr] ? monthStr : latestCalMonth;
		let calendar = fullCalendar[calMonth];

		return { festival: { festival, festivalYear }, calendar: { calendarYear, calMonth, calendar, fullCalendar } };
	}

	render() {
		let { calType: type, calendars } = this.props;
		let { month, year } = this.state;

		let allYears = Object.keys(calendars).sort();
		let latestYear = parseInt(allYears[allYears.length - 1]);
		let firstYear = parseInt(allYears[0]);

		let { festival, calendar } = this.curCalendar();
		let currentIndex = type === 'Festivals' ? year - firstYear : (year - firstYear) * 12 + month - 1;
		let steps = type === 'Festivals' ? allYears.length : allYears.length * 12;

		let stepper = (
			<Paper style={{ position: 'relative', display: 'flex', margin: '0 8px', padding: 8 }}>
				<Button
					size='small'
					style={{ justifyContent: 'flex-start', flexGrow: 1 }}
					onClick={async () => {
						if (type === 'Festivals') {
							this.setState({ year: this.state.year - 1 });
						} else {
							if (this.state.month === 1) {
								this.setState({ month: 12, year: this.state.year - 1 });
							} else {
								this.setState({ month: this.state.month - 1 });
							}
						}
					}}
					disabled={currentIndex <= 0}
				>
					{Icons.KeyLeftArrow}
					{type === 'Festivals'
						? year !== firstYear
							? this.state.year - 1 + ' '
							: ''
						: this.state.month == 1 && year === firstYear // eslint-disable-line eqeqeq
						? ''
						: ' ' +
						  new AnandDate(this.state.year + '-' + (this.state.month - 1) + '-01').format('MMM YYYY')}
				</Button>
				<Button
					size='small'
					style={{ justifyContent: 'flex-end', flexGrow: 1 }}
					onClick={async () => {
						if (type === 'Festivals') {
							this.setState({ year: this.state.year + 1 });
						} else {
							if (this.state.month === 12) {
								this.setState({ month: 1, year: this.state.year + 1 });
							} else {
								this.setState({ month: this.state.month + 1 });
							}
						}
					}}
					disabled={currentIndex >= steps - 1}
				>
					{type === 'Festivals'
						? year !== latestYear
							? this.state.year + 1 + ' '
							: ''
						: currentIndex >= steps - 1
						? ''
						: new AnandDate(this.state.year + '-' + (this.state.month + 1) + '-01').format('MMM YYYY') +
						  ' '}
					{Icons.KeyRightArrow}
				</Button>
			</Paper>
		);

		return (
			<>
				{stepper}
				{type === 'Festivals' ? (
					<CardRoot elevation={2}>
						<CardHeader
							sx={styles.header}
							avatar={<StyledAvatar>{Icons.Calendar}</StyledAvatar>}
							action={
								<IconButton onClick={this.onMoreIconClick('festivals/' + festival.festivalYear)}>
									{Icons.MoreVert}
								</IconButton>
							}
							title={<Typography noWrap>Festivals List</Typography>}
							subheader={festival.festivalYear}
						/>
						<div style={{ position: 'relative' }}>
							<Image
								sx={styles.media}
								allowZoom={true}
								src={getContentStorageUrl(ContentType.Calendar, festival.festival)}
								onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
									event.currentTarget.src = getContentStorageUrl(
										ContentType.Calendar,
										'no_internet.png'
									);
								}}
							/>
						</div>
					</CardRoot>
				) : null}
				{type === 'Calendar' ? (
					<CardRoot elevation={2}>
						<CardHeader
							sx={styles.header}
							avatar={<StyledAvatar>{Icons.DateRange}</StyledAvatar>}
							action={
								<IconButton
									onClick={this.onMoreIconClick(
										'calendar/' + calendar.calendarYear + '/' + calendar.calMonth
									)}
								>
									{Icons.MoreVert}
								</IconButton>
							}
							title={<Typography noWrap>Monthly Calendar</Typography>}
							subheader={new AnandDate(calendar.calendarYear + '-' + month + '-01').format('MMM, YYYY')}
						/>
						<div style={{ position: 'relative' }}>
							<Image
								sx={styles.media}
								allowZoom={true}
								src={getContentStorageUrl(ContentType.Calendar, calendar.calendar)}
								onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
									event.currentTarget.src = getContentStorageUrl(
										ContentType.Calendar,
										'no_internet.png'
									);
								}}
							/>
						</div>
					</CardRoot>
				) : null}
			</>
		);
	}
}

export const CalendarControl = connector(Component);
