import { Grid, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import App from 'AppInterface/App';
import { SSNTitle } from 'config';
import domtoimage from 'dom-to-image';
import { timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import numberToText from 'number2text';
import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { markSandeshShared } from 'store/data/sandesh/actions';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Sandesh, SandeshType } from 'types';

const classes = {
	card: (props) => (theme) => ({
		position: 'relative',
		// width: '100%',
		borderRadius: '12px',
		backgroundColor: '#fbfcfe',

		margin: props.detailed ? 0 : theme.spacing(1.5),

		'& .MuiCardContent-root': {
			paddingBottom: props.detailed ? theme.spacing(1.5) : 0,
		},

		'& .MuiListItem-root': {
			paddingLeft: 0,
			paddingRight: 1,
		},
	}),
	inline: {
		// display: 'inline',
		fontWeight: 700,
		color: 'white',
		fontSize: '2.2rem',
		textAlign: 'center',
	},
	chip: (theme: Theme) => ({
		float: 'right',
		padding: 0.5,
		marginRight: 1,

		'& .MuiChip-label': {
			overflow: 'inherit',
			textOverflow: 'inherit',
		},
	}),
};

interface SandeshProps {
	record: Sandesh;
	toggleDrawer: (content?: any) => void;
	markSandeshShared: (id: string) => void;
	forShare?: boolean;
}

const generateQR = async (text: string) => {
	try {
		let dataURL = await QRCode.toDataURL(text, { margin: 2 });
		console.log(dataURL);
		return dataURL;
	} catch (err) {
		console.error(err);
	}
};

const Component = (props: SandeshProps) => {
	const { record, toggleDrawer, markSandeshShared, forShare } = props;
	let [qrCode, setQRCode] = useState('');

	let card = record.card!;

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		(record as any).height = ref.current?.clientHeight;

		(async () => {
			let url = `https://www.shrianandpur.org/sandesh/?no=${card.card_no}&count=${card.people_count}`;
			let code = await generateQR(url);
			setQRCode(code);

			if (forShare) {
				let cardEl = ref.current as HTMLElement;
				if (cardEl) {
					await timeout(200);

					let dataUrl = await domtoimage.toJpeg(cardEl, { quality: 0.6 });

					let filename = 'Shri Anandpur Satsang';
					if (record?.type === SandeshType.SSN) {
						filename = `${SSNTitle} - ${card.card_no}`;
					}

					App.shareImageDataURI(filename, dataUrl, 'https://www.shrianandpur.org');

					markSandeshShared(record.id);
					toggleDrawer();
				}
			}
		})();
	}, []);

	let arrival = new AnandDate(card?.permission_date).format('dd, Do MMM, YYYY');
	let visitors = `${card.people_count} / ${numberToText(card.people_count)}`;

	return (
		<Card sx={classes.card(props)} ref={ref}>
			<Grid container spacing={2}>
				<Grid item xs={11}>
					<Grid container spacing={2} sx={(theme) => ({ p: 1, backgroundColor: theme.palette.primary.main })}>
						<Grid item xs={6} sx={{ borderRight: '1px solid white' }}>
							<Typography variant='body2' sx={classes.inline}>
								{card.card_no}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography component='div' sx={[classes.inline, { fontSize: '1.8rem' }]}>
								{visitors}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<img src={qrCode} width='100%' alt='' />
						</Grid>
						<Grid item xs={8} sx={{ mt: 1, pr: 0.5 }}>
							<Typography variant='h5' component='h2' sx={{ color: '#195b46' }}>
								{card.name}
							</Typography>

							<Typography sx={{ fontWeight: 700 }}>{arrival}</Typography>
						</Grid>
					</Grid>
					<Typography sx={{ backgroundColor: '#90ee903b', p: 1 }} variant='body2'>
						Note: Accommodation at <strong>Shri Sant Nagar</strong> is only for one night.
					</Typography>
				</Grid>
				<Grid item xs={1} sx={{ pl: '0 !important', pt: '72px !important', backgroundColor: 'wheat' }}>
					<Typography
						sx={{ transform: 'rotate(90deg)', whiteSpace: 'nowrap', fontWeight: 700 }}
						color='textPrimary'
					>
						Shri Aagya Patra
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		record: state.dataState.sandesh.byId[props.record.id],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean) => {
			dispatch(toggleBottomDrawer(content, open));
		},
		markSandeshShared: (id: string) => {
			dispatch(markSandeshShared(id));
		},
	};
}

export const SSNCardTile = connect(mapStateToProps, mapDispatchToProps)(Component);
