import { Box } from '@mui/material';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';

const classes = {
	border: {
		position: 'absolute',
		top: 0,
		display: 'flex',
		flexDirection: 'column',
		flexFlow: 'column',
		width: '4em',
		height: '100%',
	},
	leftBorder: {
		left: 0,
	},
	rightBorder: {
		right: 0,
	},
	top: {
		flex: '0 1 auto',
	},
	middle: {
		flex: '1 1 auto',
	},
	bottom: {
		flex: '0 1 auto',
	},
};

export const Border = () => {
	let border = () => {
		return (
			<>
				<Box component="img" sx={classes.top} src={getWebAppAssetPath('icons/dark/PILLAR_TOP.png')} alt="" />
				<Box component="img" sx={classes.middle} src={getWebAppAssetPath('icons/dark/PILLAR_MIDDLE.png')} alt="" />
				<Box component="img" sx={classes.bottom} src={getWebAppAssetPath('icons/dark/PILLAR_BOTTOM.png')} alt="" />
			</>
		);
	};

	return (
		<>
			<Box sx={[classes.border, classes.leftBorder]}>{border()}</Box>
			<Box sx={[classes.border, classes.rightBorder]}>{border()}</Box>
		</>
	);
};
