import FinishEmailLinkUpdate from 'components/profile/FinishEmailLinkUpdate';
import React from 'react';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function EmailLinkUpdatePage(props) {
	return (
		<>
			<HomePage />

			<DialogPage title='Finish Email Update' transition={true} maxWidth='xs' goBackOnClose={false} goBackTo='/'>
				<FinishEmailLinkUpdate />
			</DialogPage>
		</>
	);
}
