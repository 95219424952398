import { Box, Button, TextField } from '@mui/material';
import { Icons } from 'config/icons';
import React from 'react';

const classes = {
	root: {
		display: 'inline-flex',
		borderRadius: 4,

		'& .MuiButton-root': {
			minWidth: 40,
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 0,
		},

		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
		},
		'& .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
			borderRightColor: 'rgba(0, 0, 0, 0.23)',
		},
	},
};

export const NumberInput = (props: {
	number: number;
	min?: number;
	max?: number;
	style?: any;
	white?: boolean;
	allowDelete?: boolean;
	setNumber: (number) => void;
}) => {
	let { style, setNumber, number, min, max, allowDelete } = props;
	let Max = max ?? Number.MAX_SAFE_INTEGER;
	let Min = min ?? Number.MIN_SAFE_INTEGER;

	let updateNumber = (num: number) => {
		if (typeof num === 'number' && num >= Min - (allowDelete ? 1 : 0) && num <= Max) {
			setNumber(num);
		}
	};

	return (
		<Box sx={classes.root} style={style}>
			<Button
				style={props.white ? { color: 'white', borderColor: 'white' } : {}}
				disabled={number === Min - (allowDelete ? 1 : 0)}
				onClick={() => updateNumber(number - 1)}
			>
				{allowDelete && number === Min ? Icons.Delete : Icons.Remove}
			</Button>

			<TextField
				style={{ width: 70 }}
				value={number}
				onChange={(e) => updateNumber(parseInt(e.target.value))}
				size='small'
				variant='outlined'
				inputProps={{
					style: props.white
						? { border: '1px solid white', color: 'white', textAlign: 'center' }
						: { textAlign: 'center' },
				}}
			/>
			<Button
				style={props.white ? { color: 'white', borderColor: 'white' } : {}}
				disabled={number === Max}
				onClick={() => updateNumber(number + 1)}
			>
				{Icons.Add}
			</Button>
		</Box>
	);
};
