import { LinearProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import Page from './Page';
const SetRecord = React.lazy(() => import('components/admin/SetRecord'));

export default function AdminSetRecordPage(props) {
	let { isModal } = props;
	let { collection, id } = useParams();

	return isModal ? (
		<DialogPage
			disableEnforceFocus
			disableBackdropClick
			title='Record Data'
			transition={false}
			dark
			scroll='paper'
			maxWidth='lg'
		>
			<Suspense fallback={<LinearProgress />}>
				<SetRecord collection={collection!} recordId={id} />
			</Suspense>
		</DialogPage>
	) : (
		<Page title='Record Data' includeBottomNavigation>
			<Suspense fallback={<LinearProgress />}>
				<SetRecord collection={collection!} recordId={id} />
			</Suspense>
		</Page>
	);
}
