import { Box, Button } from '@mui/material';
import { CartScreens } from 'components/orders/placement/CartScreen';
import AddressList from 'components/profile/address/AddressList';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { initiateOrder } from 'services/api/orders';
import { onEvent } from 'store/temp/actions';
import { ApplicationState, UserAddresses } from 'types';
import { InitiateOrderData } from 'types/orders';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
};

interface AddressProps extends ScreenManagerProps {
	addressDetails?: UserAddresses;
	initiateOrder: (data: InitiateOrderData) => Promise<void>;
	pubId: string;
	showMessage: (...payload) => void;
}

interface AddressState {
	selectedAddrId?: string;
}

class AddressComponent extends React.Component<AddressProps, AddressState> {
	constructor(props) {
		super(props);

		this.state = {
			selectedAddrId: this.props.familyState?.addrId,
		};
	}

	render() {
		let { pushScreen, setFamilyState } = this.props;

		return (
			<Box sx={classes.root}>
				<AddressList
					tileSelect={(addrId) => {
						this.setState({ selectedAddrId: addrId });
					}}
					selectedAddrId={this.state.selectedAddrId}
				/>
				<Button
					sx={classes.btn}
					variant='contained'
					color='primary'
					disabled={!this.state.selectedAddrId}
					onClick={async () => {
						setFamilyState({ addrId: this.state.selectedAddrId });
						pushScreen(CartScreens.Payment);
					}}
				>
					Next: Confirm Subscription
					{Icons.KeyRightArrow}
				</Button>
			</Box>
		);
	}
}

function mapStateToAddressProps(state: ApplicationState) {
	return {
		addressDetails: state.userState.userStore.subColl?.addresses,
	};
}

function mapDispatchToAddressProps(dispatch: any) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		initiateOrder: async (data: InitiateOrderData) => {
			return await dispatch(initiateOrder(data));
		},
	};
}

export const SelectAddressScreen = connect(mapStateToAddressProps, mapDispatchToAddressProps)(AddressComponent);
