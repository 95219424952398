import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { Bull } from 'components/Bull';
import { DialogContext } from 'components/pages/DialogPage';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { Icons } from 'config/icons';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAddress } from 'services/api/addresses';
import { toggleAlertDialog, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';
import { Address } from 'types/orders';
import { AccountMode } from '../AccountScreenManager';

const classes = {
	card: {
		margin: 1,

		'& .MuiCardContent-root:last-child': {
			paddingBottom: 2.5,
		},
	},
	header: {
		'& div': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize',
		paddingTop: '8px',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: {
		// float: 'right',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	details: {
		display: 'flex',
		flexDirection: 'row',
	},
	leftDetails: {
		flexGrow: 1,
	},
	rightDetails: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
	actions: (theme) => ({
		backgroundColor: theme.palette.primary.main,
		justifyContent: 'center',
		color: 'white',
		borderColor: 'white',
		width: '100%',
		cursor: 'pointer',
	}),
};

interface Props {
	address: Address;
	style?: any;
	readOnly?: boolean;
	toggleDrawer: (content?: any) => void;
	toggleAlertDialog: (content?: any) => void;
	onClick?: (address: Address, tile: any) => void;
	deleteAddress: (addrId: string) => Promise<void>;
}

const Component = (props: Props) => {
	let { address, style, toggleDrawer, toggleAlertDialog, deleteAddress, readOnly } = props;
	let navigate = useNavigate();
	let context = useContext(DialogContext);

	if (address && !address.id) {
		return <span>{JSON.stringify(address)}</span>;
	}

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let options = BottomDrawerOptions({
			options: [
				{
					title: 'Edit',
					icon: Icons.Edit,
					onClick: () => {
						if (context && context.pushScreen) {
							context.pushScreen({
								key: AccountMode.Address,
								title: 'Update Address',
								props: { addrId: address.id },
							});
						} else {
							navigate(`/address/${address.id}`, { state: { isModal: true } });
						}
						toggleDrawer();
					},
				},
				{
					title: 'Delete',
					icon: Icons.Delete,
					onClick: () => {
						let alert = {
							title: 'Delete Address?',
							message: 'Do you want to delete this address!',
							okAction: {
								text: 'Delete',
								callback: () => {
									deleteAddress(address.id);
									toggleDrawer();
								},
							},
						};

						toggleAlertDialog(alert);
					},
				},
			],
			actionHandler: () => {
				toggleDrawer();
			},
		});

		toggleDrawer(options);
	};

	return (
		<>
			<Card
				sx={classes.card}
				style={style}
				data-id={address.id}
				onClick={() => {
					if (!address) {
						return;
					}

					if (props.onClick) {
						props.onClick(address, {});
						return;
					}
				}}
			>
				<CardHeader
					sx={classes.header}
					avatar={
						<Avatar sx={classes.avatar} variant='circular'>
							{address.type === 'home'
								? Icons.Home
								: address.type === 'work'
								? Icons.Work
								: Icons.Location}
						</Avatar>
					}
					action={!readOnly && <IconButton onClick={onMoreIconClick}>{Icons.MoreVert}</IconButton>}
					title={<Typography noWrap>{address.fullname}</Typography>}
					subheader={
						<Typography variant='body1' color='textSecondary' noWrap className='locale-en'>
							{address.city} <Bull /> {address.pincode}
						</Typography>
					}
				/>
				<CardContent sx={classes.content}>
					<Typography variant='body1' color='textSecondary' component='div'>
						<Box sx={classes.details}>
							<Box sx={classes.leftDetails}>
								{address.address}
								<br />
								{address.landmark ? (
									<>
										{address.landmark}
										<br />
									</>
								) : null}
								{address.city} <Bull /> {address.pincode}
								<br />
								{address.state}
								<br />
								{address.country}
								<br />
								<Divider style={{ margin: '8px 0' }} />
								{address.phone}
								<br />
								<span style={{ textTransform: 'none' }}>{address.email}</span>
								{/* {address.type ? <Chip color='primary' label={address.type} /> : null} */}
							</Box>
						</Box>
					</Typography>
				</CardContent>
				<CardActions sx={classes.actions}>
					<span>SELECT</span>
				</CardActions>
			</Card>
		</>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		toggleAlertDialog: (content?: any) => {
			dispatch(toggleAlertDialog(content));
		},
		deleteAddress: async (addrId: string) => {
			await dispatch(deleteAddress(addrId));
		},
	};
}

export const AddressTile = connect(mapStateToProps, mapDispatchToProps)(Component);
