import { createRouterReducer } from '@lagunovsky/redux-react-router';
import storage from 'localforage';
import { combineReducers } from 'redux';
import { createMigrate, getStoredState, persistCombineReducers, PersistedState, persistReducer } from 'redux-persist';
import { AppDataState, AppFilteredDataState, ApplicationState, PlayedMedia, UserState } from 'types';
import { articlesReducer, filteredArticlesReducer } from './data/articles/reducers';
import { bookletsReducer, filteredBookletsReducer } from './data/booklets/reducers';
import { cartReducer } from './data/cart/reducers';
import { configsReducer } from './data/configs/reducers';
import { featuresReducer, filteredFeaturesReducer } from './data/features/reducers';
import { filteredHDReducer, hdReducer } from './data/hd/reducers';
import { filteredNotificationsReducer, notificationsReducer } from './data/notifications/reducers';
import { opsReducer } from './data/ops/reducers';
import { filteredOrdersReducer, ordersReducer } from './data/orders/reducers';
import {
	editionStoreReducer,
	filteredEditionsReducer,
	filteredPublicationsReducer,
	publicationStoreReducer,
} from './data/publications/reducers';
import { filteredQuotesReducer, quotesReducer } from './data/quotes/reducers';
import { filteredRhymesReducer, rhymeStoreReducer } from './data/rhymes/reducers';
import { filteredSandeshReducer, sandeshReducer } from './data/sandesh/reducers';
import { userStoreReducer } from './data/user/reducers';
import { history } from './history';
import { migrations } from './migrations';
import { customCreateMigrate } from './migrations/customCreateMigrate';
import { offlineReducer } from './offline/reducers';
import tempReducers from './temp/reducers';
import uxReducers from './ux/reducers';

export let basename = process.env.PUBLIC_URL || '/';

const rootLevelMigrate = (key: string, slice?: (storedState) => any) => async (state: PersistedState) => {
	console.log('[Start] RootLevelMigrate: ', key, 'State: ', state);

	let storedState = (await getStoredState({
		key: 'ApplicationStateRoot' + basename,
		storage: storage,
	})) as ApplicationState;

	if (!storedState) {
		return state;
	} else {
		console.log('[ApplicationStateRoot] RootLevelMigrate: ', key, 'StoredState: ', storedState);
	}

	let newState = { ...state, ...(slice ? slice(storedState) : storedState[key]) };

	console.log('[End] RootLevelMigrate: ', key, 'State: ', newState);
	return newState as PersistedState;
};

const dataMigrate = (key: string, slice?: (storedState) => any) => async (state: PersistedState) => {
	console.log('[Start] DataMigrate: ', key, 'State: ', state);

	let storedState = (await getStoredState({
		key: 'ApplicationStateRoot' + basename,
		storage: storage,
	})) as ApplicationState;

	let dataState = storedState?.dataState;
	if (!dataState) {
		dataState = (await getStoredState({
			key: '[ApplicationState]:DataState' + basename,
			storage: storage,
		})) as AppDataState;

		if (!dataState || !dataState[key]) {
			return state;
		}
	}

	let newState = { ...state, ...(slice ? slice(dataState) : dataState[key]) };

	console.log('[End] DataMigrate: ', key, 'State: ', newState);
	return newState as PersistedState;
};

const persistDataConfig = (key: string, slice?: (storedState) => any) => ({
	version: 0,
	key: '[ApplicationState]:[DataState]:' + (key[0].toUpperCase() + key.substr(1)) + basename,
	storage,
	timeout: 0,
	migrate: customCreateMigrate({ 0: dataMigrate(key, slice) as any }, { debug: true, asyncMigrations: true }),
});

const dataReducers = persistCombineReducers(
	{
		version: 0,
		key: '[ApplicationState]:DataState' + basename,
		storage,
		timeout: 0,
		migrate: customCreateMigrate(
			{ 0: rootLevelMigrate('dataState') as any },
			{ debug: true, asyncMigrations: true }
		),
		blacklist: [
			'articles',
			'rhymes',
			'publications',
			'editions',
			'booklets',
			'configs',
			'notifications',
			'quotes',
			'features',
			'hd',
			'sandesh',
			'orders',
			'cart',
		],
	},
	{
		articles: persistReducer(persistDataConfig('articles'), articlesReducer),
		rhymes: persistReducer(persistDataConfig('rhymes'), rhymeStoreReducer),
		publications: persistReducer(persistDataConfig('publications'), publicationStoreReducer),
		editions: persistReducer(persistDataConfig('editions'), editionStoreReducer),
		booklets: persistReducer(persistDataConfig('booklets'), bookletsReducer),
		configs: persistReducer(
			persistDataConfig('configs', (dataState) => {
				if (!dataState.configs.byId) {
					return { byId: { ...dataState['configs'] } };
				} else {
					return { ...dataState['configs'] };
				}
			}),
			configsReducer
		),
		notifications: persistReducer(persistDataConfig('notifications'), notificationsReducer),
		quotes: persistReducer(persistDataConfig('quotes'), quotesReducer),
		features: persistReducer(persistDataConfig('features'), featuresReducer),
		hd: persistReducer(persistDataConfig('hd'), hdReducer),
		sandesh: persistReducer(persistDataConfig('sandesh'), sandeshReducer),
		orders: persistReducer(persistDataConfig('orders'), ordersReducer),
		cart: persistReducer(persistDataConfig('cart'), cartReducer),
	}
);

const filteredDataReducers = combineReducers<AppFilteredDataState>({
	articles: filteredArticlesReducer,
	quotes: filteredQuotesReducer,
	rhymes: filteredRhymesReducer,
	publications: filteredPublicationsReducer,
	editions: filteredEditionsReducer,
	booklets: filteredBookletsReducer,
	notifications: filteredNotificationsReducer,
	features: filteredFeaturesReducer,
	hd: filteredHDReducer,
	orders: filteredOrdersReducer,
	sandesh: filteredSandeshReducer,
});

// const combinedReducer = combineReducers<ApplicationState>({
// 	dataState: dataReducers,
// 	uxState: uxReducers,
// 	tempState: tempReducers,
// 	opsState: opsReducer,
// });

const userStateReducer = combineReducers<UserState>({ userStore: userStoreReducer });

// export const rootReducer = (history) =>
// 	combineReducers({
// 		router: createRouterReducer(history),
// 		dataState: dataReducers,
// 		filteredDataState: filteredDataReducers,
// 	});

// const persistedRootReducer = persistReducer(persistConfig, rootReducer(history));

const persistRootConfig = (key: string, slice?: (storedState) => any, props?) => ({
	version: 0,
	key: '[ApplicationState]:' + (key[0].toUpperCase() + key.substr(1)) + basename,
	storage,
	timeout: 0,
	migrate: customCreateMigrate({ 0: rootLevelMigrate(key, slice) as any }, { debug: true, asyncMigrations: true }),
	...props,
});

export const persistRootReducers = (history) => ({
	router: createRouterReducer(history),
	filteredDataState: filteredDataReducers,
	tempState: tempReducers,
	dataState: dataReducers,
	userState: persistReducer(
		persistRootConfig(
			'userState',
			(storedState) => ({
				userStore: {
					...((storedState.dataState as any).userStore ?? (storedState.userState as any).userStore),
				},
			})
			// { throttle: 2000 }
		),
		userStateReducer
	),
	uxState: persistReducer(persistRootConfig('uxState'), uxReducers),
	opsState: persistReducer(
		persistRootConfig('opsState', (storedState) => {
			if (!storedState.opsState.byId) {
				return { byId: { ...storedState.opsState } };
			} else {
				return storedState.opsState;
			}
		}),
		opsReducer
	),
	offlineState: persistReducer(
		persistRootConfig(
			'offlineState',
			(storedState) => {
				let newState = { ...storedState.offlineState };
				let playedMedia = (storedState.dataState as any).playedMedia as PlayedMedia;

				if (!newState.playedMedia && playedMedia) {
					newState.playedMedia = { ...playedMedia };
				}
				return newState;
			}
			// { throttle: 2000 }
		),
		offlineReducer
	),
});

// function crossSliceReducer(state, action) {
// 	switch (action.type) {
// 		case 'HACK_STATE': {
// 			let path: string = action.path;
// 			let value = action.value;

// 			let keys = path.split('.');
// 			let newState = { ...state };

// 			let deepState = newState;
// 			for (let i = 0; i < keys.length - 1; i++) {
// 				deepState[keys[i]] = { ...deepState[keys[i]] };
// 				deepState = deepState[keys[i]];
// 			}

// 			deepState[keys[keys.length - 1]] = value;

// 			return newState;
// 		}
// 		default:
// 			return state;
// 	}
// }

const persistedRootReducer = persistCombineReducers(
	{
		version: 35,
		key: 'ApplicationStateRoot' + basename,
		storage,
		blacklist: [
			'router',
			'filteredDataState',
			'dataState',
			'uxState',
			'userState',
			'tempState',
			'opsState',
			'offlineState',
		],
		// transforms: [filteredBlacklist],
		migrate: createMigrate(migrations),
		timeout: 0,
		// throttle: 200,
	},
	persistRootReducers(history)
);

export const rootReducer = persistedRootReducer as any;
// (state = initialState as any, action) => {
// 	let intermediateState = persistedRootReducer(state, action);
// 	const finalState = crossSliceReducer(intermediateState, action);
// 	return finalState;
// };

export const getLocalState = async (key: string) => {
	return await getStoredState({
		key: key + basename,
		storage: storage,
	});
};

// export const createRootReducer =
// 	(history) =>
// 	(state = initialState as any, action) => {
// 		let intermediateState = rootReducer(history)(state, action);
// 		const finalState = crossSliceReducer(intermediateState, action);
// 		return finalState;
// 	};

// export const createRootReducer = (history) => rootReducer(history);
// (state = initialState as any, action) => {
// 	let intermediateState = rootReducer(history)(state, action);
// 	const finalState = crossSliceReducer(intermediateState, action);
// 	return finalState;
// };
