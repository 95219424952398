import { combineReducers } from 'redux';
import { OpsState, OpsStore } from 'types';
import { UpdateOperation, UPDATE_OPS } from './actions';

export function opsByIdReducer(state: OpsStore = {}, action: UpdateOperation) {
	switch (action.type) {
		case UPDATE_OPS:
			return { ...state, [action.operation.key]: action.operation };
		default:
			return state;
	}
}

export const opsReducer = combineReducers<OpsState>({
	byId: opsByIdReducer,
});
