import { FireStoreModule } from 'fb';
import { fireAuth, fireDB } from 'fb/initconfig';
import { User } from 'firebase/auth';
import { collection, doc, Firestore, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { Action } from 'redux';
import { updateSandesh } from 'store/data/sandesh/actions';
import { ApplicationState } from 'types';

export class SandeshFireStoreModule implements FireStoreModule {
	private user: User;
	constructor(user: User) {
		this.user = user;
	}

	public getModuleName = () => 'sandesh';
	public getOpsKeySuffix = () => this.user?.phoneNumber ?? this.user?.uid;
	public getFBWatchQuery = (db: Firestore) =>
		query(
			collection(db, 'sandesh'),
			where('allReceiverIds', 'array-contains', this.user.phoneNumber ?? this.user.uid)
		);
	public getChangeProcessAction = (changes) => updateSandesh(changes);
}

const MODIFY_SANDESH = '@@api/MODIFY_SANDESH';
type T_MODIFY_SANDESH = typeof MODIFY_SANDESH;

export interface ModifySandeshData {
	sandeshId: string;
	deleted: boolean;
}

export interface ModifySandesh extends Action {
	type: T_MODIFY_SANDESH;
	data: ModifySandeshData;
}

export const modifySandesh = (data: ModifySandeshData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		let db = fireDB;
		let user = fireAuth.currentUser;

		if (!user) {
			throw new Error('user not signed in');
		}

		await updateDoc(doc(collection(db, 'sandesh'), data.sandeshId), {
			deleted: data.deleted,
			updatedAt: serverTimestamp(),
		});
	};
};
