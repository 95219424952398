import { connect } from 'react-redux';
import { toggleOnDemandPage } from 'store/temp/actions';
import { ApplicationState } from 'types';

export interface Props {
	open: boolean;
	component?: any;
	toggleOnDemandPage: () => void;
}

function OnDemandPage(props: Props) {
	let { open = false, component } = props;

	if (!open) {
		return null;
	}

	return component;
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { onDemandPage } = tempState;

	return {
		open: onDemandPage.open,
		component: onDemandPage.component,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleOnDemandPage: () => {
			dispatch(toggleOnDemandPage());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OnDemandPage);
