import { Cart } from 'types';
import {
	AddToCart,
	ADD_TO_CART,
	EmptyCart,
	EMPTY_CART,
	RemoveFromCart,
	REMOVE_FROM_CART,
	UpdateCartItem,
	UpdateCartOrder,
	UPDATE_CART_ITEM,
	UPDATE_CART_ORDER,
} from './actions';

export function cartReducer(
	state: Cart = { items: {} },
	action: AddToCart | RemoveFromCart | UpdateCartItem | EmptyCart | UpdateCartOrder
) {
	switch (action.type) {
		case ADD_TO_CART:
			return {
				...state,
				items: {
					...state.items,
					[action.item.itemId]: {
						...action.item,
						quantity: (state.items[action.item.itemId]?.quantity ?? 0) + action.item.quantity,
					},
				},
			};
		case REMOVE_FROM_CART:
			let s = { ...state };
			delete s.items[action.itemId];
			return s;
		case UPDATE_CART_ITEM:
			return {
				...state,
				items: { ...state.items, [action.item.itemId]: { ...state.items[action.item.itemId], ...action.item } },
			};
		case EMPTY_CART:
			return { items: {} };
		case UPDATE_CART_ORDER:
			return { ...state, orderId: action.orderId };
		default:
			return state;
	}
}
