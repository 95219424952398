import AnandDate from 'helpers/AnandDate';
import { createCachedSelector } from 're-reselect';
import { ApplicationState, FBData } from 'types';

function getData(state: ApplicationState, type: string, key?: string) {
	return state.dataState[type];
}

export const getDataById = createCachedSelector([getData], (data) => {
	let result: { [id: string]: any } = {};
	for (let id in data) {
		if (data[id].deleted === true) {
			continue;
		}
		result[id] = data[id];
	}

	return result;
})((_state_, type, key) => type + (key ?? ''));

export const getDataByDate = createCachedSelector(
	(state, type, key) => getData(state, type, key),
	(state, type, key) => type,
	(data, type) => {
		let byId = data.byId ?? data;

		let result: { [date: string]: any[] } = {};
		for (let id in byId) {
			let record = byId[id];
			let date = record.forDate ?? record.publishTime;
			if (date) {
				let dateStr = new AnandDate(date).format(type === 'hd' ? 'MM-DD' : 'YYYY-MM-DD');
				result[dateStr] = result[dateStr] || [];
				result[dateStr].push(record);
			}
		}

		return result;
	}
)((_state_, type, key) => type + (key ?? ''));

export const getDataSortedByPublishTime = createCachedSelector([getDataById], (data) => {
	let result: FBData[] = Object.values(data);

	// Sort descending
	return result.sort((a, b) => b.publishTime.seconds - a.publishTime.seconds || b.publishTime.nanoseconds - a.publishTime.nanoseconds);
})((_state_, type, key) => type + (key ?? ''));

export const getDataSorted = createCachedSelector(
	(records) => records,
	(records, sortValue) => sortValue,
	(records, sortValue, sortKey) => sortKey,
	(records, sortValue, sortKey: string) => {
		let sortFunc;
		switch (sortValue) {
			case 'By Day':
				sortFunc = (a, b) => {
					let bDate = new AnandDate(b.forDate);
					let aDate = new AnandDate(a.forDate);
					bDate.subtract(bDate.year(), 'year');
					aDate.subtract(aDate.year(), 'year');

					return bDate.isSameDate(aDate) ? (new AnandDate(b.forDate).isAfter(new AnandDate(a.forDate)) ? -1 : 1) : bDate.isAfter(aDate) ? -1 : 1;
				};
				break;
			case 'Newest First':
			case 'Latest Published':
				sortFunc = (a, b) => {
					let isPub = !!b.publishTime;
					let result = 0;
					if (isPub) {
						let bTime = b.creationTime ? b.creationTime : b.publishTime;
						let aTime = a.creationTime ? a.creationTime : a.publishTime;
						result = bTime.seconds - aTime.seconds || bTime.nanoseconds - aTime.nanoseconds;
					} else {
						result = new AnandDate(b.forDate).isAfter(new AnandDate(a.forDate)) ? 1 : -1;
					}

					if (!result) {
						result =
							(a.title?.en ?? a.title?.hi ?? (a.title?.length && a.title) ?? '') >
							(b.title?.en ?? b.title?.hi ?? (b.title?.length && b.title) ?? '')
								? 1
								: -1;
					}

					return result;
				};
				break;
			case 'Oldest First':
			case 'Oldest Published':
				sortFunc = (a, b) => {
					let result = 0;
					if (!!a.publishTime) {
						let aTime = a.creationTime ? a.creationTime : a.publishTime;
						let bTime = b.creationTime ? b.creationTime : b.publishTime;

						result = aTime.seconds - bTime.seconds || aTime.nanoseconds - bTime.nanoseconds;
					} else {
						result = new AnandDate(a.forDate).isAfter(new AnandDate(b.forDate)) ? 1 : -1;
					}

					if (!result) {
						result =
							(a.title?.en ?? a.title?.hi ?? (a.title?.length && a.title) ?? '') >
							(b.title?.en ?? b.title?.hi ?? (b.title?.length && b.title) ?? '')
								? 1
								: -1;
					}

					return result;
				};
				break;
			case 'Latest Updated':
				sortFunc = (a, b) => b.updatedAt.seconds - a.updatedAt.seconds;
				break;
			case 'Oldest Updated':
				sortFunc = (a, b) => a.updatedAt.seconds - b.updatedAt.seconds;
				break;
			case 'Title - Ascending':
				sortFunc = (a, b) =>
					(a.title?.en ?? a.title?.hi ?? (a.title?.length && a.title) ?? '') > (b.title?.en ?? b.title?.hi ?? (b.title?.length && b.title) ?? '')
						? 1
						: -1;
				break;
			case 'Title - Descending':
				sortFunc = (a, b) =>
					(a.title.en ?? a.title.hi ?? (a.title.length && a.title) ?? '') > (b.title.en ?? b.title.hi ?? (b.title.length && b.title) ?? '') ? -1 : 1;
				break;
			case 'Duration - Low to High':
				sortFunc = (a, b) => (a.mediaLength || Number.MAX_SAFE_INTEGER) - (b.mediaLength || Number.MAX_SAFE_INTEGER);
				break;
			case 'Duration - High to Low':
				sortFunc = (a, b) => (b.mediaLength ?? 0) - (a.mediaLength ?? 0);
				break;
			default:
				return records;
		}
		// Sort descending
		return [...(records as any[])].sort(sortFunc);
	}
)((records, sortFunc, sortKey) => sortKey);
