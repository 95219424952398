import UserPlaylistsControl from 'components/playlist/UserPlaylistsControl';
import React from 'react';
import Page from './Page';

export default function PlaylistsPage() {
	return (
		<Page title='Playlists' includeBottomNavigation fullscreen dark>
			<UserPlaylistsControl />
		</Page>
	);
}
