import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';

export class TrackerFireStoreModule implements FireStoreModule {
	private forWeb: boolean;

	constructor(forWeb?: boolean) {
		this.forWeb = forWeb ?? false;
	}

	public getModuleName = () => 'tracker';
	public getOpsKeySuffix = () => (this.forWeb ? 'forWeb' : 'global');
	public getFBWatchQuery = (db: Firestore) => collection(db, 'tracker');

	public getChangeProcessAction = (changes) => {
		throw 'Tracker Change Process Called!!';
	};
}
