import React from 'react';
import { connect } from 'react-redux';
import { AppDataState, ApplicationState } from 'types';

interface Props {
	collectionType: string;
	refId: string;
	dataState: AppDataState;
	locale: string;
}

class Component extends React.Component<Props> {
	public get title() {
		let { collectionType, refId, dataState, locale } = this.props;

		switch (collectionType) {
			case 'publications':
				let publications = dataState.publications.byId;
				let publication = publications[refId];

				return publication?.title;
			case 'articles':
				let articles = dataState.articles.byId;
				let article = articles[refId];

				if (article.articleType === 'shriamarvani') {
					return locale === 'hi' ? 'श्री प्रवचन' : 'Immortal Preaching';
				}

				let title = article?.title[locale] || article?.title.hi || article?.title.en || '';
				return title;
			case 'rhymes':
				let rhymes = dataState.rhymes.byId;
				let rhyme = rhymes[refId];

				return rhyme?.title;
		}

		return '';
	}

	render() {
		let { collectionType, refId, dataState, locale } = this.props;

		switch (collectionType) {
			case 'publications':
				let publications = dataState.publications.byId;
				let publication = publications[refId];

				return <span className={'locale-' + publication?.lang}>{publication?.title}</span>;
			case 'articles':
				let articles = dataState.articles.byId;
				let article = articles[refId];

				let title = article?.title[locale] || article?.title.hi || article?.title.en || '';
				let lang = article?.title[locale] ? locale : article?.title.hi ? 'hi' : 'en';
				if (article.articleType === 'shriamarvani') {
					title = locale === 'hi' ? 'श्री प्रवचन' : 'Immortal Preaching';
					lang = locale;
				}
				return <span className={'locale-' + lang}>{title}</span>;
			case 'rhymes':
				let rhymes = dataState.rhymes.byId;
				let rhyme = rhymes[refId];

				return <span className='locale-hi'>{rhyme?.title}</span>;
		}

		return null;
	}
}

function mapStateToProps(state: ApplicationState, props) {
	return {
		dataState: state.dataState,
		locale: state.uxState.locale,
	};
}

export const ReferencedComponentTitle = connect(mapStateToProps, null, null, { forwardRef: true })(Component);
