import AnandDate from 'helpers/AnandDate';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const getHD = (state: ApplicationState) => state.filteredDataState.hd;

export const getHDByDate = createSelector([getHD], (hds) => {
	let hdByDate = {};
	hds.forEach((hd) => {
		let date = new AnandDate(hd.forDate);
		let day = date.format('MM-DD');
		hdByDate[day] = hdByDate[day] ?? [];
		hdByDate[day].push(hd);
	});
	return hdByDate;
});
