import { Box, Button, MobileStepper, Skeleton, Typography } from '@mui/material';
import DialogPage from 'components/pages/DialogPage';
import TextContent from 'components/TextContent';
import { Icons } from 'config/icons';
import { isAdmin } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Feature } from 'types';

const classes = {
	feature: (theme) => ({
		padding: `0 ${theme.spacing(2)}`,
		textTransform: 'none' as 'none',
		fontWeight: 'normal' as 'normal',
		lineHeight: 1.7,
		color: 'rgba(255, 255, 255, 0.8)',
		textAlign: 'left' as 'left',

		'& figure': {
			margin: 0,
		},

		'& img': {
			display: 'block',
			height: '45vh',
			boxShadow: '2px 2px 5px darkgreen',
			float: 'right' as 'right',
			marginLeft: theme.spacing(1),
		},
	}),
	title: (theme) => ({
		margin: theme.spacing(2),
		textTransform: 'capitalize' as 'capitalize',
		borderBottom: '1px solid white',
		paddingBottom: theme.spacing(3),
		marginBottom: theme.spacing(3),
		color: 'rgba(255, 255, 255, 0.8)',
	}),
	plainAppBar: (theme) => ({
		borderTop: `8px solid ${theme.palette.primary.main}`,
		backgroundColor: 'white',
		// boxShadow: 'none',
		color: 'inherit',
		textAlign: 'center' as 'center',

		'& .MuiIconButton-root': {
			color: 'rgba(0, 0, 0, 0.54)',
		},

		'& h6': {
			transform: 'translateX(-18px)',
		},
	}),
	stepper: (theme) => ({
		backgroundColor: `${theme.palette.primary.main} !important`,
		maxWidth: 400,
		flexGrow: 1,
		color: 'rgba(255, 255, 255, 0.8)',
		margin: 'auto',
	}),
};

interface FeatureProps {
	features: Feature[];
	feature?: Feature;
	locale: string;
	toggleDrawer: (content) => void;

	isModal: boolean;
	showAll: boolean;
}

class Content extends React.Component<any> {
	render() {
		let { locale, feature, includeTitle } = this.props;

		let lang = feature?.text && feature?.text[locale] ? locale : feature?.text?.hi ? 'hi' : 'en';

		let titleText = feature.title[locale] || feature.title.hi || feature.title.en;

		let title =
			includeTitle ?? true ? (
				<Box className={'locale-' + lang} sx={classes.title}>
					{feature ? (
						<Typography variant='h4' align='center'>
							{titleText}
						</Typography>
					) : (
						<Skeleton animation='wave' width='70%' />
					)}
				</Box>
			) : null;

		let artText = feature?.text ? feature?.text[locale] || feature?.text.hi || feature?.text.en : null;
		let text = artText ? (
			<TextContent alignRhymes locale={lang}>
				<Box sx={classes.feature} dangerouslySetInnerHTML={{ __html: artText }} />
			</TextContent>
		) : (
			<Typography variant='body1'>
				<Skeleton animation='wave' />
				<Skeleton animation='wave' />
				<Skeleton animation='wave' />
			</Typography>
		);

		return (
			<div>
				{title}
				{text}
			</div>
		);
	}
}

export const FeatureContent = Content;

class Component extends React.Component<
	FeatureProps,
	{ isTitleOpen: boolean; index: number; direction: 'left' | 'right' }
> {
	constructor(props: FeatureProps) {
		super(props);

		let index;
		try {
			if (props.feature) {
				index = props.features.findIndex((val) => val.id === props.feature?.id);
			} else {
				let lastSeen = parseInt(localStorage.getItem('feature-last-seen') ?? '0');
				let lastIndex = props.features.length - 1;
				for (let i = 0; i < props.features.length; i++) {
					if ((props.features[i].publishTime?.seconds ?? 0) > lastSeen) {
						lastIndex = i;
						break;
					}
				}
				index = lastIndex;
			}
		} catch (e) {
			index = 0;
			localStorage.setItem('feature-last-seen', '0');
		}

		this.state = {
			index: index >= 0 ? index : 0,
			isTitleOpen: true,
			direction: 'left',
		};
	}

	isNew = (index) => {
		try {
			if (index >= 0 && this.props.features?.length - 1 >= index) {
				let lastSeen = parseInt(localStorage.getItem('feature-last-seen') ?? '0');
				let sec = this.props.features[index].publishTime?.seconds ?? 0;
				return sec > lastSeen;
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	};

	updateLastSeen = () => {
		try {
			if (this.props.features?.length && this.isNew(this.state.index)) {
				localStorage.setItem(
					'feature-last-seen',
					Math.floor(this.props.features[this.state.index].publishTime?.seconds ?? 0).toString()
				);
			}
		} catch (e) {}
	};

	componentDidMount = () => {
		this.updateLastSeen();
	};

	componentDidUpdate = () => {
		this.updateLastSeen();
	};

	render() {
		let { features, locale, isModal } = this.props;

		let featureContent;

		if (features.length && this.state.index < features.length) {
			let feature = features[this.state.index];

			featureContent = (
				// <Slide in={true} direction={this.state.direction} key={this.state.index} mountOnEnter unmountOnExit>
				<FeatureContent locale={locale} feature={feature} />
				// </Slide>
			);
		} else {
			featureContent = <div>No Feature Guides Available!</div>;
		}

		let currentIndex = this.state.index;
		let totalFeatures = features.length;

		return (
			<>
				<DialogPage
					title='Feature Announcement'
					frontal={true}
					goBackOnClose={isModal}
					goBackTo='/'
					style={{ paddingBottom: 12 }}
					actionButtons={
						<MobileStepper
							variant='text'
							steps={totalFeatures}
							position='static'
							activeStep={currentIndex}
							sx={classes.stepper}
							nextButton={
								<Button
									size='small'
									color='inherit'
									onClick={async () => {
										this.setState({ index: this.state.index + 1, direction: 'left' });
									}}
									disabled={currentIndex >= totalFeatures - 1}
								>
									Next
									{Icons.KeyRightArrow}
								</Button>
							}
							backButton={
								<Button
									size='small'
									color='inherit'
									onClick={async () => {
										this.setState({ index: this.state.index - 1, direction: 'right' });
									}}
									disabled={currentIndex <= 0}
								>
									{Icons.KeyLeftArrow}
									Back
								</Button>
							}
						/>
					}
				>
					{featureContent}
				</DialogPage>
			</>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { id?: string; isModal: boolean }) {
	try {
		let showAll =
			isAdmin(state.userState.userStore.user, state.userState.userStore.userData) &&
			state.userState.userStore.userData?.settings &&
			state.userState.userStore.userData?.settings['admin.records'];

		let features = state.filteredDataState.features.sort(
			(a, b) => (a.publishTime?.seconds ?? 0) - (b.publishTime?.seconds ?? 0)
		);
		let feature = props.id ? state.dataState.features.byId[props.id] : undefined;

		return {
			features: features,
			feature,
			locale: state.uxState.locale,
			showAll,
		};
	} catch (e) {
		return {
			features: [],
			feature: undefined,
			locale: 'en',
			showAll: false,
		};
	}
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
	};
}

// Component.contextType = DialogContext;

export const FeatureControl = connect(mapStateToProps, mapDispatchToProps)(Component);
