import { Box, Button, Typography } from '@mui/material';
import { DialogContext } from 'components/pages/DialogPage';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { IsMinimalRhymeSite } from 'config';
import { User } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import { AccountMode } from './AccountScreenManager';
import { NewPhoneSignInControl } from './NewPhoneSignInControl';

const classes = {
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		padding: 2,
		textAlign: 'center' as 'center',
		textTransform: 'none' as 'none',
		fontWeight: 'normal' as 'normal',
		lineHeight: 1.7,
		paddingTop: '16vh',
		backgroundColor: 'white',
		height: '100%',
		// color: theme.palette.primary.main,
		// fontSize: '0.8em',
	},
	title: {
		margin: 2,
		borderBottom: '1px solid white',
		paddingBottom: 3,
		marginBottom: 3,
		// color: theme.palette.primary.main,
		fontWeight: 'bold' as 'bold',
		textTransform: 'uppercase' as 'uppercase',
	},
	newuser: {
		alignSelf: 'center',
	},
};

interface Props extends ScreenManagerProps {
	user?: User;
}

interface State {
	mode: string;
}

class Component extends React.Component<Props, State> {
	context!: React.ContextType<typeof DialogContext>;

	constructor(props: Props) {
		super(props);

		this.state = {
			mode: 'phone',
		};
	}

	componentDidMount() {
		// this.props.purgePrevScreens();
	}

	render() {
		let { user, pushScreen, replaceScreen } = this.props;

		return (
			<Box sx={classes.root}>
				<NewPhoneSignInControl
					setMode={(mode: string) => {
						this.setState({ mode });
					}}
					style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
				/>

				{this.state.mode === 'phone' && (
					<div style={{ marginTop: 'max(calc(100vh - 16vh - 240px - 220px), 48px)' }}>
						{!user ? (
							<Box sx={classes.newuser}>
								Email User?
								<Button
									color='primary'
									onClick={() => {
										replaceScreen(AccountMode.SignIn);
									}}
								>
									Sign In using Email
								</Button>
							</Box>
						) : null}

						{!IsMinimalRhymeSite && (
							<Typography variant='h6'>
								By signing-in, you agree to our <br />
								<Button
									color='primary'
									onClick={() => {
										pushScreen(AccountMode.Policy);
									}}
								>
									Terms and Privacy Policy
								</Button>
							</Typography>
						)}
					</div>
				)}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
	};
}

export const NewPhoneSignIn = connect(mapStateToProps)(Component);
