import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { AppInterface } from 'AppInterface/AppInterface';
import { IsMinimalRhymeSite } from 'config';
import { appEpic } from 'epics/AppEpic';
import { eventsEpic } from 'epics/EventsEpic';
import { notificationsEpic } from 'epics/NotificationsEpic';
import { opsEpic } from 'epics/OpsEpic';
import { quotesEpic } from 'epics/QuotesEpic';
import { filterDataEpic } from 'epics/TimeBasedDataEpic';
import { userDataEpic } from 'epics/UserDataEpic';
import { InitFireStoreModules } from 'fb';
import { Action, applyMiddleware, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { Persistor, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { ApplicationState } from 'types';
import { history } from './history';
import { initialState as is0 } from './migrations/0';
import { rootReducer } from './reducers';

export const routerMiddleware = createRouterMiddleware(history);
const epicMiddleware = createEpicMiddleware();
let enhancer = applyMiddleware(routerMiddleware, thunkMiddleware, epicMiddleware);

// if (process.env.NODE_ENV !== 'production') {
// 	const reduxDevTools = require('@redux-devtools/extension');
// 	enhancer = reduxDevTools.composeWithDevTools(enhancer);
// }

const store = createStore<ApplicationState, Action, unknown, unknown>(rootReducer, is0, enhancer);

let appInterface = new AppInterface(store.getState, store.dispatch);

let rootEpic = IsMinimalRhymeSite
	? combineEpics(eventsEpic, opsEpic, filterDataEpic)
	: combineEpics(
			eventsEpic,
			opsEpic,
			filterDataEpic,
			userDataEpic,
			appEpic,
			quotesEpic,
			notificationsEpic
			// featuresEpic
	  );
epicMiddleware.run(rootEpic);

let modulesInit = new InitFireStoreModules(store.getState, store.dispatch);

const persistor: Persistor = persistStore(store);

export { store, persistor, modulesInit, appInterface };
