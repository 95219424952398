import { MediaPlayer } from 'components/player/MediaPlayer';
import { RouteGroup, RouteProps } from 'types';
import { Icons } from './icons';
import { DhyanArticleId, OrgName } from './index';

const homeRoute: RouteProps = {
	to: '/',
	page: 'Home',
	primary: 'Home',
	title: OrgName,
	icon: Icons.Home,
};

const bhajanListRoute: RouteProps = {
	to: '/bhajan',
	page: 'Bhajan',
	primary: 'Bhajan',
	title: 'Bhajan',
	icon: Icons.Bhajan,
	state: { allowSearch: true },
};

const satsangListRoute: RouteProps = {
	to: '/satsang',
	page: 'Satsang',
	primary: 'Satsang',
	title: 'Satsang',
	icon: Icons.Satsang,
	state: { allowSearch: true },
};

const introRoute: RouteProps = {
	to: '/introduction',
	page: 'Intro',
	primary: 'Introduction',
	title: 'Introduction',
	icon: Icons.About,
};

const publicationsRoute: RouteProps = {
	to: '/publications',
	page: 'Publications',
	primary: 'Publications',
	title: 'Publications',
	icon: Icons.Publication,
};

const publicationsSAPDRoute: RouteProps = {
	to: '/publications/sapd',
	page: 'Publications',
	primary: 'Publications SAPD',
	title: 'Publications SAPD',
	icon: Icons.Publication,
};

const publicationsSPDRoute: RouteProps = {
	to: '/publications/spd',
	page: 'Publications',
	primary: 'Publications SPD',
	title: 'Publications SPD',
	icon: Icons.Publication,
};

const publicationRoute: RouteProps = {
	to: '/publication/:id',
	page: 'Publication',
	primary: 'Publication',
	title: 'Publication',
	state: { isModal: true },
};

const subscribeRoute: RouteProps = {
	to: '/subscribe/:pubId',
	page: 'Subscribe',
	primary: 'Subscribe',
	title: 'Subscribe',
	state: { isModal: true },
};

const cartRoute: RouteProps = {
	to: '/cart',
	page: 'Cart',
	primary: 'Cart',
	title: 'Cart',
	state: { isModal: true },
};

const shriamarvaniListRoute: RouteProps = {
	to: '/shriamarvani',
	page: 'ShriAmarVani',
	primary: 'Shri Amar Vani',
	title: 'Shri Amar Vani',
	icon: Icons.ShriAmarVani,
	state: { allowSearch: true },
};

export const quoteRoute: RouteProps = {
	to: '/dailyquote/:id?',
	page: 'Quote',
	primary: 'Daily Quote',
	title: 'Daily Quote',
	state: { isModal: true },
	icon: Icons.Quote,
};

export const featureRoute: RouteProps = {
	to: '/feature/:id?',
	page: 'Feature',
	primary: 'Features Guide',
	title: 'Features',
	state: { isModal: true },
	icon: Icons.LightBulb,
};

const rhymeListRoute: RouteProps = {
	to: '/rhymes',
	page: 'RhymeList',
	primary: 'Scroll of Wisdom',
	title: 'Scroll of Wisdom',
	icon: Icons.Scroll,
	state: { allowSearch: true },
};

const rhymeRoute: RouteProps = {
	to: '/rhyme/:id',
	page: 'Rhyme',
	primary: 'Scroll of Wisdom',
	title: 'Scroll of Wisdom',
	state: { isModal: true },
};

const hdRoute: RouteProps = {
	to: '/hd/:id',
	page: 'Home',
	primary: 'Home',
	title: OrgName,
	icon: Icons.Home,
};

const pkpRoute: RouteProps = {
	to: '/pkp',
	page: 'PkP',
	primary: 'Pooja Ke Phool',
	title: 'Pooja Ke Phool',
	icon: Icons.Pooja,
};

const calendarRoute: RouteProps = {
	to: '/calendar',
	page: 'Calendar',
	primary: 'Calendar',
	title: 'Calendar',
	icon: Icons.Calendar,
};

const calendarSubRoute: RouteProps = {
	to: '/calendar/:type?/:year?/:month?',
	page: 'Calendar',
	primary: 'Calendar',
	title: 'Calendar',
	icon: Icons.Calendar,
};

const donationRoute: RouteProps = {
	to: 'https://www.shriparamhansadvaitmat.org',
	page: 'None',
	primary: 'Donation',
	title: 'Donation',
	icon: Icons.Donation,
	state: {
		target: '_blank',
	},
};

export const noticesRoute: RouteProps = {
	to: '/notices/:id?',
	page: 'Notices',
	primary: 'Important Notices',
	title: 'Important Notices',
	state: { isModal: true },
	icon: Icons.Notification,
};

const shriamarvaniPageRoute: RouteProps = {
	to: '/shriamarvani/t/:href',
	page: 'Article',
	primary: 'Shri Amar Vani',
	title: 'Shri Amar Vani',
	state: { type: 'shriamarvani', isModal: true },
};

const bhajanPageRoute: RouteProps = {
	to: '/bhajan/t/:href',
	page: 'Article',
	primary: 'Bhajan',
	title: 'Bhajan',
	state: { type: 'bhajan', isModal: true },
};

const satsangPageRoute: RouteProps = {
	to: '/satsang/t/:href',
	page: 'Article',
	primary: 'Satsang',
	title: 'Satsang',
	state: { type: 'satsang', isModal: true },
};

const sandeshPageRoute: RouteProps = {
	to: '/s/:href',
	page: 'Article',
	primary: 'Sandesh',
	title: 'Sandesh',
	state: { type: 'sandesh', isModal: true },
};

const shriaartiPageRoute: RouteProps = {
	to: '/shriaarti/t/:href',
	page: 'Article',
	primary: 'Pooja Ke Phool',
	title: 'Pooja Ke Phool',
	state: { type: 'shriaarti', isModal: true },
};

const shriaartiRoute: RouteProps = {
	to: '/shriaarti/:href',
	page: 'Media',
	primary: 'Pooja Ke Phool',
	title: 'Pooja Ke Phool',
	state: { type: 'shriaarti', isModal: true },
};

export const bhajanRoute: RouteProps = {
	to: '/bhajan/:href',
	page: 'Media',
	primary: 'Bhajan',
	title: 'Bhajan',
	state: { type: 'bhajan', isModal: true },
};

export const satsangRoute: RouteProps = {
	to: '/satsang/:href',
	page: 'Media',
	primary: 'Satsang',
	title: 'Satsang',
	state: { type: 'satsang', isModal: true },
};

const playlistsRoute: RouteProps = {
	to: '/playlists',
	page: 'Playlists',
	primary: 'Playlists',
	title: 'Playlists',
	icon: Icons.Playlist,
};

const playlistRoute: RouteProps = {
	to: '/playlist/:href',
	page: 'Playlist',
	primary: 'Playlist',
	title: 'Playlist',
};

const reorderPlaylistRoute: RouteProps = {
	to: '/playlist/reorder/:id',
	page: 'ReorderPlaylist',
	primary: 'Reorder Playlist',
	title: 'Reorder Playlist',
	state: { isModal: true },
};

const signinRoute: RouteProps = {
	to: '/signin',
	page: 'SignIn',
	primary: 'Sign In',
	title: 'Sign In',
	state: { isModal: true },
};

export const phoneSigninRoute: RouteProps = {
	to: '/phonesignin',
	page: 'PhoneSignIn',
	primary: 'Phone Sign In',
	title: 'Phone Sign In',
	state: { isModal: true },
};

// const userOptionsRoute: RouteProps = {
// 	to: '/account',
// 	page: 'UserOptions',
// 	primary: 'Account',
// 	title: 'Account',
// 	state: { isModal: true },
// 	icon: Icons.Account,
// };

const userOptionsSubRoute: RouteProps = {
	to: '/account/:key?/:path1?/:path2?/:path3?',
	page: 'UserOptions',
	primary: 'Account',
	title: 'Account',
	state: {
		isModal: true,
	},
	icon: Icons.Account,
};

const editAddressRoute: RouteProps = {
	to: '/address/:addrId?',
	page: 'EditAddress',
	primary: 'Address',
	title: 'Address',
	state: {
		isModal: true,
	},
};

export const sandeshListRoute: RouteProps = {
	to: '/sandesh',
	page: 'Sandesh',
	primary: 'Sandesh',
	title: 'Sandesh',
	icon: Icons.Chat,
	state: { allowSearch: true },
};

const ssnRequestRoute: RouteProps = {
	to: '/ssnrequest/:id?',
	page: 'SSNRequest',
	primary: 'SSNRequest',
	title: 'SSNRequest',
	icon: Icons.Chat,
	state: { isModal: true },
};

const nityaNiyamRoute: RouteProps = {
	to: '/nityaniyam',
	page: 'NityaNiyam',
	primary: 'Nitya Niyam',
	title: 'Nitya Niyam',
	icon: Icons.AllInclusiveIcon,
};

const nityaNiyamDhyanRoute: RouteProps = {
	to: '/dhyan/:href',
	exactTo: '/dhyan/hKxuvxDxigC7RDJ0KvRi',
	onClick: () => {
		MediaPlayer.instance().playAudio(DhyanArticleId, 'dhyan', 0);
	},
	page: 'Media',
	primary: 'Dhyan',
	title: 'Dhyan',
	icon: Icons.MeditationIcon,
	state: { type: 'dhyan', isModal: true },
};

export const adminRoute: RouteProps = {
	to: '/admin/:collection',
	page: 'Admin',
	primary: 'Admin',
	title: 'Admin',
	icon: Icons.Admin,
	state: {
		subRoutes: [
			{
				to: '/admin/articles',
				page: 'Admin',
				primary: 'Articles',
				title: 'Articles',
				icon: Icons.Settings,
				state: { allowSearch: true },
			},
			{
				to: '/admin/notices',
				page: 'Admin',
				primary: 'Notices',
				title: 'Notices',
				icon: Icons.Settings,
			},
			{
				to: '/admin/quotes',
				page: 'Admin',
				primary: 'Quotes',
				title: 'Quotes',
				icon: Icons.Settings,
			},
			{
				to: '/admin/editions',
				page: 'Admin',
				primary: 'Editions',
				title: 'Editions',
				icon: Icons.Settings,
			},
			{
				to: '/admin/rhymes',
				page: 'Admin',
				primary: 'SoW',
				title: 'SoW',
				icon: Icons.Settings,
				state: { allowSearch: true },
			},
			{
				to: '/admin/hd',
				page: 'Admin',
				primary: 'Historical Dates',
				title: 'Historical Dates',
				icon: Icons.Settings,
			},
			{
				to: '/admin/publications',
				page: 'Admin',
				primary: 'Publications',
				title: 'Publications',
				icon: Icons.Settings,
			},
			{
				to: '/admin/booklets',
				page: 'Admin',
				primary: 'Booklets',
				title: 'Booklets',
				icon: Icons.Settings,
			},
			{
				to: '/admin/features',
				page: 'Admin',
				primary: 'Features',
				title: 'Features',
				icon: Icons.Settings,
			},
			// {
			// 	to: '/admin/srcm',
			// 	page: 'Admin',
			// 	primary: 'SRCM',
			// 	title: 'SRCM',
			// 	icon: Icons.Satsang,
			// },
		],
	},
};

export const adminSetRecordRoute: RouteProps = {
	to: '/admin/set/:collection/:id?',
	page: 'AdminSetRecord',
	primary: 'Set Record',
	title: 'Set Record',
	state: { isModal: true },
};

export const finishEmailLinkSignUp: RouteProps = {
	to: '/finishEmailLinkSignUp',
	page: 'EmailLinkSignup',
	primary: 'SignUp',
	title: 'SignUp',
	state: { isModal: true },
};

export const finishEmailLinkUpdate: RouteProps = {
	to: '/finishEmailLinkUpdate',
	page: 'EmailLinkUpdate',
	primary: 'Email Update',
	title: 'Email Update',
	state: { isModal: true },
};

const contactUsRoute: RouteProps = {
	to: '/contact',
	page: 'Contact',
	primary: 'Contact Us',
	title: 'Contact Us',
	state: { isModal: true },
};

const feedbackRoute: RouteProps = {
	to: '/feedback',
	page: 'Feedback',
	primary: 'Feedback',
	title: 'Provide Feedback',
	state: { isModal: true },
};

const termsRoute: RouteProps = {
	to: '/terms',
	page: 'Terms',
	primary: 'Terms & Privacy',
	title: 'Policies',
	state: { isModal: true },
};

const privacyRoute: RouteProps = {
	to: '/privacy',
	page: 'Terms',
	primary: 'Terms & Privacy',
	title: 'Policies',
	state: { isModal: true },
};

// const chatRoute: RouteProps = {
// 	to: '/chat/:channelId?',
// 	page: 'Chat',
// 	primary: 'Sandesh',
// 	title: 'Sandesh',
// 	// icon: Icons.Chat,
// };

export const homeGroup: RouteGroup = {
	routes: [homeRoute, introRoute],
};

export const nityaNiyamGroup: RouteGroup = {
	routes: [nityaNiyamRoute, nityaNiyamDhyanRoute],
};

export const mediaGroup: RouteGroup = {
	routes: [pkpRoute, bhajanListRoute, satsangListRoute, playlistsRoute],
};

export const readGroup: RouteGroup = {
	routes: [publicationsSAPDRoute, publicationsSPDRoute, shriamarvaniListRoute, rhymeListRoute],
};

export const quoteGroup: RouteGroup = {
	routes: [calendarRoute, quoteRoute, noticesRoute, featureRoute],
};

export const webQuoteGroup: RouteGroup = {
	routes: [calendarRoute, quoteRoute, noticesRoute],
};

export const donationGroup: RouteGroup = {
	routes: [donationRoute],
};

export const WebGroup: RouteGroup = {
	routes: [homeRoute, publicationsRoute, calendarRoute, donationRoute],
};

export const WebRightGroup: RouteGroup = {
	routes: [quoteRoute, noticesRoute],
};

export const WebFooterGroup: RouteGroup = {
	routes: [contactUsRoute, feedbackRoute, termsRoute],
};

// AppRouteGroups is for surfacing a route in AppDrawer
export const AppRouteGroups: RouteGroup[] = [homeGroup, nityaNiyamGroup, mediaGroup, readGroup, quoteGroup, donationGroup];

export const WebRouteGroups: RouteGroup[] = [{ routes: [homeRoute, publicationsRoute] }, webQuoteGroup, donationGroup];

export const adminGroup: RouteGroup = {
	routes: [adminRoute],
};
// AppRouteGroups is for surfacing a route in AppDrawer
export const AppAdminRouteGroups: RouteGroup[] = [adminGroup, ...AppRouteGroups];

export const WebAppCommonRoutes: RouteProps[] = [
	publicationsSAPDRoute,
	publicationsSPDRoute,
	publicationsRoute,
	publicationRoute,
	subscribeRoute,
	cartRoute,
	calendarSubRoute,
	calendarRoute,
	donationRoute,
	quoteRoute,
	noticesRoute,
	signinRoute,
	phoneSigninRoute,
	userOptionsSubRoute,
	finishEmailLinkSignUp,
	finishEmailLinkUpdate,
	contactUsRoute,
	feedbackRoute,
	termsRoute,
	privacyRoute,
	editAddressRoute,
	hdRoute,
	// chatRoute,
	homeRoute,
];

// AppRoutes is for react-router to show associated content of a route
export const AppRoutes: RouteProps[] = [
	introRoute,
	pkpRoute,
	bhajanListRoute,
	satsangListRoute,
	shriamarvaniListRoute,
	rhymeListRoute,
	bhajanPageRoute,
	satsangPageRoute,
	sandeshPageRoute,
	shriamarvaniPageRoute,
	shriaartiPageRoute,
	bhajanRoute,
	satsangRoute,
	shriaartiRoute,
	playlistRoute,
	playlistsRoute,
	reorderPlaylistRoute,
	rhymeRoute,
	featureRoute,
	sandeshListRoute,
	ssnRequestRoute,
	nityaNiyamDhyanRoute,
	nityaNiyamRoute,
	...WebAppCommonRoutes,
];

export const AdminRoutes: RouteProps[] = [adminRoute, adminSetRecordRoute];

export const WebRoutes: RouteProps[] = [...WebAppCommonRoutes];

export const adminRhymeRoute = {
	to: '/admin/rhymes',
	page: 'Admin',
	primary: 'Edit Rhymes',
	title: 'Edit Rhymes',
	icon: Icons.Settings,
	state: { allowSearch: true },
};

export const adminSRCMRoute = {
	to: '/admin/srcm',
	page: 'Admin',
	primary: 'SRCM',
	title: 'SRCM',
	icon: Icons.Satsang,
	state: { allowSearch: true },
};

export const adminMinimalRoute: RouteProps = {
	to: '/admin/:collection',
	page: 'Admin',
	primary: 'Admin',
	title: 'Admin',
	icon: Icons.Admin,
	state: {
		subRoutes: [adminRhymeRoute, adminSRCMRoute],
	},
};

const minimalHomeRoute: RouteProps = {
	to: '/',
	page: 'RhymeList',
	primary: 'Rhymes List',
	title: OrgName,
	icon: Icons.Home,
};

export const MinimalRoutes: RouteProps[] = [rhymeRoute, rhymeListRoute, adminMinimalRoute, adminSetRecordRoute, userOptionsSubRoute, minimalHomeRoute];
export const minimalRouteGroups: RouteGroup[] = [
	{
		routes: [minimalHomeRoute, adminRhymeRoute, adminSRCMRoute],
	},
];
