import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import { getEditionsByPubId } from 'store/data/publications/selectors';
import { ApplicationState, ContentType, Edition } from 'types';
import { Order, OrderItem, OrderItemType } from 'types/orders';

export interface IProductOrder {
	image?: string;
	title?: string;
	icon?: JSX.Element;
	lang?: string;
	fq?: string;
	desc?: string;
	price?: number;
}

export const GetProductOrderDetails = (
	state: ApplicationState,
	order: Order,
	orderItem: OrderItem,
	locale?: string
): IProductOrder => {
	let orderItemType = orderItem.itemType;

	switch (orderItemType) {
		case OrderItemType.Publication:
			let pubId = orderItem.itemId;
			let pub = state.dataState.publications.byId[pubId];
			let allEditions = getEditionsByPubId(state) || {};
			let editions = allEditions[pubId] || [];

			let edition: Edition | null = null;

			// let paymentEvents = order.events.filter(ev => ev.type === OrderEventType.PaymentSuccess).sort((a, b) => b.createdAt - a.createdAt);
			if (order.purchaseTime) {
				editions.sort((a, b) => b.publishTime.seconds - a.publishTime.seconds);
				for (let ed of editions) {
					if (ed.publishTime.seconds - order.purchaseTime <= 0) {
						edition = ed;
						break;
					}
				}
			}
			if (!edition) {
				edition = editions.length
					? editions.reduce((max, edition) => {
							return max.publishTime.seconds > edition.publishTime.seconds ? max : edition;
					  })
					: null;
			}

			let image = getContentStorageUrl(ContentType.Edition, 'cover', edition);
			let title = pub.title;
			let icon = Icons.Publication;
			let lang = pub.lang;
			let fq = pub.frequency === 1 ? 'Monthly' : 'Bi-Monthly';
			let desc = pub.desc;
			let price = pub.price;

			return {
				image,
				title,
				icon,
				lang,
				fq,
				desc,
				price,
			};
		default:
			return {};
	}
};
