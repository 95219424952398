import { logEvent } from 'components/telemetry';
import { FireStoreModule } from 'fb/index';
import { fireAuth, fireDB } from 'fb/initconfig';
import { User } from 'firebase/auth';
import 'firebase/firestore';
import { collection, deleteField, doc, Firestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { timeout } from 'helpers';
import { Action } from 'redux';
import { updateFBUserPlaylistsLocal, updateUserSubColl } from 'store/data/user/actions';
import { ApplicationState, UserPlaylist, UserPlaylistTrack } from 'types';
import { ApiMetaData } from './http';

const setPlaylistRecord = async (userId, playlistId, playlist, merge = false) => {
	let db = fireDB;

	await setDoc(doc(collection(doc(collection(db, 'users'), userId), 'playlists'), playlistId), playlist, { merge: merge });
	await setDoc(
		doc(collection(db, 'users'), userId),
		{
			playlistUpdatedAt: serverTimestamp(),
		},
		{ merge: true }
	);
};

export class PlaylistsFireStoreModule implements FireStoreModule {
	private user: User;
	constructor(user: User) {
		this.user = user;
	}

	public getModuleName = () => 'playlists';
	public getOpsKeySuffix = () => this.user.uid;
	public getFBWatchQuery = (db: Firestore) => collection(doc(collection(db, 'users'), this.user.uid), 'playlists');
	public getChangeProcessAction = (changes) => updateUserSubColl('playlists', this.user.uid, changes, fireAuth.currentUser?.uid);
}

const CREATE_PLAYLIST = '@@api/CREATE_PLAYLIST';
type T_CREATE_PLAYLIST = typeof CREATE_PLAYLIST;

export interface CreatePlaylistData {
	title: string;
	id?: string;
}

export interface CreatePlaylist extends Action {
	type: T_CREATE_PLAYLIST;
	data: CreatePlaylistData;
}

export const createPlaylist = (data: CreatePlaylistData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		let db = fireDB;
		let user = fireAuth.currentUser;

		if (!user) {
			throw new Error('user not signed in');
		}

		let playlist = {
			title: data.title,
			tracks: {},
			deleted: false,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		};

		let id = data.id ?? doc(collection(doc(collection(fireDB, 'users'), user.uid), 'playlists')).id;
		await setPlaylistRecord(user.uid, id, playlist);
		logEvent('playlist_add', { title: data.title, playlistId: id });

		let playlists = getState().userState.userStore.subColl?.playlists ?? {};
		playlists[id] = {
			id: id,
			title: data.title,
			tracks: {},
			deleted: false,
			userId: user.uid,
		} as UserPlaylist;

		dispatch(updateFBUserPlaylistsLocal(playlists));

		return playlists[id];
	};
};

const MODIFY_PLAYLIST_TRACKS = '@@api/MODIFY_PLAYLIST_TRACKS';
type T_MODIFY_PLAYLIST_TRACKS = typeof MODIFY_PLAYLIST_TRACKS;

export interface ModifyPlaylistTracksData {
	playlistId: string;
	articleId: string;
	action: 'Add' | 'Remove';
}

export interface ModifyPlaylistTracks extends Action {
	type: T_MODIFY_PLAYLIST_TRACKS;
	data: ModifyPlaylistTracksData;
}

export const modifyPlaylistTracks = (data: ModifyPlaylistTracksData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		let db = fireDB;
		let user = fireAuth.currentUser;

		if (!user) {
			return;
		}

		let playlists = getState().userState.userStore.subColl?.playlists ?? {};
		let playlist = playlists[data.playlistId];

		if (data.action === 'Add') {
			if ((!playlist || playlist.deleted) && data.playlistId === '0') {
				playlist = await dispatch(createPlaylist({ title: 'Saved for Offline', id: '0' }));
				await timeout(10);
				return dispatch(modifyPlaylistTracks(data));
			}

			if (!playlist) {
				throw new Error('user not signed in');
			}

			let tracks = playlist.tracks;

			let minOrder = Number.MAX_SAFE_INTEGER;
			Object.values(tracks).forEach((value) => {
				if (minOrder > value.order) {
					minOrder = value.order;
				}
			});

			await setPlaylistRecord(
				user.uid,
				data.playlistId,
				{
					tracks: {
						[data.articleId]: {
							order: tracks[data.articleId]?.order ?? minOrder - 1,
						},
					},
					updatedAt: serverTimestamp(),
				},
				true
			);

			logEvent('playlist_update', {
				playlistId: data.playlistId,
				articleId: data.articleId,
				action: data.action,
			});

			playlists[data.playlistId].tracks[data.articleId] = {
				articleId: data.articleId,
				order: tracks[data.articleId]?.order ?? minOrder - 1,
			};
			dispatch(updateFBUserPlaylistsLocal(playlists));
		}
		if (data.action === 'Remove') {
			if (!playlist) {
				throw new Error('user not signed in');
			}

			await setPlaylistRecord(
				user.uid,
				data.playlistId,
				{
					tracks: { [data.articleId]: deleteField() },
					updatedAt: serverTimestamp(),
				},
				true
			);

			logEvent('playlist_update', {
				playlistId: data.playlistId,
				articleId: data.articleId,
				action: data.action,
			});

			delete playlist.tracks[data.articleId];
			dispatch(updateFBUserPlaylistsLocal(playlists));
		}
	};
};

const DELETE_PLAYLIST = '@@api/DELETE_PLAYLIST';
type T_DELETE_PLAYLIST = typeof DELETE_PLAYLIST;

export interface DeletePlaylistData {
	playlistId: string;
}

export interface DeletePlaylist extends Action {
	type: T_DELETE_PLAYLIST;
	data: DeletePlaylistData;
	meta: ApiMetaData;
}

export const deletePlaylist = (data: DeletePlaylistData, meta: ApiMetaData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		let db = fireDB;
		let user = fireAuth.currentUser;

		if (!user) {
			throw new Error('user not signed in');
		}

		updateDoc(doc(collection(doc(collection(db, 'users'), user.uid), 'playlists'), data.playlistId), {
			deleted: true,
		});

		logEvent('playlist_delete', { playlistId: data.playlistId });

		let playlists = getState().userState.userStore.subColl?.playlists ?? {};
		delete playlists[data.playlistId];
		dispatch(updateFBUserPlaylistsLocal(playlists));
	};
};

const RENAME_PLAYLIST = '@@api/RENAME_PLAYLIST';
type T_RENAME_PLAYLIST = typeof RENAME_PLAYLIST;

export interface RenamePlaylistData {
	playlistId: string;
	title: string;
}

export interface RenamePlaylist extends Action {
	type: T_RENAME_PLAYLIST;
	data: RenamePlaylistData;
	meta: ApiMetaData;
}

export const renamePlaylist = (data: RenamePlaylistData, meta: ApiMetaData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		let db = fireDB;
		let user = fireAuth.currentUser;

		if (!user) {
			throw new Error('user not signed in');
		}

		updateDoc(doc(collection(doc(collection(db, 'users'), user.uid), 'playlists'), data.playlistId), {
			title: data.title,
			updatedAt: serverTimestamp(),
		});

		logEvent('playlist_rename', { playlistId: data.playlistId, title: data.title });

		let playlists = getState().userState.userStore.subColl?.playlists ?? {};
		playlists[data.playlistId].title = data.title;
		dispatch(updateFBUserPlaylistsLocal(playlists));
	};
};

export const REORDER_PLAYLIST = '@@api/REORDER_PLAYLIST';
type T_REORDER_PLAYLIST = typeof REORDER_PLAYLIST;

export interface ReorderPlaylistData {
	playlist: UserPlaylist;
	orderedTracks: UserPlaylistTrack[];
}

export interface ReorderPlaylist extends Action {
	type: T_REORDER_PLAYLIST;
	data: ReorderPlaylistData;
}

export const reorderPlaylist = (data: ReorderPlaylistData) => {
	return {
		type: REORDER_PLAYLIST,
		data,
	};
};

export const reorderPlaylistNow = (data: ReorderPlaylistData) => {
	return async (dispatch, getState: () => ApplicationState) => {
		try {
			let db = fireDB;
			let user = fireAuth.currentUser;

			if (!user) {
				throw new Error('user not signed in');
			}

			let playlist = data.playlist;
			let tracks = {};
			data.orderedTracks.forEach((value, index) => {
				tracks[value.articleId] = { order: index };
			});

			updateDoc(doc(collection(doc(collection(db, 'users'), user.uid), 'playlists'), playlist.id), {
				tracks: tracks,
				updatedAt: serverTimestamp(),
			});

			logEvent('playlist_reorder', { playlistId: data.playlist.id, tracks: JSON.stringify(tracks) });

			let playlists = getState().userState.userStore.subColl?.playlists ?? {};
			for (let id in tracks) {
				tracks[id].articleId = id;
			}
			playlists[playlist.id].tracks = tracks;
			dispatch(updateFBUserPlaylistsLocal(playlists));
		} catch (error) {
			console.error('reorderPlaylistNow: ' + error.message, error.stack);
		}
	};
};
