import TracksList from 'components/playlist/TracksList';
import React from 'react';
import { useParams } from 'react-router-dom';
import Page from './Page';

export default function PlaylistPage(props) {
	let { href } = useParams();

	return (
		<Page title='Playlist' includeBottomNavigation topControlHeight={64} dark>
			<TracksList playlistId={href} />
		</Page>
	);
}
