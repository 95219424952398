import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Quote } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_QUOTES = '@@quotes/UPDATE_QUOTES';
export type T_UPDATE_QUOTES = typeof UPDATE_QUOTES;

export interface UpdateQuotes extends Action {
	type: T_UPDATE_QUOTES;
	changes: FBChanges;
	overwrite?: boolean;
}

export function updateQuotes(changes: FBChanges, overwrite?: boolean): UpdateQuotes {
	return {
		type: UPDATE_QUOTES,
		changes,
		overwrite,
	};
}

export const UPDATE_QUOTES_FILTERED = UPDATE_QUOTES + '_FILTERED';
export type T_UPDATE_QUOTES_FILTERED = typeof UPDATE_QUOTES_FILTERED;

export interface UpdateQuotesFiltered extends Action {
	type: T_UPDATE_QUOTES_FILTERED;
	payload: Quote[];
}

export const SHOW_TODAYS_QUOTE = 'SHOW_TODAYS_QUOTE';
export type T_SHOW_TODAYS_QUOTE = typeof SHOW_TODAYS_QUOTE;

export interface ShowTodaysQuote extends Action {
	type: T_SHOW_TODAYS_QUOTE;
}

export function showTodaysQuote(): ShowTodaysQuote {
	return {
		type: SHOW_TODAYS_QUOTE,
	};
}
