import { combineReducers } from 'redux';
import { Article, ArticlesById, ArticleStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateArticlesFiltered, UpdateFBArticles, UPDATE_FB_ARTICLES, UPDATE_FB_ARTICLES_FILTERED } from './actions';

export function fbArticlesByIdReducer(state: ArticlesById = {}, action: UpdateFBArticles): ArticlesById {
	switch (action.type) {
		case UPDATE_FB_ARTICLES:
			return updateData('Articles', state, action.changes, action.overwrite);
		default:
			return state;
	}
}

export const filteredArticlesReducer = (state: Article[] = [], action: UpdateArticlesFiltered) => {
	switch (action.type) {
		case UPDATE_FB_ARTICLES_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const articlesReducer = combineReducers<ArticleStore>({
	byId: fbArticlesByIdReducer,
});
