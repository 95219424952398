import { Box, Button, Card, CardContent, CardHeader, CardMedia, Divider, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { NumberInput } from 'components/NumberInput';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { LangMap } from 'config';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { emptyCart, removeFromCart, updateCartItem } from 'store/data/cart/actions';
import { onEvent } from 'store/temp/actions';
import { ApplicationState, Cart, ContentType } from 'types';
import { Order, OrderItem, OrderItemType, PaymentStatus } from 'types/orders';
import { GetProductOrderDetails } from '../products/ProductOrderDetails';
import { CartScreens } from './CartScreen';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	card: (theme) => ({
		margin: theme.spacing(1),
	}),
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-body1': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
			fontWeight: 700,
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
			alignSelf: 'baseline',
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	content: {},
	contentRow: (theme) => ({
		flexDirection: 'row',
		display: 'flex',
		position: 'relative' as 'relative',
		width: '100%',
		margin: `${theme.spacing(1)} 0`,

		'& .MuiToggleButton-root': {
			lineHeight: 1,
		},
	}),
	label: {
		width: '50%',
		alignSelf: 'center',
		fontSize: '1.8rem',
	},
	value: {
		flexGrow: 1,
		textAlign: 'right' as 'right',
		fontWeight: 700,
		fontSize: '1.8rem',
	},
	divider: {
		margin: '16px 0 8px 0',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	actions: (theme) => ({
		backgroundColor: theme.palette.primary.main,
		justifyContent: 'center',
		color: 'white',
		borderColor: 'white',
		width: '100%',

		'& > a': {
			color: 'white',
			borderColor: 'white',
			padding: '8px 16px',
			border: '1px solid white',
			borderRadius: 4,
			fontSize: '1.6em',
			textTransform: 'uppercase',
		},
		'& > button': {
			color: 'white',
			borderColor: 'white',
		},
	}),
	media: {
		width: 'auto',
		height: 60,
		WebkitBoxReflect:
			'below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, transparent), to(rgba(255, 255, 255, 0.5)))',
		objectFit: 'contain',
	},
	desc: (theme) => ({
		paddingLeft: theme.spacing(2),
		// height: 200,
		width: '55%',
		marginLeft: 'auto',
		marginBottom: '1.5em',
		color: theme.palette.text.secondary,
	}),
};

interface Props extends ScreenManagerProps {
	history: any;
	state: ApplicationState;
	cart: Cart;
	orders: Order[];
	updateCartItem: (pubId: string, quantity: number) => void;
	removeFromCart: (pubId: string) => void;
	emptyCart: () => void;
}

interface State {}

class Component extends React.Component<Props, State> {
	componentDidMount() {
		let orderId = this.props.cart.orderId;
		if (!orderId) {
			return;
		}

		let order: Order | undefined;
		let orderList = this.props.orders.filter((o) => o.id === orderId);
		if (orderList && orderList.length) {
			order = orderList[0];
		}

		let status = order?.paymentDetails.status ?? PaymentStatus.Started;
		if (status === PaymentStatus.Completed) {
			this.props.emptyCart();
		}
	}

	render() {
		let { cart, state, updateCartItem, removeFromCart, pushScreen, setFamilyState } = this.props;

		let grandTotal = 0;
		let grandShipping = 0;
		let grandPrice = 0;

		let orderItemTiles = Object.values(cart.items).map((item) => {
			let { price, title, image, lang } = GetProductOrderDetails(
				state,
				{} as Order,
				{ itemType: item.itemType, itemId: item.itemId } as OrderItem
			);

			price = price ?? 0;
			let quantity = item.quantity;
			let totalPrice = quantity * price;
			let shipping = totalPrice * 0.25;
			let total = totalPrice + shipping;

			grandPrice += totalPrice;
			grandShipping += shipping;
			grandTotal += total;

			return (
				<Card key={item.itemId} sx={classes.card}>
					<CardHeader
						sx={classes.header}
						avatar={
							<CardMedia
								sx={classes.media}
								component='img'
								image={image}
								onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
									event.currentTarget.src = getContentStorageUrl(
										ContentType.Calendar,
										'no_internet.png'
									);
								}}
							/>
						}
						title={
							<Typography noWrap className={lang === 'hi' ? 'locale-hi' : ''}>
								{title}
							</Typography>
						}
						subheader={
							<Typography variant='subtitle1' noWrap>
								{LangMap[lang ?? 'en']}
							</Typography>
						}
					/>
					<CardContent sx={classes.content}>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Price
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {price?.toFixed(2) ?? 0}
							</Typography>
						</Box>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Quantity
							</Typography>
							<NumberInput
								style={{ marginLeft: 'auto' }}
								min={1}
								allowDelete
								number={item.quantity}
								setNumber={(number) => {
									if (number === 0) {
										removeFromCart(item.itemId);
									} else {
										updateCartItem(item.itemId, number);
									}
								}}
							/>
						</Box>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Shipping
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {shipping.toFixed(2)}
							</Typography>
						</Box>
						<Divider sx={classes.divider} />
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Total <br />
								<span style={{ fontSize: '1.4rem' }}>(inclusive of all taxes)</span>
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {total.toFixed(2)}
							</Typography>
						</Box>
					</CardContent>
					{/* <CardActions className={classes.actions}>
						<Button
							variant='outlined'
							size='small'
							onClick={() => {
								removeFromCart(item.itemId);
							}}
						>
							Remove
						</Button>
					</CardActions> */}
				</Card>
			);
		});

		if (!orderItemTiles.length) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader={
							'No items presnt in the cart. Cart is empty!. You may go to publications page to order hard copy of books.'
						}
					/>
				</Card>
			);
		}

		return (
			<Box sx={classes.root}>
				{orderItemTiles}
				<Card sx={classes.card}>
					<CardHeader sx={classes.header} title={<Typography noWrap>Price Details</Typography>} />
					<CardContent>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Price ({orderItemTiles.length} item{orderItemTiles.length > 1 ? 's' : ''})
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {grandPrice.toFixed(2)}
							</Typography>
						</Box>

						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Shipping
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {grandShipping.toFixed(2)}
							</Typography>
						</Box>
						<Divider sx={classes.divider} />
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Grand Total <br />
								<span style={{ fontSize: '1.4rem' }}>(inclusive of all taxes)</span>
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {grandTotal.toFixed(2)}
							</Typography>
						</Box>
					</CardContent>
				</Card>
				<Button
					sx={classes.btn}
					variant='contained'
					color='primary'
					onClick={() => {
						setFamilyState({
							grandTotal: grandTotal,
							items: cart.items,
						});
						pushScreen(CartScreens.Addresses);
					}}
				>
					Next: Select Address
					{Icons.KeyRightArrow}
				</Button>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	let cart = state.dataState.cart;
	let orders = state.filteredDataState.orders;

	return { cart, orders, state };
}

function mapDispatchToProps(dispatch: any) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		removeFromCart: (pubId: string) => {
			dispatch(removeFromCart(pubId));
		},
		updateCartItem: (pubId: string, quantity: number) => {
			dispatch(updateCartItem({ itemId: pubId, itemType: OrderItemType.Publication, quantity }));
		},
		emptyCart: () => {
			dispatch(emptyCart());
		},
	};
}

export const OrderDetailsScreen = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
