import Policies from 'components/documents/Policies';
import React from 'react';
import DialogPage from './DialogPage';
import Page from './Page';

export default function TermsPage(props) {
	let { isModal } = props;

	return (
		<>
			{isModal ? (
				<DialogPage title='Policies' dark goBackOnClose={!!isModal} goBackTo='/'>
					<Policies />
				</DialogPage>
			) : (
				<Page title='Policy and Terms' includeBottomNavigation>
					<Policies />
				</Page>
			)}
		</>
	);
}
