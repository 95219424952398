import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';
import { updateFeatures } from 'store/data/features/actions';

export class FeaturesFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'features';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'features');
	public getChangeProcessAction = (changes) => updateFeatures(changes);
}
