import { Box, Button, Card, CardHeader, Theme } from '@mui/material';
import { DialogContext } from 'components/pages/DialogPage';
import { withRouter } from 'components/shell/WithRouter';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, UserAddresses } from 'types';
import { AccountMode } from '../AccountScreenManager';
import { AddressTile } from './AddressTile';

let classes = {
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	addNewBtn: (theme: Theme) => ({
		height: 42,
		width: '80%',
		alignSelf: 'center',
		margin: theme.spacing(2),
	}),
};

interface AddressListProps {
	addresses: UserAddresses;
	user?: User;
	history: any;
	tileSelect?: (addrId: string) => void;
	selectedAddrId?: string;
}

interface AddressListState {
	selectedAddrId?: string;
}

class AddressListControl extends React.Component<AddressListProps, AddressListState> {
	context!: React.ContextType<typeof DialogContext>;

	constructor(props) {
		super(props);

		this.state = {
			selectedAddrId: props.selectedAddrId,
		};
	}

	render() {
		let { addresses, user, history, tileSelect } = this.props;
		let navigate = history.navigate;

		if (!user) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader='Please signin to manage your addresses!'
						action={
							<Button
								style={{ marginTop: 8 }}
								size='small'
								onClick={() => {
									navigate('/account?screen=signin', { state: { isModal: true } });
								}}
							>
								Sign In
							</Button>
						}
					/>
				</Card>
			);
		}

		let addressesArray = Object.values(addresses).filter(
			(addr) => (addr.active ?? true) && !(addr.deleted ?? false)
		);

		let newAddressBtn = (
			<Button
				sx={classes.addNewBtn}
				size='small'
				variant='contained'
				color='primary'
				onClick={() => {
					if (this.context && this.context.pushScreen) {
						this.context.pushScreen({ key: AccountMode.Address, title: 'Add New Address' });
					} else {
						navigate('/address', { state: { isModal: true } });
					}
				}}
			>
				{Icons.Add} Add New Address
			</Button>
		);

		return (
			<Box sx={classes.root}>
				{newAddressBtn}
				{
					addressesArray.length > 0 &&
						addressesArray.map((address, i) => (
							<AddressTile
								style={
									tileSelect && this.state.selectedAddrId === address.id
										? { backgroundColor: 'rgba(50, 183, 140, 0.25)' }
										: {}
								}
								onClick={() => {
									this.setState({ selectedAddrId: address.id });
									tileSelect && tileSelect(address.id);
								}}
								key={address.id}
								address={address}
							/>
						))
					// <RecordList
					// 	records={addressesArray}
					// 	fetching={false}
					// 	RecordTile={AddressTile}
					// 	getItemSize={this.getItemSize}
					// ></RecordList>
				}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState, any) {
	return {
		addresses: state.userState.userStore.subColl?.addresses,
		allArticles: state.dataState.articles.byId,
		user: state.userState.userStore.user,
	};
}

AddressListControl.contextType = DialogContext;

export default connect(mapStateToProps)(withRouter(AddressListControl));
