import { ArticleTextContent } from 'components/articles/ArticleTextContent';
import { ArticleTypeMap } from 'config';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import Page from './Page';

export default function ArticlePage(props) {
	let { href } = useParams();
	let { routeState, isModal } = props;

	return isModal ? (
		<DialogPage title={ArticleTypeMap[routeState.type]} transition='left'>
			<ArticleTextContent articleType={routeState.type} href={href!} />
		</DialogPage>
	) : (
		<Page title={ArticleTypeMap[routeState.type]} includeBottomNavigation>
			<ArticleTextContent articleType={routeState.type} href={href!} />
		</Page>
	);
}
