import { Box, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { withRouter } from 'components/shell/WithRouter';
import TextContent from 'components/TextContent';
import { fireAuth } from 'fb/initconfig';
import { ConfirmationResult, RecaptchaVerifier, User } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { isSupportedCountry, timeout } from 'helpers';
import { MuiTelInput } from 'mui-tel-input';
import React from 'react';
import { connect } from 'react-redux';
import {
	CustomOTPData,
	CustomPhoneLoginData,
	PhoneLoginData,
	phoneSignIn,
	triggerCustomPhoneSignIn,
	triggerPhoneSignIn,
	verifyCustomOTP,
} from 'services/api/auth';
import { enqueueSnackbar, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, UserData } from 'types';
import * as Yup from 'yup';

const classes = {
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),
	}),
};

interface Props {
	locale: string;
	toggleDrawer: (content?) => void;
	history: any;
	userData?: UserData;
	user?: User;
	phoneSignIn: (
		code: string,
		confirmationResult: ConfirmationResult,
		link?: boolean,
		reauth?: boolean
	) => Promise<UserData>;
	triggerPhoneSignIn: (data: PhoneLoginData) => Promise<ConfirmationResult>;
	triggerCustomPhoneSignIn: (data: CustomPhoneLoginData) => Promise<any>;
	verifyCustomOTP: (data: CustomOTPData) => Promise<any>;
	enqueueSnackbar: (args) => void;
	onSignInComplete?: () => void;
	forDelete?: boolean;
	style?: any;
	setMode?: (mode: string) => void;
}

interface State {
	mode: 'phone' | 'otp' | 'link';
	captchaVerified: boolean;
	otpTimer: number;
	retriesLeft: number;
}

class PhoneSignInComponent extends React.Component<Props, State> {
	context!: React.ContextType<typeof DialogContext>;

	private submitBtnId = 'phone-signin-submit-btn';
	private recaptchaVerifier;
	private confirmationResult?: ConfirmationResult;
	private otpInterval;

	constructor(props) {
		super(props);

		this.state = {
			mode: 'phone',
			captchaVerified: false,
			otpTimer: 0,
			retriesLeft: 3,
		};

		this.props.setMode && this.props.setMode('phone');
	}

	startOTPTimer = () => {
		let time = 30;

		if (this.state.retriesLeft <= 1) {
			this.setState({ retriesLeft: 0 });
			return;
		}

		this.setState({ otpTimer: time, retriesLeft: this.state.retriesLeft - 1 });

		this.otpInterval = setInterval(() => {
			if (this.state.otpTimer > 0) {
				this.setState({ otpTimer: this.state.otpTimer - 1 }, () => {
					if (this.state.otpTimer === 0) {
						clearInterval(this.otpInterval);
					}
				});
			}
		}, 1000);
	};

	componentDidMount = async () => {
		this.recaptchaVerifier = new RecaptchaVerifier(fireAuth, this.submitBtnId, {
			size: 'invisible',
			callback: (response) => {
				this.setState({ captchaVerified: true });
			},
		});
	};

	componentWillUnmount = () => {
		clearInterval(this.otpInterval);
	};

	onSuccess = () => {
		this.props.enqueueSnackbar({
			message: 'Verification Successful!',
			options: {
				variant: 'success',
			},
		});

		if (this.props.onSignInComplete) {
			this.props.onSignInComplete();
		}

		if (!this.props.forDelete) {
			this.context?.popScreen(true);
		}
	};

	phoneAuth = async (values, setSubmitting, setErrors, retry?) => {
		let phone = values.phone.replace(/ /g, '').replace(/\(/, '').replace(/\)/, '').replace(/-/, '');

		try {
			if (!retry && (this.state.mode === 'otp' || this.state.mode === 'link')) {
				if (isSupportedCountry(values.phone)) {
					await this.props.verifyCustomOTP({
						OTP: values.otp,
						number: phone,
						link: this.state.mode === 'link',
					});
				} else if (this.confirmationResult) {
					await this.props.phoneSignIn(
						values.otp,
						this.confirmationResult,
						this.state.mode === 'link',
						this.props.forDelete
					);
				}

				this.onSuccess();
			} else {
				if (phone.length === 0 || (phone.startsWith('+91') && phone.length !== 13)) {
					this.props.enqueueSnackbar({
						message: 'Invalid Phone Number!',
						options: {
							variant: 'error',
						},
					});
					return;
				}

				if (isSupportedCountry(values.phone)) {
					await this.props.triggerCustomPhoneSignIn({
						number: phone,
						retry,
						voice: retry && this.state.retriesLeft === 1,
					});
				} else {
					this.confirmationResult = await this.props.triggerPhoneSignIn({
						phone: phone,
						recaptchaVerifier: this.recaptchaVerifier,
					});
				}

				this.setState({ mode: 'otp' });
				this.props.setMode && this.props.setMode('otp');

				this.startOTPTimer();
			}
		} catch (error) {
			setSubmitting(false);

			if (error?.code === 'auth/invalid-verification-code') {
				this.setState({ mode: 'otp' }, () => {
					setErrors({ otp: 'OTP verification failed' });
				});
				this.props.setMode && this.props.setMode('otp');
				this.startOTPTimer();
			} else if (error?.code === 'auth/too-many-requests') {
				this.props.enqueueSnackbar({
					message: 'Too many OTP requests! Please try again after some time.',
					options: {
						variant: 'error',
					},
				});
				this.context?.popScreen();
			} else if (error?.code === 'auth/credential-already-in-use') {
				this.setState({ mode: 'link' });
				this.props.setMode && this.props.setMode('link');
			} else {
				if (error?.code === 'auth/code-expired') {
					await timeout(2000);

					if (this.props.user?.phoneNumber) {
						this.onSuccess();
						return;
					}
				}

				this.props.enqueueSnackbar({
					message: error?.message ?? JSON.stringify(error),
					options: {
						variant: 'error',
					},
				});
			}
		}
	};

	render() {
		let { style, userData, forDelete } = this.props;

		return (
			<div style={style}>
				<Formik
					initialValues={{
						phone: userData?.phone || '',
						otp: '',
					}}
					validationSchema={() => {
						return Yup.object({
							phone: Yup.string(),
							otp:
								this.state.mode === 'otp'
									? Yup.string().required('Enter OTP received on your number')
									: Yup.string(),
						});
					}}
					onSubmit={async (values, { setErrors, setSubmitting }) => {
						await this.phoneAuth(values, setSubmitting, setErrors);
					}}
				>
					{({ values, submitForm, isSubmitting, setFieldValue, setSubmitting, setErrors }) => (
						<Box component={Form} sx={classes.form} translate='yes'>
							{this.state.mode === 'link' ? (
								isSupportedCountry(values.phone) ? (
									<>
										<TextContent style={{ marginBottom: 32 }}>
											Phone number {values.phone} is associated with an existing account. Do you
											want to link it with current user account?
										</TextContent>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											color={'primary'}
											onClick={submitForm}
											disabled={isSubmitting}
										>
											Link Both Accounts
										</Button>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											disabled={isSubmitting}
											onClick={() => {
												setFieldValue('phone', '');
												setFieldValue('otp', '');
												this.setState({ mode: 'phone' });
											}}
										>
											Verify Different Number
										</Button>
									</>
								) : (
									<>
										<TextContent style={{ marginBottom: 32 }}>
											Phone number {values.phone} is associated with an existing account.
										</TextContent>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											disabled={isSubmitting}
											onClick={() => {
												setFieldValue('phone', '');
												setFieldValue('otp', '');
												this.setState({ mode: 'phone' });
											}}
										>
											Verify Different Number
										</Button>
									</>
								)
							) : (
								<>
									{this.state.mode === 'otp' ? (
										<Field
											component={TextField}
											autoComplete='off'
											name='otp'
											type='text'
											label={`Enter OTP (sent to ${values.phone})`}
										/>
									) : (
										<MuiTelInput
											defaultCountry={'IN'}
											disableDropdown
											// autoFormat={false}
											value={values.phone}
											onChange={(value) => {
												setFieldValue('phone', value);
											}}
										/>
									)}
									<Button
										sx={classes.signinbtn}
										variant='contained'
										color={forDelete ? 'inherit' : 'primary'}
										onClick={submitForm}
										disabled={isSubmitting}
									>
										{this.state.mode === 'otp'
											? forDelete
												? 'Confirm Delete'
												: 'Verify OTP'
											: 'Send OTP'}
									</Button>
									{this.state.mode === 'otp' ? (
										<Button
											sx={classes.signinbtn}
											variant='contained'
											onClick={async () => {
												await this.phoneAuth(values, setSubmitting, setErrors, true);
											}}
											disabled={
												isSubmitting || this.state.otpTimer > 0 || this.state.retriesLeft <= 0
											}
										>
											{this.state.retriesLeft <= 1 && isSupportedCountry(values.phone)
												? 'Voice Call'
												: 'Resend OTP'}
											{this.state.otpTimer > 0 ? ` (${this.state.otpTimer})` : ''}
										</Button>
									) : null}
								</>
							)}
							<span id={this.submitBtnId} style={{ display: 'none' }}></span>
						</Box>
					)}
				</Formik>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
		userData: state.userState.userStore.userData,
		user: state.userState.userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		triggerPhoneSignIn: async (data: PhoneLoginData) => {
			return await dispatch(triggerPhoneSignIn(data));
		},
		triggerCustomPhoneSignIn: async (data: CustomPhoneLoginData) => {
			return await dispatch(triggerCustomPhoneSignIn(data));
		},
		verifyCustomOTP: async (data: CustomOTPData) => {
			return await dispatch(verifyCustomOTP(data));
		},
		phoneSignIn: async (code: string, confirmationResult, link?: boolean, reauth?: boolean) => {
			return await dispatch(phoneSignIn(code, confirmationResult, link, reauth));
		},
		enqueueSnackbar: (args) => {
			dispatch(enqueueSnackbar(args));
		},
	};
}

PhoneSignInComponent.contextType = DialogContext;

export const PhoneSignInControl = connect(mapStateToProps, mapDispatchToProps)(withRouter(PhoneSignInComponent));
