import ReorderPlaylist from 'components/playlist/ReorderPlaylist';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import PlaylistsPage from './PlaylistsPage';

export default function ReorderPlaylistPage(props) {
	let { isModal } = props;
	let { id } = useParams();

	return (
		<>
			{isModal ? null : <PlaylistsPage />}

			<DialogPage title='Reorder Playlist' transition={true} dark goBackOnClose={!!isModal} goBackTo='/'>
				<ReorderPlaylist playlistId={id} />
			</DialogPage>
		</>
	);
}
