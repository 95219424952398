import { Box, Card, Collapse, Slider, TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import { DialogContext } from 'components/pages/DialogPage';
import { DBConfig } from 'config';
import { Icons } from 'config/icons';
import { setRecord } from 'fb';
import { User } from 'firebase/auth';
import { deleteField, Timestamp } from 'firebase/firestore';
import { isAdmin, isFakeWebViewEnv } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateUserSettings } from 'store/ux/actions';
import { ApplicationState, Config, Publication, UserData } from 'types';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
	}),
	list: (theme: Theme) => ({
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	}),
	text: {
		marginRight: '12px',
	},
	inline: {
		display: 'inline',
	},
	nested: (theme: Theme) => ({
		padding: theme.spacing(0.25),
		paddingLeft: theme.spacing(10),
		marginRight: theme.spacing(8),
	}),
	slider: (theme: Theme) => ({
		margin: 'auto',
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(3),
		width: '85%',
		display: 'block',

		'& .MuiSlider-markLabel': {
			fontSize: '1.2rem',
		},
	}),
};

const FontChangeComponent = (props) => {
	let { settings, updateUserSettings } = props;

	let [font, changeFont] = useState(settings.font);

	return (
		<>
			<Slider
				sx={classes.slider}
				value={font || 100}
				valueLabelDisplay='off'
				track={false}
				step={10}
				marks={((min: number, max: number, step: number) =>
					Array.from(new Array((max - min) / step + 1)).map((v, i) => ({
						value: min + i * step,
						label: min + i * step + '%',
					})))(80, 140, 10)}
				min={80}
				max={140}
				valueLabelFormat={(value: number) => value + '%'}
				onChange={(event, value) => {
					changeFont(value);
					updateUserSettings({ ...settings, font: value });
					//onChange(event, value);
				}}
			/>
		</>
	);
};

export const FontChangeControl = connect(
	(state: ApplicationState) => ({ settings: state.uxState.settings }),
	(dispatch) => ({
		updateUserSettings: (settings) => {
			dispatch(updateUserSettings(settings));
		},
	})
)(FontChangeComponent);

interface Props {
	user?: User;
	userData?: UserData;
	settings: any;
	publications: Publication[];
	updateUserSettings: (settings: any) => void;
	ssnRequestConfig: Config;
}

class Component extends React.Component<
	Props,
	{
		checked: any;
		ssnRequestEnabled: boolean;
		ssnMaxAllowedPeople: any;
		ssnMinAllowedDate: string;
		ssnMaxAllowedDate: string;
	}
> {
	constructor(props: Props) {
		super(props);

		let ssnConfig = props.ssnRequestConfig.value;

		this.state = {
			checked: props.settings ?? {},
			ssnRequestEnabled: ssnConfig.requestEnabled,
			ssnMaxAllowedPeople: ssnConfig.maxAllowedPeople,
			ssnMinAllowedDate: ssnConfig.minAllowedDate
				? new AnandDate(ssnConfig.minAllowedDate).format('YYYY-MM-DD HH:mm:ss')
				: '',
			ssnMaxAllowedDate: ssnConfig.maxAllowedDate
				? new AnandDate(ssnConfig.maxAllowedDate).format('YYYY-MM-DD HH:mm:ss')
				: '',
		};
	}

	private handleToggle = (key: string, value?: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let { checked } = this.state;

		const newChecked = { ...checked, [key]: value ?? event.target.checked };

		this.setState({ checked: newChecked });

		this.props.updateUserSettings(newChecked);
	};

	render() {
		let { user, userData, publications, ssnRequestConfig } = this.props;
		let { checked, ssnRequestEnabled, ssnMaxAllowedPeople, ssnMinAllowedDate, ssnMaxAllowedDate } = this.state;
		let journals = publications.filter((publication) => publication.frequency > 0);

		let emailPubsDefault = { pubs: {} };
		journals.forEach((pub) => (emailPubsDefault.pubs[pub.id] = true));

		return (
			<Box sx={classes.root}>
				<UserPicInfo />
				<div style={{ marginTop: 24, marginBottom: 32 }}>
					{isFakeWebViewEnv() || user ? (
						<Card sx={classes.card}>
							<List subheader={<ListSubheader>Notiifcation Settings</ListSubheader>} sx={classes.list}>
								{user ? (
									<>
										<ListItem>
											<ListItemIcon>{Icons.Email}</ListItemIcon>
											<ListItemText
												sx={classes.text}
												primary='Publication Emails'
												secondary='Receive monthly emails when new issue of journals is published. You will receive only one combined email.'
											/>
											<ListItemSecondaryAction>
												<Switch
													edge='end'
													color='primary'
													onChange={this.handleToggle(
														'email',
														checked.email ? false : emailPubsDefault
													)}
													checked={!!checked.email ?? false}
												/>
											</ListItemSecondaryAction>
										</ListItem>
										<Collapse in={!!checked.email} timeout='auto' unmountOnExit>
											<List component='div' disablePadding>
												{journals.map((pub) => (
													<ListItem sx={classes.nested} key={pub.id}>
														<ListItemIcon style={{ minWidth: 34 }}>
															<Icons.EmailIcon fontSize='small' />
														</ListItemIcon>
														<ListItemText
															className={clsx({
																'locale-hi': pub.lang === 'hi',
															})}
															sx={classes.text}
															secondary={pub.title}
														/>
														<ListItemSecondaryAction>
															<Switch
																edge='end'
																color='primary'
																size='small'
																onChange={this.handleToggle('email', {
																	pubs: {
																		...checked.email?.pubs,
																		[pub.id]: !(
																			checked.email?.pubs &&
																			checked.email?.pubs[pub.id]
																		),
																	},
																})}
																checked={
																	(checked.email?.pubs &&
																		checked.email?.pubs[pub.id]) ??
																	false
																}
															/>
														</ListItemSecondaryAction>
													</ListItem>
												))}
											</List>
										</Collapse>
									</>
								) : null}
								{isFakeWebViewEnv() ? (
									<ListItem>
										<ListItemIcon>{Icons.NotificationImportant}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='Push Notifications'
											secondary='Receive daily push notifications when new content (i.e. bhajan, satsang) is added'
										/>
										<ListItemSecondaryAction>
											<Switch
												edge='end'
												color='primary'
												onChange={this.handleToggle('push')}
												checked={checked.push ?? false}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								) : null}
							</List>
						</Card>
					) : null}
					<Card sx={classes.card}>
						<List subheader={<ListSubheader>Application Settings</ListSubheader>} sx={classes.list}>
							<ListItem>
								<ListItemIcon>{Icons.Quote}</ListItemIcon>
								<ListItemText
									sx={classes.text}
									primary='Daily Quote'
									secondary='Show daily quote automatically'
								/>
								<ListItemSecondaryAction>
									<Switch
										edge='end'
										color='primary'
										onChange={this.handleToggle('quote')}
										checked={checked.quote ?? false}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							{isFakeWebViewEnv() && user ? (
								<ListItem>
									<ListItemIcon>{Icons.Download}</ListItemIcon>
									<ListItemText
										sx={classes.text}
										primary='Auto Download'
										secondary='Automatically download offline media'
									/>
									<ListItemSecondaryAction>
										<Switch
											edge='end'
											color='primary'
											onChange={this.handleToggle('download')}
											checked={checked.download ?? false}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							) : null}
							<ListItem>
								<ListItemIcon>{Icons.Font}</ListItemIcon>
								<ListItemText
									sx={classes.text}
									primary='Font Size'
									secondary='Adjust font size of text content'
								/>
							</ListItem>
							<FontChangeControl />
						</List>
					</Card>
					{isAdmin(user, userData) ? (
						<>
							<Card sx={classes.card}>
								<List subheader={<ListSubheader>Admin Settings</ListSubheader>} sx={classes.list}>
									<ListItem>
										<ListItemIcon>{Icons.VisibilityOff}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='Show All Records'
											secondary='Show all records including those which are not visibile for others'
										/>
										<ListItemSecondaryAction>
											<Switch
												edge='end'
												color='primary'
												onChange={this.handleToggle('admin.records')}
												checked={checked['admin.records'] ?? false}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</Card>
							<Card sx={classes.card}>
								<List subheader={<ListSubheader>SSN Admin Settings</ListSubheader>} sx={classes.list}>
									<ListItem>
										<ListItemIcon>{Icons.KeyIcon}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='Request SSN Cards'
											secondary='Allow SSN Card requests'
										/>
										<ListItemSecondaryAction>
											<Switch
												edge='end'
												color='primary'
												onChange={async () => {
													this.setState({
														ssnRequestEnabled: !ssnRequestEnabled,
													});

													let data = {
														value: {
															requestEnabled: !ssnRequestConfig.value.requestEnabled,
														},
													};

													await setRecord('configs', DBConfig.SSN, data);
												}}
												checked={ssnRequestEnabled}
											/>
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem>
										<ListItemIcon>{Icons.Person}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='Max Visitors'
											secondary='Max allowed visitors per Card'
										/>
										<ListItemSecondaryAction>
											<TextField
												style={{ maxWidth: 60 }}
												type='number'
												variant='standard'
												value={ssnMaxAllowedPeople}
												// inputProps={{ style: { fontSize: 13 } }}
												onChange={(event) => {
													let value = event.target.value;
													this.setState({
														ssnMaxAllowedPeople: value ? parseInt(value) : '',
													});
												}}
												onKeyUp={async (event) => {
													if (event.key === 'Enter') {
														let data = {
															value: {
																maxAllowedPeople: ssnMaxAllowedPeople || 0,
															},
														};

														await setRecord('configs', DBConfig.SSN, data);
													}
												}}
											/>
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem>
										<ListItemIcon>{Icons.Person}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='Start Date'
											secondary='Min allowed date of visit'
										/>
										<ListItemSecondaryAction>
											<TextField
												style={{ maxWidth: 140 }}
												type='text'
												variant='standard'
												value={ssnMinAllowedDate}
												inputProps={{ style: { fontSize: 13 } }}
												onChange={(event) => {
													let value = event.target.value;
													this.setState({
														ssnMinAllowedDate: value,
													});
												}}
												onKeyUp={async (event) => {
													if (event.key === 'Enter') {
														try {
															let date = new Date(ssnMinAllowedDate) as any;
															let data = {
																value: {
																	minAllowedDate:
																		date == 'Invalid Date'
																			? deleteField()
																			: Timestamp.fromDate(date),
																},
															};

															await setRecord('configs', DBConfig.SSN, data);
														} catch (e) {
															alert(e.message);
														}
													}
												}}
											/>
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem>
										<ListItemIcon>{Icons.Person}</ListItemIcon>
										<ListItemText
											sx={classes.text}
											primary='End Date'
											secondary='Max allowed date of visit'
										/>
										<ListItemSecondaryAction>
											<TextField
												style={{ maxWidth: 140 }}
												type='text'
												variant='standard'
												value={ssnMaxAllowedDate}
												inputProps={{ style: { fontSize: 13 } }}
												onChange={(event) => {
													let value = event.target.value;
													this.setState({
														ssnMaxAllowedDate: value,
													});
												}}
												onKeyUp={async (event) => {
													if (event.key === 'Enter') {
														try {
															let date = new Date(ssnMaxAllowedDate) as any;
															let data = {
																value: {
																	maxAllowedDate:
																		date == 'Invalid Date'
																			? deleteField()
																			: Timestamp.fromDate(date),
																},
															};

															await setRecord('configs', DBConfig.SSN, data);
														} catch (e) {
															alert(e.message);
														}
													}
												}}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</Card>
						</>
					) : null}
				</div>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		// locale: state.uxState.locale,
		user: state.userState.userStore.user,
		userData: state.userState.userStore.userData,
		settings: state.uxState.settings,
		publications: state.filteredDataState.publications,
		ssnRequestConfig: state.dataState.configs.byId[DBConfig.SSN],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateUserSettings: (settings: string[]) => {
			dispatch(updateUserSettings(settings));
		},
		// changeLocale: async (locale: LocaleType) => {
		// 	dispatch(changeLocale(locale));
		// },
	};
}

Component.contextType = DialogContext;

export const UserSettings = connect(mapStateToProps, mapDispatchToProps)(Component);
