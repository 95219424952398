import { Box, Card, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { DBConfig } from 'config';
import { User } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { FeedbackData, submitFeedback } from 'services/api/auth';
import { ApplicationState, AppParams, UserData } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
};

interface Props {
	userData?: UserData;
	user?: User;
	category?: string;
	data?: any;
	message?: string;
	appParams: AppParams;
	donationMail?: string;
	submitFeedback: (feedbackData: FeedbackData) => Promise<void>;
}

class Component extends React.Component<Props> {
	context!: React.ContextType<typeof DialogContext>;

	render() {
		let { message, userData, donationMail, submitFeedback, category, data } = this.props;

		let defaultMessages = {
			donation: `You may directly send your message to <a href = "mailto:${
				donationMail || 'ssdnmail.donation@gmail.com'
			}">${donationMail || 'ssdnmail.donation@gmail.com'}</a> for any donation related concerns/feedback.`,
			journals: `You may directly contact/post your queries to postal address of 'Anand Sandesh' (<a href = "mailto:care.publications.sap@gmail.com">care.publications.sap@gmail.com</a>) or 'Amar Sandesh' (<a href = "mailto:care.publications.spd@gmail.com">care.publications.spd@gmail.com</a>) Karyalaya.`,
			other: 'For any other concerns, please directly contact your rahbar Mahatma Ji or Bai Ji.',
		};
		return (
			<Box sx={classes.root}>
				{!category && <UserPicInfo />}
				<Card sx={classes.card}>
					<Typography style={{ marginBottom: 32 }} variant='body1'>
						{message ??
							(userData ? 'Your feedback is valuable to us:' : 'Please SignIn to submit the feedback!')}
					</Typography>
					{userData && (
						<Formik
							initialValues={{
								name: userData?.fullName ?? '',
								category: category ?? '',
								comments: '',
							}}
							validationSchema={() =>
								Yup.object({
									name: Yup.string().required('Required'),
									category: Yup.string().required('Required'),
									comments: Yup.string().required('Required'),
								})
							}
							onSubmit={async (values, { setSubmitting }) => {
								let feedbackObj = { ...values };
								if (data) {
									feedbackObj['data'] = data;
								}
								await submitFeedback(feedbackObj);
								this.context.popScreen();
								return;
							}}
						>
							{({ values, submitForm, isSubmitting }) => (
								<Box component={Form} sx={classes.form} translate='yes'>
									{userData?.fullName ? null : (
										<Field component={TextField} type='text' label='Full Name' name='name' />
									)}
									{!category && (
										<Field
											component={Select}
											name='category'
											label='Category'
											inputProps={{
												id: 'category',
											}}
										>
											<MenuItem value='donation'>Donation</MenuItem>
											<MenuItem value='journals'>Home delivery of journals</MenuItem>
											<MenuItem value='appissue'>Facing issue in this app</MenuItem>
											<MenuItem value='feature'>Request new app feature</MenuItem>
											<MenuItem value='general'>General suggestion or enquiry</MenuItem>
											<MenuItem value='other'>Other</MenuItem>
										</Field>
									)}
									{!!category || ['appissue', 'feature', 'general'].indexOf(values.category) > -1 ? (
										<>
											<Field
												style={{ width: '100%' }}
												component={TextField}
												name='comments'
												placeholder='Comments'
												rows={15}
												multiline
												variant='outlined'
											/>
											{!category && (
												<Typography variant='body2'>
													Thank you for your valuable feedback. We give utmost importance to
													your feedback and we read all your messages. However, we may not
													reply to all messages.
												</Typography>
											)}
											<Button
												sx={classes.signinbtn}
												variant='contained'
												color='primary'
												type='submit'
												onClick={submitForm}
												disabled={isSubmitting}
											>
												Submit
											</Button>
										</>
									) : (
										<Typography variant='body2' style={{ marginTop: 24 }}>
											<span
												dangerouslySetInnerHTML={{ __html: defaultMessages[values.category] }}
											/>
										</Typography>
									)}
								</Box>
							)}
						</Formik>
					)}
				</Card>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		// locale: state.uxState.locale,
		user: state.userState.userStore.user,
		userData: state.userState.userStore.userData,
		settings: state.uxState.settings,
		appParams: state.uxState.appParams,
		donationMail: state.dataState.configs.byId[DBConfig.DonationMail]?.value,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		submitFeedback: async (feedbackData: FeedbackData) => {
			await dispatch(submitFeedback(feedbackData, new Context()));
		},
	};
}

Component.contextType = DialogContext;

export const UserFeedback = connect(mapStateToProps, mapDispatchToProps)(Component);
