import { Box, Button, Card, CardHeader, CardMedia, Typography } from '@mui/material';
import { CartScreens } from 'components/orders/placement/CartScreen';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { getContentStorageUrl } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { initiateOrder } from 'services/api/orders';
import { onEvent } from 'store/temp/actions';
import { ApplicationState, ContentType, UserAddresses } from 'types';
import { InitiateOrderData } from 'types/orders';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
	media: {
		width: '100%',
	},
	card: (theme) => ({
		margin: theme.spacing(1),

		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2) + 4,
		},
	}),
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
			fontWeight: 700,
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
};

interface Props extends ScreenManagerProps {
	addressDetails?: UserAddresses;
	initiateOrder: (data: InitiateOrderData) => Promise<void>;
	pubId: string;
	showMessage: (...payload) => void;
	history: any;
}

interface State {}

class Component extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let { pushScreen, setFamilyState, familyState } = this.props;

		return (
			<Box sx={classes.root}>
				<Card sx={classes.card}>
					<CardHeader
						sx={classes.header}
						title={<Typography noWrap>Total Payment: ₹ {familyState.grandTotal.toFixed(2)}</Typography>}
					/>
					<CardMedia
						sx={classes.media}
						component='img'
						image={getContentStorageUrl(ContentType.WebApp, 'payment-screen.jpg')} //{process.env.REACT_APP_CDN_HOST + 'img/ebook_thumbs/' + latestEdition?.coverpageUri}
						onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
							event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
							// event.currentTarget.style.height = '0px'; // = process.env.REACT_APP_CDN_HOST + 'img/ebook_thumbs/null.jpg';
						}}
					/>
				</Card>
				<Button
					sx={classes.btn}
					variant='contained'
					color='primary'
					disabled={familyState.orderInitiated}
					onClick={async () => {
						setFamilyState({ orderInitiated: true });
						pushScreen(CartScreens.Complete);
					}}
				>
					Place Order
				</Button>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		addressDetails: state.userState.userStore.subColl?.addresses,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		initiateOrder: async (data: InitiateOrderData) => {
			return await dispatch(initiateOrder(data));
		},
	};
}

export const PlaceOrderScreen = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
