import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import React from 'react';

export default function PlaylistName(props: {
	open: boolean;
	name?: string;
	actionText: string;
	action: (name: string) => void;
	onClose?: () => void;
}) {
	let [name, setName] = React.useState(props.name || '');
	let [open, setOpen] = React.useState(props.open);
	let { actionText, action } = props;

	let onClose = () => {
		setOpen(false);
		setName('');

		if (props.onClose) {
			props.onClose();
		}
	};

	let onAction = async () => {
		await action(name);
		onClose();
	};

	let checkDuplicateTitle = () => {
		return false;
	};

	React.useEffect(() => {
		const listener = async (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				await onAction();
			}
		};

		document.addEventListener('keydown', listener);

		return () => {
			document.removeEventListener('keydown', listener);
		};
		// eslint-disable-next-line
	}, [name]);

	return (
		<Dialog open={open} onClose={onClose}>
			{/* <DialogTitle id='form-dialog-title'>Create New Playlist</DialogTitle> */}
			<DialogContent dividers>
				<FormControl error={checkDuplicateTitle()}>
					<Input
						value={name}
						placeholder='Playlist Name'
						autoFocus
						margin='dense'
						id='title'
						fullWidth
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setName(event.target.value);
						}}
					/>
					{checkDuplicateTitle() ? <FormHelperText>Error</FormHelperText> : null}
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button disabled={name.length === 0} onClick={onAction} color='inherit'>
					{actionText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
