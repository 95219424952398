import React from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import 'whatwg-fetch';
import App from './App';
import WebViewApp from './AppInterface/App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.log('Index Start');

if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') {
	window.location.replace(window.location.href.replace('http:', 'https:'));
} else {
	let domNode = document.getElementById('root');
	let root = createRoot(domNode!);
	root.render(<App />);

	let window_ = window as any;
	if (window_.applicationCache) {
		window.addEventListener(
			'load',
			function (e) {
				window_.applicationCache.addEventListener(
					'updateready',
					function (e) {
						// eslint-disable-next-line
						if (window_.applicationCache.status == window_.applicationCache.UPDATEREADY) {
							// Browser downloaded a new app cache.
							WebViewApp.showToast('Loading latest updates...');
							setTimeout(() => {
								if (window.location.pathname !== '/') {
									window.location.href = '/';
								} else {
									window_.location.reload();
								}
							}, 200);
						}
					},
					false
				);

				window_.applicationCache.addEventListener(
					'cached',
					function (e) {
						WebViewApp.showToast('Ready for offline use!');
					},
					false
				);
			},
			false
		);
	} else if ('serviceWorker' in navigator) {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		const onSWUpdate = (registration: ServiceWorkerRegistration) => {
			registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
			WebViewApp.showToast('Loading latest updates...');
			window.location.href = '/';
			setTimeout(() => {
				if (window.location.pathname !== '/') {
					window.location.href = '/';
				} else {
					window.location.reload();
				}
			}, 500);
		};

		const onSWSuccess = (registration: ServiceWorkerRegistration) => {
			WebViewApp.showToast('Ready for offline use!');
		};

		if (window.location.hash === '#nocache') {
			serviceWorkerRegistration.unregister();
		} else {
			serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
		}
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
	// reportWebVitals(console.log);
}
