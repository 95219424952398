import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import 'firebase/functions';
import { isWebEnv } from 'helpers';
import { getVertexAI, getGenerativeModel } from 'firebase/vertexai-preview';

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (isWebEnv()) {
	firebaseConfig['measurementId'] = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
}
// Initialize Firebase
let app = initializeApp(firebaseConfig);

if (isWebEnv()) {
	getAnalytics(app);
}

// Initialize the Vertex AI service
const vertexAI = getVertexAI(app, { location: 'asia-south1' });

// Initialize the generative model with a model that supports your use case
// Gemini 1.5 models are versatile and can be used with all API capabilities
export const genAIModel = getGenerativeModel(vertexAI, { model: 'gemini-1.5-flash' });

// try {
// 	const appCheck = initializeAppCheck(app, {
// 		provider: new ReCaptchaV3Provider('6LdSl7wbAAAAAGqcqfpakJs0eYysg_zj3qzWgfi0'),

// 		// Optional argument. If true, the SDK automatically refreshes App Check
// 		// tokens as needed.
// 		isTokenAutoRefreshEnabled: true,
// 	});
// } catch (e) {
// 	console.log(e);
// }

export let fireDB = getFirestore(app);
export let fireAuth = getAuth(app);

// fireDB.settings({
// 	cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
// });
// fireDB.enablePersistence({ synchronizeTabs: true });
// fireDB.disableNetwork();

if (window.location.hostname === 'localhost') {
	// firebase.app().functions('asia-east2').useEmulator('localhost', 5001);
	// fireDB.settings({
	// 	host: 'localhost:8081',
	// 	ssl: false,
	// });
	// firebase.auth().useEmulator('http://localhost:9099/');
}

const firebase = app;
export default firebase;

// Wrap in an async function so you can use await
async function run() {
	// Provide a prompt that contains text
	const prompt = 'Write a story about a magic backpack.';

	// To generate text output, call generateContent with the text input
	const result = await genAIModel.generateContent(prompt);

	const response = result.response;
	const text = response.text();
	console.log(text);
}
