import { UserPlaylist, UserStore } from 'types';
import {
	UpdateFBUserPlaylistsLocal,
	UpdateUser,
	UpdateUserData,
	UpdateUserSubColl,
	UPDATE_FB_USER_PLAYLISTS_LOCAL,
	UPDATE_USER,
	UPDATE_USER_DATA,
	UPDATE_USER_SUB_COLL,
} from './actions';

export function userStoreReducer(
	state: UserStore = {},
	action: UpdateUser | UpdateUserData | UpdateUserSubColl | UpdateFBUserPlaylistsLocal
) {
	switch (action.type) {
		case UPDATE_USER:
			return { ...state, user: /* action.user ? { ...action.user, email: 'hello@abc.com' } : */ action.user };
		case UPDATE_USER_DATA:
			return {
				...state,
				userData:
					/*action.userData
					? { ...action.userData, fullName: 'hello abc', email: 'hello@abc.com', rahbar: '' }
					:*/ action.userData,
			};
		case UPDATE_USER_SUB_COLL:
			var subCollState = { ...state.subColl };

			let { changes, userId, loggedInUserId } = action;

			if (userId !== loggedInUserId) {
				return state;
			}

			subCollState[action.coll] = { ...subCollState[action.coll] };

			changes.forEach(function (change) {
				let changeData = change.doc.data();
				changeData.id = change.doc.id;
				changeData.userId = userId;

				if (action.coll === 'playlists') {
					let playlist = changeData as UserPlaylist;

					if (change.type === 'added' || change.type === 'modified') {
						let tracks = playlist.tracks || {};
						for (let id in tracks) {
							let track = tracks[id];
							track.articleId = id;
						}

						subCollState[action.coll]![change.doc.id] = playlist;
					}
				} else {
					subCollState[action.coll][change.doc.id] = changeData;
				}

				if (change.type === 'removed') {
					console.log('Ignoring Remove Event: ' + changeData);
					// delete state[change.doc.id];
				}
			});

			for (let id in subCollState[action.coll]) {
				if (subCollState[action.coll][id].userId !== userId) {
					delete subCollState[action.coll][id];
				}
			}

			return { ...state, subColl: subCollState };
		case UPDATE_FB_USER_PLAYLISTS_LOCAL:
			return { ...state, subColl: { ...state.subColl, playlists: { ...action.playlist } } };
		default:
			return state;
	}
}
