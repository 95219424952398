import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';
import { updateRhymes } from 'store/data/rhymes/actions';

export class RhymesFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'rhymes';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'rhymes');
	public getChangeProcessAction = (changes) => updateRhymes(changes);
}
