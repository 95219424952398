import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Article } from 'types';

export const UPDATE_FB_ARTICLES = '@@records/UPDATE_FB_ARTICLES';
export type T_UPDATE_FB_ARTICLES = typeof UPDATE_FB_ARTICLES;

export type FBChanges = DocumentChange<DocumentData>[];

export interface UpdateFBArticles extends Action {
	type: T_UPDATE_FB_ARTICLES;
	changes: FBChanges;
	overwrite?: boolean;
}

export const updateFBArticles = (changes: FBChanges, overwrite?: boolean): UpdateFBArticles => {
	return {
		type: UPDATE_FB_ARTICLES,
		changes,
		overwrite,
	};
};

export const UPDATE_FB_ARTICLES_FILTERED = UPDATE_FB_ARTICLES + '_FILTERED';
export type T_UPDATE_FB_ARTICLES_FILTERED = typeof UPDATE_FB_ARTICLES_FILTERED;

export interface UpdateArticlesFiltered extends Action {
	type: T_UPDATE_FB_ARTICLES_FILTERED;
	payload: Article[];
}

export const UPDATE_FB_ARTICLE_PLAYED_TIME = 'UPDATE_FB_ARTICLE_PLAYED_TIME';
export type T_UPDATE_FB_ARTICLE_PLAYED_TIME = typeof UPDATE_FB_ARTICLE_PLAYED_TIME;

export interface UpdateArticlePlayedTime extends Action {
	type: T_UPDATE_FB_ARTICLE_PLAYED_TIME;
	articleId: string;
	time: number;
}

export const updateArticlePlayedTime = (articleId: string, time: number): UpdateArticlePlayedTime => {
	return {
		type: UPDATE_FB_ARTICLE_PLAYED_TIME,
		articleId,
		time,
	};
};
