import { push } from '@lagunovsky/redux-react-router';
import App from 'AppInterface/App';
import { DidDeleteMedia, DidDownloadMedia, DidUpdateDownloadProgress } from 'AppInterface/AppInterface';
import { logEvent } from 'components/telemetry';
import { DBConfig } from 'config';
import { phoneSigninRoute } from 'config/route';
import { getAudioFilenameFromSrc, getContentStorageUrl, getSessionId, isWebViewEnv, updatePushSetting } from 'helpers';
import { Context } from 'helpers/context';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, debounceTime, switchMap } from 'rxjs/operators';
import { saveExtraUserData, updateProfile } from 'services/api/auth';
import { modifyPlaylistTracks, ReorderPlaylist, reorderPlaylistNow, REORDER_PLAYLIST } from 'services/api/playlists';
import { multiAction, performOperationAtInterval, performOperationOnceInSession, updateOperation } from 'store/data/ops/actions';
import { UpdateUser, UpdateUserData, UPDATE_USER, UPDATE_USER_DATA, UPDATE_USER_SUB_COLL } from 'store/data/user/actions';
import {
	addOfflineAudio,
	AddOfflineAudio,
	ADD_OFFLINE_AUDIO,
	removeOfflineAudio,
	RemoveOfflineAudio,
	REMOVE_OFFLINE_AUDIO,
	updateDownloadState,
} from 'store/offline/actions';
import { EventType, onEvent } from 'store/temp/actions';
import { UpdateUserSettings, UPDATE_USER_SETTINGS } from 'store/ux/actions';
import { ApplicationState, ContentType, DownloadStatus } from 'types';

const settingsEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: UpdateUserSettings) => {
			if (action.fromProfile === true) {
				return EMPTY;
			}

			let user = (state$.value as ApplicationState).userState.userStore.user;
			let settings = (state$.value as ApplicationState).uxState.settings;

			updatePushSetting(settings.push);

			if (user) {
				return of(updateProfile({ settings }, new Context()));
			} else {
				return EMPTY;
			}
		})
	);

const playlistEpic = (action$, state$) =>
	action$.pipe(
		ofType(REORDER_PLAYLIST),
		debounceTime(2000),
		switchMap((action: ReorderPlaylist) => {
			let user = (state$.value as ApplicationState).userState.userStore.user;
			if (user) {
				return of(reorderPlaylistNow(action.data));
			} else {
				return EMPTY;
			}
		})
	);

const offlineAudioEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_SUB_COLL, UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: any) => {
			let state = state$.value as ApplicationState;
			let user = state.userState.userStore.user;
			if (user) {
				let playlists = state.userState.userStore?.subColl?.playlists;
				let offlinePlaylist: any = playlists ? playlists['0'] ?? {} : {};
				if (offlinePlaylist.deleted) {
					offlinePlaylist = {};
				}

				let tracks = offlinePlaylist.tracks || {};

				let offlineAudio = state.offlineState.audio;
				let articles = state.dataState.articles.byId;
				let configs = state.dataState.configs.byId;
				let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

				let audioToDownload: any[] = [];
				let audioToDelete: any[] = [];

				let settings = state.uxState.settings;

				if (settings?.download) {
					for (let id in tracks) {
						if (articles[id] && !offlineAudio[id] && autoDownloadIds.indexOf(id) < 0) {
							// if (audioToDownload.length === 0) {
							audioToDownload.push(of(addOfflineAudio(articles[id], false)));
							// } else {
							// audioToDownload.push(
							// of(addOfflineAudio(articles[id], false)).pipe(
							// delayWhen(() => action$.ofType(DidDownloadMedia))
							// )
							// );
							// }
						}
					}

					for (let id in offlineAudio) {
						if (offlineAudio[id].progress?.status === DownloadStatus.Complete) {
							if (!tracks[id] && autoDownloadIds.indexOf(id) < 0) {
								audioToDelete.push(of(removeOfflineAudio(id, true)));
							}
						}
					}
				}

				if (audioToDownload.length || audioToDelete.length) {
					console.log('Going to sync Offline Audio');
					return concat(
						of(
							onEvent(new Context(), EventType.Information, 'Offline', {
								message: 'Syncing offline audio...',
								success: true,
							})
						),
						...audioToDownload,
						...audioToDelete
					);
				}
			}
			return EMPTY;
		})
	);

const offlineAudioDownloadEpic = (action$, state$) =>
	action$.pipe(
		ofType(ADD_OFFLINE_AUDIO),
		concatMap((action: AddOfflineAudio) => {
			let article = action.article;
			if (!article) {
				return EMPTY;
			}

			if (action.forExport === true) {
				logEvent('exportMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.exportMedia(
					article.id,
					article.title.en + '.' + article.mediaUri!.split('.')[1],
					getContentStorageUrl(ContentType.Article, article.articleType, article)
				);
			} else {
				// App.deleteMedia(article.id, getAudioFilenameFromSrc(article.mediaUri!));

				// setTimeout(() => {
				logEvent('downloadMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.downloadMedia(
					article.id,
					getAudioFilenameFromSrc(article.mediaUri!),
					getContentStorageUrl(ContentType.Article, article.articleType, article),
					true
				);
				// }, 1000);
			}

			// if (!isWebViewEnv()) {
			// 	return EMPTY;
			// }

			return EMPTY;
		})
	);

const offlineAudioDeleteEpic = (action$, state$) =>
	action$.pipe(
		ofType(REMOVE_OFFLINE_AUDIO),
		concatMap((action: RemoveOfflineAudio) => {
			// if (!isWebViewEnv()) {
			// 	return EMPTY;
			// }

			let state = state$.value as ApplicationState;
			let articles = state.dataState.articles.byId;

			let article = articles[action.articleId];
			App.deleteMedia(action.articleId, getAudioFilenameFromSrc(article.mediaUri!));

			let configs = state.dataState.configs.byId;
			let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

			return autoDownloadIds.indexOf(action.articleId) < 0 && action.localOnly === false
				? of(modifyPlaylistTracks({ playlistId: '0', articleId: action.articleId, action: 'Remove' }))
				: EMPTY;
		})
	);

const downloadRetries = {};

const offlineAudioProgressEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateDownloadProgress, DidDeleteMedia, DidDownloadMedia),
		concatMap((action: { type: string; payload: any }) => {
			let state = state$.value as ApplicationState;
			let configs = state.dataState.configs.byId;
			let autoDownloadIds: string[] = (configs[DBConfig.AutoDownload]?.value ?? []) as unknown as string[];

			switch (action.type) {
				case DidUpdateDownloadProgress:
					let curState = state.offlineState.audio[action.payload.audioId];

					let curProgress;
					if (curState?.forExport === true) {
						curProgress = curState?.exportProgress?.value;
					} else {
						curProgress = curState?.progress?.value;
					}

					if (Math.floor(curProgress) + 10 <= Math.floor(action.payload.progress)) {
						return of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.InProgress,
								value: action.payload.progress,
								bytes: action.payload.totalBytesWritten,
								updatedAt: new Date(),
							})
						);
					} else {
						return EMPTY;
					}
				case DidDownloadMedia:
					let crState = state.offlineState.audio[action.payload.audioId];
					let progressBytes = state.offlineState.audio[action.payload.audioId]?.progress?.bytes;

					if (crState?.forExport === true) {
						return of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.Complete,
								value: 100,
								bytes: progressBytes ?? 0,
								updatedAt: new Date(),
							})
						);
					}
					if (action.payload.result === false) {
						let articleId = action.payload.audioId;
						if (!downloadRetries[articleId]) {
							let articles = state.dataState.articles.byId;
							downloadRetries[articleId] = true;
							return concat(of(addOfflineAudio(articles[articleId], false)));
						} else {
							return EMPTY;
							// of(
							// 	onEvent(new Context(), EventType.Information, 'Offline', {
							// 		message: 'Download failed! Please retry...',
							// 		success: false,
							// 	})
							// )
						}
					}

					if (progressBytes && progressBytes < 10000) {
						return of(removeOfflineAudio(action.payload.audioId, true));
					}

					let playlists = state.userState.userStore?.subColl?.playlists;
					let offlinePlaylist: any = playlists ? playlists['0'] ?? {} : {};
					if (offlinePlaylist.deleted) {
						offlinePlaylist = {};
					}

					let tracks = offlinePlaylist.tracks || {};
					let trackIds = Object.keys(tracks);

					return concat(
						// of(
						// 	onEvent(new Context(), EventType.Information, 'Offline', {
						// 		message: 'Download Completed...',
						// 		success: true,
						// 	})
						// ),
						of(
							updateDownloadState(action.payload.audioId, {
								status: DownloadStatus.Complete,
								value: 100,
								bytes: progressBytes ?? 0,
								updatedAt: new Date(),
							})
						),
						autoDownloadIds.indexOf(action.payload.audioId) < 0 && trackIds.indexOf(action.payload.audioId) < 0
							? of(
									modifyPlaylistTracks({
										playlistId: '0',
										articleId: action.payload.audioId,
										action: 'Add',
									})
							  )
							: EMPTY
					);
				case DidDeleteMedia:
				// return of(modifyPlaylistTracks({ playlistId: '0', articleId: action.payload, action: 'Remove' }));
				// return of(
				// 	onEvent(new Context(), EventType.Information, 'Deleted', {
				// 		message: 'Audio file has been deleted',
				// 		success: true,
				// 	})
				// );
			}

			return EMPTY;
		})
	);

const userEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER),
		debounceTime(5000),
		switchMap((action: UpdateUser) => {
			let user = action.user;

			let signinSkipped = parseInt(localStorage.getItem('SkippedSignIn') ?? '0') >= new Date().getTime() - 24 * 60 * 60 * 1000;

			if (isWebViewEnv() && !signinSkipped) {
				if (!user) {
					let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
					return of(
						performOperationOnceInSession('usersignin', phoneRouteAction, false, () => {
							return !(state$.value as ApplicationState).userState.userStore.user;
						})
					);
				}

				// if (!user?.phoneNumber) {
				// 	let phoneRouteAction = multiAction([push('/'), push(phoneSigninRoute.to, phoneSigninRoute.state)]);
				// 	return of(
				// 		performOperationOnceInSession('phonesignin', phoneRouteAction, false, () => {
				// 			return !(state$.value as ApplicationState).userState.userStore.user;
				// 		})
				// 	);
				// }
			}

			let userData = (state$.value as ApplicationState).userState.userStore.userData;

			// if (userData && (!userData.fullName || !userData.fullName.length)) {
			// 	let profileRouteAction = multiAction([/*push('/'), */ push('/account/profile', { isModal: true })]);
			// 	return of(
			// 		performOperationOnceInSession('profilefullname', profileRouteAction, false, () => {
			// 			let userData = (state$.value as ApplicationState).userState.userStore.userData;
			// 			return !userData || !userData.fullName || !userData.fullName.length;
			// 		})
			// 	).pipe(delay(60 * 60 * 1000));
			// }

			if (user) {
				let interval = 24 * 60; // minutes
				return of(performOperationAtInterval(`extrauserdata-${user.uid}`, interval * 60, saveExtraUserData(), true));
			}

			return EMPTY;
		})
	);

const userDataUpdateEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_DATA),
		debounceTime(5000),
		switchMap((action: UpdateUserData) => {
			let userData = action.userData;

			if (userData && userData.roles && userData.roles.indexOf('test') >= 0) {
				App.subscribeToTopic('test');
			}

			return EMPTY;
		})
	);

export const userDataEpic = combineEpics(
	settingsEpic,
	playlistEpic,
	offlineAudioDownloadEpic,
	offlineAudioDeleteEpic,
	offlineAudioProgressEpic,
	offlineAudioEpic,
	userEpic,
	userDataUpdateEpic
);
