import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, ImageList, ImageListItem, Paper, Stack, Theme, Typography } from '@mui/material';
import ArticleList from 'components/articles/ArticleList';
import PublicationList from 'components/publications/PublicationList';
import { filterSSNRecords, getWebAppAssetPath, isAdmin, timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getArticlesByType } from 'store/data/articles/selectors';
import { getEditionsByPubId } from 'store/data/publications/selectors';
import { getQuotesByDate } from 'store/data/quotes/selectors';
import { ApplicationState, Article, PinnedRecord, Publication, Quote, Rhyme, Sandesh } from 'types';
import { ConciseArticleTile } from './articles/ConciseArticleTile';
import { QuoteContent } from './quote/QuoteControl';
import { ReferencedComponent } from './ReferencedComponent';
import { GoldenRhyme } from './rhymes/GoldenRhyme';
import { SandeshList } from './sandesh/SandeshList';
import { ToggleSelector } from './ToggleSelector';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Flipper, Flipped, spring } from 'react-flip-toolkit';
import { HDControl } from './hd/HDControl';
import { PrimaryMainColorDark } from 'config/theme';

const classes = {
	root: (theme: Theme) => ({
		margin: '8px 8px 16px',
		borderTop: '7px solid ' + theme.palette.primary.main,
		borderBottom: '7px solid ' + theme.palette.primary.main,
	}),
	recents: {
		pb: 0.5,
		'& .MuiGrid-item:nth-of-type(even) > .MuiCard-root': {
			backgroundColor: 'ivory',
		},
	},
	header: (theme: Theme) => ({
		textTransform: 'uppercase',
		padding: 0,

		'& heading-one': {
			fontSize: '0.8em',

			[theme.breakpoints.down('sm')]: {
				fontSize: '5vw',
			},
		},
	}),
	content: {
		padding: 0,

		'& .MuiCard-root': {
			boxShadow: 'none',
			borderBottom: '1px solid darkgrey',
			borderRadius: 0,
		},
	},
	newcontent: {
		paddingBottom: 0.5,
	},
	actions: {
		float: 'right',
	},
	quoteWrapper: (theme: Theme) => ({
		// backgroundColor: theme.palette.primary.light,

		'&::before, &::after': {
			display: 'block',
			content: '""',
			backgroundImage: `url(${getWebAppAssetPath('bg/golden-border.jpg')})`,
			backgroundRepeat: 'repeat-x',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			height: '2rem',
		},

		'&::after': {
			borderRadius: '0 0 16px 16px',
		},
	}),
};

const recency = 2;
const minRecords = 2;

const PkpContent = (props) => {
	const [type, setType] = React.useState<string>('Audio');

	let records = getCurrentPKP(type.toLowerCase(), props.records);

	return (
		<>
			<ToggleSelector values={['Audio', 'Video']} type={type} setType={setType} />
			<Box>
				<ArticleList tile={ConciseArticleTile} avoidVirtual={true} avoidFab={true} records={records} recordType="shriaarti" />
			</Box>
		</>
	);
};

const RecentsCard = (props) => {
	let navigate = useNavigate();

	return (
		<Card elevation={2} sx={classes.root}>
			<CardHeader sx={classes.header} title={React.createElement('heading-one', {}, props.title)} />
			<CardContent sx={classes.content}>
				{props.content ? props.content : <ArticleList avoidVirtual={true} avoidFab={true} records={props.records} recordType={props.type} />}
			</CardContent>
			<CardActions sx={classes.actions}>
				<Button
					color="primary"
					variant="text"
					size="small"
					onClick={() => {
						navigate(props.viewAllUrl, {
							state: {
								allowSearch: props.allowSearch,
							},
						});
					}}
				>
					View All
				</Button>
			</CardActions>
		</Card>
	);
};

const onElementAppear = (el, index) =>
	spring({
		onUpdate: (val) => {
			el.style.opacity = val;
		},
		delay: index * 50,
	});

const onExit = (el, index, removeElement) => {
	spring({
		config: { overshootClamping: true },
		onUpdate: (val: any) => {
			el.style.transform = `scaleY(${1 - val})`;
		},
		delay: index * 50,
		onComplete: removeElement,
	});

	return () => {
		el.style.opacity = '';
		removeElement();
	};
};

const NewRecentsCard = (props) => {
	let navigate = useNavigate();
	let [parent, enableAnimations] = useAutoAnimate();
	const flipId = `item-${props.id}`;

	return (
		<Flipped flipId={flipId} onAppear={onElementAppear} onExit={onExit} key={flipId} stagger={'forward'}>
			<Box sx={classes.recents}>
				{!!props.title && (
					<Flipped flipId={`${flipId}-header`} translate delayUntil={flipId} shouldFlip={() => true}>
						<Stack style={{ margin: 8 }} direction="row" justifyContent="space-between" alignItems="flex-end">
							<Typography color={PrimaryMainColorDark} variant="h4">
								{props.title}
							</Typography>
							{props.viewAllUrl && (
								<Button
									color="primary"
									variant="text"
									size="small"
									onClick={() => {
										navigate(props.viewAllUrl, {
											state: {
												allowSearch: props.allowSearch,
											},
										});
									}}
								>
									View All
								</Button>
							)}
						</Stack>
					</Flipped>
				)}
				<Flipped flipId={`${flipId}-content`} delayUntil={flipId}>
					<Box ref={parent} sx={classes.newcontent}>
						{props.content ? (
							props.content
						) : (
							<ArticleList tile={ConciseArticleTile} avoidVirtual={true} avoidFab={true} records={props.records} recordType={props.type} />
						)}
					</Box>
				</Flipped>
			</Box>
		</Flipped>
	);
};

interface Section {
	id: number;
	control: any;
}

const PinnedSection = (props: { pinnedMap: { [type: string]: PinnedRecord[] } }) => {
	let types = {
		publications: { xs: 6, sm: 4, lg: 3 },
		editions: { xs: 6, sm: 4, lg: 3 },
		articles: { xs: 12, sm: 6, lg: 4 },
		articlespin: { xs: 12, sm: 6, lg: 4 },
		playlists: { xs: 12, sm: 6, lg: 4 },
		rhymespin: { xs: 12, sm: 6, lg: 4 },
	};
	return (
		// <ImageList
		// 	sx={{
		// 		gridAutoFlow: 'column',
		// 		padding: '0 8px',
		// 		gap: '12px !important',
		// 	}}
		// >
		// 	{props.pinnedRecords.map((record: any) => (
		// 		<ImageListItem key={record.id} sx={{ width: '80vw' }}>
		// 			<ReferencedComponent tileType={{ articles: ConciseArticleTile }} collectionType={record.type} refId={(record as any).id} />
		// 		</ImageListItem>
		// 	))}
		// </ImageList>
		<>
			{Object.keys(types).map((type) => {
				return (
					!!props.pinnedMap[type] && (
						<div key={type} style={{ flexGrow: 1, padding: 8, paddingTop: 8 }}>
							<Grid container spacing={1}>
								{props.pinnedMap[type].map((record, index) => (
									<Grid
										key={(record as any).id}
										item
										xs={types[type].xs}
										sm={types[type].sm}
										lg={types[type].lg}
										sx={{ paddingTop: '4px !important' }}
									>
										<ReferencedComponent
											index={index}
											tileType={{ articles: ConciseArticleTile, editions: 'concise' }}
											collectionType={record.type}
											refId={(record as any).id}
											tileProps={{ startMinimised: false }}
										/>
									</Grid>
								))}
							</Grid>
						</div>
					)
				);
			})}
		</>
	);
};

const Component = (props: {
	bhajan: Article[];
	satsang: Article[];
	pubIds: string[];
	sav: Article[];
	pkp: Article[];
	rhyme: Rhyme;
	quote: Quote;
	quoteText: string;
	locale: string;
	sandesh: Sandesh[];
	pinnedRecords: PinnedRecord[];
	pinnedMap: { [type: string]: PinnedRecord[] };
}) => {
	let navigate = useNavigate();
	let [show, setShow] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, 10);
	}, []);

	let sections: Section[] = [
		{ id: -1, control: <Box sx={{ height: 800, display: !show ? 'block' : 'none' }}></Box> },
		{ id: 0, control: <NewRecentsCard id={0} content={<HDControl />} /> },
		{
			id: 1,
			control: props.quoteText ? (
				<NewRecentsCard
					id={1}
					content={
						<Paper elevation={3} sx={{ margin: 0, borderRadius: '0 0 16px 16px', mb: 3 }}>
							<Box sx={classes.quoteWrapper}>
								<QuoteContent text={props.quoteText} locale={props.locale} hideTopFloral={props.quote?.hideTopFloral ?? false} />
							</Box>
						</Paper>
					}
				/>
			) : null,
		},
		{
			id: 2,
			control:
				props.sandesh && props.sandesh.length ? (
					<NewRecentsCard
						id={2}
						title="Shri Sant Nagar"
						content={<SandeshList avoidVirtual records={props.sandesh} controlsEnabled={false} />}
						records={props.sandesh}
						viewAllUrl="/sandesh"
					/>
				) : null,
		},
		{
			id: 3,
			control: props.rhyme ? (
				<NewRecentsCard
					id={3}
					content={
						<div
							style={{ margin: '16px 8px' }}
							onClick={() => {
								navigate('/rhyme/' + props.rhyme.id, {
									state: {
										isModal: true,
									},
								});
							}}
						>
							<GoldenRhyme
								bottomMsg={
									<CardActions>
										<Button style={{ marginLeft: 'auto' }} color="primary" variant="text" size="small">
											View Meaning
										</Button>
									</CardActions>
								}
								rhyme={props.rhyme.rhyme}
							/>
						</div>
					}
				/>
			) : null,
		},
		{
			id: 4,
			control:
				props.sav && props.sav.length ? (
					<NewRecentsCard id={4} title="Shri Amar Vani" type="shriamarvani" records={props.sav} viewAllUrl="/shriamarvani" />
				) : null,
		},
		{
			id: 5,
			control: props.pinnedRecords.length > 0 && (
				<NewRecentsCard id={5} title="Pinned Content" type="pinned" content={<PinnedSection pinnedMap={props.pinnedMap} />} />
			),
		},
		{
			id: 6,
			control:
				props.pubIds && props.pubIds.length > 0 ? (
					<NewRecentsCard
						id={6}
						title="Publications"
						type="publications"
						content={
							<PublicationList
								concise={true}
								filter={(pub: Publication) => {
									return props.pubIds.indexOf(pub.id) >= 0;
								}}
							/>
						}
					/>
				) : null,
		},
		{ id: 7, control: <NewRecentsCard id={7} title="Satsang" type="satsang" records={props.satsang} viewAllUrl="/satsang" allowSearch /> },
		{ id: 8, control: <NewRecentsCard id={8} title="Bhajan" type="bhajan" records={props.bhajan} viewAllUrl="/bhajan" allowSearch /> },
		{ id: 9, control: <NewRecentsCard id={9} title="Pooja Ke Phool" type="shriaarti" viewAllUrl="/pkp" content={<PkpContent records={props.pkp} />} /> },
	];
	return (
		<div style={{ padding: '0 0 8px 0' }}>
			<Flipper
				flipKey={'fixed-key-' + show}
				spring={'veryGentle'} //["stiff", "noWobble", "veryGentle", "gentle", "wobbly"]
				staggerConfig={{
					default: {
						reverse: false,
						speed: 1,
					},
				}}
			>
				<Flipped flipId="list">
					<div>
						{sections.map((section) => (
							<div key={section.id}>{section.control}</div>
						))}
					</div>
				</Flipped>
			</Flipper>
		</div>
	);
};

function getRecentRecords(records, minRecords) {
	let result;
	if (records) {
		records = records.sort((a, b) => {
			return (
				((b.creationTime ? b.creationTime : b.publishTime)?.seconds ?? 0) - ((a.creationTime ? a.creationTime : a.publishTime)?.seconds ?? 0) ||
				// (a.title.en > b.title.en ? 1 : -1)
				(a.title?.en ? (a.title?.en > b.title?.en ? -1 : 1) : b.createdAt - a.createdAt)
			);
		});
		result = records.filter((article: Article) => {
			return (
				((article.creationTime ? article.creationTime : article.publishTime)?.seconds ?? new Date().getTime() / 1000) >
				new Date().getTime() / 1000 - recency * 24 * 3600
			);
		});
		if (result.length < minRecords) {
			result = records.slice(0, minRecords);
		}
	} else {
		result = []; //Array.from(new Array(minRecords));
	}

	return result;
}

function getCurrentPKP(recordType: string, records: Article[]) {
	let hours = new Date().getHours();
	let pkp: Article[] = [];

	let addTimedRecord = (type: string) => {
		let record = records?.find((record: Article) => {
			return record.mediaType === recordType && (record.title?.hi || record.title?.en).toLowerCase().indexOf(type.toLowerCase()) > 0;
		});
		if (record) {
			pkp.push(record);
		}
	};

	if (hours < 6) {
		addTimedRecord('dhan');
	}

	addTimedRecord('mahima');

	if (hours < 14) {
		addTimedRecord('morning');
	} else {
		addTimedRecord('evening');
	}

	return pkp;
}

function mapStateToProps(state: ApplicationState) {
	let articlesByType = getArticlesByType(state);
	let bhajan = getRecentRecords(articlesByType['bhajan'], minRecords);
	let satsang = getRecentRecords(articlesByType['satsang'], minRecords);
	let sav = getRecentRecords(articlesByType['shriamarvani'], 0);

	let showAll =
		isAdmin(state.userState.userStore.user, state.userState.userStore.userData) &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	let rhymes;
	if (showAll) {
		let allRhymes = state.filteredDataState.rhymes;
		allRhymes = allRhymes.sort(
			(a, b) =>
				((a.creationTime ? a.creationTime : a.publishTime)?.seconds ?? 0) - ((b.creationTime ? b.creationTime : b.publishTime)?.seconds ?? 0) ||
				(a.title > b.title ? 1 : -1)
		);
		for (let r of allRhymes) {
			if ((r.publishTime?.seconds ?? new Date().getTime() / 1000) > new Date().getTime() / 1000 - recency * 24 * 3600) {
				rhymes = [r];
				break;
			}
		}
	} else {
		rhymes = getRecentRecords(state.filteredDataState.rhymes, 0);
	}

	let quotes = getQuotesByDate(state);
	let quote = quotes[new AnandDate().format('YYYY-MM-DD')];
	let locale = state.uxState.locale;
	let quoteText;
	if (quote && quote.type !== 'General' && false) {
		quoteText = quote.text[locale] || quote.text.hi || quote.text.en;
	} else if (showAll && false) {
		quote = Object.values(quotes)
			.filter((quote) => quote.type !== 'General' && quote.forDate > new AnandDate().format('YYYY-MM-DD'))
			.sort((a, b) => (a.forDate > b.forDate ? 1 : -1))[0];
		if (quote) {
			quoteText = quote.text[locale] || quote.text.hi || quote.text.en;
		}
	}

	let editionsByPubId = getEditionsByPubId(state);
	let pubIds: string[] = [];
	for (let pubId in editionsByPubId) {
		let recentEditions = editionsByPubId[pubId].filter((edition) => {
			return (edition.publishTime?.seconds ?? new Date().getTime() / 1000) > new Date().getTime() / 1000 - recency * 24 * 3600;
		});

		if (recentEditions.length > 0) {
			pubIds.push(pubId);
		}
	}

	let sandesh: Sandesh[] = filterSSNRecords(state, state.filteredDataState.sandesh);
	sandesh = getRecentRecords(sandesh, minRecords);

	let pinnedRecords = state.userState.userStore.userData?.pinnedRecords ?? {};
	for (let id in pinnedRecords) {
		pinnedRecords[id]['id'] = id;
	}
	let pinned = Object.values(pinnedRecords).sort((a, b) => b.pintime - a.pintime);
	let pinnedMap = {};
	pinned.forEach((record) => {
		pinnedMap[record.type] = pinnedMap[record.type] ?? [];
		pinnedMap[record.type].push(record);
	});

	return {
		rhyme: rhymes && rhymes.length ? rhymes[0] : null,
		bhajan,
		satsang,
		pubIds,
		pkp: articlesByType['shriaarti'],
		sav,
		quote,
		quoteText,
		locale,
		sandesh,
		pinnedRecords: pinned,
		pinnedMap,
	};
}

export const RecentRecordSections = connect(mapStateToProps)(Component);
