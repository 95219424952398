import { NityaNiyam } from 'components/niyam/NityaNiyam';
import Page from './Page';

export default function NityaNiyamPage() {
	return (
		<Page title='Nitya Niyam' includeBottomNavigation fullscreen dark>
			<NityaNiyam />
		</Page>
	);
}
