import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { AccountMode } from 'components/profile/AccountScreenManager';
import { AddressTile } from 'components/profile/address/AddressTile';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { cancelOrder, cancelSubscription } from 'services/api/orders';
import { EventType, onEvent, toggleAlertDialog, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, ContentType, UserData } from 'types';
import {
	Order,
	OrderEventType,
	OrderItem,
	OrderStatusMap,
	OrderStatusValue,
	OrderType,
	SubscriptionStatus,
} from 'types/orders';
import { GetProductOrderDetails } from './products/ProductOrderDetails';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	card: (theme) => ({
		margin: theme.spacing(1),

		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2) + 4,
		},
	}),
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
			fontWeight: 700,
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	content: {
		flexDirection: 'row',
		display: 'flex',
		position: 'relative',
	},
	media: (theme) => ({
		width: 'auto',
		height: 200,
		WebkitBoxReflect:
			'below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, transparent), to(rgba(255, 255, 255, 0.5)))',
		marginBottom: '1.5em',
		objectFit: 'contain',

		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			background: theme.palette.grey[100],
			top: theme.spacing(2),
			left: theme.spacing(2),
			height: '80%',
			width: 'calc(35% - 16px)',
			WebkitBoxReflect:
				'below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, transparent), to(rgba(255, 255, 255, 0.5)))',
		},
	}),
	desc: (theme) => ({
		paddingLeft: theme.spacing(2),
		// height: 200,
		width: '55%',
		marginLeft: 'auto',
		marginBottom: '1.5em',
		color: theme.palette.text.secondary,
	}),
	contentRow: (theme) => ({
		flexDirection: 'row',
		display: 'flex',
		position: 'relative' as 'relative',
		// width: '100%',
		margin: theme.spacing(1),

		'& .MuiToggleButton-root': {
			lineHeight: 1,
		},
	}),
	label: {
		width: '50%',
		alignSelf: 'center',
		fontSize: '1.8rem',
	},
	value: {
		flexGrow: 1,
		textAlign: 'right' as 'right',
		fontWeight: 700,
		fontSize: '1.8rem',
	},
	divider: {
		margin: '16px 0 8px 0',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	actions: (theme) => ({
		backgroundColor: theme.palette.primary.main,
		justifyContent: 'center',
		color: 'white',
		borderColor: 'white',
		width: '100%',

		'& > a': {
			color: 'white',
			borderColor: 'white',
			padding: '8px 16px',
			border: '1px solid white',
			borderRadius: 4,
			fontSize: '1.6em',
			textTransform: 'uppercase',
		},
		'& > button': {
			color: 'white',
			borderColor: 'white',
		},
	}),
};

interface Props extends ScreenManagerProps {
	order: Order;
	orderItemId: string;
	orderItem: OrderItem;
	state: ApplicationState;
	withDetail?: boolean;
	userData?: UserData;
	history: any;
	toggleDrawer: (content?: any) => void;
	cancelOrder: (orderId: string) => Promise<void>;
	cancelSubscription: (orderId: string) => Promise<void>;
	toggleAlertDialog: (content?: any) => void;
	showMessage: (...payload) => void;
}

class SubscriptionItemTile extends React.Component<Props> {
	private onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let { order, cancelSubscription, toggleAlertDialog, pushScreen, orderItemId, showMessage } = this.props;

		let optionValues = [
			// {
			// 	title: 'Share',
			// 	icon: Icons.Share,
			// 	onClick: () => {
			// 		this.props.toggleDrawer();
			// 	},
			// },
			// {
			// 	title: 'Download Invoice',
			// 	icon: Icons.Download,
			// 	onClick: () => {
			// 		this.props.toggleDrawer();
			// 	},
			// },

			{
				title: 'Need Help? Contact Us',
				icon: Icons.Business,
				onClick: () => {
					pushScreen(AccountMode.Feedback, {
						message: `Subscriber #: ${order.id}. Please mention your issue in detail:`,
						category: 'journals',
						data: { orderId: order.id, orderItemId },
					});
					this.props.toggleDrawer();
				},
			},
		];

		if (order.status.status === OrderStatusValue.Confirmed) {
			optionValues = [
				{
					title: 'Change Shipping Address',
					icon: Icons.Edit,
					onClick: () => {
						pushScreen(AccountMode.Address, { isOrder: true, addrId: order.id });
						// navigate(`/address/${order.id}`, {state: { isModal: true, isOrder: true }});
						this.props.toggleDrawer();
					},
				},
				// {
				// 	title: 'Renew Subscription',
				// 	icon: Icons.Repeat,
				// 	onClick: () => {
				// 		this.props.toggleDrawer();
				// 	},
				// },
				{
					title: 'Cancel Subscription',
					icon: Icons.Delete,
					onClick: () => {
						let alert = {
							title: 'Cancel Subscription?',
							message: 'Amount will not be refunded! Do you still want to cancel this subscription?',
							okAction: {
								text: 'Yes, Cancel It!',
								callback: async () => {
									await cancelSubscription(order.id);
									showMessage(new Context(), EventType.Information, 'subscription', {
										success: true,
										message: `Subscription cancelled successfully!`,
									});
								},
							},
							cancelAction: {
								text: 'Keep',
							},
						};
						toggleAlertDialog(alert);
						this.props.toggleDrawer();
					},
				},
				...optionValues,
			];
		}
		let options = BottomDrawerOptions({
			options: optionValues,
			actionHandler: () => {
				this.props.toggleDrawer();
			},
		});

		this.props.toggleDrawer(options);
	};

	render() {
		let { pushScreen, userData, order, orderItemId, state, orderItem, withDetail } = this.props;

		if (!order || !orderItem) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader subheader={'Details not found!'} />
				</Card>
			);
		}

		let productOrderDetails = GetProductOrderDetails(state, order, orderItem);
		let subCancelTime = order.updatedAt.seconds * 1000;
		if (order.subscription?.status === SubscriptionStatus.Cancelled) {
			let events = order.events
				.filter((ev) => ev.type === OrderEventType.SubscriptionCancelled)
				.sort((a, b) => b.createdAt - a.createdAt);
			if (events.length) {
				subCancelTime = events[0].createdAt;
			}
		}

		let isExpired = false;
		if (order.subscription?.startDate) {
			isExpired = new AnandDate()
				.setEpoch(order.subscription?.startDate)
				.add(order.subscription.period, 'year')
				.subtract(1, 'day')
				.isBeforeDate(new AnandDate());
		}

		return (
			<Box sx={classes.root}>
				<Card
					sx={classes.card}
					onClick={() => {
						if (!withDetail) {
							pushScreen(AccountMode.Subscription, {
								orderId: order.id,
								orderItemId: orderItemId,
								withDetail: true,
							});
						}
					}}
				>
					<CardHeader
						sx={classes.header}
						avatar={
							<Avatar sx={classes.avatar} variant='circular'>
								{productOrderDetails.icon ??
									(order.type === OrderType.Subscription ? Icons.Repeat : Icons.Cart)}
							</Avatar>
						}
						action={
							<IconButton onClick={this.onMoreIconClick} size='large'>
								{Icons.MoreVert}
							</IconButton>
						}
						title={
							<Typography noWrap className={productOrderDetails.lang === 'hi' ? 'locale-hi' : ''}>
								{productOrderDetails.title}
							</Typography>
						}
						subheader={<span>Subscriber #: {order.id}</span>}
					/>
					<CardContent sx={classes.content}>
						<CardMedia
							sx={classes.media}
							component='img'
							image={productOrderDetails.image}
							onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
								event.currentTarget.src = getContentStorageUrl(ContentType.Calendar, 'no_internet.png');
							}}
						/>
						<Box sx={classes.desc}>
							<Chip
								size='small'
								variant='outlined'
								label={isExpired ? 'Expired' : OrderStatusMap[order.status.status] ?? 'Inactive'}
								color={
									isExpired || order.status.status !== OrderStatusValue.Confirmed
										? 'secondary'
										: 'primary'
								}
							/>
							<br />
							<br />
							<Typography variant='body2'>
								{userData?.fullName.toLowerCase() !== order.shippingAddress.fullname.toLowerCase() ? (
									<>
										Subscribed For: <br />
										<strong>{order.shippingAddress.fullname}</strong>
										<br />
										<br />
									</>
								) : null}
								{order.purchaseTime ? 'Subscribed on:' : 'Ordered on'} <br />
								<strong>
									{new AnandDate()
										.setEpoch(order.purchaseTime ?? order.createdAt.seconds * 1000)
										.format('dd Do MMM, YYYY')}
								</strong>
								<br />
								<br />
								{!!order.subscription?.startDate && (
									<>
										Subscription start:
										<br />
										<strong>
											{new AnandDate()
												.setEpoch(order.subscription?.startDate)
												.format('MMMM, YYYY')}
										</strong>
										<br />
										<br />
									</>
								)}
								{order.status.status === OrderStatusValue.Confirmed &&
									!!order.subscription?.startDate && (
										<>
											Subscription end:
											<br />
											<strong>
												{new AnandDate()
													.setEpoch(order.subscription?.startDate)
													.add(order.subscription.period, 'year')
													.subtract(1, 'day')
													.format('MMMM, YYYY')}
											</strong>
										</>
									)}
								{order.status.status === OrderStatusValue.Cancelled && (
									<>
										Cancelled on: <br />
										<strong>
											{new AnandDate().setEpoch(subCancelTime).format('dd Do MMM, YYYY')}
										</strong>
									</>
								)}
								{/* Subscription plan: <br />
							<strong>
								{productOrderDetails.fq}, {orderItem.quantity}{' '}
								{orderItem.quantity > 1 ? 'copies' : 'copy'}
								{' for'} {order.subscription?.period} Year
								{(order.subscription?.period ?? 1) > 1 ? 's' : ''}
							</strong> */}
								{/* <br />
							<br />
							Frequency: <br />
							<strong>{productOrderDetails.fq}</strong>
							{orderItem.quantity >= 1 ? (
								<>
									<br />
									<br />
									Quantity: <br />
									<strong>{orderItem.quantity}</strong>
								</>
							) : null} */}
							</Typography>
						</Box>
					</CardContent>
					<CardActions sx={classes.actions}>
						<Box sx={classes.contentRow} style={{ width: '100%' }}>
							<Typography noWrap sx={classes.label}>
								{productOrderDetails.fq}, {orderItem.quantity}{' '}
								{orderItem.quantity > 1 ? 'Copies' : 'Copy'}
							</Typography>
							<Typography noWrap sx={classes.value} style={{ fontWeight: 400 }}>
								₹ {order.paymentDetails.amount.toFixed(2)}
							</Typography>
						</Box>
					</CardActions>
				</Card>
				{withDetail && (
					<>
						<Card sx={classes.card}>
							<CardHeader sx={classes.header} title={<Typography noWrap>Payment Details</Typography>} />
							<CardContent>
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Price
									</Typography>
									<Typography noWrap sx={classes.value}>
										₹ {orderItem.price?.toFixed(2)}
									</Typography>
								</Box>
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Years
									</Typography>
									<Typography noWrap sx={classes.value}>
										{order.subscription?.period}
									</Typography>
								</Box>
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Quantity
									</Typography>
									<Typography noWrap sx={classes.value}>
										{orderItem.quantity}
									</Typography>
								</Box>
								<Divider sx={classes.divider} />
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Total
									</Typography>
									<Typography noWrap sx={classes.value}>
										₹ {(order.paymentDetails.amount - (orderItem.shippingPrice ?? 0)).toFixed(2)}
									</Typography>
								</Box>
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Shipping
									</Typography>
									<Typography noWrap sx={classes.value}>
										₹ {orderItem.shippingPrice?.toFixed(2)}
									</Typography>
								</Box>
								<Divider sx={classes.divider} />
								<Box sx={classes.contentRow}>
									<Typography noWrap sx={classes.label}>
										Grand Total <br />
										<span style={{ fontSize: '1.4rem' }}>(inclusive of all taxes)</span>
									</Typography>
									<Typography noWrap sx={classes.value}>
										₹ {order.paymentDetails.amount.toFixed(2)}
									</Typography>
								</Box>
							</CardContent>
						</Card>
						<Typography noWrap variant='h6' style={{ marginLeft: 8 }}>
							Shipping Address
						</Typography>
						<AddressTile readOnly address={order.shippingAddress} />
						<Typography noWrap variant='h6' style={{ marginLeft: 8 }}>
							Billing Address
						</Typography>
						<AddressTile readOnly address={order.paymentDetails.billingAddress} />
					</>
				)}
			</Box>
		);
	}
}

function mapStateToProps(
	state: ApplicationState,
	{
		path,
		orderId,
		orderItemId,
		withDetail,
	}: { path?: string[]; orderId: string; orderItemId: string; withDetail?: boolean }
) {
	withDetail = withDetail ?? (path && path?.length > 1);
	orderId = orderId ?? (path ? path[0] : orderId);
	let order = state.dataState.orders.byId[orderId];

	orderItemId = orderItemId ?? (path ? path[1] : orderItemId);
	let orderItem = order?.items[orderItemId];

	return {
		order,
		orderItem,
		state,
		withDetail,
		userData: state.userState.userStore.userData,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		cancelOrder: async (orderId: string) => {
			await dispatch(cancelOrder(orderId));
		},
		cancelSubscription: async (orderId: string) => {
			await dispatch(cancelSubscription(orderId));
		},
		toggleAlertDialog: (content?: any) => {
			dispatch(toggleAlertDialog(content));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscriptionItemTile));
