import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import TextContent from 'components/TextContent';
import { AnnouncementConfig } from 'config';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApplicationState, ContentType } from 'types';

declare module 'notistack' {
	interface VariantOverrides {
		announcement: {
			title: { [locale: string]: string };
			msg: { [locale: string]: string };
			tryUrl: string;
			version: number;
		};
	}
}

interface AnnouncementProps extends CustomContentProps {
	locale: string;
	title: { [locale: string]: string };
	msg: { [locale: string]: string };
	tryUrl: string;
	version: number;
	fontSize: number;
}

const classes = {
	root: (theme) => ({
		borderRadius: 4,
	}),
	miniRoot: {
		borderRadius: '16px 16px 0 0',
		backgroundColor: '#019efd', //'#007EA7', // '#019efd', //#ad9c82
		width: '100%',
	},
	miniContent: {
		overflow: 'hidden',

		'&:last-child': {
			paddingBottom: 2,
		},
	},
	miniBtn: {
		color: 'white',
		backgroundColor: '#098dd5', //#95866f
	},
	miniTitle: {
		fontFamily: 'inherit',
		color: 'white',
		textShadow: '1px 1px 5px grey',
	},
	closeBtn: {
		position: 'absolute',
		color: 'white',
		// right: 'calc(50% - 20px)',
		// top: -25,
		backgroundColor: '#098dd5',
		right: 0,
		margin: 1,

		// backgroundColor: '#019efd', // '#098dd5',
		// top: -40,
		// borderRadius: '25px 25px 0 0',

		// '&:before': {
		// 	content: '""',
		// 	position: 'absolute',
		// 	backgroundColor: 'transparent',
		// 	bottom: 0,
		// 	height: 87,
		// 	width: 60,
		// 	borderTopLeftRadius: 60,
		// 	left: -50,
		// 	boxShadow: '0 -60px 0 0 #019efd',
		// 	transform: 'scale(-1)',
		// 	zIndex: -1,
		// },
		// '&:after': {
		// 	content: '""',
		// 	position: 'absolute',
		// 	backgroundColor: 'transparent',
		// 	bottom: 0,
		// 	height: 87,
		// 	width: 60,
		// 	borderTopLeftRadius: 60,
		// 	right: -50,
		// 	boxShadow: '0 -60px 0 0 #019efd',
		// 	transform: 'scaleY(-1)',
		// 	zIndex: -1,
		// },
	},
};

const Announcement = React.forwardRef<HTMLDivElement, AnnouncementProps>((props, ref) => {
	const {
		// You have access to notistack props and options 👇🏼
		id,
		message,
		// as well as your own custom props 👇🏼
		title,
		msg,
		tryUrl,
		version,
		locale,
		fontSize,
		...other
	} = props;

	let navigate = useNavigate();
	const { closeSnackbar } = useSnackbar();
	const [miniMode, setMiniMode] = useState(true);

	const onClose = () => {
		if (version) {
			localStorage.setItem(AnnouncementConfig.key, version.toString());
		}
		closeSnackbar();
	};

	if (miniMode) {
		return (
			<SnackbarContent ref={ref} {...other}>
				<Card sx={classes.miniRoot} elevation={0} className="mini-announcement">
					<CardHeader
						action={
							<IconButton onClick={() => setMiniMode(!miniMode)} sx={classes.miniBtn}>
								{Icons.NavigateNext}
							</IconButton>
						}
						title={
							<Box className={'locale-' + (locale ?? 'en')}>
								<Typography sx={classes.miniTitle} variant="h6" component="div">
									<Box
										sx={{ overflow: 'hidden', whiteSpace: 'no-wrap', textOverflow: 'ellipsis' }}
										dangerouslySetInnerHTML={{ __html: title[locale] }}
									/>
								</Typography>
							</Box>
						}
					/>
				</Card>
			</SnackbarContent>
		);
	}

	return (
		<SnackbarContent ref={ref} {...other}>
			<Card sx={classes.root} elevation={10}>
				<IconButton onClick={onClose} sx={classes.closeBtn}>
					{Icons.Close}
				</IconButton>
				<CardMedia sx={{ maxHeight: '22vh' }} component="img" height="140" image={getContentStorageUrl(ContentType.WebApp, 'announcement.webp')} />
				<CardContent sx={{ maxHeight: '45vh', overflow: 'auto' }}>
					<Box className={'locale-' + (locale ?? 'en')}>
						<Typography gutterBottom sx={{ marginBottom: 3, fontFamily: 'inherit' }} variant="h5" component="div">
							<div style={{ fontSize: fontSize / 100.0 + 'em' }}>
								<Box dangerouslySetInnerHTML={{ __html: title[locale] }} />
							</div>
						</Typography>
					</Box>
					<Box className={'locale-' + (locale ?? 'en')}>
						<Typography sx={{ fontFamily: 'inherit' }} variant="body2" component="div" color="text.secondary">
							<div style={{ fontSize: fontSize / 100.0 + 'em' }}>
								<Box dangerouslySetInnerHTML={{ __html: msg[locale] as string }} />
							</div>
						</Typography>
					</Box>
				</CardContent>
				{tryUrl.length > 0 && (
					<CardActions sx={{ boxShadow: '1px -1px 5px lightgrey' }}>
						<Button
							onClick={() => {
								onClose();
								navigate(tryUrl);
							}}
							size="small"
							variant="contained"
							sx={{ marginLeft: 'auto' }}
						>
							Try Now
						</Button>
					</CardActions>
				)}
			</Card>
		</SnackbarContent>
	);
});

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
		fontSize: state.uxState.settings.font ?? 100,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export const AnnouncementSnackbar = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Announcement);
