import { PostalContact } from 'components/profile/PostalContact';
import React from 'react';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function ContactPage(props) {
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<DialogPage title='Contact Us' maxWidth='xs' dark goBackOnClose={!!isModal} goBackTo='/'>
				<PostalContact />
			</DialogPage>
		</>
	);
}
