import { Box, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import App from 'AppInterface/App';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { FontChangeControl } from 'components/profile/UserSettings';
import { RecordsListControl } from 'components/RecordList';
import { AppName, DhyanArticleId, SHARE_ORIGIN } from 'config';
import { Icons } from 'config/icons';
import { isAdmin, isFakeWebViewEnv } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleBottomDrawer } from 'store/temp/actions';
import { changeLocale } from 'store/ux/actions';
import { ApplicationState, DownloadState, DownloadStatus, LocaleType } from 'types';
import { BottomDrawerOptions } from './BottomDrawerOptions';

const classes = {
	root: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 10,
		// boxShadow: '0 -1px 5px lightgrey',
	},
	button: {
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
		minWidth: 60,
		// alignSelf: 'flex-end',
		// minWidth: 'auto',
	},
	navHeight: {
		height: '55px; height: calc(55px + env(safe-area-inset-bottom)/2)',
		paddingBottom: '0; padding-bottom: calc(env(safe-area-inset-bottom)/2)',
		backgroundColor: 'transparent',
	},
	progress: {
		position: 'absolute',
		left: -4,
		top: -4,
	},
	toggle: (theme) => ({
		'& .MuiToggleButton-root': {
			color: theme.palette.primary.main,
			backgroundColor: 'white',
			flex: '1 1 auto',
			fontWeight: 'bold',
			marginLeft: 0,
			borderColor: theme.palette.primary.main,
		},

		'& .MuiToggleButton-root.Mui-selected': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,
		},
	}),
};

function SimpleBottomNavigation(props) {
	const navigate = useNavigate();

	let value = props.location.pathname;
	if (props.location.search.startsWith('?r=')) {
		value = props.location.search.replace('?r=', '');
	}

	let downloadProgress: { [id: string]: DownloadState } = props.downloadProgress;

	let isDownloadingMedia = Object.keys(downloadProgress).reduce(
		(value, id) =>
			value || downloadProgress[id].progress?.status === DownloadStatus.InProgress || downloadProgress[id].progress?.status === DownloadStatus.Queued,
		false
	);

	let contextualMoreOptions = props.contextualMoreOptions ?? [];

	let optionsObj = {
		options: [
			...contextualMoreOptions,
			{
				title: 'Share',
				icon: Icons.Share,
				onClick: () => {
					try {
						App.shareLink(
							(props.title === AppName ? 'Welcome' : props.title) + ' - ' + AppName + ': ',
							SHARE_ORIGIN + window.location.href.replace(window.location.origin, ''),
							'Page',
							window.location.href
						);
					} catch (error) {
						(App as any).shareLink((props.title === AppName ? 'Welcome' : props.title) + ' - ' + AppName + ': ', SHARE_ORIGIN);
					}
					props.toggleDrawer();
				},
			},
			{
				element: <FontChangeControl />,
				icon: Icons.Font,
			},
			{
				element: (
					<ToggleButtonGroup
						sx={classes.toggle}
						value={props.locale}
						exclusive
						onChange={(e, value) => {
							if (value) {
								props.changeLocale(value);
								props.toggleDrawer();
							}
						}}
					>
						<ToggleButton value="hi">Hindi</ToggleButton>
						<ToggleButton value="en">English</ToggleButton>
					</ToggleButtonGroup>
				),
				icon: Icons.Language,
				onClick: () => {
					// props.toggleDrawer();
				},
			},
			{
				title: 'Reload App',
				icon: Icons.SyncIcon,
				onClick: () => {
					window.location.href = '/#nocache';
					window.location.reload();
				},
			},
			{
				title: 'Privacy Policy',
				icon: Icons.Policy,
				onClick: () => {
					props.toggleDrawer();
					navigate('/terms', { state: { isModal: true } });
				},
			},
		],
		actionHandler: () => {
			props.toggleDrawer();
		},
	};

	let optionsList = BottomDrawerOptions(optionsObj);

	return (
		<>
			<Paper square sx={classes.root} elevation={5}>
				<BottomNavigation
					value={value}
					sx={classes.navHeight}
					onChange={(event, newValue) => {
						if (newValue === 'more') {
							props.toggleDrawer(optionsList);
						} else if (newValue === 'dhyan') {
							MediaPlayer.instance().playAudio(DhyanArticleId, 'dhyan', 0);
						} else if (newValue === '/notices') {
							navigate(newValue, { state: { isModal: true } });
						} else {
							if (value === newValue) {
								if (value === '/') {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								} else if (RecordsListControl) {
									RecordsListControl.scrollToItem(0, 'auto');
								}
							} else {
								navigate(newValue);
							}
						}
					}}
					showLabels
				>
					<BottomNavigationAction sx={classes.button} label="Home" value="/" icon={Icons.Home} />
					{isFakeWebViewEnv() || props.isAdmin ? (
						// <BottomNavigationAction
						// 	sx={classes.button}
						// 	label='Satsang'
						// 	value='/satsang'
						// 	icon={Icons.Satsang}
						// />
						<BottomNavigationAction sx={classes.button} label="Nitya Niyam" value="/nityaniyam" icon={Icons.AllInclusiveIcon} />
					) : (
						<BottomNavigationAction sx={classes.button} label="Publications" value="/publications" icon={Icons.Publication} />
					)}
					{isFakeWebViewEnv() || props.isAdmin ? (
						// <BottomNavigationAction
						// 	sx={classes.button}
						// 	label='Bhajan'
						// 	value='/bhajan'
						// 	icon={Icons.Bhajan}
						// />
						<BottomNavigationAction sx={classes.button} label="Dhyan" value="dhyan" icon={Icons.MeditationIcon} />
					) : (
						<BottomNavigationAction sx={classes.button} label="Quote" value="/dailyquote" icon={Icons.Quote} />
					)}
					{/* <BottomNavigationAction
						className={classes.button}
						label='Notices'
						value='/notices'
						icon={
							<Badge badgeContent={props.notificationCount} color='secondary'>
								{Icons.Notification}
							</Badge>
						}
					/> */}
					{isFakeWebViewEnv() || props.isAdmin ? (
						isDownloadingMedia ? (
							<BottomNavigationAction
								sx={classes.button}
								label="Playlists"
								value="/playlists"
								icon={
									<span style={{ position: 'relative' }}>
										{Icons.Download}
										<CircularProgress size={32} sx={classes.progress} />
									</span>
								}
							/>
						) : (
							<BottomNavigationAction sx={classes.button} label="Playlists" value="/playlists" icon={Icons.Playlist} />
						)
					) : (
						<BottomNavigationAction sx={classes.button} label="Calendar" value="/calendar" icon={Icons.Calendar} />
					)}
					<BottomNavigationAction sx={classes.button} label="More" value="more" icon={Icons.MoreVert} />
				</BottomNavigation>
			</Paper>
			<Box sx={classes.navHeight} style={props.miniMode ? { height: 135 } : {}} />
		</>
	);
}

function mapStateToProps(state: ApplicationState) {
	let notifications = state.filteredDataState.notifications;
	let acks = state.uxState.acknowledge;
	let active = notifications.filter((notice) => !acks[notice.id] || acks[notice.id] < notice.updatedAt.seconds);

	return {
		isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
		location: state.router.location,
		downloadProgress: state.offlineState.audio,
		title: state.tempState.appBarTitle,
		locale: state.uxState.locale,
		notificationCount: active.length,
		miniMode: false, //state.tempState.audioPlaybackDetails && state.tempState.audioPlaybackState.isMiniPlayerMode,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		changeLocale: async (locale: LocaleType) => {
			dispatch(changeLocale(locale));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBottomNavigation);
