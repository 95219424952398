import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Order } from 'types/orders';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_ORDERS = '@@orders/UPDATE_ORDERS';
export type T_UPDATE_ORDERS = typeof UPDATE_ORDERS;

export interface UpdateOrders extends Action {
	type: T_UPDATE_ORDERS;
	changes: FBChanges;
	userId: string;
	loggedInUserId?: string;
}

export function updateOrders(changes: FBChanges, userId: string, loggedInUserId?: string): UpdateOrders {
	return {
		type: UPDATE_ORDERS,
		changes,
		userId,
		loggedInUserId,
	};
}

export const UPDATE_ORDERS_FILTERED = '@@orders/UPDATE_ORDERS_FILTERED';
export type T_UPDATE_ORDERS_FILTERED = typeof UPDATE_ORDERS_FILTERED;

export interface UpdateOrdersFiltered extends Action {
	type: T_UPDATE_ORDERS_FILTERED;
	payload: Order[];
}

export const CLEAR_ORDERS = '@@orders/CLEAR_ORDERS';
export type T_CLEAR_ORDERS = typeof CLEAR_ORDERS;

export interface ClearOrders extends Action {
	type: T_CLEAR_ORDERS;
}

export function clearOrders(): ClearOrders {
	return {
		type: CLEAR_ORDERS,
	};
}
