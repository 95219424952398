import firebase from 'fb/index';
import 'firebase/auth';
import 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const triggerFunction = async (funcName, data) => {
	let functions = getFunctions(firebase, 'asia-east2');
	let func = httpsCallable(functions, funcName);
	let result = await func(data);

	let response = result.data as any;
	if (response?.error) {
		throw response.error;
	}

	if (response?.type === 'error') {
		// eslint-disable-next-line
		throw { code: 'other', message: response.message };
	}

	return response?.data ?? response;
};
