import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';

const classes = {
	root: (theme) => ({
		width: 'calc(100% - 16px)',
		margin: 1,
		boxShadow:
			'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
		backgroundColor: 'white',

		'& .MuiToggleButton-root': {
			color: theme.palette.primary.main,
			flex: '1 1 auto',
			fontWeight: 'bold',
			border: 'none',
			borderRight: `1px solid ${theme.palette.primary.main}`,
			marginLeft: 0,

			'&:last-child': {
				borderRight: 'none',
			},
		},

		'& .MuiToggleButton-root.Mui-selected': {
			color: 'white',
			backgroundColor: theme.palette.primary.main,

			'&:hover': {
				color: 'white',
				backgroundColor: theme.palette.primary.main,
			},
		},
	}),
};

export const ToggleSelector = (props: {
	values: string[];
	type: string;
	setType: (type: string) => void;
	labels?: string[];
	styles?: any;
}) => {
	let { type, setType, labels, styles } = props;

	const handleType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
		if (newType) {
			setType(newType);
		}
	};

	return (
		<ToggleButtonGroup style={styles} sx={classes.root} value={type} exclusive onChange={handleType}>
			{props.values.map((value, index) => {
				return (
					<ToggleButton key={value} value={value}>
						{(labels && labels[index]) || value}
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
};
