import { User } from 'firebase/auth';
import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { UserData, UserPlaylists } from 'types';
import { Address } from 'types/orders';

export const UPDATE_USER = '@@user/UPDATE_USER';
export type T_UPDATE_USER = typeof UPDATE_USER;

export interface UpdateUser extends Action {
	type: T_UPDATE_USER;
	user?: User;
}

export const updateUser = (user?: User): UpdateUser => {
	return {
		type: UPDATE_USER,
		user,
	};
};

export const UPDATE_USER_DATA = '@@user/UPDATE_USER_DATA';
export type T_UPDATE_USER_DATA = typeof UPDATE_USER_DATA;

export interface UpdateUserData extends Action {
	type: T_UPDATE_USER_DATA;
	userData?: UserData;
}

export const updateUserData = (userData?: UserData): UpdateUserData => {
	return {
		type: UPDATE_USER_DATA,
		userData,
	};
};

export const UPDATE_USER_SUB_COLL = '@@user/UPDATE_USER_SUB_COLL';
export type T_UPDATE_USER_SUB_COLL = typeof UPDATE_USER_SUB_COLL;

export type FBChanges = DocumentChange<DocumentData>[];

export interface UpdateUserSubColl extends Action {
	type: T_UPDATE_USER_SUB_COLL;
	coll: string;
	userId: string;
	changes: FBChanges;
	loggedInUserId?: string;
}

export const updateUserSubColl = (
	coll: string,
	userId: string,
	changes: FBChanges,
	loggedInUserId?: string
): UpdateUserSubColl => {
	return {
		type: UPDATE_USER_SUB_COLL,
		coll,
		userId,
		changes,
		loggedInUserId,
	};
};

export const UPDATE_FB_USER_PLAYLISTS_LOCAL = '@@user/UPDATE_FB_USER_PLAYLISTS_LOCAL';
export type T_UPDATE_FB_USER_PLAYLISTS_LOCAL = typeof UPDATE_FB_USER_PLAYLISTS_LOCAL;

export interface UpdateFBUserPlaylistsLocal extends Action {
	type: T_UPDATE_FB_USER_PLAYLISTS_LOCAL;
	playlist: UserPlaylists;
}

export const updateFBUserPlaylistsLocal = (playlist: UserPlaylists): UpdateFBUserPlaylistsLocal => {
	return {
		type: UPDATE_FB_USER_PLAYLISTS_LOCAL,
		playlist,
	};
};

export const UPDATE_ADDRESS = '@@user/UPDATE_ADDRESS';
export type T_UPDATE_ADDRESS = typeof UPDATE_ADDRESS;

export interface UpdateAddress extends Action {
	type: T_UPDATE_ADDRESS;
	address: Address;
}

export const updateAddress = (address: Address): UpdateAddress => {
	return {
		type: UPDATE_ADDRESS,
		address,
	};
};
