import { combineReducers } from 'redux';
import { Rhyme, RhymesById, RhymeStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateRhymes, UpdateRhymesFiltered, UPDATE_RHYMES, UPDATE_RHYMES_FILTERED } from './actions';

export default function rhymesByIdReducer(state: RhymesById = {}, action: UpdateRhymes): RhymesById {
	switch (action.type) {
		case UPDATE_RHYMES:
			return updateData('Rhymes', state, action.changes, action.overwrite);
		default:
			return state;
	}
}

export function filteredRhymesReducer(state: Rhyme[] = [], action: UpdateRhymesFiltered): Rhyme[] {
	switch (action.type) {
		case UPDATE_RHYMES_FILTERED:
			return action.payload;
		default:
			return state;
	}
}

export const rhymeStoreReducer = combineReducers<RhymeStore>({
	byId: rhymesByIdReducer,
});
