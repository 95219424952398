import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { BottomDrawerOptionProps, BottomDrawerOptions } from "components/shell/BottomDrawerOptions";
import { Icons } from "config/icons";
import { generateRecordId, setRecord } from "fb";
import { Timestamp, serverTimestamp } from "firebase/firestore";
import { isSSNRequestEnabled } from "helpers";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleBottomDrawer } from "store/temp/actions";
import { ApplicationState, SandeshType, UserData } from "types";

let styles = {
    root: {
        position: "fixed" as "fixed",
        bottom: "60px; bottom: calc(60px + env(safe-area-inset-bottom)/2)",
        right: 5,
        left: 5,
    },
    paper: {
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
};

interface ChatInputProps {
    userData: UserData;
    toggleDrawer: (content?: any) => void;
    SSNRequestEnabled: boolean;
}

function Component(props: ChatInputProps) {
    let navigate = useNavigate();

    let [msg, setMsg] = useState("");

    return (
        <Box sx={styles.root}>
            <Paper
                component='form'
                sx={styles.paper}
                onSubmit={async (e) => {
                    e.preventDefault();

                    let message = msg.trim();

                    let recordData = {
                        deleted: false,
                        active: true,
                        createdAt: serverTimestamp() as Timestamp,
                        publishTime: serverTimestamp() as Timestamp,
                        allReceiverIds: [props.userData?.phone],
                        receiverIds: [props.userData?.phone],
                        from: props.userData?.phone,
                        type: SandeshType.Text,
                        title: {
                            en: "Chat Message",
                        },
                        message: {
                            en: message,
                        },
                    };

                    await setRecord("sandesh", generateRecordId("sandesh"), recordData);

                    setMsg("");
                }}
            >
                <IconButton
                    sx={{ p: "10px" }}
                    onClick={() => {
                        let drawerOptionsProps: BottomDrawerOptionProps = {
                            options: [
                                {
                                    title: "Document",
                                    icon: Icons.FileIcon,
                                    onClick: () => {
                                        props.toggleDrawer();
                                    },
                                },
                                {
                                    title: "Image",
                                    icon: Icons.ImageIcon,
                                    onClick: () => {
                                        props.toggleDrawer();
                                    },
                                },
                                {
                                    title: "Audio",
                                    icon: Icons.AudioFileIcon,
                                    onClick: () => {
                                        props.toggleDrawer();
                                    },
                                },
                                {
                                    title: "Video",
                                    icon: Icons.VideoFileIcon,
                                    onClick: () => {
                                        props.toggleDrawer();
                                    },
                                },
                                {
                                    title: "PDF",
                                    icon: Icons.PdfIcon,
                                    onClick: () => {
                                        props.toggleDrawer();
                                    },
                                },
                            ],
                            actionHandler: () => {
                                props.toggleDrawer();
                            },
                        };

                        if (props.SSNRequestEnabled) {
                            drawerOptionsProps.options.push({
                                title: "Request SSN Aagya Patra",
                                icon: Icons.KeyIcon,
                                onClick: () => {
                                    navigate("/ssnrequest", { state: { isModal: true } });
                                    props.toggleDrawer();
                                },
                            });
                        }
                        props.toggleDrawer(BottomDrawerOptions(drawerOptionsProps));
                    }}
                >
                    {Icons.Add}
                </IconButton>
                <InputBase
                    value={msg}
                    onChange={(e) => {
                        setMsg(e.currentTarget.value);
                    }}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder='Type your sandesh'
                    multiline
                />

                <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
                <IconButton disabled={!msg.trim().length} type='submit' color='primary' sx={{ p: "10px" }}>
                    {Icons.Chat}
                </IconButton>
            </Paper>
        </Box>
    );
}

function mapStateToProps(state: ApplicationState) {
    let SSNRequestEnabled = isSSNRequestEnabled(state);

    return {
        userData: state.userState.userStore.userData!,
        SSNRequestEnabled,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: (content?: any, open?: boolean) => {
            dispatch(toggleBottomDrawer(content, open));
        },
    };
}

export const ChatInput = connect(mapStateToProps, mapDispatchToProps)(Component);
