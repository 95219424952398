import { combineReducers } from 'redux';
import { Quote, QuotesById, QuoteStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateQuotes, UpdateQuotesFiltered, UPDATE_QUOTES, UPDATE_QUOTES_FILTERED } from './actions';

const quotesByIdReducer = (state: QuotesById = {}, action: UpdateQuotes) => {
	switch (action.type) {
		case UPDATE_QUOTES:
			return updateData('Quotes', state, action.changes, action.overwrite);
		default:
			return state;
	}
};

export const filteredQuotesReducer = (state: Quote[] = [], action: UpdateQuotesFiltered) => {
	switch (action.type) {
		case UPDATE_QUOTES_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const quotesReducer = combineReducers<QuoteStore>({
	byId: quotesByIdReducer,
});
