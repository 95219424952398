import { DocumentChange, DocumentData } from 'firebase/firestore';
import { FBData } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const updateData = <T extends FBData>(tableName: string, curState, changes: FBChanges, overwrite?: boolean) => {
	var state = overwrite ? {} : { ...curState };

	changes.forEach(function (change) {
		if (change.type === 'removed') {
			console.log(tableName, 'Ignoring Remove Event: ' + change.doc.id);
			// delete state.articlesById[change.doc.id];
		} else {
			let changeData = change.doc.data();
			changeData.id = change.doc.id;

			let data = changeData as T;

			// console.log(data);

			if (change.type === 'added' || change.type === 'modified') {
				console.log(tableName, change.type + ': ' + data.id);
				state[change.doc.id] = data;
			}
		}
	});

	return state;
};
