import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Rhyme } from 'types';

export const UPDATE_RHYMES = '@@records/UPDATE_RHYMES';
export type T_UPDATE_RHYMES = typeof UPDATE_RHYMES;

export type FBChanges = DocumentChange<DocumentData>[];

export interface UpdateRhymes extends Action {
	type: T_UPDATE_RHYMES;
	changes: FBChanges;
	overwrite?: boolean;
}

export const updateRhymes = (changes: FBChanges, overwrite?: boolean): UpdateRhymes => {
	return {
		type: UPDATE_RHYMES,
		changes,
		overwrite,
	};
};

export const UPDATE_RHYMES_FILTERED = UPDATE_RHYMES + '_FILTERED';
export type T_UPDATE_RHYMES_FILTERED = typeof UPDATE_RHYMES_FILTERED;

export interface UpdateRhymesFiltered extends Action {
	type: T_UPDATE_RHYMES_FILTERED;
	payload: Rhyme[];
}
