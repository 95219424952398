import { Box, Button, Typography } from '@mui/material';
import { BJK, COPYRIGHT, IsOwnerSAPD, OrgName } from 'config';
import { WebFooterGroup } from 'config/route';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { RouteProps } from 'types';

const classes = {
	root: {
		textAlign: 'center',
		backgroundColor: 'primary.main',
		color: 'white',
		boxShadow: '0px -10px 6px -6px #777, 0px 0px 6px 2px #d3d3d3',
	},

	row: {
		margin: '0 30px',
		padding: '0.3rem 0',
	},
	firstRow: {
		borderBottom: '1px solid white',
		padding: '0.8em 0 0.5em',
		opacity: 0.9,
		fontSize: '1.8rem',
	},
	btns: {
		textAlign: 'right',
	},
	link: {
		color: 'white',
		fontSize: '1.3rem',
	},
	copyright: {
		fontSize: '1.3rem',
		float: 'left',
		padding: '0.7rem 0',
		opacity: 0.8,
	},
};

const Link = (props) => {
	const { primary, to, state, linkClass } = props;

	const RenderLink = React.useMemo(
		() =>
			React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
				<RouterLink to={to} state={state} ref={ref} {...itemProps} />
			)),
		[to, state]
	);

	return (
		<Button sx={linkClass} key={primary} target={state?.target} color='inherit' component={RenderLink}>
			{primary}
		</Button>
	);
};

export const Footer = () => {
	var bjk = (
		<Typography sx={classes.firstRow} className='locale-hi'>
			{BJK}
		</Typography>
	);
	var copyright = <Typography sx={classes.copyright}>{COPYRIGHT + ' - ' + OrgName}</Typography>;

	var firstRow = <Box sx={classes.row}>{bjk}</Box>;

	var secondRow = (
		<Box sx={classes.row}>
			{copyright}
			<Box sx={classes.btns}>
				{WebFooterGroup.routes.map((props: RouteProps) => {
					return IsOwnerSAPD || props.to !== '/feedback' ? (
						<Link key={props.to} {...props} linkClass={classes.link} />
					) : null;
				})}
			</Box>
		</Box>
	);

	return (
		<Box sx={classes.root}>
			{firstRow}
			{secondRow}
		</Box>
	);
};
