import { combineReducers } from 'redux';
import { Sandesh, SandeshById, SandeshStore } from 'types';
import { updateData } from '../generic/reducers';
import { MARK_SANDESH_SHARED, MarkSandeshShared, UPDATE_SANDESH, UPDATE_SANDESH_FILTERED, UpdateSandesh, UpdateSandeshFiltered } from './actions';

const sandeshByIdReducer = (state: SandeshById = {}, action: UpdateSandesh | MarkSandeshShared) => {
	switch (action.type) {
		case UPDATE_SANDESH:
			return updateData('Sandesh', state, action.changes);
		case MARK_SANDESH_SHARED:
			return {...state, [action.id]: {...state[action.id], _shared: true}}
		default:
			return state;
	}
};

export const filteredSandeshReducer = (state: Sandesh[] = [], action: UpdateSandeshFiltered) => {
	switch (action.type) {
		case UPDATE_SANDESH_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const sandeshReducer = combineReducers<SandeshStore>({
	byId: sandeshByIdReducer,
});
