import { createSelector } from 'reselect';
import { ApplicationState, Edition } from 'types';

const getEditions = (state: ApplicationState) => state.filteredDataState.editions;

export const getEditionsByPubId = createSelector([getEditions], (editions) => {
	let result: { [pubId: string]: Edition[] } = {};
	editions.forEach((edition) => {
		result[edition.publicationId] = result[edition.publicationId] || [];
		result[edition.publicationId].push(edition);
	});

	return result;
});

const getPublicationsById = (state: ApplicationState) => state.dataState.publications.byId;

export const getPublicationTypes = createSelector([getPublicationsById], (publicationsById) => {
	let publications = Object.values(publicationsById);
	let types = publications.reduce((types, publication) => {
		if (publication.type) {
			types[publication.type] = true;
		}
		return types;
	}, {});
	return Object.keys(types);
});
