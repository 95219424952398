import { Box, Card, Collapse, Divider, FormControl, MenuItem, Select } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { DialogContext } from 'components/pages/DialogPage';
import { DefaultNityaNiyamSettings } from 'config';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { updateUserSettings } from 'store/ux/actions';
import { ApplicationState } from 'types';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
	}),
	list: (theme: Theme) => ({
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	}),
	text: {
		marginRight: '12px',
	},
	inline: {
		display: 'inline',
	},
	nested: (theme: Theme) => ({
		padding: theme.spacing(0.25),
		paddingLeft: theme.spacing(10),
		marginRight: theme.spacing(8),
	}),
	slider: (theme: Theme) => ({
		margin: 'auto',
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(3),
		width: '85%',
		display: 'block',

		'& .MuiSlider-markLabel': {
			fontSize: '1.2rem',
		},
	}),
};

interface Props {
	settings: any;
	updateUserSettings: (settings: any) => void;
}

interface State {
	checked: any;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			checked: props.settings ?? {},
		};
	}

	private handleToggle = (key: string, value?: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let { checked } = this.state;

		const newChecked = { ...checked, [key]: value ?? event.target.checked };

		this.setState({ checked: newChecked });

		this.props.updateUserSettings(newChecked);
	};

	private getNityaNiyamSection(type: string, checked: any) {
		// const morningList = ['aaradhan', 'sgm', 'mAarti', 'mVinati', 'mStotra', 'chhanda', 'dhyaan'];
		// const eveningList = ['sgm', 'eAarti', 'eVinati', 'eStotra', 'stuti', 'dhyaan'];
		const morningList = ['aaradhan', 'sgm', 'mAartiStotr', 'dhyaan'];
		const eveningList = ['sgm', 'eAartiStotr', 'dhyaan'];
		const titleMap = {
			aaradhan: 'Play Aaradhan',
			sgm: 'Play Shri Guru Mahima',
			mAartiStotr: 'Play Shri Aarti Stotr',
			mAarti: 'Play Shri Aarti',
			mVinati: 'Play Vinati',
			mStotra: 'Play Stotra',
			chhanda: 'Play Chhanda',
			eAartiStotr: 'Play Shri Aarti Stotr',
			eAarti: 'Play Shri Aarti',
			eVinati: 'Play Vinati',
			eStotra: 'Play Stotra',
			stuti: 'Play Stuti',
			dhyaan: 'Have Dhyaan',
			satsang: 'Play Recent Satsang',
			bhajan: 'Play Recent Bhajan',
			notPlayed: 'Only not-played audio',
			onlyHindi: 'Only Hindi audio',
			count: 'Number of audio',
		};
		const iconMap = {
			aaradhan: Icons.Pooja,
			sgm: Icons.Pooja,
			mAartiStotr: Icons.Pooja,
			mAarti: Icons.Pooja,
			mVinati: Icons.Pooja,
			mStotra: Icons.Pooja,
			chhanda: Icons.Pooja,
			eAartiStotr: Icons.Pooja,
			eAarti: Icons.Pooja,
			eVinati: Icons.Pooja,
			eStotra: Icons.Pooja,
			stuti: Icons.Pooja,
			dhyaan: Icons.MeditationIcon,
			satsang: Icons.Satsang,
			bhajan: Icons.Bhajan,
			notPlayed: <Icons.SettingsIcon fontSize='small' />,
			onlyHindi: <Icons.SettingsIcon fontSize='small' />,
			count: <Icons.SettingsIcon fontSize='small' />,
		};

		let sbList = ['satsang', 'bhajan'];
		let sbOptionsList = ['notPlayed', 'onlyHindi', 'count'];

		let list = type === 'Morning' ? morningList : eveningList;
		let ltype = type.toLowerCase();
		let nn = checked.nityaNiyam ?? DefaultNityaNiyamSettings;
		console.log(nn);
		let nntype = nn[ltype] ?? {};
		console.log(nntype);

		return (
			<Card key={type} sx={classes.card}>
				<List subheader={<ListSubheader>{type} Nitya Niyam Settings</ListSubheader>} sx={classes.list}>
					{list.map((item) => {
						let nntypeItem = nntype[item] ?? {};

						return (
							<ListItem key={type + item}>
								<ListItemIcon>{iconMap[item]}</ListItemIcon>
								<ListItemText sx={classes.text} primary={titleMap[item]} />
								<ListItemSecondaryAction>
									<Switch
										edge='end'
										color='primary'
										onChange={this.handleToggle('nityaNiyam', {
											...nn,
											[ltype]: {
												...nntype,
												[item]: { ...nntypeItem, enabled: !nntypeItem.enabled },
											},
										})}
										checked={nntypeItem.enabled}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
					{sbList.map((item) => {
						let nntypeItem = nntype[item] ?? {};

						return (
							<React.Fragment key={type + item}>
								<ListItem key={'list' + type + item}>
									<ListItemIcon>{iconMap[item]}</ListItemIcon>
									<ListItemText sx={classes.text} primary={titleMap[item]} />
									<ListItemSecondaryAction>
										<Switch
											edge='end'
											color='primary'
											onChange={this.handleToggle('nityaNiyam', {
												...nn,
												[ltype]: {
													...nntype,
													[item]: { ...nntypeItem, enabled: !nntypeItem.enabled },
												},
											})}
											checked={nntypeItem.enabled}
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<Collapse
									key={'collapse' + type + item}
									in={!!nntypeItem.enabled}
									timeout='auto'
									unmountOnExit
								>
									<List component='div' disablePadding>
										{sbOptionsList.map((option) => (
											<ListItem sx={classes.nested} key={type + item + option}>
												<ListItemIcon style={{ minWidth: 34 }}>{iconMap[option]}</ListItemIcon>
												<ListItemText sx={classes.text} secondary={titleMap[option]} />
												<ListItemSecondaryAction>
													{typeof nntypeItem[option] === 'boolean' ? (
														<Switch
															edge='end'
															color='primary'
															size='small'
															onChange={this.handleToggle('nityaNiyam', {
																...nn,
																[ltype]: {
																	...nntype,
																	[item]: {
																		...nntypeItem,
																		[option]: !nntypeItem[option],
																	},
																},
															})}
															checked={!!nntypeItem[option]}
														/>
													) : (
														<FormControl variant='standard' sx={{ m: 1, minWidth: 40 }}>
															<Select
																value={nntypeItem[option]}
																onChange={(event) => {
																	let { checked } = this.state;

																	const newChecked = {
																		...checked,
																		nityaNiyam: {
																			...nn,
																			[ltype]: {
																				...nntype,
																				[item]: {
																					...nntypeItem,
																					[option]: event.target.value,
																				},
																			},
																		},
																	};

																	this.setState({ checked: newChecked });

																	this.props.updateUserSettings(newChecked);
																}}
															>
																<MenuItem value={0}>All</MenuItem>
																<MenuItem value={1}>1</MenuItem>
																<MenuItem value={2}>2</MenuItem>
																<MenuItem value={3}>3</MenuItem>
															</Select>
														</FormControl>
													)}
												</ListItemSecondaryAction>
											</ListItem>
										))}
									</List>
								</Collapse>
							</React.Fragment>
						);
					})}
				</List>
			</Card>
		);
	}

	render() {
		let { checked } = this.state;

		return (
			<Box sx={classes.root}>
				<UserPicInfo />
				<div style={{ marginTop: 24, marginBottom: 32 }}>
					{this.getNityaNiyamSection('Morning', checked)}
					<Divider sx={{ m: 2 }} />
					{this.getNityaNiyamSection('Evening', checked)}
				</div>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		settings: state.uxState.settings,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateUserSettings: (settings: string[]) => {
			dispatch(updateUserSettings(settings));
		},
	};
}

Component.contextType = DialogContext;

export const NityaNiyamSettings = connect(mapStateToProps, mapDispatchToProps)(Component);
