import { Box } from '@mui/material';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

class Component extends React.Component<any> {
	render() {
		const { src, allowZoom, sx, ...others } = this.props;

		return allowZoom ? (
			<TransformWrapper>
				<TransformComponent wrapperClass='mx-center'>
					<Box component='img' sx={sx} {...others} src={src} alt='' />
				</TransformComponent>
			</TransformWrapper>
		) : (
			<Box component='img' sx={sx} {...others} src={src} alt='' />
		);
	}
}

export const Image = Component;
