import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FaceIcon from '@mui/icons-material/Face';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {
	AppBar,
	Avatar,
	Box,
	Button,
	CardActions,
	Chip,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Skeleton,
	Theme,
	Toolbar,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import blueGrey from '@mui/material/colors/blueGrey';
import Typography from '@mui/material/Typography';
import App from 'AppInterface/App';
import { RecordsListControl } from 'components/RecordList';
import { BottomDrawerOptionProps, BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { SSNTitle } from 'config';
import { Icons } from 'config/icons';
import domtoimage from 'dom-to-image';
import { setRecord } from 'fb';
import { isTest, isWebEnv, timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import numberToText from 'number2text';
import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modifySandesh } from 'services/api/sandesh';
import { markSandeshShared } from 'store/data/sandesh/actions';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Sandesh, SandeshType, UserData } from 'types';
import { SSNCardTile } from './SSNCardTile';

const classes = {
	card: (props) => (theme) => ({
		position: 'relative',
		// width: '100%',
		margin: props.detailed ? 0 : theme.spacing(1.5),

		'& .MuiCardContent-root': {
			paddingBottom: props.detailed ? theme.spacing(1.5) : 0,
		},

		'& .MuiListItem-root': {
			paddingLeft: 0,
			paddingRight: 1,
		},
	}),
	header: {
		'& div': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		paddingTop: '8px',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	details: {
		display: 'flex',
		flexDirection: 'row',
	},
	leftDetails: {
		flexGrow: 1,
	},
	rightDetails: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
	abs: (theme) => ({
		position: 'absolute',
		top: theme.spacing(0.5),
		right: theme.spacing(1),
		textAlign: 'right',
	}),
	inline: {
		display: 'inline',
		fontWeight: 700,
	},
	plainAppBar: (theme) => ({
		borderTop: `8px solid ${theme.palette.primary.main}`,
		backgroundColor: 'white',
		// boxShadow: 'none',
		color: 'inherit',

		'& .MuiIconButton-root': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	}),
	chip: {
		float: 'right',
		padding: 0.5,

		'& .MuiChip-label': {
			overflow: 'inherit',
			textOverflow: 'inherit',
		},
	},
	msg: (theme: Theme) => ({
		padding: theme.spacing(1, 2),
		borderRadius: '4px',
		marginBottom: theme.spacing(0.5),
		display: 'inline-block',
		wordBreak: 'break-word',
		maxWidth: '80%',
		fontFamily:
			// eslint-disable-next-line max-len
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	}),
	left: (theme: Theme) => ({
		borderTopRightRadius: theme.spacing(2.5),
		borderBottomRightRadius: theme.spacing(2.5),
		backgroundColor: theme.palette.grey[100],
		textAlign: 'left',
		marginRight: 'auto',
	}),
	right: (theme: Theme) => ({
		borderTopLeftRadius: theme.spacing(2.5),
		borderBottomLeftRadius: theme.spacing(2.5),
		backgroundColor: '#5d95ef',
		color: theme.palette.common.white,
		textAlign: 'right',
		marginLeft: 'auto',
	}),
	leftFirst: (theme: Theme) => ({
		borderTopLeftRadius: theme.spacing(2.5),
		marginTop: theme.spacing(2),
	}),
	leftLast: (theme: Theme) => ({
		borderBottomLeftRadius: theme.spacing(2.5),
	}),
	rightFirst: (theme: Theme) => ({
		borderTopRightRadius: theme.spacing(2.5),
		marginTop: theme.spacing(2),
	}),
	rightLast: (theme: Theme) => ({
		borderBottomRightRadius: theme.spacing(2.5),
	}),
	middleText: {},
	leftRow: {
		textAlign: 'left',
		margin: '4px 12px',
	},
	rightRow: {
		textAlign: 'right',
		margin: '4px 12px',
	},
};

interface SandeshProps {
	locale: string;
	record?: Sandesh;
	style?: any;
	onClick?: (record: Sandesh) => void;
	index: number;
	listCtrl?: typeof RecordsListControl;
	detailed?: boolean;
	toggleDrawer: (content?: any) => void;
	appBuildCode: number;
	forShare?: boolean;
	markSandeshShared: (id: string) => void;
	order?: string;
	userData: UserData;
	deleteSandesh: (sandeshId: string) => void;
}

const generateQR = async (text: string) => {
	try {
		let dataURL = await QRCode.toDataURL(text, { margin: 0 });
		console.log(dataURL);
		return dataURL;
	} catch (err) {
		console.error(err);
	}
};

const Component = (props: SandeshProps) => {
	const {
		record,
		style,
		locale,
		detailed,
		toggleDrawer,
		appBuildCode,
		forShare,
		markSandeshShared,
		userData,
		deleteSandesh,
	} = props;
	let [qrCode, setQRCode] = useState('');

	let isRequest = false;
	if (!record?.card || !record.card.card_no) {
		isRequest = true;
	}

	let card = isRequest ? record?.cardRequest : record?.card;

	let navigate = useNavigate();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!record) {
			return;
		}

		(record as any).height = ref.current?.clientHeight;

		if (detailed && card?.card_no) {
			(async () => {
				let url = `ssn_card_no=${card.card_no}&visitor_count=${card.people_count}`;
				let code = await generateQR(url);
				setQRCode(code);

				if (forShare) {
					let cardEl = ref.current as HTMLElement;
					if (cardEl) {
						await timeout(200);

						let dataUrl = await domtoimage.toJpeg(cardEl, { quality: 0.6 });

						let filename = 'Shri Anandpur Satsang';
						if (record?.type === SandeshType.SSN) {
							filename = `${SSNTitle} - ${card.card_no}`;
						}

						App.shareImageDataURI(filename, dataUrl, 'https://www.shrianandpur.org');

						markSandeshShared(record.id);
						toggleDrawer();
					}
				}
			})();
			return;
		}

		if (props.listCtrl) {
			props.listCtrl.resetAfterIndex(props.index);
		}
	}, []);

	if (record && !record.id) {
		return <span>{typeof record === 'object' ? JSON.stringify(record) : record}</span>;
	}

	if (record?.type === SandeshType.SSN) {
		let arrival = new AnandDate(card?.permission_date).format('dd, Do MMM, YYYY');
		let sharedIcon = <Icons.SuccessIcon color='primary' fontSize='small' sx={{ marginLeft: 0.5 }} />;

		return (
			<Card sx={classes.card(props)} ref={ref}>
				{detailed && (
					<AppBar elevation={4} sx={classes.plainAppBar} style={{ position: 'relative' }}>
						<Toolbar style={{ alignSelf: 'center' }}>
							<Typography variant='h6' noWrap>
								{SSNTitle}
							</Typography>
						</Toolbar>
					</AppBar>
				)}
				<CardContent>
					{!detailed && (
						<Typography sx={classes.title} color='textSecondary' gutterBottom>
							{SSNTitle} {isRequest && ' Request'}
						</Typography>
					)}
					{detailed ? (
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<img src={qrCode} width='100%' alt='' />
							</Grid>
							<Grid item xs={9}>
								<Typography gutterBottom variant='h5' component='h2'>
									{card?.name}
								</Typography>
								{!forShare && (
									<Typography
										style={{ marginTop: -10, marginBottom: 8 }}
										variant='subtitle1'
										color='textSecondary'
									>
										Through — {card?.reference}
									</Typography>
								)}
							</Grid>
						</Grid>
					) : (
						<CardHeader
							style={{ padding: 0 }}
							action={
								isRequest ? (
									<Chip
										style={{
											fontSize: '1.6rem',
											color:
												record.cardRequest?.approval?.status === 'Rejected' ? 'red' : 'orange',
										}}
										variant='outlined'
										label={record.cardRequest?.approval?.status ?? 'Pending'}
									/>
								) : (
									<Chip
										style={{ fontSize: '2.2rem', color: 'primary' }}
										variant='outlined'
										label={card?.card_no}
									/>
								)
							}
							title={
								<Typography gutterBottom variant='h5' component='h2'>
									{card?.name}
								</Typography>
							}
							subheader={
								<Typography style={{ marginTop: -10, marginBottom: 8 }} variant='subtitle1'>
									Arrival — {arrival}
									<br />
									Visitors — {`${card?.people_count} ( ${numberToText(card?.people_count)} )`}
								</Typography>
							}
						/>
					)}
					{detailed && (
						<>
							<Divider />
							<List>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar sx={classes.avatar}>
											<RecentActorsIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<>
												Card ID{' '}
												<Chip
													sx={classes.chip}
													style={{ fontSize: '2.2rem' }}
													variant='outlined'
													label={card?.card_no}
												/>
											</>
										}
										secondary=' — Show this number at entry gate'
									/>
								</ListItem>
								<Divider variant='inset' component='li' />
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar sx={classes.avatar}>
											<EventAvailableIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<>
												Arrival{' '}
												<Typography
													component='span'
													variant='body2'
													sx={classes.inline}
													style={{ float: 'right' }}
													color='textPrimary'
												>
													{arrival}
												</Typography>
											</>
										}
										secondary=' — Permitted date of arrival'
									/>
								</ListItem>
								<Divider variant='inset' component='li' />

								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar sx={classes.avatar}>
											<FaceIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<>
												Visitors{' '}
												<Chip
													sx={classes.chip}
													style={{ fontSize: '1.6rem' }}
													variant='outlined'
													label={`${card?.people_count} / ${numberToText(
														card?.people_count
													)}`}
												/>
											</>
										}
										secondary='— Allowed number of visitors'
									/>
								</ListItem>
							</List>
							<Divider />
							<Typography style={{ marginTop: 12 }} variant='body2' color='textSecondary' component='p'>
								Note: Accommodation at Shri Sant Nagar is only for one night.
							</Typography>
						</>
					)}
				</CardContent>
				{!detailed && !isRequest && (
					<CardActions>
						<Button
							size='small'
							color='primary'
							onClick={async () => {
								if (appBuildCode > 45 || isWebEnv()) {
									if (false && isTest(undefined, userData)) {
										toggleDrawer(<SSNCardTile {...props} detailed forShare />);
									} else {
										toggleDrawer(<SandeshTile {...props} detailed forShare />);
									}

									// let cardEl = ref.current as HTMLElement;
									// if (cardEl) {
									// 	let dataUrl = await toJpeg(cardEl, { quality: 0.4 });

									// 	App.shareImageDataURI(
									// 		'Shri Anandpur Satsang',
									// 		dataUrl,
									// 		'https://www.shrianandpur.org'
									// 	);

									// 	return;
									// }
								} else {
									App.shareLink(
										`***SSN Anumati Patra***\n
	Card No. ${card?.card_no}
	Name: ${card?.name}
	Through: ${card?.reference}
	Visitors: ${card?.people_count}
	Arrival: ${arrival}\n
Note: Accommodation at Shri Sant Nagar is only for one night.\n`,
										'',
										// 'https://www.shrianandpur.org/ssn/' + record?.id,
										'text',
										''
									);
								}

								if (record) {
									markSandeshShared(record.id);
								}
							}}
						>
							Share {record._shared ? sharedIcon : ''}
						</Button>
						<Button
							style={{ marginLeft: 'auto' }}
							size='small'
							color='primary'
							onClick={(e) => {
								e.stopPropagation();

								// let options: BottomDrawerOptionProps = {
								// 	options: [
								// 		{
								// 			element: <SandeshTile {...props} detailed />,
								// 		},
								// 	],
								// };

								// let optionsList = BottomDrawerOptions(options);

								if (false && isTest(undefined, userData)) {
									toggleDrawer(<SSNCardTile {...props} detailed />);
								} else {
									toggleDrawer(<SandeshTile {...props} detailed />);
								}
							}}
						>
							Show Details
						</Button>
					</CardActions>
				)}
				{isRequest && (
					<CardActions>
						<Button
							size='small'
							color='inherit'
							onClick={async (e) => {
								e.stopPropagation();

								navigate('/ssnrequest/' + record.id, { state: { isModal: true } });
							}}
						>
							Edit Request
						</Button>
						<Button
							style={{ marginLeft: 'auto' }}
							size='small'
							color='secondary'
							onClick={async (e) => {
								e.stopPropagation();

								await setRecord('sandesh', record.id, { deleted: true });
							}}
						>
							Delete
						</Button>
					</CardActions>
				)}
			</Card>
		);
	}

	let localeClass = '';
	if (record?.title[locale]) {
		if (locale === 'hi') {
			localeClass = 'locale-hi';
		}
	} else if (record?.title.hi) {
		localeClass = 'locale-hi';
	}

	let side = record?.from === userData.phone ? 'right' : 'left';
	let additionalSx = record?.type === SandeshType.Text ? classes[side + props.order] : {};

	return record?.type === SandeshType.Text ? (
		<Box ref={ref} sx={[classes[side + 'Row']]}>
			<Typography sx={[classes.msg, classes[side], additionalSx]}>
				{record.message[locale] || record.message.hi || record.message.en}
				<IconButton
					onClick={() => {
						let options: BottomDrawerOptionProps = {
							options: [
								{
									title: 'Delete',
									icon: Icons.Delete,
									onClick: () => {
										deleteSandesh(record.id);

										props.toggleDrawer();
									},
								},
							],
						};

						let optionsList = BottomDrawerOptions(options);

						toggleDrawer(optionsList);
					}}
				>
					<Icons.MoreVertIcon sx={{ color: side === 'right' ? 'whitesmoke' : 'inherit' }} />
				</IconButton>
			</Typography>
		</Box>
	) : (
		<Card
			sx={[classes.card(props), { maxWidth: '80%' }]}
			style={style}
			data-id={record?.id}
			ref={ref}
			onClick={() => {
				if (!record) {
					return;
				}

				if (props.onClick) {
					props.onClick(record);
					return;
				}
			}}
		>
			(
			<CardHeader
				sx={classes.header}
				// action={!record ? null : <ArticleOptionsIcon record={record} />}
				title={
					!record ? (
						<Skeleton animation='wave' height={10} width='80%' style={{ marginBottom: 6 }} />
					) : (
						<Typography noWrap className={localeClass} variant='subtitle2'>
							{record.title[locale] || record.title.hi || record.title.en}
						</Typography>
					)
				}
			/>
			)
			<CardContent sx={classes.content}>
				{!record ? (
					<>
						<Skeleton animation='wave' height={10} style={{ marginBottom: 6 }} width='80%' />
						<Skeleton animation='wave' height={10} />
					</>
				) : (
					<>
						<Typography variant='body1' component='div' className={localeClass} sx={classes.msg}>
							<Box sx={classes.details}>
								<Box sx={classes.leftDetails}>
									{record.message[locale] || record.message.hi || record.message.en}
								</Box>
							</Box>
						</Typography>
						(
						<Box sx={classes.abs}>
							<Typography variant='caption' color='textSecondary' noWrap className='locale-en'>
								{new AnandDate(record.creationTime ?? record.publishTime).format(
									'Do MMM, YYYY hh:mm A'
								)}
							</Typography>
						</Box>
						)
					</>
				)}
			</CardContent>
		</Card>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		locale: state.uxState.locale,
		appBuildCode: state.uxState.appParams.appBuildCode,
		record: state.dataState.sandesh.byId[props.record.id],
		userData: state.userState.userStore.userData!,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean) => {
			dispatch(toggleBottomDrawer(content, open, { '& .MuiDrawer-paperAnchorBottom': { borderRadius: 4 } }));
		},
		markSandeshShared: (id: string) => {
			dispatch(markSandeshShared(id));
		},
		deleteSandesh: (sandeshId: string) => {
			dispatch(modifySandesh({ sandeshId, deleted: true }));
		},
	};
}

export const SandeshTile = connect(mapStateToProps, mapDispatchToProps)(Component);
