import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';
import { updateFBConfigs } from './auth';

export class ConfigsFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'configs';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'configs');
	public getChangeProcessAction = (changes) => updateFBConfigs(changes);
}
