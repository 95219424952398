import SetAddress from 'components/profile/address/SetAddress';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function UserOptionsPage(props) {
	let { isModal } = props;
	let { isOrder } = props.routeState;
	let { addrId } = useParams();

	return (
		<>
			{!isModal ? <HomePage /> : null}
			<DialogPage title={addrId ? 'Update Address' : 'Add New Address'} maxWidth='xs' transition={false} dark>
				<SetAddress addrId={addrId} isOrder={isOrder} />
			</DialogPage>
		</>
	);
}
