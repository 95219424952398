import { Timestamp } from 'firebase/firestore';

export default class AnandDate {
	private dateobj: Date;

	/**
	 * Converts YYYY-MM-DD HH:MM:SS to a Date object
	 *
	 * @param datetime
	 * @param timezoneOffset
	 */
	public constructor(datetime?: string | Timestamp, timezoneOffset: number = 330) {
		this.dateobj = new Date();
		if (timezoneOffset) {
			this.dateobj.setTime(this.dateobj.getTime() + (this.dateobj.getTimezoneOffset() + timezoneOffset) * 60000);
		}

		if (datetime && typeof datetime === 'string') {
			let [datestr, timestr] = datetime.split(' ');

			this.setDate(datestr);
			this.setTime(timestr);
		}

		if (datetime && typeof datetime === 'object') {
			this.setEpoch(datetime.seconds * 1000);
		}
	}

	public setDateObj(dateobj: Date) {
		this.dateobj = dateobj;
		return this;
	}

	public getDateObj() {
		return this.dateobj;
	}

	public setEpoch(epochMilli: number) {
		this.dateobj.setTime(epochMilli);
		return this;
	}

	public setDate(datestr: string) {
		if (datestr) {
			let [year, month, date] = datestr.split('-');
			this.dateobj.setFullYear(
				parseInt(year),
				month ? parseInt(month) - 1 : undefined,
				date ? parseInt(date) : undefined
			);
		}

		return this;
	}

	public setTime(timestr: string, timezoneOffset: number = 5.5) {
		if (timestr) {
			let [hh, mm, ss] = timestr.split(':');
			this.dateobj.setHours(parseInt(hh), parseInt(mm), parseInt(ss));
		}

		return this;
	}

	public add(value: number, type: string): AnandDate {
		switch (type) {
			case 'year':
				this.dateobj.setFullYear(this.dateobj.getFullYear() + value);
				break;
			case 'month':
				this.dateobj.setMonth(this.dateobj.getMonth() + value);
				break;
			case 'day':
				this.dateobj.setDate(this.dateobj.getDate() + value);
				break;
			case 'hour':
				this.dateobj.setHours(this.dateobj.getHours() + value);
				break;
			case 'minute':
				this.dateobj.setMinutes(this.dateobj.getMinutes() + value);
				break;
			case 'second':
				this.dateobj.setSeconds(this.dateobj.getSeconds() + value);
				break;
		}

		return this;
	}

	public subtract(value: number, type: string): AnandDate {
		return this.add(-1 * value, type);
	}

	public isBefore(date: AnandDate): boolean {
		return this.dateobj.getTime() < date.dateobj.getTime();
	}

	public isAfter(date: AnandDate): boolean {
		return date.isBefore(this);
	}

	public isSameOrBefore(date: AnandDate): boolean {
		return this.dateobj.getTime() <= date.dateobj.getTime();
	}

	public isSameOrAfter(date: AnandDate): boolean {
		return date.isSameOrBefore(this);
	}

	public isSameDate(date: AnandDate): boolean {
		return (
			this.dateobj.getFullYear() === date.dateobj.getFullYear() &&
			this.dateobj.getMonth() === date.dateobj.getMonth() &&
			this.dateobj.getDate() === date.dateobj.getDate()
		);
	}

	public isBeforeDate(date: AnandDate): boolean {
		return (
			this.dateobj.getFullYear() < date.dateobj.getFullYear() ||
			(this.dateobj.getFullYear() === date.dateobj.getFullYear() &&
				this.dateobj.getMonth() < date.dateobj.getMonth()) ||
			(this.dateobj.getFullYear() === date.dateobj.getFullYear() &&
				this.dateobj.getMonth() === date.dateobj.getMonth() &&
				this.dateobj.getDate() < date.dateobj.getDate())
		);
	}

	public isSameOrBeforeDate(date: AnandDate): boolean {
		return this.isSameDate(date) || this.isBefore(date);
	}

	public isSameOrAfterDate(date: AnandDate): boolean {
		return date.isSameOrBeforeDate(this);
	}

	public year() {
		return this.dateobj.getFullYear();
	}

	public month() {
		return this.dateobj.getMonth();
	}

	public date() {
		return this.dateobj.getDate();
	}

	public hours() {
		return this.dateobj.getHours();
	}

	private getDo(): string {
		let date = this.dateobj.getDate();
		let datesuffix: string;
		if (date === 1 || date === 21 || date === 31) {
			datesuffix = 'st';
		} else if (date === 2 || date === 22) {
			datesuffix = 'nd';
		} else if (date === 3 || date === 23) {
			datesuffix = 'rd';
		} else {
			datesuffix = 'th';
		}

		return date + datesuffix;
	}

	private getShortMonth(): string {
		let shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		return shortMonths[this.dateobj.getMonth()];
	}

	private getFullMonth(): string {
		let shortMonths = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		return shortMonths[this.dateobj.getMonth()];
	}

	private getDDD(): string {
		let weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		return weekDays[this.dateobj.getDay()];
	}

	private getdd(): string {
		let weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		return weekDays[this.dateobj.getDay()];
	}

	private getMM(): string {
		let month = this.dateobj.getMonth() + 1;
		return month < 10 ? '0' + month : month.toString();
	}

	private getDD(): string {
		let date = this.dateobj.getDate();
		return date < 10 ? '0' + date : date.toString();
	}

	private prefixZero(value: number) {
		if (value < 10) {
			return '0' + value.toString();
		}
		return value.toString();
	}

	public format(formatType: string): string {
		if (!formatType) {
			return formatType;
		}

		let datestr = formatType;

		let hours = this.dateobj.getHours();
		datestr = datestr.replace(/HH/g, this.prefixZero(this.dateobj.getHours()));
		datestr = datestr.replace(/hh/g, this.prefixZero(hours >= 13 ? hours - 12 : hours));
		datestr = datestr.replace(/mm/g, this.prefixZero(this.dateobj.getMinutes()));
		datestr = datestr.replace(/ss/g, this.prefixZero(this.dateobj.getSeconds()));
		datestr = datestr.replace(/A/g, hours >= 12 ? 'PM' : 'AM');
		datestr = datestr.replace(/a/g, hours >= 12 ? 'pm' : 'am');

		datestr = datestr.replace(/YYYY/g, this.dateobj.getFullYear().toString());
		datestr = datestr.replace(/MMMM/g, this.getFullMonth());
		datestr = datestr.replace(/MMM/g, this.getShortMonth());
		datestr = datestr.replace(/MM/g, this.getMM());
		datestr = datestr.replace(/ddd/g, this.getDDD());
		datestr = datestr.replace(/dd/g, this.getdd());
		datestr = datestr.replace(/DD/g, this.getDD());
		datestr = datestr.replace(/Do/g, this.getDo());

		return datestr;
	}
}
