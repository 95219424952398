import { push } from '@lagunovsky/redux-react-router';
import App from 'AppInterface/App';
import { isTestEnv } from 'config';
import { noticesRoute } from 'config/route';
import { ofType } from 'redux-observable';
import { concat, EMPTY, merge, Observable, of } from 'rxjs';
import { debounceTime, delay, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { UPDATE_NOTIFICATIONS_FILTERED } from 'store/data/notifications/actions';
import {
	performOperationAtInterval,
	performOperationDaily,
	performOperationOnce,
	performOperationOnceInSession,
	stopOperation,
	StopOperation,
	STOP_OPS,
} from 'store/data/ops/actions';
import { history } from 'store/history';
import { ACK_NOTIFICATION } from 'store/ux/actions';
import { ApplicationState } from 'types';

let hasPushedStartupPage = false;
let historyObserver = Observable.create((iobserver) => {
	history.listen((update) => {
		iobserver.next(update);
	});
	if (!hasPushedStartupPage) {
		hasPushedStartupPage = true;
		let location = window.location;
		iobserver.next({ location: location, action: 'PUSH' });
	}
});

export const notificationsEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_NOTIFICATIONS_FILTERED, ACK_NOTIFICATION),
		debounceTime(1000),
		mergeMap(() => {
			let notifications = (state$.value as ApplicationState).filteredDataState.notifications;
			let user = (state$.value as ApplicationState).userState.userStore.user;
			let acks = state$.value.uxState.acknowledge || {};
			notifications = notifications.filter(
				(notice) => !acks[notice.id] //|| acks[notice.id] < notice.updatedAt.seconds
			);

			if (!notifications.length || isTestEnv) {
				return of(stopOperation('notifications'));
			}

			let result: any[] = [];

			notifications
				.sort((a, b) => a.order - b.order)
				.forEach((notification) => {
					let fq = notification.showFrequency;
					let routeAction = push('/notices', noticesRoute.state);

					switch (fq) {
						case 'daily':
							result.push(of(performOperationDaily('notifications', routeAction)));
							break;
						case 'once':
							result.push(of(performOperationOnce('notifications', routeAction)));
							break;
						case 'startup':
							result.push(of(performOperationOnceInSession('notifications', routeAction)));
							break;
						case 'home':
							result.push(
								historyObserver.pipe(
									mergeMap((locationObj: any) => {
										let { location, action } = locationObj;
										if (action === 'PUSH' && location.pathname === '/') {
											return of(routeAction).pipe(delay(1000));
										}
										return EMPTY;
									}),
									takeUntil(
										action$.pipe(
											ofType(STOP_OPS),
											filter((act: StopOperation) => act.key === 'notifications')
										)
									)
								)
							);
							break;
						default:
							if (parseFloat(fq) > 0) {
								result.push(
									of(performOperationAtInterval('notifications', parseFloat(fq) * 60 * 60, routeAction))

									// timer(500, fq * 60 * 60 * 1000).pipe(
									// 	map(() => push(noticesRoute.to, noticesRoute.state))
									// )
								);
							}
					}
				});

			// Update badge_count
			App.putToAppStorage('badge_count', notifications.length.toString());
			return concat(of(stopOperation('notifications')), merge(...result));
		})
	);
