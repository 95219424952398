import { Box, Card, FormControlLabel, Radio, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-mui';
import React from 'react';
import { connect } from 'react-redux';
import { changeLocale } from 'store/ux/actions';
import { ApplicationState, LocaleType } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
};

export interface Props extends ScreenManagerProps {
	changeLocale: (locale: LocaleType) => void;
	locale: string;
}

class ChangePassword extends React.Component<Props, any> {
	render() {
		let { changeLocale, locale, popScreen } = this.props;

		return (
			<Box sx={classes.root}>
				<UserPicInfo />

				<Card sx={classes.card}>
					<Typography style={{ marginBottom: 24 }}>Select your preferred language:</Typography>
					<Formik
						initialValues={{
							lang: locale,
						}}
						validationSchema={() =>
							Yup.object({
								lang: Yup.string().required('Required'),
							})
						}
						onSubmit={(values, { setSubmitting }) => {
							changeLocale(values.lang as LocaleType);
							popScreen();
							return;
						}}
					>
						{({ submitForm, isSubmitting }) => (
							<Box component={Form} sx={classes.form} translate='yes'>
								<Field component={RadioGroup} name='lang'>
									<FormControlLabel
										value='en'
										control={<Radio disabled={isSubmitting} />}
										label='English'
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value='hi'
										control={<Radio disabled={isSubmitting} />}
										label='Hindi'
										disabled={isSubmitting}
									/>
								</Field>
								<Button
									sx={classes.signinbtn}
									variant='contained'
									color='primary'
									onClick={submitForm}
									disabled={isSubmitting}
								>
									Save
								</Button>
							</Box>
						)}
					</Formik>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		changeLocale: async (locale: LocaleType) => {
			dispatch(changeLocale(locale));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
