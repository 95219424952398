import { combineReducers } from 'redux';
import { HD, HDById, HDStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateHD, UpdateHDFiltered, UPDATE_HD, UPDATE_HD_FILTERED } from './actions';

const hdByIdReducer = (state: HDById = {}, action: UpdateHD) => {
	switch (action.type) {
		case UPDATE_HD:
			return updateData('HD', state, action.changes, action.overwrite);
		default:
			return state;
	}
};

export const filteredHDReducer = (state: HD[] = [], action: UpdateHDFiltered) => {
	switch (action.type) {
		case UPDATE_HD_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const hdReducer = combineReducers<HDStore>({
	byId: hdByIdReducer,
});
