import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Rhyme } from 'types';
import RecordList from '../RecordList';
import { RhymeTile } from './RhymeTile';

interface RhymeListProps {
	rhymes: Rhyme[];
}

class Component extends React.Component<RhymeListProps, any> {
	private getItemSize(index) {
		let { rhymes } = this.props;

		let record = rhymes[index];
		if (!record) {
			return 86;
		}

		return 86 + 10;
	}

	render() {
		let { rhymes } = this.props;

		return <RecordList records={rhymes} fetching={!rhymes.length} RecordTile={RhymeTile} getItemSize={this.getItemSize.bind(this)}></RecordList>;
	}
}

function mapStateToProps(state: ApplicationState) {
	let rhymes = state.filteredDataState.rhymes;
	if (rhymes) {
		if (state.tempState.searchTerm.length > 0) {
			rhymes = rhymes.filter((rhyme) => rhyme.title.indexOf(state.tempState.searchTerm) >= 0);
		}
		rhymes = rhymes.sort((a, b) => b.publishTime.seconds - a.publishTime.seconds);
	} else {
		rhymes = Array.from(new Array(4)) as Rhyme[];
	}

	return {
		rhymes,
	};
}

export const RhymeList = connect(mapStateToProps)(Component);
