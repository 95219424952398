import { Card } from '@mui/material';
import { BookletControl } from 'components/booklets/Booklet';
import { HDControl } from 'components/hd/HDControl';
import { MovingImages } from 'components/MovingImages';
import { RecentRecordSections } from 'components/RecentRecordSections';
import { Banner } from 'components/website/Banner';
import { WebPage } from 'components/website/WebPage';
import { AppName, DBConfig, IntroImg1Src, IntroImg2Src } from 'config';
import { isAdmin, isFakeWebViewEnv, isMobile } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'types';
import IntroPage from './IntroPage';
import Page from './Page';

const Component = (props) => {
	let { id } = useParams();

	return isMobile() || props.isAdmin ? (
		isFakeWebViewEnv() || props.isAdmin ? (
			<Page title={AppName} includeBottomNavigation dark>
				<RecentRecordSections />
			</Page>
		) : (
			<IntroPage />
		)
	) : (
		<>
			<Banner />
			<WebPage bgcolor="white">
				<Card elevation={3}>
					<HDControl id={id} style={{ marginLeft: 16, marginRight: 16 }} />
					<MovingImages img1Src={IntroImg1Src} img2Src={IntroImg2Src} />
					<BookletControl style={{ padding: 16 }} configKey={DBConfig.IntroductionBooklet} />
				</Card>
			</WebPage>
		</>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		isAdmin: isAdmin(state.userState.userStore.user, state.userState.userStore.userData),
	};
}

export default connect(mapStateToProps)(Component);
