import { combineReducers } from 'redux';
import { Notification, NotificationsById, NotificationStore } from 'types';
import { updateData } from '../generic/reducers';
import {
	UpdateNotifications,
	UpdateNotificationsFiltered,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_FILTERED,
} from './actions';

const notificationsByIdReducer = (state: NotificationsById = {}, action: UpdateNotifications) => {
	switch (action.type) {
		case UPDATE_NOTIFICATIONS:
			return updateData('Notifications', state, action.changes, action.overwrite);
		default:
			return state;
	}
};

export const filteredNotificationsReducer = (state: Notification[] = [], action: UpdateNotificationsFiltered) => {
	switch (action.type) {
		case UPDATE_NOTIFICATIONS_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const notificationsReducer = combineReducers<NotificationStore>({
	byId: notificationsByIdReducer,
});
