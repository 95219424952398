import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_CONFIG = '@@configs/UPDATE_CONFIG';
export type T_UPDATE_CONFIG = typeof UPDATE_CONFIG;

export interface UpdateConfigs extends Action {
	type: T_UPDATE_CONFIG;
	changes: FBChanges;
}

export function updateConfigs(changes: FBChanges): UpdateConfigs {
	return {
		type: UPDATE_CONFIG,
		changes,
	};
}
