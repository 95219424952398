import Faq from 'components/documents/Faq';
import Policies from 'components/documents/Policies';
import OrderItemTile from 'components/orders/OrderItemTile';
import OrderList from 'components/orders/OrderList';
import SubscriptionItemTile from 'components/orders/SubscriptionItemTile';
import { Subscriptions } from 'components/orders/Subscriptions';
import ScreenManager from 'components/shell/ScreenManager';
import { IsOwnerSAPD } from 'config';
import React from 'react';
import AccountOptions from './AccountOptions';
import AddressList from './address/AddressList';
import SetAddress from './address/SetAddress';
import ChangeLanguage from './ChangeLanguage';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import { NewPhoneSignIn } from './NewPhoneSignIn';
import { NityaNiyamSettings } from './NityaNiyamSettings';
import { PostalContact } from './PostalContact';
import ProfileDetails from './ProfileDetails';
import SignIn from './SignIn';
import UpdateEmail from './UpdateEmail';
import UpdatePhone from './UpdatePhone';
import { UserFeedback } from './UserFeedback';
import { UserSettings } from './UserSettings';

interface Props {
	initScreen?: string;
	rootOnInit?: boolean;
	query?: URLSearchParams;
	path?: string[];
}

export enum AccountMode {
	MainOptions = 'main',
	SignIn = 'signin',
	PhoneSignIn = 'phonesignin',
	Profile = 'profile',
	UpdateEmail = 'updateemail',
	UpdatePhone = 'updatephone',
	Password = 'password',
	Delete = 'delete',
	ChangeLang = 'lang',
	Settings = 'settings',
	Addresses = 'addresses',
	Faq = 'faq',
	Feedback = 'feedback',
	Contact = 'contact',
	Policy = 'policy',
	Orders = 'orders',
	Order = 'order',
	Subscriptions = 'subscriptions',
	Subscription = 'subscription',
	Address = 'address',
	NityaNiyam = 'nityaniyam',
}

const ComponentsMap = {
	[AccountMode.MainOptions]: AccountOptions,
	[AccountMode.PhoneSignIn]: IsOwnerSAPD ? NewPhoneSignIn : null,
	[AccountMode.SignIn]: IsOwnerSAPD ? SignIn : null,
	[AccountMode.Profile]: ProfileDetails,
	[AccountMode.UpdateEmail]: UpdateEmail,
	[AccountMode.UpdatePhone]: UpdatePhone,
	[AccountMode.Password]: ChangePassword,
	[AccountMode.Delete]: DeleteAccount,
	[AccountMode.ChangeLang]: ChangeLanguage,
	[AccountMode.Settings]: UserSettings,
	[AccountMode.Addresses]: AddressList,
	[AccountMode.Address]: SetAddress,
	[AccountMode.Faq]: Faq,
	[AccountMode.Feedback]: IsOwnerSAPD ? UserFeedback : null,
	[AccountMode.Contact]: PostalContact,
	[AccountMode.Policy]: Policies,
	[AccountMode.Orders]: OrderList,
	[AccountMode.Subscriptions]: Subscriptions,
	[AccountMode.Subscription]: SubscriptionItemTile,
	[AccountMode.Order]: OrderItemTile,
	[AccountMode.NityaNiyam]: NityaNiyamSettings,
};

export const getAccountScreenTitle = (key: string) => {
	let title;
	switch (key) {
		case AccountMode.SignIn:
		case AccountMode.PhoneSignIn:
			title = 'Sign In';
			break;
		case AccountMode.Profile:
			title = 'Update Profile Details';
			break;
		case AccountMode.UpdateEmail:
			title = 'Update Email Address';
			break;
		case AccountMode.UpdatePhone:
			title = 'Update Phone Number';
			break;
		case AccountMode.Password:
			title = 'Change Password';
			break;
		case AccountMode.Delete:
			title = 'Delete Account';
			break;
		case AccountMode.ChangeLang:
			title = 'Choose Language';
			break;
		case AccountMode.Settings:
			title = 'App Settings';
			break;
		case AccountMode.Addresses:
			title = 'Manage Addresses';
			break;
		case AccountMode.Faq:
			title = 'FAQ';
			break;
		case AccountMode.Policy:
			title = 'Policies';
			break;
		case AccountMode.Feedback:
			title = 'Provide Feedback';
			break;
		case AccountMode.Contact:
			title = 'Contact Us';
			break;
		case AccountMode.Orders:
			title = 'Your Orders';
			break;
		case AccountMode.Order:
			title = 'Order Details';
			break;
		case AccountMode.Subscriptions:
			title = 'Manage Subscriptions';
			break;
		case AccountMode.Subscription:
			title = 'Subscription Details';
			break;
		case AccountMode.Address:
			title = 'Update Address';
			break;
		case AccountMode.NityaNiyam:
			title = 'Nitya Niyam Settings';
			break;
		default:
			title = 'Account';
			break;
	}
	return title;
};

export const isScreenBgDark = (key: string) => {
	let isBgDark = true;
	switch (key) {
		case AccountMode.SignIn:
		case AccountMode.PhoneSignIn:
			isBgDark = false;
			break;
	}
	return isBgDark;
};

export class AccountScreenManager extends React.Component<Props> {
	getComponentForScreen = (key: string) => {
		return ComponentsMap[key];
	};

	render() {
		return (
			<ScreenManager
				{...this.props}
				getComponentForScreen={this.getComponentForScreen}
				getScreenTitle={getAccountScreenTitle}
				isBgDark={isScreenBgDark}
			/>
		);
	}
}
