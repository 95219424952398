import { Context } from 'helpers/context';
import { Action } from 'redux';
import { Article, AudioCurrentState, NetStatus } from 'types';

export const FILTER_DATA_INIT = '@@temp/FILTER_DATA_INIT';
export type T_FILTER_DATA_INIT = typeof FILTER_DATA_INIT;

export interface InitializeFilteredData extends Action {
	type: T_FILTER_DATA_INIT;
}

export const initializeFilteredData = (): InitializeFilteredData => {
	return {
		type: FILTER_DATA_INIT,
	};
};

export const WEB_APP_INIT = '@@temp/WEB_APP_INIT';
export type T_WEB_APP_INIT = typeof WEB_APP_INIT;

export interface WebAppInitialized extends Action {
	type: T_WEB_APP_INIT;
}

export const webAppInitialized = (): WebAppInitialized => {
	return {
		type: WEB_APP_INIT,
	};
};

export const START_LOADING = '@@temp/START_LOADING';
export type T_START_LOADING = typeof START_LOADING;

export interface StartLoading extends Action {
	type: T_START_LOADING;
}

export const startLoading = (): StartLoading => {
	return {
		type: START_LOADING,
	};
};

export const STOP_LOADING = '@@temp/STOP_LOADING';
export type T_STOP_LOADING = typeof STOP_LOADING;

export interface StopLoading extends Action {
	type: T_STOP_LOADING;
}

export const stopLoading = (): StopLoading => {
	return {
		type: STOP_LOADING,
	};
};

export type ACTION_LOADING = T_START_LOADING | T_STOP_LOADING;
export type ActionLoading = StartLoading | StopLoading;

export const OPEN_DRAWER = '@@temp/OPEN_DRAWER';
export type T_OPEN_DRAWER = typeof OPEN_DRAWER;

export interface OpenDrawer extends Action {
	type: T_OPEN_DRAWER;
}

export const openDrawer = (): OpenDrawer => {
	return {
		type: OPEN_DRAWER,
	};
};

export const CLOSE_DRAWER = '@@temp/CLOSE_DRAWER';
export type T_CLOSE_DRAWER = typeof CLOSE_DRAWER;

export interface CloseDrawer extends Action {
	type: T_CLOSE_DRAWER;
}

export const closeDrawer = (): CloseDrawer => {
	return {
		type: CLOSE_DRAWER,
	};
};

export const TOGGLE_DRAWER = '@@temp/TOGGLE_DRAWER';
export type T_TOGGLE_DRAWER = typeof TOGGLE_DRAWER;

export interface ToggleDrawer extends Action {
	type: T_TOGGLE_DRAWER;
}

export const toggleDrawer = (): ToggleDrawer => {
	return {
		type: TOGGLE_DRAWER,
	};
};

export type ACTION_DRAWER = T_OPEN_DRAWER | T_CLOSE_DRAWER | T_TOGGLE_DRAWER;
export type ActionDrawer = OpenDrawer | CloseDrawer | ToggleDrawer;

export const TOGGLE_BOTTOM_DRAWER = '@@temp/TOGGLE_BOTTOM_DRAWER';
export type T_TOGGLE_BOTTOM_DRAWER = typeof TOGGLE_BOTTOM_DRAWER;

export interface ToggleBottomDrawer extends Action {
	type: T_TOGGLE_BOTTOM_DRAWER;
	content?: any;
	open?: boolean;
	styles?: any;
}

export const toggleBottomDrawer = (content?: any, open?: boolean, styles?: any): ToggleBottomDrawer => {
	return {
		type: TOGGLE_BOTTOM_DRAWER,
		content: content,
		open,
		styles,
	};
};

export const TOGGLE_ALERT_DIALOG = '@@temp/TOGGLE_ALERT_DIALOG';
export type T_TOGGLE_ALERT_DIALOG = typeof TOGGLE_ALERT_DIALOG;

export interface ToggleAlertDialog extends Action {
	type: T_TOGGLE_ALERT_DIALOG;
	content?: any;
}

export const toggleAlertDialog = (content?: any): ToggleAlertDialog => {
	return {
		type: TOGGLE_ALERT_DIALOG,
		content: content,
	};
};

export const TOGGLE_ON_DEMAND_PAGE = '@@temp/TOGGLE_ON_DEMAND_PAGE';
export type T_TOGGLE_ON_DEMAND_PAGE = typeof TOGGLE_ON_DEMAND_PAGE;

export interface ToggleOnDemandPage extends Action {
	type: T_TOGGLE_ON_DEMAND_PAGE;
	component?: any;
}

export const toggleOnDemandPage = (component?: any): ToggleOnDemandPage => {
	return {
		type: TOGGLE_ON_DEMAND_PAGE,
		component,
	};
};

export const UPDATE_APP_BAR_TITLE = '@@temp/UPDATE_APP_BAR_TITLE';
export type T_UPDATE_APP_BAR_TITLE = typeof UPDATE_APP_BAR_TITLE;

export interface UpdateAppBarTitle extends Action {
	type: T_UPDATE_APP_BAR_TITLE;
	title: string;
}

export const updateAppBarTitle = (title: string): UpdateAppBarTitle => {
	return {
		type: UPDATE_APP_BAR_TITLE,
		title: title,
	};
};

export const UPDATE_NET_STATUS = '@@temp/UPDATE_NET_STATUS';
export type T_UPDATE_NET_STATUS = typeof UPDATE_NET_STATUS;

export interface UpdateNetStatus extends Action {
	type: T_UPDATE_NET_STATUS;
	status: NetStatus;
}

export const updateNetStatus = (status: NetStatus): UpdateNetStatus => {
	return {
		type: UPDATE_NET_STATUS,
		status: status,
	};
};

export const UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST = '@@temp/UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST';
export type T_UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST = typeof UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST;

export interface UpdateAudioPlaybackArticleList extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST;
	articleList: Article[];
	currentIndex: number;
}

export const updateAudioPlaybackArticleList = (
	articleList: Article[],
	currentIndex: number
): UpdateAudioPlaybackArticleList => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_ARTICLE_LIST,
		articleList: articleList,
		currentIndex: currentIndex,
	};
};

export const UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX = '@@temp/UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX';
export type T_UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX = typeof UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX;

export interface UpdateAudioPlaybackArticleIndex extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX;
	currentIndex: number;
}

export const updateAudioPlaybackArticleIndex = (currentIndex: number): UpdateAudioPlaybackArticleIndex => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_ARTICLE_INDEX,
		currentIndex: currentIndex,
	};
};

export const UPDATE_AUDIO_PLAYBACK_STATE = '@@temp/UPDATE_AUDIO_PLAYBACK_STATE';
export type T_UPDATE_AUDIO_PLAYBACK_STATE = typeof UPDATE_AUDIO_PLAYBACK_STATE;

export interface UpdateAudioPlaybackState extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_STATE;
	currentState: AudioCurrentState;
}

export const updateAudioPlaybackState = (currentState: AudioCurrentState): UpdateAudioPlaybackState => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_STATE,
		currentState: currentState,
	};
};

export const UPDATE_AUDIO_PLAYBACK_TIME = '@@temp/UPDATE_AUDIO_PLAYBACK_TIME';
export type T_UPDATE_AUDIO_PLAYBACK_TIME = typeof UPDATE_AUDIO_PLAYBACK_TIME;

export interface UpdateAudioPlaybackTime extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_TIME;
	currentTime: number;
}

export const updateAudioPlaybackTime = (currentTime: number): UpdateAudioPlaybackTime => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_TIME,
		currentTime: currentTime,
	};
};

export const UPDATE_AUDIO_PLAYBACK_DURATION = '@@temp/UPDATE_AUDIO_PLAYBACK_DURATION';
export type T_UPDATE_AUDIO_PLAYBACK_DURATION = typeof UPDATE_AUDIO_PLAYBACK_DURATION;

export interface UpdateAudioPlaybackDuration extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_DURATION;
	duration: number;
}

export const updateAudioPlaybackDuration = (duration: number): UpdateAudioPlaybackDuration => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_DURATION,
		duration,
	};
};

export const UPDATE_AUDIO_PLAYBACK_SPEED = '@@temp/UPDATE_AUDIO_PLAYBACK_SPEED';
export type T_UPDATE_AUDIO_PLAYBACK_SPEED = typeof UPDATE_AUDIO_PLAYBACK_SPEED;

export interface UpdateAudioPlaybackSpeed extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_SPEED;
	playbackSpeed: number;
}

export const updateAudioPlaybackSpeed = (playbackSpeed: number): UpdateAudioPlaybackSpeed => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_SPEED,
		playbackSpeed,
	};
};

export const UPDATE_AUDIO_PLAYBACK_REPEAT = '@@temp/UPDATE_AUDIO_PLAYBACK_REPEAT';
export type T_UPDATE_AUDIO_PLAYBACK_REPEAT = typeof UPDATE_AUDIO_PLAYBACK_REPEAT;

export interface UpdateAudioPlaybackRepeat extends Action {
	type: T_UPDATE_AUDIO_PLAYBACK_REPEAT;
	repeat: number;
}

export const updateAudioPlaybackRepeat = (repeat: number): UpdateAudioPlaybackRepeat => {
	return {
		type: UPDATE_AUDIO_PLAYBACK_REPEAT,
		repeat,
	};
};

export const REMOVE_AUDIO_PLAYBACK_DETAILS = '@@temp/REMOVE_AUDIO_PLAYBACK_DETAILS';
export type T_REMOVE_AUDIO_PLAYBACK_DETAILS = typeof REMOVE_AUDIO_PLAYBACK_DETAILS;

export interface RemoveAudioPlaybackDetails extends Action {
	type: T_REMOVE_AUDIO_PLAYBACK_DETAILS;
}

export const removeAudioPlaybackDetails = (): RemoveAudioPlaybackDetails => {
	return {
		type: REMOVE_AUDIO_PLAYBACK_DETAILS,
	};
};

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
	const key = notification.options && notification.options.key;

	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			...notification,
			key: key || new Date().getTime() + Math.random(),
		},
	};
};

export const closeSnackbar = (key) => ({
	type: CLOSE_SNACKBAR,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const removeSnackbar = (key) => ({
	type: REMOVE_SNACKBAR,
	key,
});

export const ON_EVENT = 'ON_EVENT';
export enum EventType {
	OperationStart,
	OperationEnd,
	Progress,
	Exception,
	AssertionFailure,
	Information,
	Action,
}

export interface OnEvent extends Action {
	type: typeof ON_EVENT;
	context: Context;
	eventType: EventType;
	eventName: string;
	payload: any;
}

export const onEvent = (context: Context, eventType: EventType, eventName: string, payload: any): OnEvent => ({
	type: ON_EVENT,
	context,
	eventType,
	eventName,
	payload,
});

export const UPDATE_MINI_PLAYER_MODE = '@@temp/UPDATE_MINI_PLAYER_MODE';
export type T_UPDATE_MINI_PLAYER_MODE = typeof UPDATE_MINI_PLAYER_MODE;

export interface UpdateMiniPlayerMode extends Action {
	type: T_UPDATE_MINI_PLAYER_MODE;
	value: boolean;
}

export const updateMiniPlayerMode = (value: boolean): UpdateMiniPlayerMode => {
	return {
		type: UPDATE_MINI_PLAYER_MODE,
		value,
	};
};

export const SEARCH_FOR = '@@temp/SEARCH_FOR';
export type T_SEARCH_FOR = typeof SEARCH_FOR;

export interface SearchFor extends Action {
	type: T_SEARCH_FOR;
	term: string;
}

export const searchFor = (term: string): SearchFor => {
	return {
		type: SEARCH_FOR,
		term,
	};
};

export const SHOW_PREVIEW = '@@temp/SHOW_PREVIEW';
export type T_SHOW_PREVIEW = typeof SHOW_PREVIEW;

export interface AdminPreview extends Action {
	type: T_SHOW_PREVIEW;
	value: boolean;
}

export const showAdminPreview = (value: boolean): AdminPreview => {
	return {
		type: SHOW_PREVIEW,
		value,
	};
};

export const SET_PREVIEW_SRC = '@@temp/SET_PREVIEW_SRC';
export type T_SET_PREVIEW_SRC = typeof SET_PREVIEW_SRC;

export interface SetPreviewSrc extends Action {
	type: T_SET_PREVIEW_SRC;
	value: string;
}

export const setPreviewSrc = (value: string): SetPreviewSrc => {
	return {
		type: SET_PREVIEW_SRC,
		value,
	};
};
