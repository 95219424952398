import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DialogContext } from 'components/pages/DialogPage';
import TextContent from 'components/TextContent';
import { fireAuth } from 'fb/initconfig';
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';
import { Form, Formik } from 'formik';
import { isSupportedCountry, timeout } from 'helpers';
import { MuiTelInput } from 'mui-tel-input';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import OtpInput from 'react18-input-otp';
import {
	CustomOTPData,
	CustomPhoneLoginData,
	PhoneLoginData,
	phoneSignIn,
	triggerCustomPhoneSignIn,
	triggerPhoneSignIn,
	verifyCustomOTP,
} from 'services/api/auth';
import { enqueueSnackbar, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';
import * as Yup from 'yup';

const classes = {
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 48,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(4),
		fontSize: '1.7rem',
	}),
	skipSigninButton: (theme: Theme) => ({
		height: 48,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(-6),
		fontSize: '1.4rem',
	}),
	ccDropDown: {
		'& .MuiBackdrop-root': {
			top: 0,
		},
	},
	countryList: (theme: Theme) => ({
		height: 'calc(100vh - 68px)',

		'& .flag.margin': {
			marginRight: theme.spacing(2),
		},

		'& .flag': {
			transform: 'scale(1.5)',
		},
	}),
	positionStart: {
		position: 'relative' as 'relative',
	},
	phoneInput: {
		'& .MuiInputBase-root': {
			fontSize: '2rem',
		},
	},
	flagButton: {
		minWidth: 30,
		padding: 0,
		height: 30,

		'& .flag': {
			transform: 'scale(1.8)',
		},
	},
	otpStyle: {
		width: 40, //'3.5rem !important',
		height: 50, //'4.5rem',
		marginRight: 8,
		fontSize: '2rem',
		borderRadius: 4,
		border: '2px solid rgba(0, 0, 0, 0.3)',
	},
	otpErrorStyle: {
		border: '2px solid red !important',
		color: 'red',
	},
	title: {
		marginBottom: 3,
		marginTop: 2,
		textAlign: 'center' as 'center',
	},
};

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
		userData: state.userState.userStore.userData,
		user: state.userState.userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		triggerPhoneSignIn: async (data: PhoneLoginData) => {
			return await dispatch(triggerPhoneSignIn(data));
		},
		triggerCustomPhoneSignIn: async (data: CustomPhoneLoginData) => {
			return await dispatch(triggerCustomPhoneSignIn(data));
		},
		verifyCustomOTP: async (data: CustomOTPData) => {
			return await dispatch(verifyCustomOTP(data));
		},
		phoneSignIn: async (code: string, confirmationResult, link?: boolean, reauth?: boolean) => {
			return await dispatch(phoneSignIn(code, confirmationResult, link, reauth));
		},
		enqueueSnackbar: (args) => {
			dispatch(enqueueSnackbar(args));
		},
	};
}

let connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
	forDelete?: boolean;
	style?: any;
	setMode?: (mode: string) => void;
	onSignInComplete?: () => void;
}

interface State {
	mode: 'phone' | 'otp' | 'link';
	captchaVerified: boolean;
	otpTimer: number;
	retriesLeft: number;
	iso2: string;
}

class PhoneSignInComponent extends React.Component<Props, State> {
	context!: React.ContextType<typeof DialogContext>;

	private phoneRef = React.createRef<any>();

	private submitBtnId = 'phone-signin-submit-btn';
	private recaptchaVerifier;
	private confirmationResult?: ConfirmationResult;
	private otpInterval;

	constructor(props: Props) {
		super(props);

		this.state = {
			mode: 'phone',
			captchaVerified: false,
			otpTimer: 0,
			retriesLeft: 3,
			iso2: '',
		};

		this.props.setMode && this.props.setMode('phone');
	}

	startOTPTimer = () => {
		let time = 30;

		if (this.state.retriesLeft <= 1) {
			this.setState({ retriesLeft: 0 });
			return;
		}

		this.setState({ otpTimer: time, retriesLeft: this.state.retriesLeft - 1 });

		this.otpInterval = setInterval(() => {
			if (this.state.otpTimer > 0) {
				this.setState({ otpTimer: this.state.otpTimer - 1 }, () => {
					if (this.state.otpTimer === 0) {
						clearInterval(this.otpInterval);
					}
				});
			}
		}, 1000);
	};

	componentDidMount = async () => {
		this.recaptchaVerifier = new RecaptchaVerifier(fireAuth, this.submitBtnId, {
			size: 'invisible',
			callback: (response) => {
				this.setState({ captchaVerified: true });
			},
		});
	};

	componentWillUnmount = () => {
		clearInterval(this.otpInterval);
	};

	onSuccess = () => {
		this.props.enqueueSnackbar({
			message: 'Verification Successful!',
			options: {
				variant: 'success',
			},
		});

		if (this.props.onSignInComplete) {
			this.props.onSignInComplete();
		}

		if (!this.props.forDelete) {
			this.context?.popScreen(true);
		}
	};

	phoneAuth = async (values, setSubmitting, setFieldValue, setErrors, retry?) => {
		let phone = values.phone.replace(/ /g, '').replace(/\(/, '').replace(/\)/, '').replace(/-/, '');

		try {
			if (!retry && (this.state.mode === 'otp' || this.state.mode === 'link')) {
				if (isSupportedCountry(this.state.iso2)) {
					await this.props.verifyCustomOTP({
						OTP: values.otp,
						number: phone,
						link: this.state.mode === 'link',
					});
				} else if (this.confirmationResult) {
					await this.props.phoneSignIn(
						values.otp,
						this.confirmationResult,
						this.state.mode === 'link',
						this.props.forDelete
					);
				}

				this.onSuccess();
			} else {
				if (phone.length === 0 || (this.state.iso2 === 'in' && phone.length !== 13)) {
					this.props.enqueueSnackbar({
						message: 'Invalid Phone Number!',
						options: {
							variant: 'error',
						},
					});
					return;
				}

				if (isSupportedCountry(this.state.iso2)) {
					await this.props.triggerCustomPhoneSignIn({
						number: phone,
						retry,
						voice: retry && this.state.retriesLeft === 1,
					});
				} else {
					this.confirmationResult = await this.props.triggerPhoneSignIn({
						phone: phone,
						recaptchaVerifier: this.recaptchaVerifier,
					});
				}

				this.setState({ mode: 'otp' });
				this.props.setMode && this.props.setMode('otp');

				this.startOTPTimer();
			}
		} catch (error) {
			setSubmitting(false);

			if (error?.code === 'auth/invalid-verification-code') {
				this.setState({ mode: 'otp' }, () => {
					setErrors({ otp: 'OTP verification failed' });
				});
				this.props.setMode && this.props.setMode('otp');
				this.startOTPTimer();
				this.props.enqueueSnackbar({
					message: 'OTP verification failed',
					options: {
						variant: 'error',
					},
				});
				// setFieldValue('otp', '');
			} else if (error?.code === 'auth/too-many-requests') {
				this.props.enqueueSnackbar({
					message: 'Too many OTP requests! Please try again after some time.',
					options: {
						variant: 'error',
					},
				});
				this.context?.popScreen();
			} else if (error?.code === 'auth/credential-already-in-use') {
				this.setState({ mode: 'link' });
				this.props.setMode && this.props.setMode('link');
			} else {
				if (error?.code === 'auth/code-expired') {
					await timeout(2000);

					if (this.props.user?.phoneNumber) {
						this.onSuccess();
						return;
					}
				}

				this.props.enqueueSnackbar({
					message: error?.message ?? JSON.stringify(error),
					options: {
						variant: 'error',
					},
				});
			}
		}
	};

	render() {
		let { style, userData, forDelete } = this.props;

		return (
			<div style={style}>
				{this.state.mode === 'phone' ? (
					<Typography variant='h5' sx={classes.title}>
						Enter Phone Number
					</Typography>
				) : this.state.mode === 'otp' ? (
					<Typography variant='h5' sx={classes.title}>
						Enter OTP
					</Typography>
				) : null}

				<Formik
					initialValues={{
						phone: userData?.phone || '',
						otp: '',
					}}
					validationSchema={() => {
						return Yup.object({
							phone: Yup.string(),
							otp:
								this.state.mode === 'otp'
									? Yup.string().required('Enter OTP received on your number')
									: Yup.string(),
						});
					}}
					onSubmit={async (values, { setErrors, setSubmitting, setFieldValue }) => {
						await this.phoneAuth(values, setSubmitting, setFieldValue, setErrors);
					}}
				>
					{({ values, errors, submitForm, isSubmitting, setFieldValue, setSubmitting, setErrors }) => (
						<Box component={Form} sx={classes.form} translate='yes'>
							{this.state.mode === 'link' ? (
								isSupportedCountry(this.state.iso2) ? (
									<>
										<TextContent style={{ marginBottom: 32 }}>
											Phone number {values.phone} is associated with an existing account. Do you
											want to link it with current user account?
										</TextContent>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											color={'primary'}
											onClick={submitForm}
											disabled={isSubmitting}
										>
											Link Both Accounts
										</Button>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											disabled={isSubmitting}
											onClick={() => {
												setFieldValue('phone', '');
												setFieldValue('otp', '');
												this.setState({ mode: 'phone' });
											}}
										>
											Verify Different Number
										</Button>
									</>
								) : (
									<>
										<TextContent style={{ marginBottom: 32 }}>
											Phone number {values.phone} is associated with an existing account.
										</TextContent>
										<Button
											sx={classes.signinbtn}
											variant='contained'
											disabled={isSubmitting}
											onClick={() => {
												setFieldValue('phone', '');
												setFieldValue('otp', '');
												this.setState({ mode: 'phone' });
											}}
										>
											Verify Different Number
										</Button>
									</>
								)
							) : (
								<>
									{this.state.mode === 'otp' ? (
										<OtpInput
											value={values.otp}
											onChange={(otp) => {
												setFieldValue('otp', otp);
											}}
											numInputs={6}
											separator={<span></span>}
											inputStyle={classes.otpStyle}
											containerStyle={{ alignSelf: 'center' }}
											shouldAutoFocus={true}
											isInputNum={true}
											hasErrored={!!errors.otp}
											errorStyle={classes.otpErrorStyle}
										/>
									) : (
										<MuiTelInput
											defaultCountry={'IN'}
											preferredCountries={['IN', 'US']}
											value={values.phone}
											ref={this.phoneRef}
											focusOnSelectCountry
											onChange={(value, info) => {
												setFieldValue('phone', value);
												this.setState({ iso2: info.countryCallingCode ?? '' });
											}}
										/>
									)}
									<Button
										sx={classes.signinbtn}
										variant='contained'
										color={forDelete ? 'inherit' : 'primary'}
										onClick={submitForm}
										disabled={
											// eslint-disable-next-line eqeqeq
											isSubmitting || (this.state.mode === 'otp' ? values.otp.length != 6 : false)
										}
									>
										{this.state.mode === 'otp'
											? forDelete
												? 'Confirm Delete'
												: 'Verify OTP'
											: 'Send OTP'}
									</Button>
									{this.state.mode === 'otp' ? (
										<Button
											sx={classes.skipSigninButton}
											variant='contained'
											color='inherit'
											onClick={async () => {
												await this.phoneAuth(
													values,
													setSubmitting,
													setFieldValue,
													setErrors,
													true
												);
												setFieldValue('otp', '');
											}}
											disabled={
												isSubmitting || this.state.otpTimer > 0 || this.state.retriesLeft <= 0
											}
										>
											{this.state.retriesLeft <= 1 && isSupportedCountry(this.state.iso2)
												? 'Voice Call'
												: 'Resend OTP'}
											{this.state.otpTimer > 0 ? ` (${this.state.otpTimer})` : ''}
										</Button>
									) : (
										<Button
											sx={classes.skipSigninButton}
											variant='contained'
											color='inherit'
											onClick={async () => {
												localStorage.setItem('SkippedSignIn', new Date().getTime().toString());
												this.context?.popScreen(true);
											}}
										>
											Skip Sign In (for 24 hours)
										</Button>
									)}
								</>
							)}
							<span id={this.submitBtnId} style={{ display: 'none' }}></span>
						</Box>
					)}
				</Formik>
			</div>
		);
	}
}

PhoneSignInComponent.contextType = DialogContext;

export const NewPhoneSignInControl = connector(PhoneSignInComponent);
