import { FBData } from './fbtypes';

export interface Address extends FBData {
	type?: string;
	fullname: string;
	address: string;
	phone: string;
	pincode: string;
	city: string;
	state: string;
	country: string;
	landmark?: string;
	email: string;
}

export enum OrderItemType {
	Publication = 'Publication',
}

export const collectionMap = {
	[OrderItemType.Publication]: 'publications',
};

export enum OrderItemShippingStatusValue {
	Queued = 'Queued',
	Confirmed = 'Confirmed',
	Shipped = 'Shipped',
	Delivered = 'Delivered',
	Cancelled = 'Cancelled',
	Failed = 'Failed',
	Returned = 'Returned',
}

export interface OrderItemShippingStatus {
	status: OrderItemShippingStatusValue;
	data: { [key: string]: string };
}

export interface OrderItemShippingStatusDetails {
	[itemId: string]: OrderItemShippingStatus;
}

export enum OrderType {
	Subscription = 'Subscription',
	OneTime = 'OneTime',
}

export interface OrderShippingDetails {
	shippingAddress: Address;
	items: OrderItemShippingStatusDetails;
}

export enum OrderStatusValue {
	Initiated = 'Initiated',
	Confirmed = 'Confirmed',
	Failed = 'Failed',
	Waiting = 'Waiting',
	Cancelled = 'Cancelled',
}

export interface OrderStatus {
	status: OrderStatusValue;
	data?: { [key: string]: string };
}

export enum OrderEventType {
	OrderCreated = 'OrderCreated',
	PaymentSuccess = 'PaymentSuccess',
	PaymentFailed = 'PaymentFailed',
	UpdateAddress = 'UpdateAddress',
	OrderCancelled = 'OrderCancelled',
	SubscriptionCancelled = 'SubscriptionCancelled',
	Other = 'Other',
}

export interface OrderEvent {
	type: OrderEventType;
	title: string;
	details: string;
	data?: { [key: string]: string };
	createdAt: number;
}

export enum SubscriptionStatus {
	Active = 'Active',
	Paused = 'Paused',
	Cancelled = 'Cancelled',
	Declined = 'Declined',
}

export interface OrderItem {
	itemType: OrderItemType;
	itemId: string;
	quantity: number;
	price?: number;
	shippingPrice?: number;
	shippingStatus?: OrderItemShippingStatus;
}

export interface OrderItems {
	[itemId: string]: OrderItem;
}

export interface Subscription {
	period: number;
	startDate?: number;
	status?: SubscriptionStatus;
}

export enum PaymentStatus {
	NotStarted = 'NotStarted',
	Started = 'Started',
	Failed = 'Failed',
	Completed = 'Completed',
}

export enum PaymentMethod {
	CC = 'CC',
	DC = 'DC',
	NB = 'NB',
	UPI = 'UPI',
	COD = 'COD',
	Other = 'Other',
	None = 'None',
	Unknown = 'Unknown',
}

interface OrderPaymentDetails {
	method: PaymentMethod;
	status: PaymentStatus;
	amount: number;
	data?: { [key: string]: string };
	billingAddress: Address;
}

export interface Order extends FBData {
	userId: string;
	type: OrderType;
	items: OrderItems;
	events: OrderEvent[];
	shippingAddress: Address;
	paymentDetails: OrderPaymentDetails;
	status: OrderStatus;
	subscription?: Subscription;
	purchaseTime?: number;
}

export interface InitiateOrderData {
	items: OrderItems;
	type: OrderType;
	subscription?: Subscription;
	shippingDetails: { addrId: string };
}

export const OrderStatusMap = {
	[OrderStatusValue.Initiated]: 'Initiated',
	[OrderStatusValue.Waiting]: 'Waiting for Payment',
	[OrderStatusValue.Failed]: 'Payment Failed',
	[OrderStatusValue.Confirmed]: 'Active',
	[OrderStatusValue.Cancelled]: 'Cancelled',
};

export const LangMap = {
	hi: 'Hindi',
	en: 'English',
	si: 'Sindhi',
	pu: 'Punjabi',
	hien: 'Hindi & English',
};
