import { Action } from 'redux';

export const UPDATE_PLAYED_MEDIA = '@@played/UPDATE_PLAYED_MEDIA';
export type T_UPDATE_PLAYED_MEDIA = typeof UPDATE_PLAYED_MEDIA;

export interface UpdatePlayedMedia extends Action {
	type: T_UPDATE_PLAYED_MEDIA;
	articleId: string;
	time: number;
	force: boolean;
}

export const updatePlayedMedia = (articleId: string, time: number, force: boolean): UpdatePlayedMedia => {
	return {
		type: UPDATE_PLAYED_MEDIA,
		articleId,
		time,
		force,
	};
};

export const UPDATE_ALL_PLAYED_MEDIA = '@@played/UPDATE_ALL_PLAYED_MEDIA';
export type T_UPDATE_ALL_PLAYED_MEDIA = typeof UPDATE_ALL_PLAYED_MEDIA;

export interface UpdateAllPlayedMedia extends Action {
	type: T_UPDATE_ALL_PLAYED_MEDIA;
	playedMedia: any;
}

export const updateAllPlayedMedia = (playedMedia: any): UpdateAllPlayedMedia => {
	return {
		type: UPDATE_ALL_PLAYED_MEDIA,
		playedMedia,
	};
};
