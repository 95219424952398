// import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// import { Action } from 'redux';
// import { ThunkAction, ThunkDispatch } from 'redux-thunk';
// import { UpdateUser } from 'store/data/user/actions';
// import { StartLoading, StopLoading } from 'store/temp/actions';
// import { ApplicationState } from 'types';

// const instance = axios.create({
// 	baseURL: process.env.REACT_APP_API_HOST,
// 	withCredentials: true,
// 	headers: {
// 		'Content-Type': 'application/json', //'application/vnd.api+json'
// 	},
// });

export enum CacheStrategy {
	NetworkOnly, // For data which can't be stored in cache like sensitive data, app shouldn't stop loading for such data
	NetworkFallingBackToCache, // For offline
	CacheFallingBackToNetwork, // For data which can be stale for long, we can decide expiry
	CacheThenNetwork, // For faster loads, but loads and then updates latest data
}

export interface ApiMetaData {
	cacheStrategy: CacheStrategy;
}

// export interface API {
// 	endpoint: string;
// 	method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
// 	data?: any;
// 	meta?: ApiMetaData;
// }

// const TRIGGER_API = '@@services/TRIGGER_API';
// type TRIGGER_API = typeof TRIGGER_API;

// export interface TriggerApi extends Action {
// 	type: TRIGGER_API;
// 	api: API;
// }

// enum ErrorStatusCode {
// 	JWT_INVALID = -3,
// 	JWT_EXPIRED,
// 	CSRF_MISMATCH,
// 	USER_ALREADY_AUTHENTICATED,
// 	INPUT_VALIDATION,
// 	INVALID_CREDENTIALS,
// 	INTERNAL_ERROR,
// 	USER_UNAUTHENTICATED,
// 	USER_UNAUTHORIZED,
// 	OTHER,
// }

// export const triggerApi = (
// 	api: API
// ): ThunkAction<Promise<AxiosResponse>, ApplicationState, unknown, StartLoading | StopLoading | UpdateUser> => {
// 	return async (
// 		dispatch: ThunkDispatch<ApplicationState, unknown, StartLoading | StopLoading | UpdateUser>,
// 		getState: () => ApplicationState
// 	) => {
// 		//TODO: Handle all 4 caching strategies
// 		//TODO: Handle delay param in loading to avoind showing indicator if request completes quickly

// 		// dispatch(startLoading());

// 		let config: AxiosRequestConfig = {
// 			url: api.endpoint,
// 			method: api.method || 'POST',
// 			data: api.data,
// 		};

// 		// const state = getState();
// 		// const user = state.dataState.user;

// 		// if (user && user.token) {
// 		// 	config.headers = {
// 		// 		Authorization: 'Bearer ' + user.token,
// 		// 	};
// 		// }

// 		let response = await instance.request(config);

// 		// if (response?.data?.result?.token) {
// 		// 	dispatch(updateUser({ ...user, token: response.data.result.token }));
// 		// }

// 		if (response?.data?.errors) {
// 			let error = response?.data?.errors[0];

// 			if (error.statusCode === ErrorStatusCode.JWT_EXPIRED) {
// 				// let token = error.message;
// 				// dispatch(updateUser({ ...user, token: token }));
// 				// return await dispatch(triggerApi(api));
// 			}
// 		}
// 		// dispatch(stopLoading());

// 		return response;
// 	};
// };
