import { FireStoreModule } from 'fb';
import { collection, Firestore } from 'firebase/firestore';
import { updateNotifications } from 'store/data/notifications/actions';

export class NotificationsFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'notifications';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'notifications');
	public getChangeProcessAction = (changes) => updateNotifications(changes);
}
