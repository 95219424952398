import { FeatureControl } from 'components/features/FeatureControl';
import { withRouter } from 'components/shell/WithRouter';
import React from 'react';
import { useParams } from 'react-router-dom';
import HomePage from './HomePage';

function FeaturePage(props) {
	let { isModal } = props;
	let { id } = useParams();

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<FeatureControl id={id} isModal={!!isModal} />
		</>
	);
}

export default withRouter(FeaturePage);
