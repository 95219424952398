import { AccountMode, AccountScreenManager } from 'components/profile/AccountScreenManager';
import { UserFeedback } from 'components/profile/UserFeedback';
import { IsOwnerSAPD } from 'config';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

function FeedbackPage(props) {
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			{IsOwnerSAPD ? (
				<DialogPage
					title={props.user ? 'Provide Feedback' : 'Sign In to Submit Feedback'}
					maxWidth='xs'
					dark
					goBackOnClose={!!isModal}
					goBackTo='/'
				>
					{props.user ? (
						<UserFeedback />
					) : (
						<AccountScreenManager rootOnInit={true} initScreen={AccountMode.PhoneSignIn} />
					)}
				</DialogPage>
			) : null}
		</>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
	};
}

export default connect(mapStateToProps)(FeedbackPage);
