import { Box, Card } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { DialogContext } from 'components/pages/DialogPage';
import { User } from 'firebase/auth';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TextField, ToggleButtonGroup } from 'formik-mui';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import React from 'react';
import { connect } from 'react-redux';
import { updateAddress } from 'services/api/addresses';
import { ApplicationState, UserData } from 'types';
import { Address } from 'types/orders';
import * as Yup from 'yup';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(8),
	}),
	label: (theme: Theme) => ({
		color: theme.palette.grey[500],
		marginBottom: theme.spacing(-1) + 'px !important',
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: (theme: Theme) => ({
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	}),
};

export interface SetAddressProps {
	addrId?: string;
	address?: Address;
	userData?: UserData;
	user?: User;
	isOrder?: boolean;
	updateAddress: (address: Address, isOrder?: boolean) => Promise<void>;
}

class SetAddress extends React.Component<SetAddressProps, any> {
	context!: React.ContextType<typeof DialogContext>;

	render() {
		let { user, userData, updateAddress, address, isOrder, addrId } = this.props;

		return (
			<Box sx={classes.root}>
				<Card sx={classes.card}>
					<Formik
						initialValues={{
							fullname: address?.fullname ?? (userData?.fullName || ''),
							country: address?.country || 'India',
							address: address?.address || '',
							pincode: address?.pincode || '',
							city: address?.city || '',
							state: address?.state || '',
							type: address?.type || 'home',
							landmark: address?.landmark || '',
							phone:
								address?.phone || (user?.phoneNumber?.startsWith('+91') ? user?.phoneNumber : '') || '',
							email: address?.email || userData?.email || '',
						}}
						validationSchema={() => {
							return Yup.object({
								fullname: Yup.string().required('Full Name is required'),
								landmark: Yup.string(),
								pincode: Yup.string().required('Pincode is required'),
								city: Yup.string().required('City is required'),
								state: Yup.string().required('State is required'),
								address: Yup.string().required('Address is required'),
								phone: Yup.string().length(13, 'Must be 10 numbers').required('Phone is required'),
								email: Yup.string().email('Must be a valid email').required('Email is required'),
							});
						}}
						onSubmit={async (values, { setSubmitting }) => {
							await updateAddress(
								{
									...values,
									id: addrId,
								} as Address,
								isOrder
							);

							setSubmitting(false);
							this.context.popScreen();
						}}
					>
						{({ submitForm, isSubmitting, values, setFieldValue }) => (
							<Box component={Form} sx={classes.form} translate='yes'>
								<Field
									component={TextField}
									autoComplete='off'
									name='fullname'
									type='text'
									label='Full Name'
								/>
								<Field
									component={TextField}
									autoComplete='off'
									name='address'
									rows={3}
									multiline
									variant='outlined'
									InputProps={{ notched: true }}
									label='Complete Address'
									placeholder='House No, Building, Street Name, Area'
								/>
								<Field
									component={TextField}
									autoComplete='off'
									name='landmark'
									type='text'
									label='Landmark'
								/>

								<Field component={TextField} autoComplete='off' name='city' type='text' label='City' />
								<Field
									component={TextField}
									autoComplete='off'
									name='state'
									type='text'
									label='State'
								/>
								<Field
									component={TextField}
									autoComplete='off'
									name='pincode'
									type='text'
									label='Pin Code'
								/>
								<Field
									component={TextField}
									autoComplete='off'
									name='country'
									type='text'
									label='Country'
									disabled={true}
								/>
								<MuiTelInput
									defaultCountry={'IN'}
									disableDropdown
									// autoFormat={false}
									value={values.phone}
									onlyCountries={['IN']}
									// countryCodeEditable={false}
									onChange={(value, info: MuiTelInputInfo) => {
										let phone = value
											.replace(/ /g, '')
											.replace(/\(/, '')
											.replace(/\)/, '')
											.replace(/-/, '');

										setFieldValue('phone', phone);
									}}
								/>
								<ErrorMessage name='phone'>
									{(msg) => (
										<p style={{ color: 'crimson' }} className='MuiFormHelperText-root Mui-error'>
											{msg}
										</p>
									)}
								</ErrorMessage>
								<Field
									component={TextField}
									autoComplete='off'
									name='email'
									type='email'
									label='Email'
								/>
								<Typography sx={classes.label}>Address Type</Typography>
								<Field
									component={ToggleButtonGroup}
									type='checkbox'
									style={{ width: '100%' }}
									name='type'
									exclusive
								>
									<ToggleButton color='primary' style={{ width: '50%' }} value='home'>
										Home
									</ToggleButton>
									<ToggleButton color='primary' style={{ width: '50%' }} value='work'>
										Work
									</ToggleButton>
									<ToggleButton color='primary' style={{ width: '50%' }} value='other'>
										Other
									</ToggleButton>
								</Field>
								<Button
									sx={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}
								>
									Save Changes
								</Button>
							</Box>
						)}
					</Formik>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps(
	{ userState, dataState }: ApplicationState,
	{ addrId, isOrder }: { addrId?: string; isOrder?: boolean }
) {
	let { userStore } = userState;

	let address;
	if (isOrder && addrId) {
		let order = dataState.orders.byId[addrId];
		address = order.shippingAddress;
	} else {
		address = userStore.subColl?.addresses && addrId ? userStore.subColl?.addresses[addrId] : undefined;
	}
	return {
		user: userStore.user,
		userData: userStore.userData,
		address,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateAddress: async (address: Address, isOrder?: boolean) => {
			await dispatch(updateAddress(address, isOrder));
		},
	};
}

SetAddress.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(SetAddress);
