import { AppBar, Box, Button, IconButton, MobileStepper, Toolbar, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import App from 'AppInterface/App';
import DialogPage, { DialogContext } from 'components/pages/DialogPage';
import TextContent from 'components/TextContent';
import { MainRhyme4 } from 'config';
import { Icons } from 'config/icons';
import domtoimage from 'dom-to-image';
import { getWebAppAssetPath, isAdmin } from 'helpers';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getQuotesByDate } from 'store/data/quotes/selectors';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, Quote } from 'types';

const classes = {
	quote: {
		textAlign: 'center' as 'center',
		textTransform: 'none' as 'none',
		fontWeight: 'normal' as 'normal',
		lineHeight: 1.7,
		padding: '1px',
	},
	borderImg: {
		height: '3.5rem',
	},
	quoteDiv: (theme: Theme) => ({
		padding: theme.spacing(2),
		backgroundColor: 'white',
		textAlign: 'center',
	}),
	pickerbar: {
		width: '100%',
		marginRight: '-12px',
		textAlign: 'center' as 'center',
	},
	picker: {
		transform: 'translateY(6px)',
		minWidth: 200,

		'& .MuiInput-underline::after': {
			transform: 'scaleX(0) !important',
			borderWidth: 1,
		},
		'& input': {
			textAlign: 'center' as 'center',
			fontSize: '0.9em',
		},
		'& .MuiIconButton-root': {
			visibility: 'visible',
		},
	},
	plainAppBar: (theme: Theme) => ({
		borderTop: `8px solid ${theme.palette.primary.main}`,
		backgroundColor: 'white',
		// boxShadow: 'none',
		color: 'inherit',

		'& .MuiIconButton-root': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	}),
	stepper: (theme: Theme) => ({
		fontSize: theme.typography.body2.fontSize,
		maxWidth: 400,
		flexGrow: 1,
		background: 'white',
		margin: 'auto',
	}),
};

let minQuoteDate = moment('2017-05-26');

interface QuoteProps {
	quotes: { [date: string]: Quote };
	quote?: Quote;
	locale: string;
	toggleDrawer: (content?: any, open?: boolean, styles?: string) => void;
	isModal: boolean;
	showAll: boolean;
	appBuildCode: number;
	appEnv: string;
}

interface QuoteContentProps {
	locale: string;
	hideTopFloral?: boolean;
	text: string;
}

class Content extends React.Component<QuoteContentProps> {
	render() {
		let { locale, text, hideTopFloral } = this.props;

		return (
			<Box sx={classes.quoteDiv}>
				{!hideTopFloral && <Box component="img" sx={classes.borderImg} src={getWebAppAssetPath('bg/dark/heading-bg-top.png')} />}
				<TextContent locale={locale} alignRhymes={true} useFontSize={true}>
					<Box sx={classes.quote} dangerouslySetInnerHTML={{ __html: text }}></Box>
				</TextContent>
				<Box component="img" sx={classes.borderImg} src={getWebAppAssetPath('bg/dark/heading-bg-bottom.png')} />
			</Box>
		);
	}
}

export const QuoteContent = Content;

class Component extends React.Component<QuoteProps, { date: Moment; forShare: boolean }> {
	private quoteRef = React.createRef<any>();

	constructor(props) {
		super(props);

		this.state = {
			date: moment(),
			forShare: false,
		};
	}

	componentDidMount = () => {};

	render() {
		let { quotes, locale, isModal, showAll, appBuildCode } = this.props;
		let quote = this.props.quote ?? quotes[this.state.date.format('YYYY-MM-DD')];

		let text;
		if (quote) {
			text = quote.text[locale] || quote.text.hi || quote.text.en;
		} else {
			text = MainRhyme4;
		}

		let quoteContent = <QuoteContent locale={locale} text={text} hideTopFloral={quote?.hideTopFloral ?? false} />;

		let maxDate = new Date();
		if (showAll) {
			maxDate = new Date(Object.keys(quotes).sort((a, b) => (a < b ? 1 : -1))[0]);
		}
		maxDate = maxDate >= new Date() ? maxDate : new Date();

		let picker = (
			<Box sx={classes.pickerbar}>
				<DatePicker
					key={this.state.date.format('YYYY-MM-DD')}
					sx={classes.picker}
					minDate={minQuoteDate}
					maxDate={moment(maxDate)}
					value={this.state.date}
					openTo="year"
					format="ddd, Do MMM, YYYY"
					views={['year', 'month', 'day']}
					// disableOpenPicker={true}
					slotProps={{ textField: { variant: 'standard' } }}
					shouldDisableDate={(date: Moment) => {
						return !quotes[date.format('YYYY-MM-DD')];
					}}
					onAccept={(date: Moment | null) => {
						if (date) {
							this.setState({
								date: date,
							});
						}
					}}
				/>

				<IconButton
					style={{
						float: 'right',
						visibility: appBuildCode > 45 ? 'visible' : 'hidden',
					}}
					onClick={async () => {
						await new Promise<void>((resolve) => {
							this.setState({ forShare: true }, () => {
								resolve();
							});
						});

						let quoteEl = this.quoteRef.current as HTMLElement;
						if (quoteEl) {
							// let dataUrl = await toJpeg(quoteEl, { quality: 0.4 });
							// await timeout(1000);
							// dataUrl = await toJpeg(quoteEl, { quality: 0.4 });

							let dataUrl = await domtoimage.toJpeg(quoteEl, { quality: 0.6 });

							App.shareImageDataURI(
								'Shri Anandpur Satsang, Quote - ' + this.state.date.format('dddd, Do MMM, YYYY'),
								dataUrl,
								'https://www.shrianandpur.org'
							);
						}

						await new Promise<void>((resolve) => {
							this.setState({ forShare: false }, () => {
								resolve();
							});
						});
					}}
					size="large"
				>
					{Icons.Share}
				</IconButton>
			</Box>
		);

		let currentIndex = this.state.date.diff(minQuoteDate, 'days');
		let totalDates = moment(maxDate).diff(minQuoteDate, 'days') + 1;
		return (
			<DialogPage
				appBarSx={classes.plainAppBar}
				extraAppBarElement={picker}
				showAppBar={!this.state.forShare}
				title=""
				transition={true}
				drawerType={true}
				goBackOnClose={isModal}
				goBackTo="/"
				style={{ paddingBottom: 0 }}
				disableBackdropClick={true}
				actionButtons={
					!this.state.forShare && (
						<MobileStepper
							variant="text"
							steps={totalDates}
							position="static"
							activeStep={currentIndex}
							sx={classes.stepper}
							nextButton={
								<Button
									size="small"
									onClick={async () => {
										this.setState({ date: this.state.date.add(1, 'day') });
									}}
									disabled={currentIndex >= totalDates - 1}
									color="inherit"
								>
									Next
									{Icons.KeyRightArrow}
								</Button>
							}
							backButton={
								<Button
									size="small"
									onClick={async () => {
										this.setState({ date: this.state.date.subtract(1, 'day') });
									}}
									disabled={currentIndex <= 0}
									color="inherit"
								>
									{Icons.KeyLeftArrow}
									Back
								</Button>
							}
						/>
					)
				}
			>
				<div ref={this.quoteRef}>
					{this.state.forShare && (
						<AppBar elevation={4} sx={classes.plainAppBar} style={{ position: 'relative' }}>
							<Toolbar style={{ alignSelf: 'center' }}>
								<Typography variant="h6" noWrap>
									Shri Anandpur Satsang
								</Typography>
							</Toolbar>
						</AppBar>
					)}
					{quoteContent}
					{this.state.forShare && (
						<AppBar
							elevation={4}
							sx={classes.plainAppBar}
							style={{
								position: 'relative',
								transform: 'scaleY(-1)',
							}}
						>
							<Toolbar style={{ alignSelf: 'center', transform: 'scaleY(-1)' }}>
								<Typography variant="h6" noWrap>
									{this.state.date.format('ddd, Do MMM, YYYY')}
								</Typography>
							</Toolbar>
						</AppBar>
					)}
				</div>
			</DialogPage>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { id?: string; isModal: boolean }) {
	let quotes = getQuotesByDate(state);
	let showAll =
		isAdmin(state.userState.userStore.user, state.userState.userStore.userData) &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	let quote = props.id ? state.dataState.quotes.byId[props.id] : undefined;

	return {
		quotes,
		quote,
		locale: state.uxState.locale,
		showAll,
		appBuildCode: state.uxState.appParams.appBuildCode,
		appEnv: state.uxState.appParams.appEnv,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any, open?: boolean, styles?: string) => {
			dispatch(toggleBottomDrawer(content, open, styles));
		},
	};
}

Component.contextType = DialogContext;

export const QuoteControl = connect(mapStateToProps, mapDispatchToProps)(Component);
