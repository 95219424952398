import Box from '@mui/material/Box';
import * as React from 'react';

export function Bull() {
	return (
		<Box component='span' sx={{ display: 'inline-block', mx: '4px', transform: 'scale(1)' }}>
			•
		</Box>
	);
}
