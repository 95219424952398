import { ReduxRouterState } from '@lagunovsky/redux-react-router';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { FBData } from './fbtypes';
import { Address, Order, OrderItemType } from './orders';

export * from './fbtypes';

export interface Article extends FBData {
	title: {
		[lang: string]: string;
	};
	context?: {
		[lang: string]: string;
	};
	articleType: string;
	mediaType: string;
	mediaUri?: string;
	mediaUriR2?: string;
	mediaLength: number;
	text?: {
		[lang: string]: string;
	};
	author: string;
	place?: string;
	group: string;
	lang: string;
	allowExport?: boolean;
	__playbackState?: 'Not Started' | 'Partial' | 'Completed'; // only for ux
	__component?: any; // only for ux
}

export interface ArticlesById {
	[articleId: string]: Article;
}

export interface ArticlesByType {
	[articleType: string]: Article[];
}

export interface ArticleStore {
	byId: ArticlesById;
}

export interface Media {
	audioId: number;
	src: string;
	filename: string;
	title: string;
	isFav: boolean;
	href: string;
}

export interface Rhyme extends FBData {
	title: string;
	rhyme: string;
	rhymeType: string;
	author: string;
	book: string;
	chapter: string;
	rhymeNo: string;
	pageNo: string;
	text: {
		[lang: string]: string;
	};
	context: {
		[lang: string]: string;
	};
}

export interface RhymesById {
	[id: string]: Rhyme;
}

export interface RhymeStore {
	byId: RhymesById;
}

export interface OrdersById {
	[id: string]: Order;
}

export interface OrderStore {
	byId: OrdersById;
}

export interface PinnedRecord {
	type: string;
	pintime: number;
	extras?: any;
}

export interface UserData extends FBData {
	fullName: string;
	email: string;
	phone: string;
	dob: string;
	gender: string;
	rahbar: string;
	locale: string;
	photoUrl: string;
	roles: string[];
	verified: boolean;
	refUserIds?: string[];
	settings: { [key: string]: any };
	pinnedRecords?: { [id: string]: PinnedRecord };
	playlistUpdatedAt?: Timestamp;
	sandeshUpdatedAt?: Timestamp;
}

export interface UserSubScoll {
	playlists?: UserPlaylists;
	addresses?: UserAddresses;
}

export interface UserStore {
	user?: User;
	userData?: UserData;
	subColl?: UserSubScoll;
}

export interface UserPlaylistTrack {
	articleId: string;
	order: number;
}

export interface UserPlaylistTracks {
	[articleId: string]: UserPlaylistTrack;
}

export interface UserPlaylist extends FBData {
	userId: string;
	title: string;
	tracks: UserPlaylistTracks;
}

export interface UserPlaylists {
	[id: string]: UserPlaylist;
}

export interface UserAddresses {
	[Id: string]: Address;
}

export enum SandeshType {
	Text = 'Text',
	Audio = 'Audio',
	Video = 'Video',
	Image = 'Image',
	Url = 'Url',
	SSN = 'SSN',
}

export interface Sandesh extends FBData {
	receiverIds: string[];
	allReceiverIds?: string[];
	visibilityOverride?: boolean;
	title: {
		[lang: string]: string;
	};
	message: {
		[lang: string]: string;
	};
	card?: {
		name: string;
		people_count: number;
		permission_date: string;
		phone: string;
		reference: string;
		card_no: number;
	};
	cardRequest?: {
		name: string;
		people_count: number;
		permission_date: string;
		phone: string;
		reference: string;
		approval?: { status?: string; message?: string };
		card_no?: number;
	};
	type: SandeshType;
	from: string;
	value?: boolean; // for special config docs
	_shared?: boolean; // only for UX
}

export interface SandeshById {
	[id: string]: Sandesh;
}

export interface SandeshStore {
	byId: SandeshById;
}

// export interface UserPlaylistTrack {
// 	href: string;
// 	order: number;
// }

// export interface UserPlaylist {
// 	id: string;
// 	user_id: string;
// 	title: string;
// 	tracks: UserPlaylistTrack[];
// 	updated_at: string;
// }

// export interface UserPlaylists {
// 	[id: string]: UserPlaylist;
// }

export interface Publication extends FBData {
	title: string;
	desc: string;
	type: string;
	owner: string;
	lang: string;
	frequency: number;
	order: number;
	sellHardcopy?: boolean;
	price?: number;
	allowedPeriodValues?: number[];
}

export interface Edition extends FBData {
	publicationId: string;
	coverpageUri: string;
	fileUri: string;
}

export interface PublicationsById {
	[id: string]: Publication;
}

export interface PublicationStore {
	byId: PublicationsById;
}

export interface EditionsById {
	[id: string]: Edition;
}

export interface EditionStore {
	byId: EditionsById;
}

export interface BookletPage {
	pageCollectionType: string;
	pageRefId: string;
	order: 0;
	indexTitle: string;
	pages: BookletPage[];
	startTime: Timestamp;
	endTime: Timestamp;
	flatIndex: number; // UX property, Not in DB
}

export interface Booklet extends FBData {
	type: string;
	title: string;
	pages: BookletPage[];
}

export interface BookletsById {
	[id: string]: Booklet;
}

export interface BookletStore {
	byId: BookletsById;
}

export interface Config extends FBData {
	value: any;
}

export interface ConfigsById {
	[id: string]: Config;
}

export interface ConfigStore {
	byId: ConfigsById;
}

export interface Notification extends FBData {
	type: string; // 'Info', 'ShriAmarVaani' // 'DarshanDates' // 'Felicitations' // 'SnackMessage' // 'AlertMessage' // 'FeatureAnnouncement' // 'HowToGuide'
	priority: number;
	order: number;
	content: {
		collectionType: string; // 'articles',
		refId: string; //'',
	};
	title: {
		[lang: string]: string;
	};
	text: {
		[lang: string]: string;
	};
	showFrequency: string; //'daily', // 'once' // 'startup' // 'home' // 48 (in hours)
	showUpfront: boolean;
	requireAck: boolean;
}

export interface NotificationsById {
	[id: string]: Notification;
}
export interface NotificationStore {
	byId: NotificationsById;
}

export interface Quote extends FBData {
	type: string; // 'General', 'Special'
	text: {
		[lang: string]: string;
	};
	hideTopFloral?: boolean;
}

export interface QuotesById {
	[id: string]: Quote;
}
export interface QuoteStore {
	byId: QuotesById;
}

export interface Feature extends FBData {
	type: string; // 'General'
	autoShow: boolean;
	title: {
		[lang: string]: string;
	};
	text: {
		[lang: string]: string;
	};
}

export interface FeaturesById {
	[id: string]: Feature;
}
export interface FeatureStore {
	byId: FeaturesById;
}

export interface HD extends FBData {
	text: {
		[lang: string]: string;
	};
}

export interface HDById {
	[id: string]: HD;
}
export interface HDStore {
	byId: HDById;
}

export enum DownloadStatus {
	Queued,
	InProgress,
	Complete,
}

export interface DownloadProgress {
	status: DownloadStatus;
	value: number;
	bytes: number;
	updatedAt: Date;
}

export interface DownloadState {
	progress?: DownloadProgress;
	exportProgress?: DownloadProgress;
	uri: string;
	forExport: boolean;
}

export interface DownloadStore {
	[id: string]: DownloadState;
}

export interface OfflineState {
	audio: DownloadStore;
	playedMedia: PlayedMedia;
}

export interface PlayedMedia {
	[articleId: string]: {
		time: number;
		timestamp: number;
	};
}

export interface AppDataState {
	rhymes: RhymeStore;
	articles: ArticleStore;
	publications: PublicationStore;
	editions: EditionStore;
	booklets: BookletStore;
	configs: ConfigStore;
	notifications: NotificationStore;
	quotes: QuoteStore;
	features: FeatureStore;
	hd: HDStore;
	orders: OrderStore;
	cart: Cart;
	sandesh: SandeshStore;
}

export interface AppFilteredDataState {
	articles: Article[];
	quotes: Quote[];
	rhymes: Rhyme[];
	publications: Publication[];
	editions: Edition[];
	booklets: Booklet[];
	notifications: Notification[];
	features: Feature[];
	hd: HD[];
	orders: Order[];
	sandesh: Sandesh[];
}

export type LocaleType = 'en' | 'hi';
export interface AppParams {
	appId: string;
	appEnv: string;
	appVersion: number;
	appBuildCode: number;
	webappBuildVersion: number;
}

export interface AppUxState {
	locale: LocaleType;
	bookletCurrentIndex: {
		[id: string]: number;
	};
	pageMarker: {
		[id: string]: number;
	};
	activeNotifications: number;
	settings: { [key: string]: any };
	appParams: AppParams;
	sort: {
		[recordType: string]: string;
	};
	filters: {
		[recordType: string]: any;
	};
	acknowledge: {
		[id: string]: number;
	};
}

export type NetStatus = 'online' | 'offline';

export enum AudioCurrentState {
	Stopped,
	Initializing,
	Playing,
	Paused,
	Ended,
	Buffering,
	Error,
	Seeking,
}

export interface AudioPlaybackState {
	currentState: AudioCurrentState;
	duration?: number;
	currentTime: number;
	repeat: number;
	isMiniPlayerMode: boolean;
	playbackSpeed?: number;
}

export interface AudioPlaybackDetails {
	articleList: Article[];
	currentIndex: number;
}

export interface CartItem {
	itemId: string;
	itemType: OrderItemType;
	quantity: number;
}

export interface CartItems {
	[itemId: string]: CartItem;
}

export interface Cart {
	orderId?: string;
	items: CartItems;
}

export interface AppTempState {
	isLoading: number;
	isDrawerOpen: boolean;
	bottomDrawer: { open: boolean; content?: any; styles?: any };
	alertDialog: { open: boolean; content?: any };
	onDemandPage: { open: boolean; component?: any };
	appBarTitle: string;
	netStatus: NetStatus;
	audioPlaybackDetails: AudioPlaybackDetails | null;
	audioPlaybackState: AudioPlaybackState;
	notifications: any[];
	searchTerm: string;
	showAdminPreview: boolean;
	previewSrc?: string;
}

export interface Operation {
	key: string;
	value: any;
	createdAt: Date;
	updatedAt: Date;
}

export interface OpsStore {
	[key: string]: Operation;
}

export interface OpsState {
	byId: OpsStore;
}

export interface UserState {
	userStore: UserStore;
}

export interface ApplicationState {
	router: ReduxRouterState;
	dataState: AppDataState;
	userState: UserState;
	filteredDataState: AppFilteredDataState;
	uxState: AppUxState;
	tempState: AppTempState;
	opsState: OpsState;
	offlineState: OfflineState;
}

export interface RouteProps {
	icon?: React.ReactElement;
	primary: string;
	to: string;
	title?: string;
	page?: string;
	state?: any;
	exactTo?: string;
	onClick?: () => void;
}
export interface RouteGroup {
	title?: string;
	routes: RouteProps[];
}

export enum ArticleType {
	Bhajan,
	Satsang,
	ShriAarti,
	ShriAmarVani,
}

export enum ContentType {
	Article,
	Publication,
	Edition,
	WebApp,
	Calendar,
	PKP,
	SGM,
	Data,
	Files,
	User,
}
