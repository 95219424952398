import { Box, Card, CardActions, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { onEvent, toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';
import { Order } from 'types/orders';
import OrderItemTile from './OrderItemTile';
import SubscriptionItemTile from './SubscriptionItemTile';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	card: (theme) => ({
		margin: theme.spacing(1),

		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2) + 4,
		},
	}),
	contentRow: (theme) => ({
		flexDirection: 'row',
		display: 'flex',
		position: 'relative' as 'relative',
		// width: '100%',
		margin: theme.spacing(1),

		'& .MuiToggleButton-root': {
			lineHeight: 1,
		},
	}),
	label: {
		width: '50%',
		alignSelf: 'center',
		fontSize: '1.8rem',
	},
	value: {
		flexGrow: 1,
		textAlign: 'right' as 'right',
		fontWeight: 700,
		fontSize: '1.8rem',
	},
	actions: (theme) => ({
		backgroundColor: theme.palette.primary.main,
		justifyContent: 'center',
		color: 'white',
		borderColor: 'white',
		width: '100%',

		'& > a': {
			color: 'white',
			borderColor: 'white',
			padding: '8px 16px',
			border: '1px solid white',
			borderRadius: 4,
			fontSize: '1.6em',
			textTransform: 'uppercase',
		},
		'& > button': {
			color: 'white',
			borderColor: 'white',
		},
	}),
};

interface Props {
	order: Order;
	toggleDrawer: (content?: any) => void;
	isSubscription?: boolean;
}

class OrderTile extends React.Component<Props> {
	render() {
		let { order, isSubscription, ...smProps } = this.props;
		let orderItems = Object.values(order.items);
		let itemTiles = orderItems.map((item) => {
			return isSubscription ? (
				<SubscriptionItemTile
					key={order.id + item.itemId}
					orderId={order.id}
					orderItemId={item.itemId}
					withDetail={false}
					{...smProps}
				/>
			) : (
				<OrderItemTile
					key={order.id + item.itemId}
					orderId={order.id}
					orderItemId={item.itemId}
					withDetail={false}
					{...smProps}
				/>
			);
		});

		return isSubscription ? (
			itemTiles
		) : (
			<Box sx={classes.root}>
				<Card sx={classes.card}>
					{itemTiles}
					<CardActions sx={classes.actions}>
						<Box sx={classes.contentRow} style={{ width: '100%' }}>
							<Typography noWrap sx={classes.label}>
								Amount Paid
							</Typography>
							<Typography noWrap sx={classes.value} style={{ fontWeight: 400 }}>
								₹ {order.paymentDetails.amount.toFixed(2)}
							</Typography>
						</Box>
					</CardActions>
				</Card>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState, { orderId }) {
	let order = state.dataState.orders.byId[orderId];

	return {
		order,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTile);
