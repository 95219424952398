import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ArticleTypeMap } from 'config';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Article } from 'types';

const classes = {
	header: (theme) => ({
		padding: theme.spacing(0.5),

		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			// float: 'right'
		},

		'& .MuiCardHeader-content': {
			minWidth: 0,
			textAlign: 'center',

			'& p': {
				fontSize: '2.5rem',
			},
		},

		'& .MuiCardHeader-avatar': {
			marginRight: 0,

			'& button': {
				marginLeft: '-8px',
				marginTop: '-8px',
			},
		},
	}),
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	appBar: {
		position: 'relative' as 'relative',
	},
	title: (theme) => ({
		marginLeft: theme.spacing(1),
		flex: 1,
		fontSize: '1.85rem',
	}),
};

interface TitleProps {
	article?: Article;
	toggleFullScreen: (e: React.MouseEvent) => void;
	stopMusicPlayer: (e: React.MouseEvent) => void;
}

class FullScreenPlayerTitle extends React.Component<TitleProps, any> {
	render() {
		let { article, toggleFullScreen, stopMusicPlayer } = this.props;

		return (
			<AppBar sx={classes.appBar}>
				<Toolbar>
					<IconButton edge='start' color='inherit' onClick={stopMusicPlayer} aria-label='close' size='large'>
						{Icons.Close}
					</IconButton>
					{!article ? (
						<Skeleton
							animation='wave'
							height={12}
							width='80%'
							style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}
						/>
					) : (
						<Typography variant='h6' sx={classes.title}>
							{ArticleTypeMap[article!.articleType]}
						</Typography>
					)}
					<IconButton onClick={toggleFullScreen} color='inherit' size='large'>
						{Icons.ExpandMore}
					</IconButton>
				</Toolbar>
			</AppBar>
		);
	}
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { audioPlaybackDetails } = tempState;

	return {
		article: audioPlaybackDetails?.articleList[audioPlaybackDetails?.currentIndex],
	};
}

export default connect(mapStateToProps)(FullScreenPlayerTitle);
