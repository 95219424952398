import ArticleList from 'components/articles/ArticleList';
import PublicationList from 'components/publications/PublicationList';
import { ToggleSelector } from 'components/ToggleSelector';
import React, { useEffect } from 'react';
import { Article, Publication } from 'types';
import Page from './Page';

const PKP = (props) => {
	return (
		<>
			{props.type !== 'Text' ? (
				<ArticleList
					recordType="shriaarti"
					avoidVirtual
					avoidFilterBar={true}
					avoidFab={true}
					filter={(article: Article) => {
						return article.mediaType === props.type.toLowerCase();
					}}
				/>
			) : (
				<>
					<PublicationList
						filter={(pub: Publication) => {
							return pub.type === 'shriaarti';
						}}
					/>
					<ArticleList
						recordType="shriaarti"
						avoidVirtual
						avoidFilterBar={true}
						avoidFab={true}
						filter={(article: Article) => {
							return article.mediaType === 'text' && article.lang === 'hi';
						}}
					/>
				</>
			)}
		</>
	);
};

export default function PkpPage(props) {
	const [type, setType] = React.useState<string>('Audio');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title="Pooja Ke Phool" includeBottomNavigation topControlHeight={64} dark>
			<ToggleSelector values={['Audio', 'Video', 'Text']} type={type} setType={setType} />
			<PKP type={type} />
		</Page>
	);
}
