import { Box, Card, LinearProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { Icons } from 'config/icons';
import React from 'react';
import { connect } from 'react-redux';
import { deleteByGoogleOrAppleSignIn, deleteByPasswordLogin, deleteByPhoneSign } from 'services/api/auth';
import ReAuthenticate from './ReAuthenticate';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	}),
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(4),
	}),
};

export interface Props extends ScreenManagerProps {
	deleteByPasswordLogin: (currentPass: string) => Promise<void>;
	deleteByGoogleOrAppleSignIn: () => Promise<void>;
	deleteByPhoneSign: () => Promise<void>;
}

class DeleteAccount extends React.Component<Props, { isSubmitting: boolean }> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isSubmitting: false,
		};
	}
	render() {
		let { deleteByGoogleOrAppleSignIn, deleteByPasswordLogin, deleteByPhoneSign } = this.props;
		let { isSubmitting } = this.state;

		return (
			<Box sx={classes.root}>
				{isSubmitting ? <LinearProgress /> : null}
				<UserPicInfo />
				<Card sx={classes.card}>
					<Icons.WarningIcon style={{ fontSize: 64, alignSelf: 'center', color: 'red' }} />
					<Typography sx={classes.message} variant="body2" component="div">
						<h4>This action is irreversible! Deletion will:</h4>
						<ul style={{ paddingLeft: 24 }}>
							<li>Delete your account details from our server</li>
							<li>Delete your settings and profile photo</li>
							<li>Delete your playlists</li>
							<li>Unsubscribe you from email communications</li>
						</ul>
						<h4>Confirm deletion by again signing-in:</h4>
					</Typography>

					<ReAuthenticate
						onGoogleorAppleSignIn={deleteByGoogleOrAppleSignIn}
						onPasswordLogin={deleteByPasswordLogin}
						onPhoneSign={deleteByPhoneSign}
						confirmText="Confirm Delete"
						onSubmittingChange={(value) => {
							this.setState({ isSubmitting: value });
						}}
					/>
				</Card>
			</Box>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		deleteByPasswordLogin: async (currentPass: string) => {
			await dispatch(deleteByPasswordLogin({ currentPass }));
		},
		deleteByGoogleOrAppleSignIn: async () => {
			await dispatch(deleteByGoogleOrAppleSignIn());
		},
		deleteByPhoneSign: async () => {
			await dispatch(deleteByPhoneSign());
		},
	};
}

export default connect(undefined, mapDispatchToProps)(DeleteAccount);
