import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Notification } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_NOTIFICATIONS = '@@notifications/UPDATE_NOTIFICATIONS';
export type T_UPDATE_NOTIFICATIONS = typeof UPDATE_NOTIFICATIONS;

export interface UpdateNotifications extends Action {
	type: T_UPDATE_NOTIFICATIONS;
	changes: FBChanges;
	overwrite?: boolean;
}

export function updateNotifications(changes: FBChanges, overwrite?: boolean): UpdateNotifications {
	return {
		type: UPDATE_NOTIFICATIONS,
		changes,
		overwrite,
	};
}

export const UPDATE_NOTIFICATIONS_FILTERED = UPDATE_NOTIFICATIONS + '_FILTERED';
export type T_UPDATE_NOTIFICATIONS_FILTERED = typeof UPDATE_NOTIFICATIONS_FILTERED;

export interface UpdateNotificationsFiltered extends Action {
	type: T_UPDATE_NOTIFICATIONS_FILTERED;
	payload: Notification[];
}
