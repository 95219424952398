import ArticleList from 'components/articles/ArticleList';
import React from 'react';
import Page from './Page';

export default function ShriAmarVaniPage() {
	return (
		<Page title='Shri Amar Vani' includeBottomNavigation fullscreen dark>
			<ArticleList avoidFilterBar recordType='shriamarvani' />
		</Page>
	);
}
