import ArticleList from 'components/articles/ArticleList';
import React from 'react';
import Page from './Page';

export default function SatsangPage() {
	return (
		<Page title='Satsang' includeBottomNavigation fullscreen dark>
			<ArticleList recordType='satsang' />
		</Page>
	);
}
