import ArticleList from 'components/articles/ArticleList';
import { DBConfig, DefaultNityaNiyamSettings } from 'config';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getArticlesByType, getArticlesFiltered } from 'store/data/articles/selectors';
import { getDataSorted } from 'store/data/generic/selectors';
import { updateUserSettings } from 'store/ux/actions';
import { ApplicationState, Article } from 'types';

interface NityaNiyamProps {
	nityaNiyamSettings: { [key: string]: any };
	nityaNiyamConfig: { [key: string]: any };
	updateSettings: (settings: { [key: string]: any }) => void;
	// records: Article[];
	state: ApplicationState;
}

const Component = memo(
	function Component(props: NityaNiyamProps) {
		let { nityaNiyamSettings, updateSettings, nityaNiyamConfig, state } = props;
		let [records, setRecords] = useState<Article[]>([]);

		useEffect(() => {
			let isMorning = new Date().getHours() < 14;

			let settings = isMorning ? nityaNiyamSettings.morning : nityaNiyamSettings.evening;
			let routineList = isMorning ? morningList : eveningList;
			let articlesByType = getArticlesByType(state);

			let result: Article[] = [];

			for (let key of routineList) {
				let config = nityaNiyamConfig[key];
				let setting = settings[key] ?? {};

				if (!setting.enabled) {
					continue;
				}

				if (config.type === 'audio') {
					if (config.audioType === 'shriaarti') {
						result.push(state.dataState.articles.byId[config.articleId]);
					}

					if (config.audioType === 'satsang' || config.audioType === 'bhajan') {
						let recordType = 'articles-' + config.audioType;
						let records = articlesByType[config.audioType];

						let sortValue = 'Newest First';
						records = getDataSorted(records, sortValue, recordType + '-' + sortValue) as Article[];

						if (setting.notPlayed && setting.onlyHindi) {
							records = getArticlesFiltered(state, records, notPlayedHindiFilter, filtersMeta);
						} else if (setting.notPlayed) {
							records = getArticlesFiltered(state, records, notPlayedFilter, filtersMeta);
						} else if (setting.onlyHindi) {
							records = getArticlesFiltered(state, records, onlyHindiFilter, filtersMeta);
						}

						let count = setting.count ?? 0;
						if (count) {
							result = result.concat(records.slice(0, count));
						} else {
							result = result.concat(records);
						}
					}
				}
			}

			setRecords(result);
		}, [nityaNiyamSettings]);

		// let { morning = {}, evening = {} } = nityaNiyamSettings;

		// let nityaNiyamList: any[] = [];
		// let isMorning = new Date().getHours() < 14;

		// if (isMorning) {
		//     nityaNiyamList.push({ aaradhan: morning.aaradhan });
		//     nityaNiyamList.push({ mAarti: morning.aarti });
		//     nityaNiyamList.push({ mVinati: morning.vinati });
		//     nityaNiyamList.push({ mStotra: morning.stotra });
		//     nityaNiyamList.push({ chhanda: morning.chhanda });
		//     nityaNiyamList.push({ dhyan: morning.dhyan });
		//     nityaNiyamList.push({ satsang: morning.satsang });
		//     nityaNiyamList.push({ bhajan: morning.bhajan });
		// } else {
		//     nityaNiyamList.push({ eAarti: evening.aarti });
		//     nityaNiyamList.push({ eVinati: evening.vinati });
		//     nityaNiyamList.push({ eStotra: evening.stotra });
		//     nityaNiyamList.push({ stuti: evening.stuti });
		//     nityaNiyamList.push({ dhyan: evening.dhyan });
		//     nityaNiyamList.push({ satsang: evening.satsang });
		//     nityaNiyamList.push({ bhajan: evening.bhajan });
		// }

		// let records = nityaNiyamList.map((object) => {
		//     let key = Object.keys(object)[0];
		//     let value: any = Object.values(object)[0];

		//     if (!value.enabled) {
		//         return;
		//     }

		//     let config = nityaNiyamConfig[key];
		//     if (config.type === "audio") {
		//         if (config.audioType === "shriaarti") {
		//             return <ArticleTile record={config.record} />;
		//         }

		//         if (config.audioType === "satsang") {
		//         }
		//     }
		// });

		return <ArticleList records={records} />;
	},
	(prevProps, nextProps) => {
		return prevProps.nityaNiyamSettings === nextProps.nityaNiyamSettings;
	}
);

const filtersMeta = {
	lang: {
		label: 'Language',
		type: 'checkbox',
		renderType: 'togglebar',
		dependsOn: [],
	},
	playbackState: {
		label: 'Playback Status',
		type: 'custom',
		renderType: 'togglebar',
		value: (record) => {
			return record.__playbackState ?? 'Not Started';
		},
		dependsOn: ['lang'],
		optionLabel: (option) => option,
	},
};

const notPlayedFilter = {
	playbackState: {
		'Not Started': true,
		Partial: false,
		Completed: false,
	},
};
const onlyHindiFilter = {
	lang: {
		en: false,
		hi: true,
		si: false,
		pu: false,
	},
};
const notPlayedHindiFilter = {
	lang: {
		en: false,
		hi: true,
		si: false,
		pu: false,
	},
	playbackState: {
		'Not Started': true,
		Partial: false,
		Completed: false,
	},
};
const morningList = [
	'aaradhan',
	'sgm',
	'mAartiStotr',
	'mAarti',
	'mVinati',
	'mStotra',
	'chhanda',
	'dhyaan',
	'satsang',
	'bhajan',
];
const eveningList = ['sgm', 'eAartiStotr', 'eAarti', 'eVinati', 'eStotra', 'stuti', 'dhyaan', 'satsang', 'bhajan'];
const defaultConfig = {
	aaradhan: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'UDd3MOAAftvjTNaldx0b',
	},
	sgm: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'zttqoZ8uXivTFmv7WjR5',
	},
	mAartiStotr: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'JSAbPtmmAoMIZrMiEYKL',
	},
	mAarti: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'IKz6tolnrSyOd4R7jqHQ',
	},
	mVinati: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: '5wOOUhi61St4YqRCmmzO',
	},
	mStotra: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'GIVT8d4wMJ4eSruTHJNb',
	},
	chhanda: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'FbM4U8zgoYgygzyGr30M',
	},
	eAartiStotr: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'v8X3COp68cPdPWQs0DjI',
	},
	eAarti: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'Rvr2ahUDqB0DOq38Xmq7', // 'v8X3COp68cPdPWQs0DjI',
	},
	eVinati: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'jjdmEvb11Fdqtrd6zk2o',
	},
	eStotra: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'wKiwXTfhHNKFhZg4lbWu',
	},
	stuti: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'x3zcoKnVzuOCgdWGDhTH',
	},
	dhyaan: {
		type: 'audio',
		audioType: 'shriaarti',
		articleId: 'POwDvI5aY7qAPNjORfIG',
	},
	satsang: {
		type: 'audio',
		audioType: 'satsang',
	},
	bhajan: {
		type: 'audio',
		audioType: 'bhajan',
	},
};

function mapStateToProps(state: ApplicationState) {
	let nityaNiyamConfig = state.dataState.configs.byId[DBConfig.NityaNiyam]?.value ?? defaultConfig;
	let nityaNiyamSettings = state.uxState.settings.nityaNiyam ?? DefaultNityaNiyamSettings;
	// let articlesByType = getArticlesByType(state);

	// for (let key in nityaNiyamConfig) {
	//     let config = nityaNiyamConfig[key];

	//     if (config.type === "audio") {
	//         if (config.audioType === "shriaarti") {
	//             config.record = state.dataState.articles.byId[config.articleId];
	//         }
	//     }
	// }

	// let result: Article[] = [];

	// for (let key of routineList) {
	//     let config = nityaNiyamConfig[key];
	//     let setting = settings[key];

	//     if (!setting.enabled) {
	//         continue;
	//     }

	//     if (config.type === "audio") {
	//         if (config.audioType === "shriaarti") {
	//             result.push(state.dataState.articles.byId[config.articleId]);
	//         }

	//         if (config.audioType === "satsang" || config.audioType === "bhajan") {
	//             let recordType = "articles-" + config.audioType;
	//             let records = articlesByType[config.audioType];

	//             let sortValue = "Newest First";
	//             records = getDataSorted(records, sortValue, recordType + "-" + sortValue) as Article[];

	//             if (setting.notPlayed && setting.onlyHindi) {
	//                 records = getArticlesFiltered(state, records, notPlayedHindiFilter, filtersMeta);
	//             } else if (setting.notPlayed) {
	//                 records = getArticlesFiltered(state, records, notPlayedFilter, filtersMeta);
	//             } else if (setting.onlyHindi) {
	//                 records = getArticlesFiltered(state, records, onlyHindiFilter, filtersMeta);
	//             }

	//             let count = setting.count ?? 0;
	//             if (count) {
	//                 result = result.concat(records.slice(0, count));
	//             } else {
	//                 result = result.concat(records);
	//             }
	//         }
	//     }
	// }

	return {
		nityaNiyamConfig,
		nityaNiyamSettings,
		state,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateSettings: (settings: { [key: string]: any }) => {
			dispatch(updateUserSettings(settings));
		},
	};
}

export const NityaNiyam = connect(mapStateToProps, mapDispatchToProps)(Component);
