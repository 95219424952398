import { logEvent } from 'components/telemetry';
import firebase, { fireAuth } from 'fb/initconfig';
import { getAnalytics, logEvent as fireLog, setUserProperties } from 'firebase/analytics';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { isSafari, timeout } from 'helpers';
import { appInterface } from 'store';
import { IAnandApp } from './IAnandApp';

export class JSAnandApp implements IAnandApp {
	private version: number = -1;
	private AnandApp: any;
	private callbacks = {};
	public appInstallationId;
	public buildVersionCode;

	public initialize = (webappBuildVersion?) => {};

	public getAppVersion = () => {
		return this.version;
	};

	public receiveFromApp(opName, data) {}

	public getBuildVersionCode = async () => {
		return -1;
	};

	public downloadMedia = (id, filename, src, force) => {
		this.downloadFile(src, filename, id, true);
	};

	public downloadFile = async (url, filename, id?, isMediaMock?) => {
		if (isMediaMock) {
			console.log(url, filename, id);
			let total = 10000;
			let step = 50;
			let increment = total / step;
			let time = 2000;
			let waitTime = time / step;
			await timeout(5000);

			for (let loaded = 0; loaded <= total; loaded += increment) {
				appInterface.didDownloadAudioData(id, 0, loaded, total);
				var percentComplete = (loaded * 100) / total;

				if (percentComplete >= 100) {
					appInterface.didDownloadMedia(id, true);
				}

				await timeout(waitTime + (Math.random() * waitTime) / 10);
			}

			return;
		}

		if (isSafari()) {
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.target = '_blank';
			// the filename you want
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			return;
		}

		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		if (id) {
			xhr.addEventListener(
				'progress',
				function (evt) {
					if (evt.lengthComputable) {
						appInterface.didDownloadAudioData(id, 0, evt.loaded, evt.total);
						var percentComplete = (evt.loaded * 100) / evt.total;

						if (percentComplete >= 100) {
							appInterface.didDownloadMedia(id, true);
						}
					}
				},
				false
			);
		}

		xhr.onload = function (event) {
			console.log('xhronload');
			var blob = xhr.response;

			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			// the filename you want
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		};

		console.log(url);
		xhr.open('GET', url);
		xhr.send();
	};

	public getAppInstallationId = async () => {
		return 'Web';
	};

	public getAppDetailesJson = async () => {};

	public deleteMedia = (id, filename) => {};

	public loadGoogleSignin = async () => {
		try {
			let provider = new GoogleAuthProvider();
			provider.addScope('email');
			provider.addScope('profile');
			let result = await signInWithPopup(fireAuth, provider);
			appInterface.googleUserSignedIn(result);
		} catch (e) {
			appInterface.googleUserSignedIn(null, e);
		}
	};

	public gSignout = () => {};

	public loadAppleSignin = () => {};
	public appleSignout = () => {};

	public shareLink = (title, link, type, id) => {
		logEvent('share', { content_type: type, item_id: id, link, title });
		window.prompt('Copy to clipboard: Ctrl+C, Enter:: ' + title, link);
	};

	public shareImageDataURI = (title: string, dataURI: string, link: string) => {
		logEvent('share', { link, title });
		// window.prompt('Copy to clipboard: Ctrl+C, Enter:: ' + title, link);
		this.downloadFile(dataURI, title + '.jpg');
	};

	public getAppEnv = () => {
		return 'Web';
	};

	public showToast = (toast) => {
		console.log(toast);
	};

	public showProgress = () => {};

	public hideProgress = () => {};

	public showSnack = (message) => {
		console.log(message);
	};

	public putToAppStorage = (key, value) => {
		localStorage.setItem(key, value);
	};

	public removeFromAppStorage = (key) => {
		localStorage.removeItem(key);
	};

	public getFromAppStorage = async (key) => {
		return localStorage.getItem(key);
	};

	public subscribeToTopic = (topic) => {};

	public unsubscribeFromTopic = (topic) => {};

	public playMedia = (audioId, src, filename, title, isFav, playAuto, startTime, albumTitle, artist) => {};

	public playAllMedia = (mediaArryaJson: string, replace: boolean) => {};

	public togglePlayPause = () => {};

	public downloadCurrentMedia = (title, force) => {};

	public deleteCurrentMedia = () => {};

	public stopAndRelease = () => {};

	public seekToTime = (time) => {};

	public setPlaybackSpeed = (speed) => {};

	public setRepeatMode = (repeatMode: number) => {};
	public setShuffle = async (shuffle: boolean) => {
		return [];
	};
	public playNextMedia = () => {};
	public playPrevMedia = () => {};
	public hasNextMedia = async () => {
		return false;
	};
	public hasPrevMedia = async () => {
		return false;
	};
	public getMediaIdAt = async (i: number) => {
		return '';
	};
	public getCurMediaId = async () => {
		return '';
	};
	public getMediaCount = async () => {
		return 0;
	};
	public getCurMediaIndex = async () => {
		return -1;
	};
	public seekToMediaItemIndex = (itemIndex: number, time: number) => {};

	public exportMedia = (id, filename, src) => {
		this.downloadFile(src, filename, id);
	};

	public logEvent = (name, params) => {
		let analytics = getAnalytics(firebase);
		fireLog(analytics, name, params);
	};

	public setUserProperty = (name, value) => {
		let analytics = getAnalytics(firebase);
		setUserProperties(analytics, { [name]: value });
	};

	public clearAppData() {}

	public clearWebViewCache() {}
}
