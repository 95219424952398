import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import React from 'react';

interface Props {
	label: string;
	inputType?: string;
	initValue?: string;
	actionText?: string;
	action: (value: string) => void;
	onClose?: () => void;
}

export default function DialogForResult(props: Props) {
	let [value, setValue] = React.useState(props.initValue || '');
	let [open, setOpen] = React.useState(true);
	let { actionText, action } = props;

	let onClose = () => {
		setOpen(false);
		setValue('');

		if (props.onClose) {
			props.onClose();
		}
	};

	let onAction = async () => {
		await action(value);
		onClose();
	};

	let checkDuplicateTitle = () => {
		return false;
	};

	React.useEffect(() => {
		const listener = async (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				await onAction();
			}
		};

		document.addEventListener('keydown', listener);

		return () => {
			document.removeEventListener('keydown', listener);
		};
		// eslint-disable-next-line
	}, [value]);

	return (
		<Dialog open={open} onClose={onClose}>
			{/* <DialogTitle id='form-dialog-title'>Create New Playlist</DialogTitle> */}
			<DialogContent dividers>
				<FormControl error={checkDuplicateTitle()}>
					<Input
						value={value}
						placeholder={props.label}
						autoFocus
						type={props.inputType || 'text'}
						margin='dense'
						id='title'
						fullWidth
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setValue(event.target.value);
						}}
					/>
					{checkDuplicateTitle() ? <FormHelperText>Error</FormHelperText> : null}
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button disabled={value.length === 0} onClick={onAction}>
					{actionText || 'Submit'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
