import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { NumberInput } from 'components/NumberInput';
import { CartScreens } from 'components/orders/placement/CartScreen';
import PublicationTile from 'components/publications/PublicationTile';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { ToggleSelector } from 'components/ToggleSelector';
import { Icons } from 'config/icons';
import { User } from 'firebase/auth';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { onEvent } from 'store/temp/actions';
import { ApplicationState, Publication } from 'types';
import { Order, OrderStatusValue, OrderType } from 'types/orders';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	card: (theme) => ({
		marginTop: theme.spacing(2),
	}),
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
			fontWeight: 700,
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	content: {},
	contentRow: (theme) => ({
		flexDirection: 'row',
		display: 'flex',
		position: 'relative' as 'relative',
		width: '100%',
		margin: `${theme.spacing(1)} 0`,

		'& .MuiToggleButton-root': {
			lineHeight: 1,
		},
	}),
	label: {
		width: '50%',
		alignSelf: 'center',
		fontSize: '1.8rem',
	},
	value: {
		flexGrow: 1,
		textAlign: 'right' as 'right',
		fontWeight: 700,
		fontSize: '1.8rem',
	},
	divider: {
		margin: '16px 0 8px 0',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
};

interface SubscriptionDetailsProps extends ScreenManagerProps {
	pubId: string;
	publication: Publication;
	activeSubs: Order[];
	user?: User;
	history: any;
}

interface SubscriptionDetailsState {
	years: string;
	quantity: number;
	startYear: string;
}

class SubscriptionDetailsComponent extends React.Component<SubscriptionDetailsProps, SubscriptionDetailsState> {
	constructor(props) {
		super(props);

		this.state = {
			years: this.props.familyState?.years ?? '1',
			quantity: this.props.familyState?.quantity ?? 1,
			startYear: this.props.familyState?.startYear ?? new Date().getFullYear().toString(),
		};

		// this.props.setFamilyState({ publication: this.props.publication });
	}

	render() {
		let { user, publication, pushScreen, setFamilyState, activeSubs, history } = this.props;
		let navigate = history.navigate;

		if (!user || !user?.phoneNumber) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader='Please signin to continue!'
						action={
							<Button
								style={{ marginTop: 8 }}
								size='small'
								onClick={() => {
									navigate('/phonesignin?force', { state: { isModal: true } });
								}}
							>
								Sign In
							</Button>
						}
					/>
				</Card>
			);
		}

		let price = publication.price ?? 0;
		let quantity = this.state.quantity;
		let totalPrice = parseInt(this.state.years) * quantity * price;
		let shipping = publication.frequency !== 0 ? 0 : totalPrice * 0.25;
		let grandTotal = totalPrice + shipping;
		let periodValues = publication.allowedPeriodValues?.map((p) => p.toString()) ?? ['1'];
		let startYearValues: string[] = [];
		let curYear = new Date().getFullYear();
		let curMonth = new Date().getMonth();

		if (curMonth < 3) {
			startYearValues = [(curYear - 1).toString(), curYear.toString()];
		} else {
			startYearValues = [curYear.toString(), (curYear + 1).toString()];
		}

		return (
			<Box sx={classes.root}>
				<PublicationTile record={publication} showOnlyPubDetails />
				<Card sx={classes.card}>
					<CardHeader sx={classes.header} title={<Typography noWrap>Subscription Details</Typography>} />
					<CardContent sx={classes.content}>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Price
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {price.toFixed(2)}
							</Typography>
						</Box>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Quantity
							</Typography>
							<NumberInput
								style={{ marginLeft: 'auto' }}
								min={1}
								number={this.state.quantity}
								setNumber={(number) => this.setState({ quantity: number })}
							/>
						</Box>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Years
							</Typography>
							<ToggleSelector
								type={this.state.years}
								setType={(type) => {
									this.setState({ years: type });
								}}
								values={periodValues}
								styles={{ marginRight: 0 }}
							/>
						</Box>
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Starts from
							</Typography>
							<ToggleSelector
								type={this.state.startYear}
								setType={(type) => {
									this.setState({ startYear: type });
								}}
								values={startYearValues}
								labels={startYearValues.map((y) => 'April ' + y)}
								styles={{ marginRight: 0 }}
							/>
						</Box>
						{/* <Typography style={{ textAlign: 'right', fontSize: '1.4rem', marginTop: -8 }}>
							(starts from April {this.state.startYear})
						</Typography> */}
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Shipping
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {shipping.toFixed(2)}
							</Typography>
						</Box>
						<Divider sx={classes.divider} />
						<Box sx={classes.contentRow}>
							<Typography noWrap sx={classes.label}>
								Total <br />
								<span style={{ fontSize: '1.4rem' }}>(inclusive of all taxes)</span>
							</Typography>
							<Typography noWrap sx={classes.value}>
								₹ {grandTotal.toFixed(2)}
							</Typography>
						</Box>
					</CardContent>
				</Card>
				{activeSubs.length > 0 && (
					<Card style={{ margin: '8px 0' }}>
						<CardHeader
							subheader={`Note: You already have an active subscription of this publication.`}
							action={
								<Button
									style={{ marginTop: 8 }}
									size='small'
									onClick={() => {
										navigate(`/account/subscription/${activeSubs[0].id}/${publication.id}`, {
											state: {
												isModal: true,
											},
										});
									}}
								>
									View
								</Button>
							}
						/>
					</Card>
				)}
				<Button
					sx={classes.btn}
					variant='contained'
					color='primary'
					onClick={() => {
						setFamilyState({
							years: this.state.years,
							quantity: this.state.quantity,
							startYear: this.state.startYear,
							grandTotal: grandTotal,
						});
						pushScreen(CartScreens.Addresses);
					}}
				>
					Next: Select Address
					{Icons.KeyRightArrow}
				</Button>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState, { pubId }) {
	let orders = state.filteredDataState.orders;
	let activeSubs = orders.filter((order) => {
		let isSamePub = Object.keys(order.items).indexOf(pubId) >= 0;
		if (!isSamePub) {
			return false;
		}

		let isValid = order.status.status === OrderStatusValue.Confirmed && order.type === OrderType.Subscription;
		if (!isValid) {
			return false;
		}

		let isExpired = true;
		if (order.subscription?.startDate) {
			isExpired = new AnandDate()
				.setEpoch(order.subscription?.startDate)
				.add(order.subscription.period, 'year')
				.subtract(1, 'day')
				.isBeforeDate(new AnandDate());
		}
		if (isExpired) {
			isValid = false;
		}

		return isValid;
	});

	return {
		publication: state.dataState.publications.byId[pubId],
		activeSubs,
		user: state.userState.userStore.user,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

export const SubscriptionDetailsScreen = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SubscriptionDetailsComponent));
