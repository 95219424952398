import { Box, CardContent, Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArticleOptionsIcon } from 'components/articles/ArticleOptions';
import { DownloadIndicator } from 'components/articles/DownloadIndicator';
import { ArticleTypeMap, LangMap } from 'config';
import { Icons } from 'config/icons';
import { canContinouslyPlay, getFormattedTimeFromSec, timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { updateAudioPlaybackRepeat } from 'store/temp/actions';
import { ApplicationState, Article, AudioCurrentState } from 'types';
import { AudioPlaybackInstance } from './AudioPlayback';

const Root = styled('div')(({ theme }) => ({
	paddingTop: theme.spacing(1.5),
	// 'margin-top': theme.spacing(0.5),
	borderTop: '5px solid',
	borderColor: theme.palette.primary.main,
}));

const classes = {
	currentTime: {
		float: 'left' as 'left',
	},
	duration: {
		float: 'right' as 'right',
	},
	audioContent: {
		maxWidth: 500,
		margin: '0 auto',
		width: '90%',
		// paddingBottom: theme.spacing(2) + 'px !important',
	},
	slider: {
		'&.MuiSlider-root': {
			padding: '12px 0',
			height: 4,
		},
	},
	chips: {
		display: 'inline-flex',
		width: 'calc(min(90vw, 500px) - 32px - 150px - 8px)',
		maxWidth: 'fit-content',
		overflowX: 'scroll' as 'scroll',
	},
	chip: {
		margin: '1px',
	},
	controls: {
		flexGrow: 1,
		marginTop: 1,

		'& .MuiGrid-item': {
			textAlign: 'center',
		},
	},
	header: {
		padding: '8px 16px',
		flex: '0 0 auto',

		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			// float: 'right'
		},

		'& .MuiCardHeader-content': {
			minWidth: 0,
			textAlign: 'center',

			'& p.MuiTypography-body1': {
				fontSize: '2rem',
			},
			'& .MuiCardHeader-subheader': {
				fontSize: '1.6rem',
			},
		},

		'& .MuiCardHeader-avatar': {
			marginRight: 0,

			'& button': {
				marginLeft: '-8px',
				marginTop: '-8px',
			},
		},
	},
};

interface StateProps {
	currentTime?: number;
	duration: number;
	currentState?: AudioCurrentState;
	repeat: number;
	locale: string;
}

interface SuppliedProps {
	articles?: Article[];
	currentIndex: number;
	playPauseAudio: (e) => void;
	moveToIndex: (index: number) => void;
	updateAudioPlaybackRepeat: (repeat: number) => void;
}

interface ControlsProps extends StateProps, SuppliedProps {}
// export var globalCurrentTime = { time: 0 };

function FullscreenPlayerControls(props: ControlsProps) {
	let { currentTime = 0, duration, currentIndex = 0, articles, currentState, playPauseAudio, moveToIndex, repeat, locale, updateAudioPlaybackRepeat } = props;
	let [skipDur, setSkipDur] = useState(0);
	let article = articles ? articles[currentIndex] : undefined;

	let isReady =
		article &&
		(currentState === AudioCurrentState.Playing ||
			currentState === AudioCurrentState.Paused ||
			currentState === AudioCurrentState.Seeking ||
			currentState === AudioCurrentState.Ended);

	let curTimeStr = getFormattedTimeFromSec((currentTime || 0) - skipDur * 60);

	let durationStr = getFormattedTimeFromSec(Math.max(currentTime, duration) - skipDur * 60);
	const bull = (
		<Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
			•
		</Box>
	);
	let localeClass = '';
	if (article?.title[locale]) {
		if (locale === 'hi') {
			localeClass = 'locale-hi';
		}
	} else if (article?.title.hi) {
		localeClass = 'locale-hi';
	}

	const DurationChip = (chipDur: number) => {
		return (
			<Chip
				sx={classes.chip}
				key={chipDur}
				size="small"
				label={chipDur + 'm'}
				variant={60 - skipDur === chipDur ? 'filled' : 'outlined'}
				color="primary"
				onClick={() => {
					setSkipDur(60 - chipDur);
					AudioPlaybackInstance().seekTo((60 - chipDur) * 60);
				}}
			/>
		);
	};

	return (
		<Root className="fullscreen-controls-root">
			<CardHeader
				sx={classes.header}
				avatar={
					(articles?.length ?? 0) <= 1 && canContinouslyPlay() ? (
						<IconButton
							color={repeat !== 0 ? 'primary' : 'default'}
							onClick={(event) => {
								let repeatMode = repeat === 0 ? 1 : 0;
								event.stopPropagation();
								updateAudioPlaybackRepeat(repeatMode);
								AudioPlaybackInstance().setRepeatMode(repeatMode);
							}}
						>
							{repeat === 0 ? Icons.Repeat : Icons.RepeatOneIcon}
						</IconButton>
					) : (
						<Avatar sx={{ backgroundColor: '#fff', fontSize: '1.7rem' }}>
							<DownloadIndicator record={article} />
						</Avatar>
					)
				}
				action={<ArticleOptionsIcon record={article} />}
				title={
					!article ? (
						<Skeleton animation="wave" height={12} width="80%" style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }} />
					) : (
						<Typography noWrap className={localeClass}>
							{article?.title[locale] || article?.title.hi || article?.title.en}
						</Typography>
					)
				}
				subheader={
					!article ? (
						<Skeleton animation="wave" height={12} width="40%" style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }} />
					) : (
						<span>
							{ArticleTypeMap[article!.articleType]}
							{bull}
							{LangMap[article!.lang]}
							{bull}
							{new AnandDate(article!.creationTime ?? article!.publishTime).format('Do MMM, YYYY')}
						</span>
					)
				}
			></CardHeader>
			{/* {article?.mediaType === 'audio' && ( */}
			<CardContent sx={classes.audioContent}>
				<Typography style={{ height: '24px', textAlign: 'center' }} component="div">
					<span style={{ float: 'left' }}>{curTimeStr}</span>
					{article?.articleType === 'dhyan' && <div style={classes.chips}>{[60, 45, 30, 15].map((val) => DurationChip(val))}</div>}
					<span style={{ float: 'right' }}>{durationStr}</span>
				</Typography>
				{!isReady ? (
					<Skeleton animation="wave" height={6} width="100%" style={{ marginTop: 8, marginBottom: 16, marginLeft: 'auto', marginRight: 'auto' }} />
				) : (
					<Slider
						sx={classes.slider}
						disabled={!isReady || currentState === AudioCurrentState.Seeking}
						value={!isReady ? 0 : Math.max(0, currentTime - skipDur * 60)}
						onChange={(e, newValue) => {
							AudioPlaybackInstance().seekTo((newValue as number) + skipDur * 60);
						}}
						min={0}
						max={!isReady ? 1 : duration - skipDur * 60}
					/>
				)}
				<Box component="div" sx={classes.controls} className="fullscreen-controls-buttons">
					<Grid container>
						<Grid item xs>
							<IconButton
								className="skip-prev"
								onClick={async () => {
									if (currentIndex > 0) {
										moveToIndex(currentIndex - 1);
									} else if (articles) {
										moveToIndex(articles?.length - 1);
									}

									if (canContinouslyPlay()) {
										await timeout(100);
										AudioPlaybackInstance().playAudio();
									}
								}}
								disabled={!isReady || !articles || articles.length <= 1 || (repeat !== 2 && currentIndex <= 0)}
								size="large"
							>
								{Icons.SkipPrevious}
							</IconButton>
						</Grid>
						<Grid item xs>
							<IconButton
								className="replay-back"
								onClick={() => {
									AudioPlaybackInstance().seekTo(Math.max(0 + skipDur * 60, currentTime - 10));
								}}
								disabled={!isReady || currentState === AudioCurrentState.Seeking}
								size="large"
							>
								{Icons.Replay10}
							</IconButton>
						</Grid>
						<Grid item xs>
							<IconButton onClick={playPauseAudio} disabled={!isReady} size="large" className="play-pause">
								{currentState === AudioCurrentState.Playing ? Icons.Pause : Icons.Play}
							</IconButton>
						</Grid>
						<Grid item xs>
							<IconButton
								className="forward"
								onClick={(e: React.MouseEvent) => {
									if (currentTime < duration - 10) {
										AudioPlaybackInstance().seekTo(Math.min(duration - 10, currentTime + 30.0));
									}
								}}
								disabled={!isReady || currentState === AudioCurrentState.Seeking}
								size="large"
							>
								{Icons.Forward30}
							</IconButton>
						</Grid>
						<Grid item xs>
							<IconButton
								className="skip-next"
								onClick={async () => {
									if (articles && currentIndex < articles?.length - 1) {
										moveToIndex(currentIndex + 1);
									} else {
										moveToIndex(0);
									}

									if (canContinouslyPlay()) {
										await timeout(100);
										AudioPlaybackInstance().playAudio();
									}
								}}
								disabled={!isReady || !articles || articles.length <= 1 || (repeat !== 2 && currentIndex >= articles.length - 1)}
								size="large"
							>
								{Icons.SkipNext}
							</IconButton>
						</Grid>
					</Grid>
				</Box>
			</CardContent>
			{/* )} */}
		</Root>
	);
}

function mapStateToProps(state: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = state.tempState;
	let articles = audioPlaybackDetails?.articleList;
	let currentIndex = audioPlaybackDetails?.currentIndex ?? 0;
	let article = articles && articles[currentIndex];

	return {
		articles,
		currentIndex,
		currentTime: audioPlaybackState.currentTime,
		currentState: audioPlaybackState.currentState,
		duration: audioPlaybackState.duration || article?.mediaLength || 0,
		repeat: audioPlaybackState.repeat,
		locale: state.uxState.locale,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateAudioPlaybackRepeat: (repeat: number) => {
			dispatch(updateAudioPlaybackRepeat(repeat));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenPlayerControls);
