import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { Feature } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_FEATURES = '@@features/UPDATE_FEATURES';
export type T_UPDATE_FEATURES = typeof UPDATE_FEATURES;

export interface UpdateFeatures extends Action {
	type: T_UPDATE_FEATURES;
	changes: FBChanges;
	overwrite?: boolean;
}

export function updateFeatures(changes: FBChanges, overwrite?: boolean): UpdateFeatures {
	return {
		type: UPDATE_FEATURES,
		changes,
		overwrite,
	};
}

export const UPDATE_FEATURES_FILTERED = UPDATE_FEATURES + '_FILTERED';
export type T_UPDATE_FEATURES_FILTERED = typeof UPDATE_FEATURES_FILTERED;

export interface UpdateFeaturesFiltered extends Action {
	type: T_UPDATE_FEATURES_FILTERED;
	payload: Feature[];
}

export const SHOW_TODAYS_FEATURE = 'SHOW_TODAYS_FEATURE';
export type T_SHOW_TODAYS_FEATURE = typeof SHOW_TODAYS_FEATURE;

export interface ShowTodaysFeature extends Action {
	type: T_SHOW_TODAYS_FEATURE;
}

export function showTodaysFeature(): ShowTodaysFeature {
	return {
		type: SHOW_TODAYS_FEATURE,
	};
}
