import { FireStoreModule } from 'fb/index';
import { fireAuth, fireDB } from 'fb/initconfig';
import { User } from 'firebase/auth';
import { collection, doc, Firestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { updateUserSubColl } from 'store/data/user/actions';
import { Address } from 'types/orders';
import { triggerFunction } from './functions';

export class AddressesFireStoreModule implements FireStoreModule {
	private user: User;
	constructor(user: User) {
		this.user = user;
	}

	public getModuleName = () => 'addresses';
	public getOpsKeySuffix = () => this.user.uid;
	public getFBWatchQuery = (db: Firestore) => collection(doc(collection(db, 'users'), this.user.uid), 'addresses');
	public getChangeProcessAction = (changes) =>
		updateUserSubColl('addresses', this.user.uid, changes, fireAuth.currentUser?.uid);
}

export const updateAddress = (data: Address, isOrder: boolean = false) => {
	return async (dispatch, getState) => {
		try {
			let userStore = getState().userState.userStore;
			let user = userStore.user;

			if (!user) {
				throw new Error('user not signed in');
			}

			if (isOrder && data.id) {
				return await triggerFunction('updateOrderAddress', { orderId: data.id, address: data });
			}

			let addrId = data.id ?? doc(collection(doc(collection(fireDB, 'users'), user.uid), 'addresses')).id;
			let addrRef = doc(collection(doc(collection(fireDB, 'users'), user.uid), 'addresses'), addrId);

			let address: any = {
				...data,
				updatedAt: serverTimestamp(),
			};

			address.id = addrId;

			if (data.id) {
				await setDoc(addrRef, address, { merge: true });
			} else {
				address.createdAt = serverTimestamp();
				address.active = true;
				address.deleted = false;
				await setDoc(addrRef, address, { merge: true });
			}
		} catch (error) {
			throw error;
		}
	};
};

export const deleteAddress = (addrId: string) => {
	return async (dispatch, getState) => {
		try {
			let userStore = getState().userState.userStore;
			let user = userStore.user;

			if (!user) {
				throw new Error('user not signed in');
			}

			let addrRef = doc(collection(doc(collection(fireDB, 'users'), user.uid), 'addresses'), addrId);

			await setDoc(
				addrRef,
				{
					deleted: true,
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			);
		} catch (error) {
			throw error;
		}
	};
};
