import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Description from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import LightbulbIcon from '@mui/icons-material/EmojiObjects';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Forward30Icon from '@mui/icons-material/Forward30';
import GetAppIcon from '@mui/icons-material/GetApp';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyIcon from '@mui/icons-material/Key';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LanguageIcon from '@mui/icons-material/Language';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import Pause from '@mui/icons-material/Pause';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PinIcon from '@mui/icons-material/Pin';
import Play from '@mui/icons-material/PlayArrow';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PolicyIcon from '@mui/icons-material/Policy';
import PublishIcon from '@mui/icons-material/Publish';
import QueueIcon from '@mui/icons-material/Queue';
import QueueMusic from '@mui/icons-material/QueueMusic';
import RemoveIcon from '@mui/icons-material/Remove';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import Replay10Icon from '@mui/icons-material/Replay10';
import Replay30Icon from '@mui/icons-material/Replay30';
import SearchIcon from '@mui/icons-material/Search';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TelegramIcon from '@mui/icons-material/Telegram';
import TuneIcon from '@mui/icons-material/Tune';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EcoIcon from '@mui/icons-material/VolunteerActivism';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WorkIcon from '@mui/icons-material/Work';
import SvgIcon from '@mui/material/SvgIcon';
import SyncIcon from '@mui/icons-material/Sync';

export const Icons = {
	SyncIcon: <SyncIcon />,
	RepeatOnIcon: <RepeatOnIcon />,
	RepeatOneIcon: <RepeatOneIcon />,
	BookmarkAdd: <BookmarkAddIcon />,
	BookmarkRemove: <BookmarkRemoveIcon />,
	MeditationIcon: <SelfImprovementIcon />,
	EventRepeatIcon: <EventRepeatIcon />,
	AllInclusiveIcon: <AllInclusiveIcon />,
	KeyIcon: <KeyIcon />,
	FileIcon: <InsertDriveFileIcon />,
	PdfIcon: <PictureAsPdfIcon />,
	AttachFileIcon: <AttachFileIcon />,
	VideoFileIcon: <VideoFileIcon />,
	AudioFileIcon: <AudioFileIcon />,
	ImageIcon: <ImageIcon />,
	PinIcon: <PinIcon />,
	SuccessIcon: CheckCircleIcon,
	Phone: <PhoneIcon />,
	LightBulb: <LightbulbIcon />,
	Chat: <TelegramIcon />,
	Cart: <ShoppingCartIcon />,
	Location: <LocationCityIcon />,
	Work: <WorkIcon />,
	OfflinePinIcon: OfflinePinIcon,
	EditIcon: EditIcon,
	DeleteIcon: DeleteIcon,
	Duplicate: <FileCopyIcon />,
	Video: <VideoLibraryIcon />,
	CloudDownload: <CloudDownloadIcon />,
	Font: <FontDownloadIcon />,
	ExpandLess: <ExpandLess />,
	Menu: <MenuIcon />,
	Search: <SearchIcon />,
	Description: <Description />,
	NotificationImportant: <NotificationImportantIcon />,
	Email: <EmailIcon />,
	EmailIcon: EmailIcon,
	AccountBoxIcon: AccountBoxIcon,
	Feedback: <FeedbackIcon />,
	Business: <BusinessIcon />,
	Help: <HelpIcon />,
	Language: <LanguageIcon />,
	Lock: <LockIcon />,
	NavigateNext: <NavigateNextIcon />,
	Person: <PersonIcon />,
	Policy: <PolicyIcon />,
	WarningIcon: WarningRoundedIcon,
	Visibility: <Visibility />,
	VisibilityOff: <VisibilityOff />,
	PlayCircleOutline: <PlayCircleOutlineIcon />,
	PlaylistPlay: <PlaylistPlayIcon />,
	SwapVert: <SwapVertIcon />,
	Forward30: <Forward30Icon />,
	Replay30: <Replay30Icon />,
	Replay10: <Replay10Icon />,
	Pause: <Pause />,
	Play: <Play />,
	PlayCircle: <PlayCircleFilled />,
	SkipNext: <SkipNextIcon />,
	SkipPrevious: <SkipPreviousIcon />,
	DoubleArrow: <DoubleArrowIcon />,
	Repeat: <RepeatIcon />,
	Shuffle: <ShuffleIcon />,
	Home: <HomeIcon />,
	Bhajan: <LibraryMusic />,
	Satsang: <QueueMusic />,
	About: <InfoIcon />,
	Publication: <LibraryBooksIcon />,
	ShriAmarVani: <WhatshotIcon />,
	Quote: <FormatQuoteIcon />,
	Calendar: <EventNoteIcon />,
	Donation: <EcoIcon />,
	Notification: <NotificationsIcon />,
	Account: <AccountCircle />,
	Admin: <TuneIcon />,
	Settings: <SettingsIcon />,
	SettingsIcon: SettingsIcon,
	// PlayArrowIcon: PlayArrowIcon,
	Download: <GetAppIcon />,
	DownloadIcon: GetAppIcon,
	MoreVert: <MoreVertIcon />,
	MoreVertIcon: MoreVertIcon,
	Queue: <QueueIcon />,
	Share: <ShareIcon />,
	DoneAll: <DoneAllIcon />,
	Done: <DoneIcon />,
	ExpandMore: <ExpandMoreIcon />,
	FilterList: <FilterListRoundedIcon />,
	Sort: <SortRoundedIcon />,
	Edit: <EditIcon />,
	Add: <AddIcon />,
	Remove: <RemoveIcon />,
	Delete: <DeleteIcon />,
	DeleteForever: <DeleteForeverIcon />,
	ListAlt: <ListAltIcon />,
	Upload: <PublishIcon />,
	KeyLeftArrow: <KeyboardArrowLeft />,
	KeyRightArrow: <KeyboardArrowRight />,
	DateRange: <DateRangeIcon />,
	NotificationsActive: <NotificationsActiveIcon />,
	ArrowBack: <ArrowBackIcon />,
	CloseIcon: (
		<SvgIcon viewBox="0 0 50 50">
			<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
		</SvgIcon>
	),
	Close: <CloseIcon />,
	Scroll: (
		<SvgIcon viewBox="0 0 24 24">
			<path d="M17.8,20C17.4,21.2 16.3,22 15,22H5C3.3,22 2,20.7 2,19V18H5L14.2,18C14.6,19.2 15.7,20 17,20H17.8M19,2C20.7,2 22,3.3 22,5V6H20V5C20,4.4 19.6,4 19,4C18.4,4 18,4.4 18,5V18H17C16.4,18 16,17.6 16,17V16H5V5C5,3.3 6.3,2 8,2H19M8,6V8H15V6H8M8,10V12H14V10H8Z" />
		</SvgIcon>
	),
	Orders: (
		<SvgIcon viewBox="0 0 426 426">
			<path
				d="M377.763,83.169l-86.238-80.33c-1.957-1.83-4.54-2.839-7.21-2.839H55.291c-5.855,0-10.597,4.742-10.597,10.59v404.647
		c0,5.843,4.742,10.595,10.597,10.595H370.54c5.854,0,10.599-4.74,10.599-10.595V90.92
		C381.134,87.979,379.915,85.172,377.763,83.169z M108.599,388.26c0-8.273,6.735-15.011,15.018-15.011
		c8.282,0,15.012,6.737,15.012,15.011c0,8.284-6.73,15.016-15.012,15.016C115.334,403.276,108.599,396.544,108.599,388.26z
		 M185.611,388.26c0-8.273,6.736-15.011,15.019-15.011c8.275,0,15.003,6.737,15.003,15.011c0,8.284-6.728,15.016-15.003,15.016
		C192.347,403.276,185.611,396.544,185.611,388.26z M360.118,404.654l-135.527-0.131c3.152-4.641,5.007-10.238,5.007-16.258
		c0-15.983-12.993-28.974-28.968-28.974c-15.981,0-28.983,12.99-28.983,28.974c0,6.003,1.839,11.574,4.972,16.214l-28.979-0.031
		c3.126-4.618,4.952-10.191,4.952-16.183c0-15.983-12.994-28.974-28.975-28.974c-15.98,0-28.98,12.99-28.98,28.974
		c0,5.971,1.814,11.519,4.925,16.132l-33.844-0.033l0.252-134.205L87.207,355.1h144.215l69.822-160.598h21.06
		c5.79,0,10.476-4.69,10.476-10.473c0-5.782-4.686-10.471-10.476-10.471h-34.79l-69.828,160.589h-114.13l-17.453-69.821h108.77
		c5.79,0,10.473-4.691,10.473-10.468c0-5.791-4.684-10.486-10.473-10.486H66.021l0.005-3.951V21.17h197.629v79.471
		c0,5.844,4.738,10.585,10.583,10.585h85.88V404.654z"
			/>
		</SvgIcon>
	),
	Pooja: (
		<SvgIcon>
			<path d="M15,2L13.5,3.5L15,5L16.5,3.5L15,2M11,3C10,9 17,10 20,6L18,4.5C17,6 13,8 11,3M9,7C7,7 4.5,8.5 4.5,8.5L6,11C7,10 9,9.5 10,10C12,11 9,13 7,12V15.5C10,14 12,16 11,17.5C8,22 3,16 3,13C1,19 6,22 9,22C12,22 14,20 12.5,15H14C12.5,19.5 18,24 21,18C22,16 22,9.5 17,9.5C13,9.5 14,15 10.5,13.5C14,10 12,7 9,7M19,12C22,15 15,21 15,15C15,13 17,10.5 19,12Z" />
		</SvgIcon>
	),
	Playlist: (
		<SvgIcon>
			<path d="M17,19.09L19.45,20.58L18.8,17.77L21,15.89L18.11,15.64L17,13L15.87,15.64L13,15.89L15.18,17.77L14.5,20.58L17,19.09M4,14H12V16H4V14M4,6H16V8H4V6M4,10H16V12H4V10Z" />
		</SvgIcon>
	),
};
