import App from 'AppInterface/App';
import { logEvent } from 'components/telemetry';
import { canContinouslyPlay, capitalize, getAudioFilenameFromSrc, getContentStorageUrl, isWebViewEnv } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { updatePlayedMedia } from 'store/data/played/actions';
import { updateAudioPlaybackDuration, updateAudioPlaybackState, updateAudioPlaybackTime } from 'store/temp/actions';
import { ApplicationState, Article, AudioCurrentState, AudioPlaybackDetails, AudioPlaybackState, ContentType, PlayedMedia } from 'types';

interface AudioPlaybackProps {
	article?: Article;
	audioPlaybackDetails: AudioPlaybackDetails | null;
	audioPlaybackState: AudioPlaybackState | null;
	updateAudioPlaybackState: (currentState: AudioCurrentState) => void;
	updateAudioPlaybackTime: (currentTime: number, articleId?: string, force?: boolean) => void;
	updateAudioPlaybackDuration: (duration: number) => void;
	playedMedia: PlayedMedia;
}

interface IAudioPlayback extends React.Component<AudioPlaybackProps, any> {
	constructor: Function;
	playAudio(): void;
	pauseAudio(): void;
	playPauseAudio(): void;
	stopAudio(): void;
	seekTo(time: number): void;
	setPlaybackSpeed(speed: number): void;
	setRepeatMode: (repeatMode: number) => void;
	setShuffle: (shuffle: boolean) => Promise<string[]>;
	playNextMedia: () => void;
	playPrevMedia: () => void;
	hasNextMedia: () => Promise<boolean>;
	hasPrevMedia: () => Promise<boolean>;
	getMediaIdAt: (i: number) => Promise<string>;
	getCurMediaId: () => Promise<string>;
	getMediaCount: () => Promise<number>;
	getCurMediaIndex: () => Promise<number>;
}

class AppAudioPlayback extends React.Component<AudioPlaybackProps, any> implements IAudioPlayback {
	public PlaylistBatchSize = 50;

	private getMediaDetail(audio: Article) {
		let audioSrc = getContentStorageUrl(ContentType.Article, audio.articleType, audio);
		let filename = getAudioFilenameFromSrc(audio.mediaUri!);
		let album = audio.articleType === 'shriaarti' ? 'Pooja Ke Phool' : capitalize(audio.articleType);
		let title = audio.title.hi || audio.title.en || album;

		let mediaDetail = {
			audioId: audio.id,
			src: audioSrc,
			filename: filename,
			title: title,
			isFav: false,
			albumTitle: album,
			artist: audio.group,
		};

		return mediaDetail;
	}

	public playAudio() {
		let { article, audioPlaybackDetails } = this.props;
		let articleList = audioPlaybackDetails?.articleList ?? [];
		let curIndex = audioPlaybackDetails?.currentIndex ?? 0;

		if (!article && articleList.length === 0) {
			return;
		}

		article = article ?? articleList[0];

		let albumTitle = article.articleType === 'shriaarti' ? 'Pooja Ke Phool' : capitalize(article.articleType);
		logEvent('playMedia', {
			id: article.id,
			title: article.title.hi || article.title.en || albumTitle,
			uri: article.mediaUri,
		});

		this.props.updateAudioPlaybackState(AudioCurrentState.Initializing);
		this.props.updateAudioPlaybackTime(0, article.id);

		if (canContinouslyPlay() && articleList.length > 1) {
			let audio = articleList[curIndex];
			let mediaDetail = this.getMediaDetail(audio);
			App.playAllMedia(JSON.stringify([mediaDetail]), true);

			setTimeout(() => {
				let mediaArray: any = [];
				for (let i = curIndex + 1; i < Math.min(articleList.length, curIndex + this.PlaylistBatchSize); i++) {
					let audio = articleList[i];
					let mediaDetail = this.getMediaDetail(audio);
					mediaArray.push(mediaDetail);
				}

				App.playAllMedia(JSON.stringify(mediaArray), false);
			}, 500);
		} else {
			let audioSrc = getContentStorageUrl(ContentType.Article, article.articleType, article);

			App.playMedia(
				article.id,
				audioSrc,
				getAudioFilenameFromSrc(article.mediaUri!),
				article.title.hi || article.title.en || albumTitle,
				false,
				true,
				0,
				albumTitle,
				article.group
			);
		}
	}

	public pauseAudio() {
		if (this.props.audioPlaybackState?.currentState === AudioCurrentState.Playing) {
			this.playPauseAudio();
		}
	}

	public playPauseAudio() {
		App.togglePlayPause();
	}

	public stopAudio() {
		App.stopAndRelease();
	}

	public seekTo(time: number) {
		// this.props.updateAudioPlaybackState(AudioCurrentState.Seeking);
		App.seekToTime(time);
		// this.props.updateAudioPlaybackTime(time);
	}

	public setPlaybackSpeed(speed: number) {
		App.setPlaybackSpeed(speed);
	}

	public setRepeatMode = (repeatMode: number) => {
		App.setRepeatMode(repeatMode);
	};

	public setShuffle = async (shuffle: boolean) => {
		return await App.setShuffle(shuffle);
	};

	public playNextMedia = () => {
		App.playNextMedia();
	};

	public playPrevMedia = () => {
		App.playPrevMedia();
	};

	public hasNextMedia = async () => {
		return await App.hasNextMedia();
	};

	public hasPrevMedia = async () => {
		return await App.hasPrevMedia();
	};

	public getMediaIdAt = async (i: number) => {
		return await App.getMediaIdAt(i);
	};

	public getCurMediaId = async () => {
		return await App.getCurMediaId();
	};

	public getMediaCount = async () => {
		return await App.getMediaCount();
	};

	public getCurMediaIndex = async () => {
		return await App.getCurMediaIndex();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.article !== this.props.article && this.props.article) {
			// this.playAudio();
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: AudioPlaybackProps) {
		if (this.props.audioPlaybackDetails && newProps.article && newProps.article !== this.props.article) {
			this.pauseAudio();
		}
	}

	render() {
		return null;
	}
}

class WebAudioPlayback extends React.Component<AudioPlaybackProps, any> implements IAudioPlayback {
	private audioRef: React.RefObject<HTMLAudioElement> = React.createRef();
	private currentState: AudioCurrentState = AudioCurrentState.Stopped;

	constructor(props: AudioPlaybackProps) {
		super(props);

		this.state = {
			enabled: false,
		};
	}

	private setAudioState(state: AudioCurrentState) {
		this.currentState = state;
		this.props.updateAudioPlaybackState(state);
	}

	public setRepeatMode = (repeatMode: number) => {};
	public setShuffle = async (shuffle: boolean) => {
		return [];
	};
	public playNextMedia = () => {};
	public playPrevMedia = () => {};
	public hasNextMedia = async () => {
		return false;
	};
	public hasPrevMedia = async () => {
		return false;
	};
	public getMediaIdAt = async (i: number) => {
		return '';
	};
	public getCurMediaId = async () => {
		return '';
	};
	public getMediaCount = async () => {
		return 0;
	};
	public getCurMediaIndex = async () => {
		return -1;
	};

	public playAudio() {
		// if (article) {
		// 	this.setState(
		// 		{
		// 			article: article
		// 		},
		// 		() => {
		// 			this.props.updateAudioPlaybackArticle(article);
		// 			this.props.updateAudioPlaybackState((this.currentState = AudioCurrentState.Initializing));
		// 		}
		// 	);
		// } else

		// if (this.audioRef.current) {
		// 	this.audioRef.current?.play();
		// 	this.props.updateAudioPlaybackState((this.currentState = AudioCurrentState.Playing));
		// }

		this.setState(
			{
				enabled: true,
			},
			() => {
				this.setAudioState(AudioCurrentState.Initializing);
			}
		);
	}

	public pauseAudio() {
		if (this.audioRef.current && this.currentState === AudioCurrentState.Playing) {
			this.audioRef.current.pause();
			this.setAudioState(AudioCurrentState.Paused);
		}
	}

	public playPauseAudio() {
		if (this.currentState === AudioCurrentState.Playing) {
			this.audioRef.current?.pause();
			this.setAudioState(AudioCurrentState.Paused);
		} else {
			this.audioRef.current?.play();
			this.setAudioState(AudioCurrentState.Playing);
		}
	}

	public stopAudio() {
		this.audioRef.current?.pause();

		this.setState(
			{
				enabled: false,
			},
			() => {
				this.setAudioState(AudioCurrentState.Stopped);
				this.props.updateAudioPlaybackTime(0, this.props.article?.id);
			}
		);
	}

	public seekTo(time: number) {
		if (this.audioRef.current && this.audioRef.current.seeking === false) {
			this.setAudioState(AudioCurrentState.Paused);
			this.audioRef.current.currentTime = time;
			this.props.updateAudioPlaybackTime(this.audioRef.current.currentTime, this.props.article?.id);
		}
	}

	public setPlaybackSpeed(speed: number) {
		this.audioRef.current!.playbackRate = speed;
	}

	public getDuration() {
		return this.audioRef.current?.duration || 0;
	}

	public getCurrentTime() {
		return this.audioRef.current?.currentTime || 0;
	}

	private onTimeUpdate = () => {
		let articleId = this.props.article!.id;
		let mediaLength = this.getDuration();
		let curPlayedTime = this.props.playedMedia[articleId]?.time ?? 0;
		let curTime = this.audioRef.current?.currentTime || 0;
		let force = mediaLength - curTime < 3 || mediaLength - curPlayedTime < 10;

		this.props.updateAudioPlaybackTime(curTime, articleId, force);
		// this.props.updateAudioPlaybackDuration(this.audioRef.current?.duration || 0);
	};

	private onDurationChange = () => {
		this.props.updateAudioPlaybackDuration(this.audioRef.current?.duration || this.props.article?.mediaLength || 0);
	};

	private onCanPlay = () => {
		this.audioRef.current
			?.play()
			.then(() => {
				this.setAudioState(AudioCurrentState.Playing);
			})
			.catch((e) => {
				this.setAudioState(AudioCurrentState.Paused);
				console.error('AudioPlayback: onCanPlay: ' + e.message, e.stack);
			});

		this.audioRef.current!.muted = false;
		this.audioRef.current!.playbackRate = this.props.audioPlaybackState?.playbackSpeed ?? 1;
	};

	private onEnded = () => {
		this.setAudioState(AudioCurrentState.Ended);
	};

	private onPlay = () => {
		this.setAudioState(AudioCurrentState.Playing);
	};

	private onPause = () => {
		this.setAudioState(AudioCurrentState.Paused);
	};

	componentDidMount() {
		// this.props.updateAudioPlaybackState(AudioCurrentState.Initializing);
	}

	componentDidUpdate() {
		// this.audioRef.current.pause();
	}

	shouldComponentUpdate(nextProps: Readonly<AudioPlaybackProps>, nextState: Readonly<any>, nextContext: any) {
		if (nextProps.article !== this.props.article || this.state.enabled !== nextState.enabled) {
			return true;
		}

		return false;
	}

	UNSAFE_componentWillReceiveProps(newProps: AudioPlaybackProps) {
		if (this.audioRef.current && newProps.article && newProps.article !== this.props.article) {
			this.audioRef.current.pause();
			this.audioRef.current.currentTime = 0;
			this.setAudioState(AudioCurrentState.Initializing);
			this.props.updateAudioPlaybackTime(0, newProps.article?.id);
		}
	}

	render() {
		let { article } = this.props;

		if (!article || this.state.enabled === false || article.mediaType !== 'audio') {
			return <></>;
		}

		let audioSrc = getContentStorageUrl(ContentType.Article, article.articleType, article);

		return (
			<audio
				ref={this.audioRef}
				onTimeUpdate={this.onTimeUpdate}
				onDurationChange={this.onDurationChange}
				onCanPlay={this.onCanPlay}
				onEnded={this.onEnded}
				onPlay={this.onPlay}
				onPause={this.onPause}
				preload="auto"
				muted
				autoPlay
				loop={false}
				key={'audio-' + Math.random() + article?.id}
				src={audioSrc}
			></audio>
		);
	}
}

class AudioPlayback extends React.Component<AudioPlaybackProps, any> {
	private static _instance: IAudioPlayback;

	public static instance(): IAudioPlayback {
		return AudioPlayback._instance;
	}

	render() {
		let Playback = isWebViewEnv() ? AppAudioPlayback : WebAudioPlayback;
		return (
			<Playback
				ref={(instance) => {
					AudioPlayback._instance = instance!;
				}}
				{...this.props}
			/>
		);
	}
}

function mapStateToProps({ tempState, offlineState }: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = tempState;

	return {
		article: audioPlaybackDetails
			? canContinouslyPlay()
				? audioPlaybackDetails.articleList[0]
				: audioPlaybackDetails.articleList[audioPlaybackDetails.currentIndex]
			: undefined,
		audioPlaybackDetails: isWebViewEnv() ? audioPlaybackDetails : null,
		audioPlaybackState: isWebViewEnv() ? audioPlaybackState : audioPlaybackState,
		playedMedia: offlineState.playedMedia,
	};
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, unknown, AnyAction>) {
	return {
		updateAudioPlaybackState: (currentState: AudioCurrentState) => {
			dispatch(updateAudioPlaybackState(currentState));
		},
		updateAudioPlaybackTime: (currentTime: number, articleId?: string, force?: boolean) => {
			dispatch(updateAudioPlaybackTime(currentTime));
			if (articleId) {
				dispatch(updatePlayedMedia(articleId, currentTime, force ?? false));
			}
		},
		updateAudioPlaybackDuration: (duration: number) => {
			dispatch(updateAudioPlaybackDuration(duration));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayback);
export const AudioPlaybackInstance = AudioPlayback.instance;
