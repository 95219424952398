import { createSelector } from 'reselect';
import { ApplicationState, UserPlaylist, UserPlaylists } from 'types';

const getFBPlaylists = (state: ApplicationState) => state.userState.userStore.subColl?.playlists;
const currentSigninUser = (state: ApplicationState) => state.userState.userStore.user;

export const getUserPlaylists = createSelector([getFBPlaylists, currentSigninUser], (playlists, user) => {
	let result: UserPlaylists = {};
	for (let key in playlists) {
		let playlist = playlists[key];
		if (playlist.deleted === true || !user || playlist.userId !== user.uid) {
			continue;
		}

		playlist.tracks = playlist.tracks || {};
		result[playlist.id] = playlist;
	}

	return result;
});

export const getUserPlaylistsByTrackId = createSelector([getFBPlaylists, currentSigninUser], (playlists, user) => {
	let result: { [trackId: string]: UserPlaylist[] } = {};
	for (let key in playlists) {
		let playlist = playlists[key];
		if (playlist.deleted === true || !user || playlist.userId !== user.uid) {
			continue;
		}

		playlist.tracks = playlist.tracks || {};
		for (let articleId in playlist.tracks) {
			result[articleId] = result[articleId] ?? [];
			result[articleId].push(playlist);
		}
	}

	return result;
});
