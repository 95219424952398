import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { User } from 'firebase/auth';
import { getBuildVersion } from 'helpers';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { signout } from 'services/api/auth';
import { ApplicationState } from 'types';

const classes = {
	root: (theme: Theme) => ({
		paddingBottom: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	signoutbtn: (theme: Theme) => ({
		height: 42,
		width: '60%',
		alignSelf: 'center',
		marginTop: theme.spacing(4),
	}),
};

export interface SignOutProps {
	signout: () => Promise<void>;
	user?: User;
}

class Component extends React.Component<SignOutProps> {
	render() {
		let { user, signout } = this.props;

		return (
			<Box sx={classes.root}>
				{user ? (
					<Button
						sx={classes.signoutbtn}
						variant='contained'
						color='primary'
						onClick={async () => {
							await signout();
						}}
					>
						Sign Out
					</Button>
				) : null}
				<Typography align='center' variant='body2' style={{ marginTop: 32 }}>
					Current Version: {getBuildVersion()}
				</Typography>
			</Box>
		);
	}
}

function mapStateToProps({ userState }: ApplicationState) {
	let { userStore } = userState;

	return {
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		signout: async () => {
			await dispatch(signout({}, new Context()));
		},
	};
}

export const SignOut = connect(mapStateToProps, mapDispatchToProps)(Component);
