import { DocumentChange, DocumentData } from 'firebase/firestore';
import { Action } from 'redux';
import { HD } from 'types';

export type FBChanges = DocumentChange<DocumentData>[];

export const UPDATE_HD = '@@hd/UPDATE_HD';
export type T_UPDATE_HD = typeof UPDATE_HD;

export interface UpdateHD extends Action {
	type: T_UPDATE_HD;
	changes: FBChanges;
	overwrite?: boolean;
}

export function updateHD(changes: FBChanges, overwrite?: boolean): UpdateHD {
	return {
		type: UPDATE_HD,
		changes,
		overwrite,
	};
}

export const UPDATE_HD_FILTERED = UPDATE_HD + '_FILTERED';
export type T_UPDATE_HD_FILTERED = typeof UPDATE_HD_FILTERED;

export interface UpdateHDFiltered extends Action {
	type: T_UPDATE_HD_FILTERED;
	payload: HD[];
}
