import { DialogContext, Screen } from 'components/pages/DialogPage';
import { timeout } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

export interface ScreenManagerProps {
	setFamilyState: (state: any) => any;
	familyState: any;
	pushScreen: (key: string, props?: any) => void;
	replaceScreen: (key: string, props?: any) => void;
	popScreen: () => void;
	purgePrevScreens: () => void;
	setDisablePop: (value: boolean) => void;
	close: () => void;
	query?: string;
	path?: string[];
}

interface Props {
	initScreen?: string;
	query?: string;
	path?: string[];
	getScreenTitle: (screenKey: string) => string;
	handlePushScreen?: (screenKey: string) => Screen | null;
	getComponentForScreen: (screenKey: string) => any;
	isBgDark?: (screenKey: string) => boolean;
}

class ScreenManager extends React.Component<Props | any, any> {
	context!: React.ContextType<typeof DialogContext>;

	private pushScreen = (key: string, props: any = {}) => {
		if (this.props.handlePushScreen) {
			let screen = this.props.handlePushScreen(key);
			if (screen) {
				this.context.pushScreen(screen);
				return;
			}
		}

		this.context.pushScreen({
			title: this.props.getScreenTitle(key),
			key,
			dark: this.props.isBgDark ? this.props.isBgDark(key) : true,
			props,
		});
	};

	private replaceScreen = (key: string, props: any = {}) => {
		if (this.props.handlePushScreen) {
			let screen = this.props.handlePushScreen(key);
			if (screen) {
				this.context.replaceScreen(screen);
				return;
			}
		}

		this.context.replaceScreen({
			title: this.props.getScreenTitle(key),
			key,
			dark: this.props.isBgDark ? this.props.isBgDark(key) : true,
			props,
		});
	};

	componentDidMount = async () => {
		if (this.props.initScreen) {
			this.pushScreen(this.props.initScreen);

			await timeout(1);
			if (this.props.rootOnInit) {
				this.context.purgePrevScreens();
			}
		}
	};

	render() {
		let {
			getComponentForScreen,
			initScreen,
			query,
			path,
			getScreenTitle,
			handlePushScreen,
			isBgDark,
			...cmpProps
		} = this.props;

		let screen = this.context.screens[this.context.screens.length - 1];
		let Component = getComponentForScreen(screen.key);

		if (!Component) {
			return null;
		}

		return (
			<Component
				{...cmpProps}
				{...screen.props}
				query={query}
				path={path}
				pushScreen={this.pushScreen}
				replaceScreen={this.replaceScreen}
				popScreen={this.context.popScreen}
				purgePrevScreens={this.context.purgePrevScreens}
				setDisablePop={this.context.setDisablePop}
				familyState={this.state}
				setFamilyState={this.setState.bind(this)}
				close={this.context.close}
			/>
		);
	}
}

function mapStateToProps(state: ApplicationState, props: { initScreen?: string }) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {};
}

ScreenManager.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(ScreenManager);
