import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { Theme } from '@mui/material/styles';
import App from 'AppInterface/App';
import { EndContent } from 'components/EndContent';
import { ReferencedComponent } from 'components/ReferencedComponent';
import { ReferencedComponentTitle } from 'components/ReferencedComponentTitle';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import TextContent from 'components/TextContent';
import { AppName, SHARE_ORIGIN } from 'config';
import { Icons } from 'config/icons';
import { getSessionId, isAdmin } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { updateOperation } from 'store/data/ops/actions';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ackNotification } from 'store/ux/actions';
import { ApplicationState, Notification } from 'types';

const classes = {
	root: (theme: Theme) => ({
		margin: theme.spacing(1),
	}),
	header: (theme: Theme) => ({
		padding: '16px 0',
		margin: theme.spacing(0, 1, 0, 2),

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			// whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	next: {
		marginLeft: 'auto !important',
	},
	expand: (theme: Theme) => ({
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}),
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	content: (theme: Theme) => ({
		padding: theme.spacing(0, 2, 1),

		'&:last-child': {
			padding: theme.spacing(0, 2, 1),
		},
	}),
};

function mapStateToProps(state: ApplicationState, props: { id?: string }) {
	let articles = state.dataState.articles.byId || {};
	let notifications = state.filteredDataState.notifications;

	let notification: Notification | undefined;
	let admin = isAdmin(state.userState.userStore.user, state.userState.userStore.userData);
	let showAll =
		admin &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	if (admin && props.id) {
		notification = state.dataState.notifications.byId[props.id];

		let isPublished = notification.publishTime.seconds <= new Date().getTime() / 1000;
		let isExpired = notification.expireTime.seconds <= new Date().getTime() / 1000;

		if (!showAll && (!isPublished || isExpired)) {
			notification = undefined;
		}
	}

	return {
		articles,
		notifications,
		notification,
		locale: state.uxState.locale,
		acks: state.uxState.acknowledge,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		ackNotification: (id: string) => {
			dispatch(ackNotification(id));
		},
		didVisitNotificationsPage: () => {
			let now = new Date();
			dispatch(updateOperation({ key: 'notifications', value: getSessionId(), createdAt: now, updatedAt: now }));
		},
	};
}

let connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface NotificationsProps extends PropsFromRedux {}

class Component extends React.Component<NotificationsProps, any> {
	constructor(props) {
		super(props);

		this.state = {
			expanded: {},
		};
	}
	private optionsObj = (noticeTitle) => {
		return {
			options: [
				{
					title: 'Share',
					icon: Icons.Share,
					onClick: () => {
						try {
							App.shareLink(
								noticeTitle + ' - Important Notice, ' + AppName + ': ',
								SHARE_ORIGIN + '/notices',
								'Notice',
								'main'
							);
						} catch (error) {
							(App as any).shareLink(
								noticeTitle + ' - Important Notice, ' + AppName + ': ',
								SHARE_ORIGIN + '/notices'
							);
						}
						this.props.toggleDrawer();
					},
				},
			],
			actionHandler: () => {
				this.props.toggleDrawer();
			},
		};
	};

	private optionsList = (noticeTitle) => BottomDrawerOptions(this.optionsObj(noticeTitle));

	private onMoreIconClick = (noticeTitle) => (e: React.MouseEvent) => {
		e.stopPropagation();

		this.props.toggleDrawer(this.optionsList(noticeTitle));
	};

	private notificationTile = (notification: Notification, acked) => {
		let { locale, ackNotification, acks } = this.props;

		let content;
		let noticeTitle = notification.title[locale] || notification.title.hi || notification.title.en || '';
		let textLocale =
			notification.text[locale] || notification.title[locale]
				? locale
				: notification.text.hi || notification.title.hi
				? 'hi'
				: 'en';
		let title = <span className={'locale-' + textLocale}>{noticeTitle}</span>;

		if (notification.content) {
			title =
				noticeTitle && noticeTitle.length ? (
					title
				) : (
					<ReferencedComponentTitle
						collectionType={notification.content.collectionType}
						refId={notification.content.refId}
						ref={(ref) => {
							noticeTitle = ref?.title ?? noticeTitle;
						}}
					/>
				);
			content = (
				<ReferencedComponent
					collectionType={notification.content.collectionType}
					refId={notification.content.refId}
				/>
			);
		} else if (notification.title && notification.text) {
			let text = notification.text[locale] || notification.text.hi || notification.text.en || '';
			content = (
				<TextContent alignRhymes={true} locale={textLocale}>
					<span dangerouslySetInnerHTML={{ __html: text }} />
				</TextContent>
			);
		}
		let expanded = this.state.expanded[notification.id] ?? !acked;

		const expandAction = () => {
			this.setState({
				expanded: {
					...this.state.expanded,
					[notification.id]: !(this.state.expanded[notification.id] ?? !acked),
				},
			});
		};

		return (
			<Card key={notification.id} sx={classes.root} elevation={2}>
				<CardHeader
					sx={classes.header}
					onClick={expandAction}
					avatar={
						<Avatar sx={classes.avatar}>{acked ? Icons.Notification : Icons.NotificationsActive}</Avatar>
					}
					action={
						<IconButton onClick={(e) => this.onMoreIconClick(noticeTitle)(e)}>{Icons.MoreVert}</IconButton>
					}
					title={<Typography>{title}</Typography>}
					subheader={
						new AnandDate(notification.creationTime ?? notification.publishTime).format('Do MMM, YYYY') +
						(acks[notification.id] && !acked ? ' [Updated]' : '')
					}
				/>
				<Collapse in={expanded} timeout='auto' unmountOnExit>
					<CardContent
						sx={classes.content}
						style={notification.content?.collectionType === 'rhymes' ? { padding: 0 } : {}}
					>
						{content}
						<EndContent
							locale={textLocale}
							isb={
								notification.content?.collectionType === 'articles' &&
								notification.type === 'ShriAmarVaani'
							}
						/>
					</CardContent>
				</Collapse>
				{acked ? null : (
					<CardActions disableSpacing>
						<Button
							color='primary'
							size='small'
							onClick={() => {
								ackNotification(notification.id);
							}}
							style={{ marginLeft: 'auto' }}
						>
							Acknowledge
						</Button>

						{/* <IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={expandAction}>
						{Icons.ExpandMore}
					</IconButton> */}
					</CardActions>
				)}
			</Card>
		);
	};

	componentDidMount() {
		this.props.didVisitNotificationsPage();
	}

	render() {
		let { acks, notifications, notification } = this.props;

		notifications = notification ? [notification] : notifications || [];
		if (notifications.length === 0) {
			return (
				<Card style={{ margin: 8 }}>
					<CardHeader subheader='No active notices available!' />
				</Card>
			);
		}

		notifications = notifications.sort((a, b) => b.order - a.order);

		let acked = notifications.filter((notice) => acks[notice.id] && acks[notice.id] >= notice.updatedAt.seconds);
		let active = notifications.filter((notice) => !acks[notice.id] || acks[notice.id] < notice.updatedAt.seconds);

		return (
			<div style={{ paddingTop: 8, paddingBottom: 8 }}>
				{active.map((notification) => this.notificationTile(notification, false))}
				{active.length && acked.length ? <Divider /> : null}
				{acked.map((notification) => this.notificationTile(notification, true))}
			</div>
		);
	}
}

export const NotificationsControl = connector(Component);
