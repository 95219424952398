import { User } from '@firebase/auth';
import { Card, CardHeader } from '@mui/material';
import { SSNRequest } from 'components/sandesh/SSNRequest';
import { SSNTitle } from 'config';
import { getWebAppAssetPath, isSSNRequestEnabled } from 'helpers';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState, UserData } from 'types';
import DialogPage from './DialogPage';

function SSNRequestPage(props: { user?: User; userdata?: UserData; SSNRequestEnabled: boolean }) {
	let { id } = useParams();

	return (
		<DialogPage
			title={SSNTitle}
			dark
			style={{
				backgroundImage: `url(${getWebAppAssetPath('sandeshBg2.png')})`,
				backgroundSize: 'contain',
			}}
		>
			{props.SSNRequestEnabled && props.user && props.userdata?.refUserIds?.length ? (
				<SSNRequest id={id} />
			) : (
				<Card style={{ margin: 8 }}>
					<CardHeader subheader="You do not have required permissions to access this page!" />
				</Card>
			)}
		</DialogPage>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
		userdata: state.userState.userStore.userData,
		SSNRequestEnabled: isSSNRequestEnabled(state),
	};
}

export default connect(mapStateToProps)(SSNRequestPage);
