import { Box, Divider, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DialogContext } from 'components/pages/DialogPage';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { IsMinimalRhymeSite } from 'config';
import { Icons } from 'config/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { getWebAppAssetPath, isApple } from 'helpers';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { signInWithGoogleOrApple, login, sendResetPasswordEmail, signupWithEmailLink } from 'services/api/auth';
import { EventType, onEvent } from 'store/temp/actions';
import { ApplicationState } from 'types';
import * as Yup from 'yup';
import { AccountMode } from './AccountScreenManager';

const classes = {
	root: (theme: Theme) => ({
		padding: `6vh ${theme.spacing(2)} ${theme.spacing(1)}`,
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
		marginBottom: '-24px',
		height: '100%',
	}),
	gsignin: (theme: Theme) => ({
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		border: '1px solid ' + theme.palette.primary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	}),
	asignin: (theme: Theme) => ({
		marginTop: theme.spacing(-3),
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',

		'& .a-logo': {
			width: '100%',
			minWidth: 140,
			minHeight: 30,
		},
	}),
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -2,
		alignSelf: 'center',
		fontSize: '2rem',
	},
	message: (theme: Theme) => ({
		marginBottom: theme.spacing(8),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& .MuiTextField-root': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
	newuser: {
		alignSelf: 'center',
		marginTop: 1,
	},
};

export interface SignInProps extends ScreenManagerProps {
	signin: (email: string, password: string) => Promise<void>;
	signInWithGoogleOrApple: (isApple?: boolean) => Promise<void>;
	signupWithEmailLink: (email: string) => Promise<void>;
	sendResetPasswordEmail: (email: string) => Promise<void>;
	showMessage: (...payload) => void;
	appBuildCode: number;
	appEnv: string;
}

enum SignInMode {
	SignIn,
	SignUp,
	Reset,
}

class SignIn extends React.Component<SignInProps, any> {
	context!: React.ContextType<typeof DialogContext>;

	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
			mode: SignInMode.SignIn,
		};
	}

	render() {
		let { appEnv, appBuildCode, signin, signInWithGoogleOrApple, signupWithEmailLink, sendResetPasswordEmail, showMessage, replaceScreen, pushScreen } =
			this.props;
		let { showPassword, mode } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		let showAppleSignin = isApple() && appBuildCode >= 49;

		return (
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={() =>
					Yup.object({
						email: Yup.string().email('Invalid email').required('Required'),
						password: mode === SignInMode.SignIn ? Yup.string().required('Required') : Yup.string(),
					})
				}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						if (mode === SignInMode.SignIn) {
							await signin(values.email, values.password);
							this.context.popScreen();
							return;
						}
						if (mode === SignInMode.SignUp) {
							await signupWithEmailLink(values.email);
							this.context.popScreen();
							showMessage(new Context(), EventType.Information, 'signup', {
								success: true,
								message: 'SignUp email sent to ' + values.email,
							});
							return;
						}
						if (mode === SignInMode.Reset) {
							await sendResetPasswordEmail(values.email);
							this.context.popScreen();
							showMessage(new Context(), EventType.Information, 'password', {
								success: true,
								message: 'Password reset email sent to ' + values.email,
							});
							return;
						}
					} catch (error) {
						if (error.code === 'auth/wrong-password') {
							showMessage(new Context(), EventType.Information, 'signin', {
								success: false,
								message: 'Wrong Credentials!',
							});
						} else if (error.code === 'auth/too-many-requests') {
							showMessage(new Context(), EventType.Information, 'signin', {
								success: false,
								message: 'Too many requests! Please try after some time!',
							});
						} else if (error.code === 'auth/user-not-found') {
							showMessage(new Context(), EventType.Information, 'signin', {
								success: false,
								message: 'Wrong Credentials!',
							});
						}
					}
					setSubmitting(false);
				}}
			>
				{({ submitForm, isSubmitting, setSubmitting, values }) => (
					<>
						{isSubmitting ? <LinearProgress /> : null}
						<Box sx={classes.root}>
							<Button
								sx={classes.gsignin}
								disabled={isSubmitting}
								onClick={async () => {
									try {
										setSubmitting(true);
										await signInWithGoogleOrApple();
										setSubmitting(false);

										this.context.popScreen();
									} catch (e) {
										setSubmitting(false);
									}
								}}
								variant="contained"
								color="primary"
							>
								<img src={getWebAppAssetPath('g-logo.png')} className="g-logo" alt="Google Logo" />
								<span className="g-msg">Connect with Google</span>
							</Button>

							{showAppleSignin && (
								<Button
									sx={classes.asignin}
									disabled={isSubmitting}
									onClick={async () => {
										try {
											setSubmitting(true);
											await signInWithGoogleOrApple(true);
											setSubmitting(false);

											this.context.popScreen();
										} catch (e) {
											setSubmitting(false);
										}
									}}
								>
									<img src={getWebAppAssetPath('sign-in-with-apple.png')} className="a-logo" />
								</Button>
							)}

							<Divider />
							<Box sx={classes.or}>or</Box>
							<Box component={Form} sx={classes.form} translate="yes">
								<Field
									component={TextField}
									name="email"
									type="email"
									label="Email"
									autoComplete="off"
									autoCapitalize="off"
									autoCorrect="off"
									spellCheck="false"
									value={values.email}
								/>
								{mode === SignInMode.SignIn ? (
									<Field
										component={TextField}
										type={showPassword ? 'text' : 'password'}
										label="Password"
										name="password"
										autoComplete="off"
										autoCapitalize="off"
										autoCorrect="off"
										spellCheck="false"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														disabled={isSubmitting}
														tabIndex={-1}
														size="large"
													>
														{showPassword ? Icons.Visibility : Icons.VisibilityOff}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								) : null}
								<Button sx={classes.signinbtn} variant="contained" color="primary" type="submit" onClick={submitForm} disabled={isSubmitting}>
									{mode === SignInMode.SignIn ? 'Sign In' : mode === SignInMode.SignUp ? 'Sign Up' : 'Send Reset Link'}
								</Button>
								{!IsMinimalRhymeSite && (
									<>
										{mode === SignInMode.SignIn ? (
											<Button
												sx={classes.signinbtn}
												variant="outlined"
												color="primary"
												disabled={isSubmitting}
												onClick={() => {
													if (mode === SignInMode.SignIn) {
														this.setState({ mode: SignInMode.Reset });
													} else {
														this.setState({ mode: SignInMode.SignIn });
													}
												}}
											>
												{mode === SignInMode.SignIn ? 'Forgot Password' : 'Sign In'}
											</Button>
										) : null}

										<Box sx={classes.newuser}>
											{mode === SignInMode.SignIn ? 'New User?' : mode === SignInMode.SignUp ? 'Existing User?' : 'Remember Password?'}
											<Button
												color="primary"
												disabled={isSubmitting}
												onClick={() => {
													if (mode === SignInMode.SignIn) {
														this.setState({ mode: SignInMode.SignUp });
													} else {
														this.setState({ mode: SignInMode.SignIn });
													}
												}}
											>
												{mode === SignInMode.SignIn ? 'Sign Up' : 'Sign In'}
											</Button>
										</Box>
									</>
								)}
							</Box>
							<div
								style={{
									marginTop: `max(calc(100vh - 6vh - ${mode === SignInMode.SignIn ? 470 : 340}px - 200px - ${
										showAppleSignin ? 64 : 0
									}px), 8px)`,
									textAlign: 'center',
								}}
							>
								<Box sx={classes.newuser}>
									Phone User?
									<Button
										color="primary"
										disabled={isSubmitting}
										onClick={() => {
											replaceScreen(AccountMode.PhoneSignIn);
										}}
									>
										Sign In using Phone
									</Button>
								</Box>

								{!IsMinimalRhymeSite && (
									<Typography variant="h6">
										By signing-in, you agree to our <br />
										<Button
											color="primary"
											disabled={isSubmitting}
											onClick={() => {
												pushScreen(AccountMode.Policy);
											}}
										>
											Terms and Privacy Policy
										</Button>
									</Typography>
								)}
							</div>
						</Box>
					</>
				)}
			</Formik>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		appBuildCode: state.uxState.appParams.appBuildCode,
		appEnv: state.uxState.appParams.appEnv,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		signin: async (email: string, password: string) => {
			await dispatch(login({ email, password }));
		},
		signInWithGoogleOrApple: async (isApple?: boolean) => {
			await dispatch(signInWithGoogleOrApple(isApple));
		},
		signupWithEmailLink: async (email: string) => {
			await dispatch(signupWithEmailLink({ email }));
		},
		sendResetPasswordEmail: async (email: string) => {
			await dispatch(sendResetPasswordEmail({ email }));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

SignIn.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
