import { LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleAlertDialog } from 'store/temp/actions';
import { ApplicationState } from 'types';

export interface AlertDialogProps {
	open: boolean;
	title?: string;
	message: string;
	okAction?: {
		text?: string;
		color?: string;
		callback: () => Promise<void>;
	};
	cancelAction?: {
		text?: string;
		callback: () => void;
	};
	toggleDialog: () => void;
}

function AlertDialog(props: AlertDialogProps) {
	let { open = false, title, message, okAction, cancelAction, toggleDialog } = props;
	let [waiting, setWaiting] = useState(false);

	if (!open) {
		return <></>;
	}

	const handleClose = (action: boolean) => async () => {
		setWaiting(true);

		if (action) {
			await okAction?.callback();
		} else if (cancelAction?.callback) {
			cancelAction?.callback();
		}
		setWaiting(false);
		toggleDialog();
	};

	return (
		<Dialog open={open} onClose={handleClose(false)}>
			{title ? <DialogTitle>{title}</DialogTitle> : null}
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button disabled={waiting} onClick={handleClose(false)} color='inherit'>
					{cancelAction?.text || 'Cancel'}
				</Button>
				<Button disabled={waiting} onClick={handleClose(true)} color='inherit'>
					{okAction?.text || 'Ok'}
				</Button>
			</DialogActions>
			{waiting && <LinearProgress />}
		</Dialog>
	);
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { alertDialog } = tempState;

	return {
		open: alertDialog.open,
		title: alertDialog.content?.title,
		message: alertDialog.content?.message,
		okAction: alertDialog.content?.okAction,
		cancelAction: alertDialog.content?.cancelAction,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDialog: () => {
			dispatch(toggleAlertDialog());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
