import { BookletControl } from 'components/booklets/Booklet';
import { MovingImages } from 'components/MovingImages';
import { DBConfig, IntroImg1Src, IntroImg2Src, OrgName } from 'config';
import React from 'react';
import Page from './Page';

export default function IntroPage() {
	return (
		<Page title={OrgName} includeBottomNavigation>
			<MovingImages img1Src={IntroImg1Src} img2Src={IntroImg2Src} />
			<BookletControl configKey={DBConfig.IntroductionBooklet} />
		</Page>
	);
}
