import { combineReducers } from 'redux';
import { Booklet, BookletStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateBooklets, UpdateBookletsFiltered, UPDATE_BOOKLETS, UPDATE_BOOKLETS_FILTERED } from './actions';

export const bookletsByIdReducer = (state = {}, action: UpdateBooklets) => {
	switch (action.type) {
		case UPDATE_BOOKLETS:
			return updateData('Booklets', state, action.changes, action.overwrite);
		default:
			return state;
	}
};

export const filteredBookletsReducer = (state: Booklet[] = [], action: UpdateBookletsFiltered) => {
	switch (action.type) {
		case UPDATE_BOOKLETS_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const bookletsReducer = combineReducers<BookletStore>({
	byId: bookletsByIdReducer,
});
