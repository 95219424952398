import { OrderConfirmationScreen } from 'components/orders/placement/OrderConfirmation';
import { PlaceOrderScreen } from 'components/orders/placement/PlaceOrder';
import { SelectAddressScreen } from 'components/orders/placement/SelectAddress';
import { SubscriptionDetailsScreen } from 'components/orders/placement/SubscriptionDetails';
import SetAddress from 'components/profile/address/SetAddress';
import ScreenManager, { ScreenManagerProps } from 'components/shell/ScreenManager';
import React from 'react';
import { OrderDetailsScreen } from './OrderDetails';

export enum CartScreens {
	Main = 'main',
	Addresses = 'addresses',
	Address = 'address',
	Payment = 'payment',
	Complete = 'complete',
}

const ComponentsMap = {
	[CartScreens.Main]: SubscriptionDetailsScreen,
	[CartScreens.Addresses]: SelectAddressScreen,
	[CartScreens.Address]: SetAddress,
	[CartScreens.Payment]: PlaceOrderScreen,
	[CartScreens.Complete]: OrderConfirmationScreen,
};
const TitleMap = {
	[CartScreens.Main]: 'Subscrition Details',
	[CartScreens.Addresses]: 'Select Address',
	[CartScreens.Payment]: 'Payment',
	[CartScreens.Complete]: 'Order Status',
};

export default class CartScreen extends React.Component<ScreenManagerProps | any> {
	getComponentForScreen = (key: string) => {
		if (key === CartScreens.Main) {
			if (this.props.pubId) {
				return SubscriptionDetailsScreen;
			} else {
				return OrderDetailsScreen;
			}
		}

		return ComponentsMap[key];
	};

	getScreenTitle = (key: string) => {
		if (key === CartScreens.Main) {
			if (this.props.pubId) {
				return 'Subscrition Details';
			} else {
				return 'Order Details';
			}
		}

		return TitleMap[key];
	};

	render() {
		return (
			<ScreenManager
				pubId={this.props.pubId}
				getComponentForScreen={this.getComponentForScreen}
				getScreenTitle={this.getScreenTitle}
			/>
		);
	}
}
