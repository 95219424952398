import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import App from 'AppInterface/App';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { withRouter } from 'components/shell/WithRouter';
import { timeout } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { initiateOrder } from 'services/api/orders';
import { emptyCart, updateCartOrder } from 'store/data/cart/actions';
import { onEvent } from 'store/temp/actions';
import { ApplicationState } from 'types';
import { InitiateOrderData, Order, OrderItemType, OrderType, PaymentStatus } from 'types/orders';

const classes = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	btn: (theme) => ({
		height: 42,
		width: '70%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(4),
		},
	}),
	media: {
		width: '100%',
	},
	card: (theme) => ({
		margin: theme.spacing(1),

		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2) + 4,
		},
	}),
	header: {
		borderBottom: '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& p.MuiTypography-root': {
			fontSize: '1.8rem',
			whiteSpace: 'nowrap',
			fontWeight: 700,
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	desc: (theme) => ({ padding: theme.spacing(1) }),
	title: (theme) => ({
		textAlign: 'center' as 'center',
		fontSize: '2.4rem',
		padding: theme.spacing(4),
	}),
	progress: {
		width: '60%',
		marginLeft: '20%',
	},
	icon: {
		width: '40%',
		height: '40%',
		marginLeft: '30%',
		marginBottom: '-50%',
	},
};

interface Props extends ScreenManagerProps {
	orders: Order[];
	initiateOrder: (data: InitiateOrderData) => Promise<string>;
	emptyCart: () => void;
	pubId: string;
	showMessage: (...payload) => void;
	updateCartOrder: (orderId: string) => void;
	history: any;
}

interface State {
	orderId?: string;
}

class Component extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidUpdate() {
		let { orderId } = this.state;
		let order: Order | undefined;
		let orderList = this.props.orders.filter((o) => o.id === orderId);
		if (orderList && orderList.length) {
			order = orderList[0];
		}

		let status = order?.paymentDetails.status ?? PaymentStatus.Started;
		if (status === PaymentStatus.Completed) {
			this.props.emptyCart();
		}
	}

	componentDidMount = async () => {
		let { setDisablePop, initiateOrder, familyState, pubId, updateCartOrder } = this.props;

		// purgePrevScreens();
		setDisablePop(true);

		if (familyState.items || pubId) {
			let order = initiateOrder({
				type: pubId ? OrderType.Subscription : OrderType.OneTime,
				items: familyState.items ?? {
					[pubId]: {
						itemType: OrderItemType.Publication,
						itemId: pubId,
						quantity: familyState.quantity,
					},
				},
				shippingDetails: {
					addrId: familyState.addrId,
				},
				subscription: pubId
					? {
							period: parseInt(familyState.years),
							startDate: new Date(familyState.startYear + '-04-01').getTime(),
					  }
					: undefined,
			});

			let orderId = await order; //'9oVEJO0699eKAez2kh7h'; //
			console.log({ orderId });
			this.setState({ orderId });

			updateCartOrder(orderId);
			App.subscribeToTopic('order_' + orderId);
		}
	};

	render() {
		let { orders, setFamilyState, popScreen, setDisablePop, pubId, close, history } = this.props;
		let navigate = history.navigate;

		let { orderId } = this.state;
		let order: Order | undefined;
		let orderList = orders.filter((o) => o.id === orderId);
		if (orderList && orderList.length) {
			order = orderList[0];
			console.log(order);
		}

		let status = order?.paymentDetails.status ?? PaymentStatus.Started;
		status = status === PaymentStatus.NotStarted ? PaymentStatus.Started : status;

		let titleMap = {
			[PaymentStatus.Started]: 'Processing Payment',
			[PaymentStatus.Completed]: 'Payment Complete',
			[PaymentStatus.Failed]: 'Payment Failed',
		};
		let descMap = {
			[PaymentStatus.Started]: 'Please wait while payment is being processed!',
			[PaymentStatus.Completed]:
				'Thank you, your payment has been successful. A confirmation message has been sent to your mobile number and email address.',
			[PaymentStatus.Failed]: 'Payment has failed. Please check with your bank and try again.',
		};
		let iconMap = {
			[PaymentStatus.Started]: null, //<HourglassEmptyIcon className={classes.icon} />,
			[PaymentStatus.Completed]: <DoneIcon color='primary' sx={classes.icon} />,
			[PaymentStatus.Failed]: <ErrorOutlineIcon color='secondary' sx={classes.icon} />,
		};

		return (
			<Box sx={classes.root}>
				<Card sx={classes.card}>
					{/* <CardHeader
						className={classes.header}
						avatar={
							<Avatar className={classes.avatar} variant='circular'>
								{Icons.Done}
							</Avatar>
						}
						title={<Typography noWrap>Order Confirmed</Typography>}
					/> */}

					<CardContent>
						<span style={{ position: 'relative' }}>
							{iconMap[status]}

							<CircularProgress
								size={'60%'}
								variant={status !== PaymentStatus.Completed ? 'indeterminate' : 'determinate'}
								value={100}
								color='primary'
								sx={classes.progress}
								style={{ visibility: status === PaymentStatus.Failed ? 'hidden' : 'visible' }}
							/>
						</span>

						<Typography variant='body1' sx={classes.title}>
							{titleMap[status]}
						</Typography>
						{status === PaymentStatus.Completed && (
							<Typography variant='body1' sx={classes.desc}>
								<strong>Order ID #: {orderId}</strong>
							</Typography>
						)}
						<Typography variant='body1' sx={classes.desc}>
							{descMap[status]}
						</Typography>
					</CardContent>
				</Card>
				{status !== PaymentStatus.Started && (
					<Button
						sx={classes.btn}
						variant='contained'
						color='primary'
						onClick={async () => {
							if (status === PaymentStatus.Completed) {
								close();
								await timeout(500);
								if (pubId) {
									navigate(`/account/subscription/${order?.id}/${pubId}`, {
										state: { isModal: true },
									});
								} else {
									navigate(`/account/orders`, { state: { isModal: true } });
								}
							} else {
								setFamilyState({ paymentFailed: true, orderInitiated: false });
								setDisablePop(false);
								popScreen();
							}
						}}
					>
						{status === PaymentStatus.Completed
							? 'View Order Details'
							: status === PaymentStatus.Failed
							? 'Retry Payment'
							: ''}
					</Button>
				)}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		orders: state.filteredDataState.orders,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
		initiateOrder: async (data: InitiateOrderData) => {
			return await dispatch(initiateOrder(data));
		},
		emptyCart: () => {
			dispatch(emptyCart());
		},
		updateCartOrder: (orderId: string) => {
			dispatch(updateCartOrder(orderId));
		},
	};
}

export const OrderConfirmationScreen = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
