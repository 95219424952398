import { Action } from 'redux';
import { CartItem } from 'types';

export const ADD_TO_CART = '@@cart/ADD_TO_CART';
export type T_ADD_TO_CART = typeof ADD_TO_CART;

export interface AddToCart extends Action {
	type: T_ADD_TO_CART;
	item: CartItem;
}

export const addToCart = (item: CartItem): AddToCart => {
	return {
		type: ADD_TO_CART,
		item,
	};
};

export const REMOVE_FROM_CART = '@@cart/REMOVE_TO_CART';
export type T_REMOVE_FROM_CART = typeof REMOVE_FROM_CART;

export interface RemoveFromCart extends Action {
	type: T_REMOVE_FROM_CART;
	itemId: string;
}

export const removeFromCart = (itemId: string): RemoveFromCart => {
	return {
		type: REMOVE_FROM_CART,
		itemId,
	};
};

export const UPDATE_CART_ITEM = '@@cart/UPDATE_CART_ITEM';
export type T_UPDATE_CART_ITEM = typeof UPDATE_CART_ITEM;

export interface UpdateCartItem extends Action {
	type: T_UPDATE_CART_ITEM;
	item: CartItem;
}

export const updateCartItem = (item: CartItem): UpdateCartItem => {
	return {
		type: UPDATE_CART_ITEM,
		item,
	};
};

export const EMPTY_CART = '@@cart/EMPTY_CART';
export type T_EMPTY_CART = typeof EMPTY_CART;

export interface EmptyCart extends Action {
	type: T_EMPTY_CART;
}

export const emptyCart = (): EmptyCart => {
	return {
		type: EMPTY_CART,
	};
};

export const UPDATE_CART_ORDER = '@@cart/UPDATE_CART_ORDER';
export type T_UPDATE_CART_ORDER = typeof UPDATE_CART_ORDER;

export interface UpdateCartOrder extends Action {
	type: T_UPDATE_CART_ORDER;
	orderId: string;
}

export const updateCartOrder = (orderId: string): UpdateCartOrder => {
	return {
		type: UPDATE_CART_ORDER,
		orderId,
	};
};
