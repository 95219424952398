import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import { IsOwnerSAPD } from 'config';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import './MovingImages.css';

const classes = {
	root: (theme) => ({
		position: 'relative',
		width: `calc(100% - ${theme.spacing(4)})`,
		paddingBottom: `calc(${IsOwnerSAPD ? 76 : 57}% - ${theme.spacing(IsOwnerSAPD ? 4 : 3)})`,
		margin: theme.spacing(2),
		border: IsOwnerSAPD ? `3px solid ${theme.palette.primary.main}` : 'none',
		boxSizing: 'border-box',
		boxShadow: IsOwnerSAPD ? 'inherit' : 'none',

		'& > img': {
			position: 'absolute',
			left: 0,
			transition: 'opacity 1s ease-in-out',
			width: '100%',
			height: 'auto',
		},
	}),
	top: {
		animationName: 'MIFadeInOut',
		animationTimingFunction: 'ease-in-out',
		animationIterationCount: 'infinite',
		animationDuration: '6s',
		animationDirection: 'alternate',
	},
};

interface MovingImagesProps {
	img1Src: string;
	img2Src: string;
}

export const MovingImages = (props: MovingImagesProps) => {
	let img1Src = getWebAppAssetPath(props.img1Src);
	let img2Src = getWebAppAssetPath(props.img2Src);

	return (
		<Card sx={classes.root} elevation={3}>
			<img src={img1Src} alt="" />
			<Box component="img" sx={classes.top} src={img2Src} alt="" />
		</Card>
	);
};
