import { Avatar, Card, CardContent, CardHeader, IconButton, Skeleton, Typography } from '@mui/material';
import green from '@mui/material/colors/blueGrey';
import App from 'AppInterface/App';
import { Bull } from 'components/Bull';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { AppName, SHARE_ORIGIN } from 'config';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleRecordPin } from 'services/api/auth';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState, ContentType, PinnedRecord, Rhyme } from 'types';

const classes = {
	card: {
		margin: 1,
	},
	header: {
		'& div': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},

		'& .MuiCardHeader-avatar': {
			marginLeft: -2,
			marginTop: -2,
			marginBottom: -2,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize',
		paddingTop: '8px',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: {
		float: 'right',
	},
	avatar: {
		color: '#fff',
		backgroundColor: green[300],
	},
	imgavatar: {
		width: 86,
		height: 86,
		borderRadius: 0,
		transform: 'scale(1)',

		'& img': {
			width: '100%',
		},
	},
};

interface RhymeProps {
	record?: Rhyme;
	showExtras?: boolean;
	style?: any;
	pinnedRecords: { [id: string]: PinnedRecord };
	toggleDrawer: (content?: any) => void;
	toggleRecordPin: (recordId: string) => void;
}

const Component = (props: RhymeProps) => {
	let { record, showExtras, style, pinnedRecords, toggleDrawer, toggleRecordPin } = props;
	let navigate = useNavigate();

	let AvatarIcon = <img style={{ transform: 'scale(1.2) translateY(5px)' }} src={getContentStorageUrl(ContentType.PKP, 'webapp/scroll.jpeg')} />; // Icons.Description;
	let href = '/rhyme/' + record?.id;

	let optionsObj = {
		options: [
			{
				title: 'Share',
				icon: Icons.Share,
				onClick: () => {
					try {
						App.shareLink(record?.title + ' - Scroll of Wisdom, ' + AppName + ': ', SHARE_ORIGIN + href, 'Rhyme', record?.id);
					} catch (error) {
						(App as any).shareLink(record?.title + ' - Scroll of Wisdom, ' + AppName + ': ', SHARE_ORIGIN + href);
					}
					toggleDrawer();
				},
			},
			{
				title: pinnedRecords[record!.id] ? 'Unpin from Home' : 'Pin to Home',
				icon: pinnedRecords[record!.id] ? Icons.BookmarkRemove : Icons.BookmarkAdd,
				onClick: () => {
					toggleRecordPin(record!.id);
					toggleDrawer();
				},
			},
		],
		actionHandler: () => {
			props.toggleDrawer();
		},
	};

	let optionsList = BottomDrawerOptions(optionsObj);

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		props.toggleDrawer(optionsList);
	};

	return (
		<Card
			sx={classes.card}
			style={style}
			onClick={() => {
				navigate(href, {
					state: {
						isModal: true,
					},
				});
			}}
		>
			<CardHeader
				sx={classes.header}
				avatar={
					!record ? <Skeleton animation="wave" variant="rectangular" width={40} height={40} /> : <Avatar sx={classes.imgavatar}>{AvatarIcon}</Avatar>
				}
				action={!record ? null : <IconButton onClick={onMoreIconClick}>{Icons.MoreVert}</IconButton>}
				title={
					!record ? (
						<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
					) : (
						<Typography noWrap className="locale-hi">
							{record.title}
						</Typography>
					)
				}
				subheader={
					!record ? (
						<Skeleton animation="wave" height={10} width="40%" />
					) : (
						<Typography variant="body2" color="textSecondary" noWrap className="locale-hi" component="div">
							{record.rhymeType}
							<Bull />

							<Typography variant="body2" color="textSecondary" component={'span'}>
								{showExtras ? (
									<span>
										<Bull />
										{record.author}
										<Bull />
										{record.book}
										<br />
										{record.chapter} <Bull /> {record.rhymeNo}
										<br />
									</span>
								) : null}
								{new AnandDate(record.creationTime ?? record.publishTime).format('Do MMM, YYYY')}
							</Typography>
						</Typography>
					)
				}
			/>

			{/* <CardContent sx={classes.content}>
				{!record ? (
					<>
						<Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
						<Skeleton animation="wave" height={10} />
					</>
				) : (
					<Typography variant="body2" color="textSecondary">
						{showExtras ? (
							<span>
								{record.author}
								<Bull />
								{record.book}
								<br />
								{record.chapter} <Bull /> {record.rhymeNo}
								<br />
							</span>
						) : null}
						{new AnandDate(record.creationTime ?? record.publishTime).format('Do MMM, YYYY')}
					</Typography>
				)}
			</CardContent> */}
		</Card>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	let pinnedRecords = state.userState.userStore.userData?.pinnedRecords ?? {};

	return {
		pinnedRecords,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		toggleRecordPin: (recordId: string) => {
			dispatch(toggleRecordPin(recordId, 'rhymespin'));
		},
	};
}

export const RhymeTile = connect(mapStateToProps, mapDispatchToProps)(Component);
