import { ConfigsById } from 'types';

export const ConfigDump: ConfigsById = {
	Snapshot: {
		deleted: false,
		timestamp: 1723561483560,
		publishTime: {
			seconds: 1723561492,
			nanoseconds: 307000000,
		},
		uri: 'Snapshot - 1723561493341.json',
		active: true,
		updatedAt: {
			seconds: 1723561492,
			nanoseconds: 307000000,
		},
		id: 'Snapshot',
	},
	WebSnapshot: {
		deleted: false,
		publishTime: {
			seconds: 1723561492,
			nanoseconds: 813000000,
		},
		active: true,
		timestamp: 1723561483560,
		updatedAt: {
			seconds: 1723561492,
			nanoseconds: 813000000,
		},
		uri: 'WebSnapshot - 1723561491620.json',
		id: 'WebSnapshot',
	},

	LatestBuildVersion: {
		active: true,
		value: 438,
		deleted: false,
		publishTime: {
			seconds: 1722792110,
			nanoseconds: 588000000,
		},
		updatedAt: {
			seconds: 1722792110,
			nanoseconds: 588000000,
		},
		id: 'LatestBuildVersion',
	},
	LatestBuildVersionv1: {
		publishTime: {
			seconds: 1722791279,
			nanoseconds: 960000000,
		},
		value: 441,
		updatedAt: {
			seconds: 1722791279,
			nanoseconds: 960000000,
		},
		active: true,
		deleted: false,
		id: 'LatestBuildVersionv1',
	},
	LatestBuildVersionSPD: {
		active: true,
		publishTime: {
			seconds: 1721411245,
			nanoseconds: 622000000,
		},
		value: 8,
		updatedAt: {
			seconds: 1721411245,
			nanoseconds: 622000000,
		},
		deleted: false,
		id: 'LatestBuildVersionSPD',
	},
	Announcement: {
		active: false,
		createdAt: {
			seconds: 1699364653,
			nanoseconds: 242000000,
		},
		updatedAt: {
			seconds: 1721307605,
			nanoseconds: 210000000,
		},
		publishTime: {
			seconds: 1699364680,
			nanoseconds: 50000000,
		},
		deleted: false,
		value: {
			title: {
				hi: 'नई सुविधा <strong>Pin to Home!</strong>',
				en: 'Introducing <strong>Pin to Home!</strong>',
			},
			tryUrl: '',
			version: 5,
			message: {
				en: "Congratulations! We have launched a new feature <strong>Pin to Home!</strong> You can now add your favourite content to the Home page. <br /> <br /> For this, click on three-dots of a tile i.e., <i>Bhajan</i>, <i>Satsang</i>, <i>Playlist</i>, <i>Publications</i>, <i>Scroll of Wisdom</i> or <i>Shri Amar Vani</i>. <br /> <br /> Later, if required, you can remove it from the Home page by using 'Unpin from Home'.",
				hi: "बधाई हो! हमने एक नई सुविधा <strong>Pin to Home</strong> का शुभारम्भ किया है। अब आप अपनी पसंदीदा सामग्री को Home Page में add कर सकते हैं।<br /> <br /> इसके लिये <i>भजन</i>, <i>सत्संग</i>, <i>Playlist</i>, <i>Publications</i>, <i>Scroll of Wisdom</i> या <i>श्री अमर वाणी</i> के three-dots पर click करें। <br /> <br /> बाद में, यदि आवश्यक हो, आप इसे 'Unpin from Home' का उपयोग करके Home Page से हटा भी सकते हैं। <br /> <br /> सभी के लिये शुभकामनायें।",
			},
		},
		id: 'Announcement',
	},
	SSN: {
		by: '16',
		deleted: false,
		publishTime: {
			seconds: 1664716920,
			nanoseconds: 923000000,
		},
		active: true,
		createdAt: {
			seconds: 1664716882,
			nanoseconds: 206000000,
		},
		updatedAt: {
			seconds: 1709986610,
			nanoseconds: 283000000,
		},
		value: {
			disableAt: {
				seconds: 1746026571,
				nanoseconds: 927000000,
			},
			enableAt: {
				nanoseconds: 431000000,
				seconds: 1696310556,
			},
			maxAllowedPeople: 80,
			minAllowedDate: {
				seconds: 1709490900,
				nanoseconds: 0,
			},
			requestEnabled: false,
			maxAllowedDate: {
				seconds: 1710095100,
				nanoseconds: 0,
			},
		},
		id: 'SSN',
	},
	AutoDownload: {
		publishTime: {
			seconds: 1599417000,
			nanoseconds: 0,
		},
		value: [
			'DKPiv1j10dStc3mx0xFO',
			'c3qWKDBuyrgoanYfWcE2',
			'YSfmZYXFwN9OBCjkxmpE',
			'PO2gU8SZbbq40mEKXDdN',
			'UDd3MOAAftvjTNaldx0b',
			'zttqoZ8uXivTFmv7WjR5',
			'JSAbPtmmAoMIZrMiEYKL',
			'v8X3COp68cPdPWQs0DjI',
			'POwDvI5aY7qAPNjORfIG',
			'hKxuvxDxigC7RDJ0KvRi',
		],
		deleted: false,
		updatedAt: {
			seconds: 1704113340,
			nanoseconds: 237000000,
		},
		active: true,
		createdAt: {
			seconds: 1599417000,
			nanoseconds: 0,
		},
		id: 'AutoDownload',
	},
	Calendar2024: {
		active: true,
		deleted: false,
		createdAt: {
			seconds: 1700850600,
			nanoseconds: 96000000,
		},
		publishTime: {
			seconds: 1700850600,
			nanoseconds: 422000000,
		},
		updatedAt: {
			seconds: 1700985815,
			nanoseconds: 657000000,
		},
		value: {
			hi: {
				'1': 'monthly/2024/JANUARY.JPG',
				'2': 'monthly/2024/FEBRUARY.JPG',
				'3': 'monthly/2024/MARCH.JPG',
				'4': 'monthly/2024/APRIL.JPG',
				'5': 'monthly/2024/MAY.JPG',
				'6': 'monthly/2024/JUNE.JPG',
				'7': 'monthly/2024/JULY.JPG',
				'8': 'monthly/2024/AUGUST.JPG',
				'9': 'monthly/2024/SEPTEMBER.JPG',
				'10': 'monthly/2024/OCTOBER.JPG',
				'11': 'monthly/2024/NOVEMBER.JPG',
				'12': 'monthly/2024/DECEMBER.JPG',
				calendar: 'monthly/2024/calendar_2024.pdf',
			},
		},
		id: 'Calendar2024',
	},
	Calendars: {
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 258000000,
		},
		publishTime: {
			seconds: 1594571521,
			nanoseconds: 628000000,
		},
		value: ['2020', '2019', '2018', '2017', '2021', '2022', '2023', '2024'],
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1700855700,
			nanoseconds: 462000000,
		},
		active: true,
		deleted: false,
		id: 'Calendars',
	},
	Festivals2024: {
		createdAt: {
			seconds: 1700850600,
			nanoseconds: 784000000,
		},
		updatedAt: {
			seconds: 1700855604,
			nanoseconds: 341000000,
		},
		value: {
			hi: 'festivals/2024/festivals_2024.jpg',
			en: 'festivals/2024/festivals_2024_en.jpg',
		},
		deleted: false,
		active: true,
		publishTime: {
			seconds: 1700850600,
			nanoseconds: 583000000,
		},
		id: 'Festivals2024',
	},
	Sandesh: {
		publishTIme: {
			seconds: 1664723250,
			nanoseconds: 590000000,
		},
		value: {
			enableAt: {
				seconds: 1682917707,
				nanoseconds: 723000000,
			},
			messagingEnabled: false,
			disableAt: {
				seconds: 1758985722,
				nanoseconds: 574000000,
			},
		},
		active: true,
		deleted: false,
		updatedAt: {
			seconds: 1697281930,
			nanoseconds: 509000000,
		},
		createdAt: {
			seconds: 1664723269,
			nanoseconds: 690000000,
		},
		id: 'Sandesh',
	},
	Calendar2023: {
		createdAt: {
			seconds: 1672079683,
			nanoseconds: 929000000,
		},
		publishTime: {
			seconds: 1640995200,
			nanoseconds: 0,
		},
		value: {
			hi: {
				'1': 'monthly/2023/JANUARY.JPG',
				'2': 'monthly/2023/FEBRUARY.JPG',
				'3': 'monthly/2023/MARCH.JPG',
				'4': 'monthly/2023/APRIL.JPG',
				'5': 'monthly/2023/MAY.JPG',
				'6': 'monthly/2023/JUNE.JPG',
				'7': 'monthly/2023/JULY.JPG',
				'8': 'monthly/2023/AUGUST.JPG',
				'9': 'monthly/2023/SEPTEMBER.JPG',
				'10': 'monthly/2023/OCTOBER.JPG',
				'11': 'monthly/2023/NOVEMBER.JPG',
				'12': 'monthly/2023/DECEMBER.JPG',
				calendar: 'monthly/2023/Calendar_2023.pdf',
			},
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		updatedAt: {
			seconds: 1672079683,
			nanoseconds: 929000000,
		},
		id: 'Calendar2023',
	},
	Festivals2023: {
		value: {
			hi: 'festivals/2023/festivals_2023.jpg',
			en: 'festivals/2023/festivals_2023_en.jpg',
		},
		createdAt: {
			seconds: 1672079363,
			nanoseconds: 171000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1640995200,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1672079363,
			nanoseconds: 171000000,
		},
		active: true,
		deleted: false,
		id: 'Festivals2023',
	},
	Festivals2022: {
		deleted: false,
		value: {
			en: 'festivals/2022/festivals_2022_en.jpg',
			hi: 'festivals/2022/festivals_2022.jpg',
		},
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1640245372,
			nanoseconds: 952000000,
		},
		createdAt: {
			seconds: 1640245372,
			nanoseconds: 952000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		id: 'Festivals2022',
	},
	Calendar2022: {
		createdAt: {
			seconds: 1640245128,
			nanoseconds: 562000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		value: {
			hi: {
				'1': 'monthly/2022/JANUARY.jpg',
				'2': 'monthly/2022/FEBRUARY.jpg',
				'3': 'monthly/2022/MARCH.jpg',
				'4': 'monthly/2022/APRIL.jpg',
				'5': 'monthly/2022/MAY.jpg',
				'6': 'monthly/2022/JUNE.jpg',
				'7': 'monthly/2022/JULY.jpg',
				'8': 'monthly/2022/AUGUST.jpg',
				'9': 'monthly/2022/SEPTEMBER.jpg',
				'10': 'monthly/2022/OCTOBER.jpg',
				'11': 'monthly/2022/NOVEMBER.jpg',
				'12': 'monthly/2022/DECEMBER.jpg',
				calendar: 'monthly/2022/Calendar_2022.pdf',
			},
		},
		active: true,
		updatedAt: {
			seconds: 1640245128,
			nanoseconds: 562000000,
		},
		deleted: false,
		id: 'Calendar2022',
	},
	SPDIntroductionBooklet: {
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1610264340,
			nanoseconds: 278000000,
		},
		active: true,
		deleted: false,
		value: 'IRXPRWKyvzo69q0Qc6X7',
		createdAt: {
			seconds: 1610264340,
			nanoseconds: 278000000,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		id: 'SPDIntroductionBooklet',
	},
	SPDPrivacy: {
		createdAt: {
			seconds: 1610260654,
			nanoseconds: 784000000,
		},
		active: true,
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: 'AK2RtPRmvVaGRXt09ZNb',
		deleted: false,
		updatedAt: {
			seconds: 1610260654,
			nanoseconds: 784000000,
		},
		id: 'SPDPrivacy',
	},
	SPDTerms: {
		updatedAt: {
			seconds: 1610259578,
			nanoseconds: 373000000,
		},
		createdAt: {
			seconds: 1610259578,
			nanoseconds: 373000000,
		},
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: 'sXKddAzZ9yBdaZ0mNnh2',
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		active: true,
		id: 'SPDTerms',
	},
	SPDPostalContact: {
		deleted: false,
		active: true,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1610258391,
			nanoseconds: 100000000,
		},
		value: '<div><div><strong>Shri Prayagdham</strong></div><div>P.O. Shri Prayagdham</div><div>Dist. Pune - 412202</div><div>Maharashtra</div><div>India</div></div>',
		updatedAt: {
			seconds: 1610258391,
			nanoseconds: 100000000,
		},
		id: 'SPDPostalContact',
	},
	Festivals2018: {
		updatedAt: {
			seconds: 1609404368,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1514764800,
			nanoseconds: 0,
		},
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606566908,
			nanoseconds: 837000000,
		},
		value: {
			en: 'festivals/2018/festivals_2018_en.jpg',
			hi: 'festivals/2018/festivals_2018.jpg',
		},
		active: true,
		id: 'Festivals2018',
	},
	Festivals2017: {
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		active: true,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: {
			hi: 'festivals/2017/festivals.jpg',
			en: 'festivals/2017/festivals_en.jpg',
		},
		deleted: false,
		createdAt: {
			seconds: 1606566964,
			nanoseconds: 471000000,
		},
		updatedAt: {
			seconds: 1609404364,
			nanoseconds: 0,
		},
		id: 'Festivals2017',
	},
	Calendar2018: {
		value: {
			hi: {
				'1': 'monthly/2018/JANUARY_2018.jpg',
				'2': 'monthly/2018/FEBRUARY_2018.jpg',
				'3': 'monthly/2018/MARCH_2018.jpg',
				'4': 'monthly/2018/APRIL_2018.jpg',
				'5': 'monthly/2018/MAY_2018.jpg',
				'6': 'monthly/2018/JUNE_2018.jpg',
				'7': 'monthly/2018/JULY_2018.jpg',
				'8': 'monthly/2018/AUGUST_2018.jpg',
				'9': 'monthly/2018/SEPTEMBER_2018.jpg',
				'10': 'monthly/2018/OCTOBER_2018.jpg',
				'11': 'monthly/2018/NOVEMBER_2018.jpg',
				'12': 'monthly/2018/DECEMBER_2018.jpg',
				calendar: 'monthly/2018/Calendar_2018.pdf',
			},
		},
		active: true,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		deleted: false,
		createdAt: {
			seconds: 1606567402,
			nanoseconds: 588000000,
		},
		publishTime: {
			seconds: 1514764800,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1609404060,
			nanoseconds: 0,
		},
		id: 'Calendar2018',
	},
	Calendar2019: {
		updatedAt: {
			seconds: 1609404023,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1546300800,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606567343,
			nanoseconds: 436000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		value: {
			hi: {
				'1': 'monthly/2019/JANUARY_2019.jpg',
				'2': 'monthly/2019/FEBRUARY_2_2019.jpg',
				'3': 'monthly/2019/MARCH_2019.jpg',
				'4': 'monthly/2019/APRIL_2019.jpg',
				'5': 'monthly/2019/MAY_2019.jpg',
				'6': 'monthly/2019/JUNE_2019.jpg',
				'7': 'monthly/2019/JULY_2019.jpg',
				'8': 'monthly/2019/AUGUST_2019.jpg',
				'9': 'monthly/2019/SEPTEMBER_2019.jpg',
				'10': 'monthly/2019/OCTOBER_2019.jpg',
				'11': 'monthly/2019/NOVEMBER_2019.jpg',
				'12': 'monthly/2019/DECEMBER_2019.jpg',
				calendar: 'monthly/2019/Calendar_2019.pdf',
			},
		},
		id: 'Calendar2019',
	},
	Calendar2020: {
		publishTime: {
			seconds: 1594571520,
			nanoseconds: 855000000,
		},
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 31000000,
		},
		deleted: false,
		active: true,
		value: {
			hi: {
				'1': 'monthly/2020/JANUARY_2020.jpg',
				'2': 'monthly/2020/FEBRUARY_2020.jpg',
				'3': 'monthly/2020/MARCH_2020.jpg',
				'4': 'monthly/2020/APRIL_2020.jpg',
				'5': 'monthly/2020/MAY_2020.jpg',
				'6': 'monthly/2020/JUNE_2020.jpg',
				'7': 'monthly/2020/JULY_2020.jpg',
				'8': 'monthly/2020/AUGUST_2020.jpg',
				'9': 'monthly/2020/SEPTEMBER_2020.jpg',
				'10': 'monthly/2020/OCTOBER_2020.jpg',
				'11': 'monthly/2020/NOVEMBER_2020.jpg',
				'12': 'monthly/2020/DECEMBER_2020.jpg',
				calendar: 'monthly/2020/Calendar_2020.pdf',
			},
		},
		updatedAt: {
			seconds: 1609404002,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		id: 'Calendar2020',
	},
	Calendar2021: {
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		active: true,
		updatedAt: {
			seconds: 1609403400,
			nanoseconds: 0,
		},
		value: {
			hi: {
				'1': 'monthly/2021/JANUARY.jpg',
				'2': 'monthly/2021/FEBRUARY.jpg',
				'3': 'monthly/2021/MARCH.jpg',
				'4': 'monthly/2021/APRIL.jpg',
				'5': 'monthly/2021/MAY.jpg',
				'6': 'monthly/2021/JUNE.jpg',
				'7': 'monthly/2021/JULY.jpg',
				'8': 'monthly/2021/AUGUST.jpg',
				'9': 'monthly/2021/SEPTEMBER.jpg',
				'10': 'monthly/2021/OCTOBER.jpg',
				'11': 'monthly/2021/NOVEMBER.jpg',
				'12': 'monthly/2021/DECEMBER.jpg',
				calendar: 'monthly/2021/Calendar_2021.pdf',
			},
		},
		createdAt: {
			seconds: 1609395085,
			nanoseconds: 551000000,
		},
		deleted: false,
		id: 'Calendar2021',
	},
	Calendar2017: {
		value: {
			hi: {
				'1': 'monthly/2017/JANUARY.jpg',
				'2': 'monthly/2017/FEBRUARY.jpg',
				'3': 'monthly/2017/MARCH.jpg',
				'4': 'monthly/2017/APRIL.jpg',
				'5': 'monthly/2017/MAY.jpg',
				'6': 'monthly/2017/JUNE.jpg',
				'7': 'monthly/2017/JULY.jpg',
				'8': 'monthly/2017/AUGUST.jpg',
				'9': 'monthly/2017/SEPTEMBER.jpg',
				'10': 'monthly/2017/OCTOBER.jpg',
				'11': 'monthly/2017/NOVEMBER.jpg',
				'12': 'monthly/2017/DECEMBER.jpg',
				calendar: 'monthly/2017/Calendar_2017.pdf',
			},
		},
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1609400520,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606567470,
			nanoseconds: 252000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		deleted: false,
		active: true,
		id: 'Calendar2017',
	},
	Festivals2021: {
		active: true,
		value: {
			en: 'festivals/2021/festivals_2021_en.jpg',
			hi: 'festivals/2021/festivals_2021.jpg',
		},
		createdAt: {
			seconds: 1609394580,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1609394640,
			nanoseconds: 0,
		},
		deleted: false,
		publishTime: {
			seconds: 1609353000,
			nanoseconds: 0,
		},
		id: 'Festivals2021',
	},
	PostalContact: {
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		deleted: false,
		active: true,
		updatedAt: {
			seconds: 1608981900,
			nanoseconds: 0,
		},
		value: '<div><div><strong>Shri Anandpur Dham</strong></div><div>P.O. Shri Anandpur</div><div>Dist. Ashok Nagar - 473331 </div><div>Madhya Pradesh</div><div>India</div></div>',
		publishTime: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		id: 'PostalContact',
	},
	Privacy: {
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		active: true,
		publishTime: {
			seconds: 1529951400,
			nanoseconds: 0,
		},
		value: 'z937A6UsBEdDeHqEQjD2',
		deleted: false,
		updatedAt: {
			seconds: 1606740600,
			nanoseconds: 0,
		},
		id: 'Privacy',
	},
	Terms: {
		value: 'lR9GQKDaDMJ2hhztpij3',
		active: true,
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		deleted: false,
		publishTime: {
			seconds: 1529951400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1606650120,
			nanoseconds: 0,
		},
		id: 'Terms',
	},
	Festivals2019: {
		publishTime: {
			seconds: 1546300800,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1606566746,
			nanoseconds: 908000000,
		},
		active: true,
		value: {
			en: 'festivals/2019/festivals_2_2019_en.jpg',
			hi: 'festivals/2019/festivals_2_2019.jpg',
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606566746,
			nanoseconds: 908000000,
		},
		deleted: false,
		id: 'Festivals2019',
	},
	Festivals2020: {
		deleted: false,
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 166000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: {
			en: 'festivals/2020/festivals_2020_en.jpg',
			hi: 'festivals/2020/festivals_2020.jpg',
		},
		updatedAt: {
			seconds: 1598986862,
			nanoseconds: 0,
		},
		active: true,
		publishTime: {
			seconds: 1594571521,
			nanoseconds: 524000000,
		},
		id: 'Festivals2020',
	},
	IntroductionBooklet: {
		deleted: false,
		value: '9WRnYemFk5L8LHwpmCOU',
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1594566752,
			nanoseconds: 68000000,
		},
		createdAt: {
			seconds: 1594566752,
			nanoseconds: 68000000,
		},
		active: true,
		publishTime: {
			seconds: 1594566750,
			nanoseconds: 733000000,
		},
		id: 'IntroductionBooklet',
	},
} as unknown as ConfigsById;
