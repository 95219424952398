import { Button, Card, CardHeader } from '@mui/material';
import { SandeshList } from 'components/sandesh/SandeshList';
import { withRouter } from 'components/shell/WithRouter';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import Page from './Page';

function SandeshListPage(props) {
	return (
		<Page
			title="Sandesh"
			includeBottomNavigation
			fullscreen
			dark
			style={{
				backgroundImage: `url(${getWebAppAssetPath('sandeshBg2.png')})`,
				backgroundSize: 'contain',
			}}
		>
			{props.user ? (
				<SandeshList />
			) : (
				<Card style={{ margin: 8 }}>
					<CardHeader
						subheader="Please signin to view your Sandesh"
						action={
							<Button
								style={{ marginTop: 8 }}
								size="small"
								onClick={() => {
									props.navigate('/account?screen=signin', { state: { isModal: true } });
								}}
							>
								Sign In
							</Button>
						}
					/>
				</Card>
			)}
			{/* </div> */}
		</Page>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
	};
}

export default connect(mapStateToProps)(withRouter(SandeshListPage));
