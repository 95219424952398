import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import blueGrey from '@mui/material/colors/blueGrey';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import App from 'AppInterface/App';
import clsx from 'clsx';
import { Bull } from 'components/Bull';
import { ArticleTypeMap, LangMap } from 'config';
import { getFormattedTimeFromSec, timeout } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import 'scss/audioindicator.scss';
import { updateAudioPlaybackArticleIndex } from 'store/temp/actions';
import { ApplicationState, Article, AudioCurrentState } from 'types';
import { AudioPlaybackInstance } from './AudioPlayback';

const classes = {
	root: (theme) => ({
		margin: 0,

		'&.selected': {
			color: theme.palette.primary.main,

			'& .MuiTypography-root': {
				// 'font-weight': 'bold'
				color: theme.palette.primary.main,
			},
		},
	}),
	header: {
		padding: '16px 0',
		margin: '0 16px',

		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize' as 'capitalize',
		paddingTop: '8px',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: (theme) => ({
		fontSize: 14,
		padding: theme.spacing(2),
		marginRight: '-8px',
		marginTop: '-8px',
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
};

interface TileProps {
	record?: Article;
	index?: number;
	currentIndex?: number;
	currentState?: AudioCurrentState;
	listLength: number;
	moveToIndex: (currentIndex: number) => void;
}

class PlayListTile extends React.Component<TileProps, any> {
	shouldComponentUpdate(nextProps: TileProps) {
		return this.props.record !== nextProps.record || this.props.index !== nextProps.index || this.props.currentIndex !== nextProps.currentIndex;
	}

	render() {
		let { record, index, moveToIndex, currentIndex, listLength } = this.props;

		return (
			<Box
				className={clsx({ selected: index === currentIndex })}
				sx={classes.root}
				onClick={async () => {
					moveToIndex(index!);

					await timeout(100);
					AudioPlaybackInstance().playAudio();
				}}
			>
				<CardHeader
					sx={classes.header}
					style={{ borderBottom: index === listLength - 1 ? 'none' : '1px solid grey' }}
					action={
						!record ? null : (
							<>
								{index === currentIndex ? (
									<div className="audioindicator" style={{ marginRight: '-16px' }} />
								) : (
									<Box sx={classes.audioLength}>{getFormattedTimeFromSec(record.mediaLength)}</Box>
								)}
							</>
						)
					}
					title={
						!record ? (
							<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
						) : (
							<Typography noWrap className={record.title.hi ? 'locale-hi' : ''}>
								{record.title.hi || record.title.en}
							</Typography>
						)
					}
					subheader={
						!record ? (
							<Skeleton animation="wave" height={10} width="40%" />
						) : (
							<Typography variant="body2" color="textSecondary">
								{ArticleTypeMap[record.articleType]}
								<Bull />
								{LangMap[record.lang]}
							</Typography>
						)
					}
				/>
			</Box>
		);
	}
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = tempState;

	return {
		currentState: audioPlaybackState.currentState,
		currentIndex: audioPlaybackDetails?.currentIndex,
		listLength: audioPlaybackDetails?.articleList?.length || 0,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		moveToIndex: (currentIndex: number) => {
			dispatch(updateAudioPlaybackArticleIndex(currentIndex));
		},
	};
}

let connectedEle = connect(mapStateToProps, mapDispatchToProps)(PlayListTile);
export default connectedEle;
