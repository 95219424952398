import { Box, Button, CardContent, MobileStepper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ArticleTextContent } from 'components/articles/ArticleTextContent';
import { ArticleTile } from 'components/articles/ArticleTile';
import { Icons } from 'config/icons';
import { isMobile, timeout } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { updateBookletIndex } from 'store/ux/actions';
import { ApplicationState, ArticlesById, Booklet, BookletPage, BookletsById } from 'types';

const classes = {
	wrapper: {
		padding: 0,
	},
	root: {
		padding: 0,
		position: 'relative',
	},
	stepper: { maxWidth: 400, flexGrow: 1, background: 'white', margin: 'auto' },
	next: {
		marginLeft: 'auto !important',
	},
	details: {
		'& p:first-of-type': {
			marginTop: 0,
		},
	},
};

interface BookletProps {
	isEmbed?: boolean;
	booklet: Booklet;
	booklets: { [id: string]: Booklet };
	articles: ArticlesById;
	flatPages: BookletPage[];
	currentIndex: number;
	updateBookletIndex: (booklet: string, index: number) => void;

	style?: any;
}

class Component extends React.Component<BookletProps, any> {
	private pageRef = React.createRef<any>();

	getPageComponent = (page, index) => {
		let collection = page.pageCollectionType;
		let refId = page.pageRefId;

		let component;
		switch (collection) {
			case 'articles':
				let article = this.props.articles[refId];
				if (!article) {
					component = (
						<>
							<Skeleton
								animation='wave'
								height={30}
								style={{ marginBottom: 6, marginLeft: 'auto', marginRight: 'auto' }}
								width='60%'
							/>
							<Skeleton animation='wave' />
							<Skeleton animation='wave' />
							<Skeleton animation='wave' />
							<Skeleton animation='wave' />
						</>
					);
				} else if (article.mediaType === 'text') {
					component = (
						<ArticleTextContent
							isEmbed={true}
							includeTitle={false}
							articleType={article.articleType}
							href={article.id}
						/>
					);
				} else {
					component = <ArticleTile record={article} />;
				}
				break;
			case 'booklets':
				component = <BookletControl isEmbed={true} bookletId={refId} />;
				break;
		}

		return component;
	};

	async scrollToTop() {
		await timeout(200);

		let scrollTop = document.body.scrollHeight - this.pageRef.current.clientHeight - (isMobile() ? 220 : 270);

		window.scrollTo({
			top: Math.max(0, scrollTop),
			behavior: 'smooth',
		});
	}

	render() {
		let { booklet, flatPages: pages, updateBookletIndex, currentIndex, style } = this.props;

		let currentPage = pages[currentIndex];
		if (!currentPage) {
			return null;
		}
		let page = this.getPageComponent(currentPage, currentIndex);
		let stepper = (
			<MobileStepper
				variant='progress'
				steps={pages.length}
				position='static'
				activeStep={currentIndex}
				sx={classes.stepper}
				nextButton={
					<Button
						size='small'
						onClick={async () => {
							updateBookletIndex(booklet.id, currentIndex + 1);
							await this.scrollToTop();
						}}
						disabled={currentIndex >= pages.length - 1}
					>
						Next
						{Icons.KeyRightArrow}
					</Button>
				}
				backButton={
					<Button
						size='small'
						onClick={async () => {
							updateBookletIndex(booklet.id, currentIndex - 1);
							await this.scrollToTop();
						}}
						disabled={currentIndex <= 0}
					>
						{Icons.KeyLeftArrow}
						Back
					</Button>
				}
			/>
		);

		return (
			<Box sx={classes.root} style={style}>
				{currentIndex > 0 ? stepper : null}
				<CardContent
					ref={this.pageRef}
					sx={currentPage.pageCollectionType === 'booklets' ? classes.wrapper : {}}
				>
					{page}
				</CardContent>
				{stepper}
			</Box>
		);
	}
}

function flattenBooklet(pages: BookletPage[], booklets: BookletsById, flatPages: BookletPage[] = []) {
	pages = pages.sort((a, b) => a.order - b.order);
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i];
		page.flatIndex = flatPages.length;
		if (page.pageCollectionType === 'booklets') {
			let bookletId = page.pageRefId;
			let sectionPages = booklets[bookletId]?.pages || [];
			flatPages = flattenBooklet(sectionPages, booklets, flatPages);
		} else {
			flatPages.push(page);
		}
	}

	return flatPages;
}

interface Config {
	configKey: string;
}

interface BookletId {
	bookletId: string;
}

function mapStateToProps(state: ApplicationState, props: Config | BookletId | { isEmbed?: boolean; style?: any }) {
	let bookletId = (props as BookletId).bookletId;
	if (!bookletId) {
		let configs = state.dataState.configs.byId; //getDataById(state, 'configs') || {};
		bookletId = configs[(props as Config).configKey]?.value || '';
	}
	let booklets = state.dataState.booklets.byId || {};
	let booklet = booklets[bookletId] || {};

	let articles = state.dataState.articles.byId || {};
	let flatPages = flattenBooklet(booklet?.pages || [], booklets);

	let currentIndex = state.uxState.bookletCurrentIndex[booklet.id] || 0;

	return {
		booklets,
		booklet,
		articles,
		flatPages,
		currentIndex,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateBookletIndex: (bookletId: string, index: number) => {
			dispatch(updateBookletIndex(bookletId, index));
		},
	};
}

export const BookletControl = connect(mapStateToProps, mapDispatchToProps)(Component);
