import { AccountMode, AccountScreenManager } from 'components/profile/AccountScreenManager';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

function PhoneSignInPage(props) {
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<DialogPage
				title='Sign In'
				transition={false}
				maxWidth='xs'
				onClose={() => !!props.user}
				style={{ height: '100%' }}
				goBackOnClose={!!isModal}
				goBackTo='/'
			>
				<AccountScreenManager rootOnInit={true} initScreen={AccountMode.PhoneSignIn} />
			</DialogPage>
		</>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.userState.userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneSignInPage);
