import { v1 as uuidv1 } from 'uuid';

export class Context {
	private _correlationId: string;

	constructor(private _name?: string) {
		this._correlationId = uuidv1();
	}

	public get correlationId() {
		return this._correlationId;
	}

	public get name() {
		return this._name;
	}
}
