import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import blueGrey from '@mui/material/colors/blueGrey';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MediaPlayer } from 'components/player/MediaPlayer';
import { BottomDrawerOptions } from 'components/shell/BottomDrawerOptions';
import { Icons } from 'config/icons';
import { getContentStorageUrl } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleRecordPin } from 'services/api/auth';
import { CacheStrategy } from 'services/api/http';
import { deletePlaylist, renamePlaylist } from 'services/api/playlists';
import { toggleAlertDialog, toggleBottomDrawer, updateAudioPlaybackArticleIndex } from 'store/temp/actions';
import { ApplicationState, Article, ContentType, DownloadState, DownloadStatus, PinnedRecord, UserPlaylist } from 'types';
import PlaylistName from './PlaylistName';

const classes = {
	root: (theme) => ({
		margin: theme.spacing(1),

		'&.selected': {
			color: theme.palette.primary.main,

			'& .MuiTypography-root': {
				// 'font-weight': 'bold'
				color: theme.palette.primary.main,
			},
		},
	}),
	header: {
		// 'border-bottom': '1px solid grey',
		padding: '16px 0',
		margin: '0 16px',

		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'auto',
			marginTop: 0,
			marginRight: 0,
		},
		'& .MuiCardHeader-avatar': {
			marginLeft: -2,
			marginTop: -2,
			marginBottom: -2,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize',
		paddingTop: '8px',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: (theme) => ({
		padding: theme.spacing(2),
		marginRight: '-8px',
		marginTop: '-8px',
	}),
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
	},
	imgavatar: {
		width: 86,
		height: 86,
		borderRadius: 0,
		transform: 'scale(1)',

		'& img': {
			width: '100%',
			transform: 'scale(1.2)',
		},
	},
	progress: {
		position: 'absolute',
		left: -3,
		top: -3,
	},
};

interface TileProps {
	record: UserPlaylist;
	getTracks?: (playlist: UserPlaylist) => Article[];
	toggleDrawer: (content?: any) => void;
	toggleAlertDialog: (content?: any) => void;
	deletePlaylist: (playlistId: string) => void;
	renamePlaylist: (playlistId: string, title: string) => void;
	downloadProgress: { [id: string]: DownloadState };
	pinnedRecords: { [id: string]: PinnedRecord };
	toggleRecordPin: (recordId: string) => void;
}

const PlayListTile = (props: TileProps) => {
	let { record: playlist, toggleDrawer, deletePlaylist, toggleAlertDialog, renamePlaylist, getTracks, pinnedRecords, toggleRecordPin } = props;
	let [open, setOpen] = React.useState(false);

	let navigate = useNavigate();

	let href = '/playlist/' + playlist?.id;

	const onMoreIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		let isDisabled = Object.keys(playlist.tracks || []).length === 0;

		let options = BottomDrawerOptions({
			options: [
				{
					title: 'Play all tracks',
					icon: Icons.PlayCircleOutline,
					disabled: isDisabled,
					onClick: () => {
						if (getTracks) {
							MediaPlayer.instance().playRecords(getTracks(playlist!));
							toggleDrawer();
						}
					},
				},
				// {
				// 	title: 'Re-order tracks',
				// 	icon: Icons.SwapVert,
				// 	disabled: Object.keys(playlist.tracks || []).length <= 1,
				// 	onClick: () => {
				// 		toggleDrawer();
				// 		navigate('/playlist/reorder/' + playlist.id, {
				// 			state: {
				// 				isModal: true,
				// 			},
				// 		});
				// 	},
				// },
				{
					title: 'Rename',
					icon: Icons.Edit,
					onClick: () => {
						setOpen(true);
					},
				},
				{
					title: 'Delete',
					icon: Icons.Delete,
					onClick: () => {
						if (!playlist.tracks || Object.keys(playlist.tracks).length === 0) {
							deletePlaylist(playlist!.id);
							toggleDrawer();
							return;
						}

						if (playlist!.id === '0') {
							let alert = {
								title: 'Delete All Offline Audio?',
								message: 'By deleting this playlist, all your audio files stored on the device will be deleted!',
								okAction: {
									text: 'Delete',
									callback: () => {
										deletePlaylist(playlist!.id);
										toggleDrawer();
									},
								},
							};

							toggleAlertDialog(alert);
						} else {
							let alert = {
								title: 'Delete Playlist?',
								message: 'Do you want to delete this playlist?',
								okAction: {
									text: 'Delete',
									callback: () => {
										deletePlaylist(playlist!.id);
										toggleDrawer();
									},
								},
							};

							toggleAlertDialog(alert);
						}
					},
				},
				{
					title: pinnedRecords[playlist!.id] ? 'Unpin from Home' : 'Pin to Home',
					icon: pinnedRecords[playlist!.id] ? Icons.BookmarkRemove : Icons.BookmarkAdd,
					onClick: () => {
						toggleRecordPin(playlist!.id);
						toggleDrawer();
					},
				},
			],
			actionHandler: () => {
				toggleDrawer();
			},
		});

		toggleDrawer(options);
	};

	let isDownloadingMedia =
		playlist.id === '0' &&
		Object.keys(props.downloadProgress).reduce(
			(value, id) =>
				value ||
				props.downloadProgress[id]?.progress?.status === DownloadStatus.InProgress ||
				props.downloadProgress[id]?.progress?.status === DownloadStatus.Queued,
			false
		);

	let trackesKength = Object.keys(playlist?.tracks).length;

	return (
		<>
			{open ? (
				<PlaylistName
					open={open}
					actionText="RENAME"
					name={playlist?.title}
					action={async (name: string) => {
						await renamePlaylist(playlist!.id, name);
						toggleDrawer();
					}}
					onClose={() => {
						setOpen(false);
					}}
				/>
			) : null}
			<Card
				sx={classes.root}
				onClick={() => {
					navigate(href);
				}}
			>
				<CardHeader
					sx={classes.header}
					avatar={
						isDownloadingMedia ? (
							<div style={{ position: 'relative' }}>
								<Avatar sx={classes.avatar} variant="circular">
									{Icons.Download}
								</Avatar>
								<CircularProgress size={46} sx={classes.progress} />
							</div>
						) : (
							<Avatar sx={classes.imgavatar} variant="circular">
								{/* {Icons.PlaylistPlay} */}
								<img src={getContentStorageUrl(ContentType.PKP, 'webapp/playlist.png')} />
							</Avatar>
						)
					}
					action={<IconButton onClick={onMoreIconClick}>{Icons.MoreVert}</IconButton>}
					title={<Typography noWrap>{playlist?.title}</Typography>}
					subheader={trackesKength + ' Track' + (trackesKength > 1 ? 's' : '')}
				/>
			</Card>
		</>
	);
};

function mapStateToProps(state: ApplicationState) {
	let { audioPlaybackDetails, audioPlaybackState } = state.tempState;
	let pinnedRecords = state.userState.userStore.userData?.pinnedRecords ?? {};

	return {
		currentState: audioPlaybackState.currentState,
		currentIndex: audioPlaybackDetails?.currentIndex,
		downloadProgress: state.offlineState.audio,
		pinnedRecords,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		moveToIndex: (currentIndex: number) => {
			dispatch(updateAudioPlaybackArticleIndex(currentIndex));
		},
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
		toggleAlertDialog: (content?: any) => {
			dispatch(toggleAlertDialog(content));
		},
		deletePlaylist: (playlistId: string) => {
			dispatch(deletePlaylist({ playlistId: playlistId }, { cacheStrategy: CacheStrategy.NetworkOnly }));
		},
		renamePlaylist: (playlistId: string, title: string) => {
			dispatch(renamePlaylist({ playlistId: playlistId, title: title }, { cacheStrategy: CacheStrategy.NetworkOnly }));
		},
		toggleRecordPin: (recordId: string) => {
			dispatch(toggleRecordPin(recordId, 'playlists'));
		},
	};
}

let connectedEle = connect(mapStateToProps, mapDispatchToProps)(PlayListTile);
export default connectedEle;
