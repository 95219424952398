import { push } from '@lagunovsky/redux-react-router';
import { quoteRoute } from 'config/route';
import AnandDate from 'helpers/AnandDate';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { performOperationDaily, stopOperation } from 'store/data/ops/actions';
import { SHOW_TODAYS_QUOTE, UPDATE_QUOTES_FILTERED } from 'store/data/quotes/actions';
import { UPDATE_USER_SETTINGS } from 'store/ux/actions';
import { ApplicationState, Quote } from 'types';

export const quotesEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_QUOTES_FILTERED, UPDATE_USER_SETTINGS, SHOW_TODAYS_QUOTE),
		debounceTime(3000),
		mergeMap((action: { type: string }) => {
			let quotes = (state$.value as ApplicationState).filteredDataState.quotes as Quote[];
			let settings = (state$.value as ApplicationState).uxState.settings as { [key: string]: any };
			let user = (state$.value as ApplicationState).userState.userStore.user;
			let routeAction = push('/dailyquote', quoteRoute.state);

			// if (action.type === SHOW_TODAYS_QUOTE) {
			// 	if (quotes && quotes.length && settings?.quote) {
			// 		let todaysQuote = quotes.filter((quote) => quote.forDate === new AnandDate().format('YYYY-MM-DD'));
			// 		if (todaysQuote && todaysQuote.length) {
			// 			return of(routeAction);
			// 		}
			// 	}
			// 	return of(stopOperation('quotes'));
			// }

			if (quotes && quotes.length && settings?.quote && user) {
				return concat(
					of(stopOperation('quotes')),
					of(
						performOperationDaily('quotes', routeAction, false, () => {
							if (quotes && quotes.length && settings?.quote) {
								let todaysQuote = quotes.filter((quote) => quote.forDate === new AnandDate().format('YYYY-MM-DD'));
								if (todaysQuote && todaysQuote.length) {
									return true;
								}
							}
							return false;
						})
					)
				);
			} else {
				return of(stopOperation('quotes'));
			}
		})
	);
