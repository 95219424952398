import { combineReducers } from 'redux';
import { Edition, EditionsById, EditionStore, Publication, PublicationsById, PublicationStore } from 'types';
import { updateData } from '../generic/reducers';
import {
	UpdateEditions,
	UpdateEditionsFiltered,
	UpdatePublications,
	UpdatePublicationsFiltered,
	UPDATE_EDITIONS,
	UPDATE_EDITIONS_FILTERED,
	UPDATE_PUBLICATIONS,
	UPDATE_PUBLICATIONS_FILTERED,
} from './actions';

export function publicationsReducer(curState: PublicationsById = {}, action: UpdatePublications): PublicationsById {
	switch (action.type) {
		case UPDATE_PUBLICATIONS:
			return updateData('Publications', curState, action.changes, action.overwrite);
		default:
			return curState;
	}
}

export function filteredPublicationsReducer(
	curState: Publication[] = [],
	action: UpdatePublicationsFiltered
): Publication[] {
	switch (action.type) {
		case UPDATE_PUBLICATIONS_FILTERED:
			return action.payload;
		default:
			return curState;
	}
}

export function editionsReducer(curState: EditionsById = {}, action: UpdateEditions): EditionsById {
	switch (action.type) {
		case UPDATE_EDITIONS:
			return updateData('Editions', curState, action.changes, action.overwrite);
		default:
			return curState;
	}
}

export function filteredEditionsReducer(curState: Edition[] = [], action: UpdateEditionsFiltered): Edition[] {
	switch (action.type) {
		case UPDATE_EDITIONS_FILTERED:
			return action.payload;
		default:
			return curState;
	}
}

export const publicationStoreReducer = combineReducers<PublicationStore>({
	byId: publicationsReducer,
});

export const editionStoreReducer = combineReducers<EditionStore>({
	byId: editionsReducer,
});
