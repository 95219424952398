import { Box, Card } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';
import { ScreenManagerProps } from 'components/shell/ScreenManager';
import { Icons } from 'config/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { connect } from 'react-redux';
import { changePassword } from 'services/api/auth';
import { enqueueSnackbar } from 'store/temp/actions';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const classes = {
	root: (theme: Theme) => ({
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	}),
	card: (theme: Theme) => ({
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	}),
	form: (theme: Theme) => ({
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& .MuiTextField-root': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	signinbtn: (theme: Theme) => ({
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	}),
};

export interface Props extends ScreenManagerProps {
	changePassword: (current: string, password: string) => Promise<void>;
	enqueueSnackbar: (args) => void;
}

class ChangePassword extends React.Component<Props, any> {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
		};
	}

	render() {
		let { changePassword, popScreen, enqueueSnackbar } = this.props;
		let { showPassword } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<Box sx={classes.root}>
				<UserPicInfo />

				<Card sx={classes.card}>
					<Formik
						initialValues={{
							current: '',
							password: '',
							confirm: '',
						}}
						validationSchema={() =>
							Yup.object({
								current: Yup.string().required('Required'),
								password: Yup.string().required('Required').min(6),
								confirm: Yup.string()
									.required('Required')
									.oneOf([Yup.ref('password')], 'Passwords do not match'),
							})
						}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								await changePassword(values.current, values.password);
								popScreen();
								enqueueSnackbar({
									options: {
										variant: 'success',
									},
									message: 'Password Changed',
								});
							} catch (error) {
								if (error?.code === 'auth/wrong-password') {
									enqueueSnackbar({
										message: 'Wrong Password!',
										options: {
											variant: 'error',
										},
									});
								} else {
									enqueueSnackbar({
										message: error?.message ?? JSON.stringify(error),
										options: {
											variant: 'error',
										},
									});
								}
							}
						}}
					>
						{({ submitForm, isSubmitting }) => (
							<Box component={Form} sx={classes.form} translate='yes'>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='Current Password'
									name='current'
									autoComplete='off'
									autoCapitalize='off'
									autoCorrect='off'
									spellCheck='false'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}
													size='large'
												>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='New Password'
									name='password'
									autoComplete='off'
									autoCapitalize='off'
									autoCorrect='off'
									spellCheck='false'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}
													size='large'
												>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='Confirm Password'
									name='confirm'
									autoComplete='off'
									autoCapitalize='off'
									autoCorrect='off'
									spellCheck='false'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}
													size='large'
												>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Button
									sx={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}
								>
									Change Password
								</Button>
							</Box>
						)}
					</Formik>
				</Card>
			</Box>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		changePassword: async (current: string, password: string) => {
			await dispatch(changePassword({ current, password }));
		},
		enqueueSnackbar: (args) => {
			dispatch(enqueueSnackbar(args));
		},
	};
}

export default connect(undefined, mapDispatchToProps)(ChangePassword);
