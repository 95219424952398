import { Avatar, Box, Card, CardContent, CardHeader, Chip, CircularProgress, Skeleton, Typography } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { Bull } from 'components/Bull';
import { logEvent } from 'components/telemetry';
import { ArticleTypeMap, LangMap } from 'config';
import { PrimaryMainColor } from 'config';
import { getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modifyPlaylistTracks } from 'services/api/playlists';
import { getUserPlaylistsByTrackId } from 'store/data/user/selectors';
import { ApplicationState, Article, UserPlaylist } from 'types';
import { MediaPlayer } from '../player/MediaPlayer';
import { ArticleOptionsIcon, getArticleHref, getArticleIcon } from './ArticleOptions';
import { DownloadIndicator } from './DownloadIndicator';

const classes = {
	card: {
		margin: 1,

		'& .MuiCardContent-root:last-child': {
			paddingBottom: 2.5,
		},
	},
	header: {
		paddingBottom: 0,

		'& div': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize',
		paddingTop: 1,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: {
		// float: 'right',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
		width: 48,
		height: 48,
		transform: 'scale(0.8)',
	},
	details: {
		display: 'flex',
		flexDirection: 'row',
	},
	leftDetails: {
		flexGrow: 1,
	},
	rightDetails: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
	playlists: {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',

		'& .MuiChip-root': {
			marginRight: 0.5,
		},
	},
	progress: {
		position: 'absolute',
		left: 0,
		top: 0,
	},
};

interface ArticleProps {
	locale: string;
	record?: Article;
	showExtras?: boolean;
	style?: any;
	playedTime?: number;
	lastPlayedTimestamp?: number;
	playlists?: UserPlaylist[];
	onClick?: (record: Article) => void;
	modifyPlaylistTracks: (playlistId: string, articleId: string, action: 'Add' | 'Remove') => Promise<UserPlaylist>;
}

const Component = (props: ArticleProps) => {
	let { record, showExtras, style, locale, playedTime, lastPlayedTimestamp, playlists } = props;
	let navigate = useNavigate();

	if (record && !record.id && record.articleType === 'component') {
		return record.__component;
	}

	if (record && !record?.id) {
		return <span>{JSON.stringify(record.title)}</span>;
	}

	let AvatarIcon = getArticleIcon(record);
	let href = getArticleHref(record);

	let localeClass = '';
	// if (record?.mediaType === 'text') {
	if (record?.title[locale]) {
		if (locale === 'hi') {
			localeClass = 'locale-hi';
		}
	} else if (record?.title.hi) {
		localeClass = 'locale-hi';
	}
	// }

	let recordLang = record?.lang && typeof record?.lang === 'string' ? record.lang : record?.title[locale] ? locale : record?.title.hi ? 'hi' : 'en';

	return (
		<Card
			sx={classes.card}
			style={style}
			data-id={record?.id}
			onClick={() => {
				if (!record) {
					return;
				}

				logEvent('select_content', {
					content_type: ArticleTypeMap[record.articleType] || record.articleType,
					item_id: record.id,
					mediaType: record.mediaType,
					title: record.title.en || record.title.hi || record.title,
					group: record.group,
					lang: record.lang,
					mediaLength: record.mediaLength,
					mediaUri: record.mediaUri,
				});

				if (props.onClick) {
					props.onClick(record);
					return;
				}

				record.mediaType === 'audio' || record.mediaType === 'video'
					? MediaPlayer.instance().playAudio(record.id, record.articleType, 0)
					: navigate(href, {
							state: {
								type: record.articleType,
								isModal: true,
							},
					  });
			}}
		>
			{/* <CardActionArea
				onClick={() => {
					navigate(record?.article_type + '/' + record?.href);
				}}
			> */}
			<CardHeader
				sx={classes.header}
				avatar={
					!record ? (
						<Skeleton animation="wave" variant="rectangular" width={40} height={40} />
					) : (
						<div style={{ position: 'relative' }}>
							<Avatar sx={classes.avatar} variant="circular">
								{AvatarIcon}
							</Avatar>
							{record && playedTime ? (
								<>
									<CircularProgress
										variant="determinate"
										sx={[
											classes.progress,
											{
												color: `${PrimaryMainColor}33`,
											},
										]}
										size={48}
										thickness={3}
										value={100}
									/>
									<CircularProgress
										variant="determinate"
										size={48}
										thickness={3}
										value={(playedTime * 100) / record.mediaLength}
										color="primary"
										sx={[
											classes.progress,
											{
												color: `${PrimaryMainColor}bb`,
											},
										]}
									/>
								</>
							) : null}
						</div>
						// <Avatar sx={classes.avatar} variant='circular' alt={record.articleType}>
						// 	{AvatarIcon}
						// </Avatar>
					)
				}
				action={!record ? null : <ArticleOptionsIcon record={record} />}
				title={
					!record ? (
						<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
					) : (
						<Typography noWrap className={localeClass}>
							{record.title[locale] || record.title.hi || record.title.en}
						</Typography>
					)
				}
				subheader={
					!record ? (
						<Skeleton animation="wave" height={10} width="40%" />
					) : (
						<Typography variant="body2" color="textSecondary" noWrap className="locale-en">
							{record.group}
						</Typography>
					)
				}
			/>

			<CardContent sx={classes.content}>
				{!record ? (
					<>
						<Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
						<Skeleton animation="wave" height={10} />
					</>
				) : (
					<>
						<Box sx={classes.playlists}>
							{playlists?.map((playlist) => (
								<Chip key={playlist.id} size="small" variant="outlined" label={playlist.title} color="primary" />
							))}
						</Box>
						<Typography variant="body2" color="textSecondary" component="div">
							<Box sx={classes.details}>
								<Box sx={classes.leftDetails}>
									{record?.mediaType === 'text' ? 'Text ' : ''}
									{ArticleTypeMap[record.articleType]}
									<Bull />
									{LangMap[recordLang]}
									{showExtras && record.author ? (
										<span>
											<br />
											{record.author}
										</span>
									) : null}
									<br />
									{new AnandDate(record.creationTime ?? record.publishTime).format('Do MMM, YYYY')}
								</Box>
								{record?.mediaType === 'audio' || record?.mediaType === 'video' ? (
									<Box sx={classes.rightDetails}>
										{lastPlayedTimestamp ? (
											<span>{new AnandDate().setEpoch(lastPlayedTimestamp).format('Do MMM, YYYY')}</span>
										) : (
											<span>&nbsp;</span>
										)}
										<Box component="span" sx={classes.audioLength}>
											<DownloadIndicator record={record} />
											{getFormattedTimeFromSec(record.mediaLength || 0)}
										</Box>
									</Box>
								) : null}
							</Box>
						</Typography>
					</>
				)}
			</CardContent>
			{/* </CardActionArea> */}
		</Card>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		showExtras: props.showExtras ?? (state.userState.userStore.userData?.settings && state.userState.userStore.userData?.settings['admin.records']),
		locale: state.uxState.locale,
		playedTime: props.record && props.record.id ? state.offlineState.playedMedia[props.record.id]?.time : 0,
		lastPlayedTimestamp: props.record && props.record.id ? state.offlineState.playedMedia[props.record.id]?.timestamp : undefined,
		playlists: props.record && props.record.id ? getUserPlaylistsByTrackId(state)[props.record.id]?.filter((playlist) => playlist.id !== '0') : undefined,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		modifyPlaylistTracks: async (playlistId: string, articleId: string, action: 'Add' | 'Remove') => {
			return dispatch(modifyPlaylistTracks({ playlistId, articleId, action }));
		},
	};
}

export const ArticleTile = connect(mapStateToProps, mapDispatchToProps)(Component);
