import { PersistedState } from 'redux-persist';
import { PlayedMedia, UserStore } from 'types';
import { initialState as is0 } from './0';

export const migrations = {
	34: (state) => is0 as unknown as PersistedState,
	35: (state) => {
		let newState = { ...state };

		console.log('[Start] Migration 35: State - ', newState);

		let playedMedia = (newState.dataState as any).playedMedia as PlayedMedia;
		if (playedMedia) {
			newState.offlineState.playedMedia = { ...playedMedia };
			delete newState.dataState['playedMedia'];
		}

		let userStore = (newState.dataState as any).userStore as UserStore;
		if (userStore) {
			newState.userState = { userStore: { ...userStore } };
			delete newState.dataState['userStore'];
		}

		let opsState = { ...newState.opsState };
		delete newState['opsState'];
		newState.opsState = { byId: opsState };

		let configsState = { ...newState.dataState.configs };
		delete newState.dataState.configs;
		newState.dataState.configs = { byId: configsState };

		console.log('[End] Migration 35: State - ', newState);
		return newState;
	},
};
