import SubscribeControl from 'components/orders/placement/CartScreen';
import React from 'react';
import { useParams } from 'react-router-dom';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function SubscribePage(props) {
	let { pubId } = useParams();
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<DialogPage
				title={pubId ? 'Subscribe' : 'Cart'}
				maxWidth='xs'
				dark
				transition={true}
				goBackOnClose={!!isModal}
				goBackTo='/'
			>
				<SubscribeControl pubId={pubId} />
			</DialogPage>
		</>
	);
}
