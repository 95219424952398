import { QuoteControl } from 'components/quote/QuoteControl';
import React from 'react';
import { useParams } from 'react-router-dom';
import HomePage from './HomePage';

function QuotePage(props) {
	let { isModal } = props;
	let { id } = useParams();

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<QuoteControl id={id} isModal={!!isModal} />
		</>
	);
}

export default QuotePage;
