import { Avatar, Box, Card, CardContent, CardHeader, Chip, CircularProgress, Skeleton, Slider, Typography } from '@mui/material';
import blueGrey from '@mui/material/colors/blueGrey';
import { Bull } from 'components/Bull';
import { logEvent } from 'components/telemetry';
import { ArticleTypeMap, LangMap } from 'config';
import { Icons } from 'config/icons';
import { getContentStorageUrl, getFormattedTimeFromSec } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import React, { useEffect, useState } from 'react';
import { Flipped, spring } from 'react-flip-toolkit';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modifyPlaylistTracks } from 'services/api/playlists';
import { getUserPlaylistsByTrackId } from 'store/data/user/selectors';
import { ApplicationState, Article, ContentType, UserPlaylist } from 'types';
import { MediaPlayer } from '../player/MediaPlayer';
import { ArticleOptionsIcon, getArticleHref, getArticleIcon, getArticleIconImg } from './ArticleOptions';
import { DownloadIndicator } from './DownloadIndicator';

const classes = {
	card: {
		margin: 1,
		borderRadius: 2,

		transition: 'margin 0.5s',
		position: 'relative',

		'& .MuiCardContent-root:last-child': {
			paddingBottom: 1,
		},

		'&:nth-of-type(even)': {
			backgroundColor: '#f0f4f2', //'ivory',
		},
	},
	header: {
		padding: 1.5,
		'& div': {
			// overflow: 'hidden',
		},
		'& .MuiCardHeader-title': {
			fontSize: '1.6rem',
			whiteSpace: 'nowrap',
		},
		'& .MuiCardHeader-content': {
			minWidth: 0,
			// overflowX: 'hidden',
			overflow: 'visible',
		},

		'& .MuiCardHeader-avatar': {
			marginLeft: -2,
			marginTop: -2,
			marginBottom: -2,
		},

		'& .MuiTypography-root': {
			overflow: 'visible',
			// overflowX: 'hidden',
		},
	},
	title: {
		fontSize: 14,
	},
	content: {
		textTransform: 'capitalize',
		paddingTop: 1,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 5px',
	},
	audioLength: {
		// float: 'right',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
	},
	avatar: {
		color: '#fff',
		backgroundColor: blueGrey[300],
		width: 48,
		height: 48,
		transform: 'scale(0.8)',
	},
	imgavatar: {
		width: 78,
		height: 78,
		borderRadius: 0,
		transform: 'scale(1)',

		'& img': {
			width: '100%',
		},
	},
	details: {
		display: 'flex',
		flexDirection: 'row',
	},
	leftDetails: {
		flexGrow: 1,
	},
	rightDetails: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
	playlists: {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',

		'& .MuiChip-root': {
			marginRight: 0.5,
		},
	},
	progress: {
		position: 'absolute',
		left: 0,
		top: 0,
	},
	slider: {
		'&.MuiSlider-root': {
			display: 'block',
			padding: 0,
			height: 2,
		},

		'&.MuiSlider-root.Mui-disabled': {
			color: 'primary.main',
		},

		'& .MuiSlider-track': {
			height: 3,
		},

		'& .MuiSlider-thumb': {
			display: 'none',
		},

		'& .MuiSlider-rail': {
			height: 3,
		},
	},
};

interface ArticleProps {
	locale: string;
	record?: Article;
	showExtras?: boolean;
	style?: any;
	playedTime?: number;
	lastPlayedTimestamp?: number;
	playlists?: UserPlaylist[];
	index: number;
	startMinimised?: boolean;
	onClick?: (record: Article) => void;
	modifyPlaylistTracks: (playlistId: string, articleId: string, action: 'Add' | 'Remove') => Promise<UserPlaylist>;
}

const onElementAppear = (el, index) =>
	spring({
		onUpdate: (val) => {
			el.style.opacity = val;
		},
		delay: index * 50,
	});

const onListExit = (el, index, removeElement) => {
	spring({
		config: { overshootClamping: true },
		onUpdate: (val: any) => {
			el.style.transform = `scaleY(${1 - val})`;
		},
		delay: index * 50,
		onComplete: removeElement,
	});

	return () => {
		el.style.opacity = '';
		removeElement();
	};
};

const Component = (props: ArticleProps) => {
	let { index, startMinimised, record, showExtras, style, locale, playedTime, lastPlayedTimestamp, playlists } = props;
	startMinimised = false;

	let [mt, setMT] = useState(startMinimised ?? true ? '-70px' : '0px');
	let navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			setMT('0px');
		}, 1500);
	}, []);

	if (record && !record.id && record.articleType === 'component') {
		return record.__component;
	}

	if (record && !record.id) {
		return <span>{JSON.stringify(record.title)}</span>;
	}

	let AvatarIcon = getArticleIconImg(record); //getArticleIcon(record);
	let href = getArticleHref(record);

	let localeClass = '';
	// if (record?.mediaType === 'text') {
	if (record?.title[locale]) {
		if (locale === 'hi') {
			localeClass = 'locale-hi';
		}
	} else if (record?.title.hi) {
		localeClass = 'locale-hi';
	}
	// }

	let recordLang = record?.lang && typeof record?.lang === 'string' ? record.lang : record?.title[locale] ? locale : record?.title.hi ? 'hi' : 'en';

	return (
		<Flipped
			flipId={record?.id}
			onAppear={onElementAppear}
			onExit={onListExit}
			// key={flipId}
			stagger={'forward'}
			// shouldInvert={this.shouldFlip}
		>
			<Card
				sx={{ ...classes.card, mt: index > 0 ? mt : 0, zIndex: 10 - index }}
				style={style}
				data-id={record?.id}
				onClick={() => {
					if (!record) {
						return;
					}

					logEvent('select_content', {
						content_type: ArticleTypeMap[record.articleType] || record.articleType,
						item_id: record.id,
						mediaType: record.mediaType,
						title: record.title.en || record.title.hi || record.title,
						group: record.group,
						lang: record.lang,
						mediaLength: record.mediaLength,
						mediaUri: record.mediaUri,
					});

					if (props.onClick) {
						props.onClick(record);
						return;
					}

					record.mediaType === 'audio' || record.mediaType === 'video'
						? MediaPlayer.instance().playAudio(record.id, record.articleType, 0)
						: navigate(href, {
								state: {
									type: record.articleType,
									isModal: true,
								},
						  });
				}}
			>
				{/* <CardActionArea
				onClick={() => {
					navigate(record?.article_type + '/' + record?.href);
				}}
			> */}
				<CardHeader
					sx={classes.header}
					avatar={
						!record ? (
							<Skeleton animation="wave" variant="rectangular" width={40} height={40} />
						) : (
							<div style={{ position: 'relative' }}>
								<Avatar sx={classes.imgavatar} variant="circular">
									{AvatarIcon}
								</Avatar>
							</div>
						)
					}
					action={!record ? null : <ArticleOptionsIcon record={record} />}
					title={
						!record ? (
							<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
						) : (
							<Typography noWrap className={localeClass}>
								{record.title[locale] || record.title.hi || record.title.en}
							</Typography>
						)
					}
					subheader={
						!record ? (
							<Skeleton animation="wave" height={10} width="40%" />
						) : (
							<Typography variant="body2" color="textSecondary" noWrap className="locale-en" component="div">
								<Box sx={classes.details}>
									<Box sx={classes.leftDetails}>
										{new AnandDate(record.creationTime ?? record.publishTime).format('Do MMM, YYYY')}
										{record.mediaLength && (
											<>
												<Bull />
												{getFormattedTimeFromSec(record.mediaLength || 0)}
											</>
										)}
									</Box>
									<Box sx={classes.rightDetails}>
										<Box component="span" sx={classes.audioLength}>
											<DownloadIndicator record={record} />
										</Box>
									</Box>
								</Box>
							</Typography>
						)
					}
				/>
				{record && playedTime ? <Slider sx={classes.slider} disabled value={playedTime} min={0} max={record?.mediaLength} /> : null}
			</Card>
		</Flipped>
	);
};

function mapStateToProps(state: ApplicationState, props) {
	return {
		showExtras: props.showExtras ?? (state.userState.userStore.userData?.settings && state.userState.userStore.userData?.settings['admin.records']),
		locale: state.uxState.locale,
		playedTime: props.record && props.record.id ? state.offlineState.playedMedia[props.record.id]?.time : 0,
		lastPlayedTimestamp: props.record && props.record.id ? state.offlineState.playedMedia[props.record.id]?.timestamp : undefined,
		playlists: getUserPlaylistsByTrackId(state)[props.record.id]?.filter((playlist) => playlist.id !== '0'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		modifyPlaylistTracks: async (playlistId: string, articleId: string, action: 'Add' | 'Remove') => {
			return dispatch(modifyPlaylistTracks({ playlistId, articleId, action }));
		},
	};
}

export const ConciseArticleTile = connect(mapStateToProps, mapDispatchToProps)(Component);
