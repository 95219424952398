export const SiteOwner = process.env.REACT_APP_SITE_OWNER ?? 'SAPD';
export const IsOwnerSAPD = SiteOwner === 'SAPD';

export const PrimaryMainColor = '#038b00'; // '#007a01', // '#1ba35f', // '#25d366'; '#32b78d'

export const IsMinimalRhymeSite = window.location.host === 'rhymes.uniplatform.app';

export const OrgName = IsMinimalRhymeSite ? 'Rhymes Management' : IsOwnerSAPD ? 'Shri Anandpur Dham' : 'Shri Prayagdham';
export const OrgNameHi = IsMinimalRhymeSite ? 'Rhymes Management' : IsOwnerSAPD ? 'श्री आनन्दपुर धाम' : 'श्री प्रयागधाम';
export const AppName = 'Shri Anandpur Satsang';
export const OrgLogoSrc = IsOwnerSAPD ? 'dark/Shri_AnandpurDham_Logo.png' : 'dark/Shri_Prayagdham_Logo.png';
export const IntroImg1Src = IsOwnerSAPD ? 'dark/Shri_AnandpurDham_Holy_Gate.jpg' : 'dark/Shri_PrayagDham_Holy_Gate.jpeg';
export const IntroImg2Src = IsOwnerSAPD ? 'dark/Shri_AnandpurDham_Temples.jpg' : 'dark/Shri_PrayagDham_Temple.jpeg';

export const SSDN = 'श्रीसद्गुरुदेवाय नम:';
export const MainRhyme =
	"<rhyme class='content-inline-rhymes locale-hi' data-lang='hi'><rhyme-line class='rhyme-line'>श्री आरती-पूजा सत्संग सेवा, सुमिरण और ध्यान<hide>।</hide>।<hide>।</hide></rhyme-line><rhyme-line class='rhyme-line'>श्रद्धा सहित सेवन करे, निश्चय हो कल्याण<hide>।</hide>।।</rhyme-line></rhyme>";
export const MainRhyme4 =
	"<rhyme class='content-inline-rhymes locale-hi' data-lang='hi'><rhyme-line class='rhyme-line'>श्री आरती-पूजा सत्संग सेवा,<hide>।।।।।</hide></rhyme-line><rhyme-line class='rhyme-line'><hide>।।।।।</hide>सुमिरण और ध्यान<hide>।</hide>।<hide>।</hide></rhyme-line><rhyme-line class='rhyme-line'>श्रद्धा सहित सेवन करे,<hide>।।।।।</hide></rhyme-line><rhyme-line class='rhyme-line'><hide>।।।।।</hide>निश्चय हो कल्याण<hide>।</hide>।।</rhyme-line></rhyme>";
export const BJK = '।। बोलो जयकारा बोल मेरे श्री गुरु महाराज की जय ।।';
export const COPYRIGHT = 'All rights reserved, © 2020';

export const SSNTitle = 'Shri Sant Nagar, Shri Aagya-Patra';

export const SHARE_ORIGIN = IsMinimalRhymeSite
	? window.location.origin + '/?r='
	: IsOwnerSAPD
	? 'https://www.shrianandpur.org/?r='
	: 'https://www.shriprayagdham.org/?r=';

export const LangMap = {
	hi: 'Hindi',
	en: 'English',
	si: 'Sindhi',
	pu: 'Punjabi',
	hien: 'Hindi & English',
};

export const ArticleTypeMap = {
	bhajan: 'Bhajan',
	satsang: 'Satsang',
	shriamarvani: 'Shri Amar Vani',
	shriaarti: 'Pooja Ke Phool',
	introduction: 'Introduction',
	notice: 'Notification',
	shridarshan: 'Shri Darshan',
	policies: 'Policies',
	sandesh: 'Sandesh',
	nityaniyam: 'Nitya Niyam',
	dhyan: 'Dhyan',
	'': '',
};

export const MediaTypeMap = {
	audio: 'Audio',
	text: 'Text',
	video: 'Video',
};

export const LocaleIdMap = {
	1: 'en',
	2: 'hi',
};

export const DBConfig = {
	IntroductionBooklet: IsOwnerSAPD ? 'IntroductionBooklet' : 'SPDIntroductionBooklet',
	Calendars: 'Calendars',
	Calendar: 'Calendar',
	Festivals: 'Festivals',
	FAQ: 'FAQ',
	Terms: IsOwnerSAPD ? 'Terms' : 'SPDTerms',
	Privacy: IsOwnerSAPD ? 'Privacy' : 'SPDPrivacy',
	DonationMail: 'DonationMail',
	PostalContact: IsOwnerSAPD ? 'PostalContact' : 'SPDPostalContact',
	Snapshot: 'Snapshot',
	WebSnapshot: 'WebSnapshot',
	AutoDownload: 'AutoDownload',
	LatestBuildVersion: IsOwnerSAPD ? 'LatestBuildVersion' : 'LatestBuildVersionSPD',
	UpdateTimes: 'UpdateTimes',
	SSN: 'SSN',
	Sandesh: 'Sandesh',
	NityaNiyam: 'NityaNiyam',
	Announcement: 'Announcement',
	UpdatedAt: (collection) => collection + 'UpdatedAt',
};

export const DefaultNityaNiyamSettings = {
	morning: {
		aaradhan: {
			enabled: false,
		},
		sgm: {
			enabled: true,
		},
		mAartiStotr: {
			enabled: true,
		},
		// mAarti: {
		// 	enabled: true,
		// },
		// mVinati: {
		// 	enabled: true,
		// },
		// mStotra: {
		// 	enabled: true,
		// },
		// chhanda: {
		// 	enabled: true,
		// },
		dhyaan: {
			enabled: true,
		},
		satsang: {
			enabled: true,
			notPlayed: true,
			onlyHindi: false,
			count: 1,
			shuffle: false,
		},
		bhajan: {
			enabled: true,
			notPlayed: true,
			onlyHindi: false,
			count: 1,
			shuffle: false,
		},
	},
	evening: {
		sgm: {
			enabled: true,
		},
		eAartiStotr: {
			enabled: true,
		},
		// eAarti: {
		// 	enabled: true,
		// },
		// eVinati: {
		// 	enabled: true,
		// },
		// eStotra: {
		// 	enabled: true,
		// },
		// stuti: {
		// 	enabled: true,
		// },
		dhyaan: {
			enabled: true,
		},
		satsang: {
			enabled: true,
			notPlayed: true,
			onlyHindi: false,
			count: 1,
			shuffle: false,
		},
		bhajan: {
			enabled: true,
			notPlayed: true,
			onlyHindi: false,
			count: 1,
			shuffle: false,
		},
	},
};

export const isTestEnv = window.location.href.indexOf('pw=true') >= 0;

export const DefaultUserSettings = {
	push: true,
	quote: isTestEnv ? false : true,
	nityaNiyam: DefaultNityaNiyamSettings,
};
export const SpecialRecordIds = {
	SSNRequestEnabled: '--ssn-request-enabled--',
};

export const AnnouncementConfig = { key: 'accouncement' };

export const DhyanArticleId = 'hKxuvxDxigC7RDJ0KvRi';
export const MorningAartiId = 'JSAbPtmmAoMIZrMiEYKL';
export const EveningAartiId = 'v8X3COp68cPdPWQs0DjI';
