import { FireStoreModule } from 'fb';
import 'firebase/firestore';
import { collection, Firestore } from 'firebase/firestore';
import { updateHD } from 'store/data/hd/actions';

export class HDFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'hd';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: Firestore) => collection(db, 'hd');
	public getChangeProcessAction = (changes) => updateHD(changes);
}
