import { AccountMode, AccountScreenManager } from 'components/profile/AccountScreenManager';
import React from 'react';
import DialogPage from './DialogPage';
import HomePage from './HomePage';

export default function SignInPage(props) {
	let { isModal } = props;

	return (
		<>
			{!isModal ? <HomePage /> : null}

			<DialogPage
				title='Sign In'
				transition={true}
				maxWidth='xs'
				style={{ height: '100%' }}
				goBackOnClose={!!isModal}
				goBackTo='/'
			>
				<AccountScreenManager initScreen={AccountMode.SignIn} />
			</DialogPage>
		</>
	);
}
