import { FireStoreModule } from 'fb/index';
import { fireAuth } from 'fb/initconfig';
import { User } from 'firebase/auth';
import { collection, Firestore, query, where } from 'firebase/firestore';
import { addToCart, emptyCart } from 'store/data/cart/actions';
import { updateOrders } from 'store/data/orders/actions';
import { ApplicationState, CartItem } from 'types';
import { InitiateOrderData, Order, PaymentStatus } from 'types/orders';
import { triggerFunction } from './functions';

export class OrdersFireStoreModule implements FireStoreModule {
	private user: User;
	constructor(user: User) {
		this.user = user;
	}

	public getModuleName = () => 'orders';
	public getOpsKeySuffix = () => this.user.uid;
	public getFBWatchQuery = (db: Firestore) => query(collection(db, 'orders'), where('userId', '==', this.user.uid));
	public getChangeProcessAction = (changes) => updateOrders(changes, this.user.uid, fireAuth.currentUser?.uid);
}

export const addToCartThunk = (item: CartItem) => {
	return async (dispatch, getState) => {
		let state = getState() as ApplicationState;
		let cart = state.dataState.cart;
		let orderId = cart.orderId;

		if (orderId) {
			let orders = state.filteredDataState.orders;
			let order: Order | undefined;
			let orderList = orders.filter((o) => o.id === orderId);
			if (orderList && orderList.length) {
				order = orderList[0];
			}
			let status = order?.paymentDetails.status ?? PaymentStatus.Started;
			if (status === PaymentStatus.Completed) {
				dispatch(emptyCart());
			}
		}

		dispatch(addToCart(item));
	};
};

export const initiateOrder = (data: InitiateOrderData) => {
	return async (dispatch, getState) => {
		try {
			let userStore = getState().userState.userStore;
			let user = userStore.user;

			if (!user) {
				throw new Error('user not signed in');
			}

			return await triggerFunction('initiateOrder', data);
		} catch (error) {
			throw error;
		}
	};
};

export const cancelOrder = (orderId: string) => {
	return async (dispatch, getState) => {
		try {
			let userStore = getState().userState.userStore;
			let user = userStore.user;

			if (!user) {
				throw new Error('user not signed in');
			}

			return await triggerFunction('cancelOrder', { orderId });
		} catch (error) {
			throw error;
		}
	};
};

export const cancelSubscription = (orderId: string) => {
	return async (dispatch, getState) => {
		try {
			let userStore = getState().userState.userStore;
			let user = userStore.user;

			if (!user) {
				throw new Error('user not signed in');
			}

			return await triggerFunction('cancelSubscription', { orderId });
		} catch (error) {
			throw error;
		}
	};
};
