import { Avatar, Box } from "@mui/material";

const styles = {
    avatar: {
        border: "3px solid rgb(244, 247, 250)",
        position: "relative",
    },
    root: {
        position: "relative",

        "&:before": {
            inset: "0px",
            margin: "-3px",
            content: '""',
            display: "block",
            position: "absolute",
            background: "linear-gradient(to right bottom, rgb(254, 172, 94), rgb(199, 121, 208), rgb(75, 192, 200))",
            borderRadius: "50%",
        },
    },
};

export const ChatAvatar = (props) => {
    return (
        <>
            <Box sx={styles.root}>{props.src ? <Avatar sx={styles.avatar} src={props.src} /> : <Avatar sx={styles.avatar}>{props.children}</Avatar>}</Box>
        </>
    );
};
