import { Theme, ThemeProvider } from '@mui/material/styles';
import { enUsTheme, hiTheme } from 'config/theme';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

// declare module '@mui/styles' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

interface ThemeProps {
	locale: string;
	children: JSX.Element | JSX.Element[];
}

class StatefulThemeProvider extends React.Component<ThemeProps> {
	shouldComponentUpdate(nextProps: ThemeProps) {
		if (this.props.locale === nextProps.locale) {
			return false;
		}

		return true;
	}

	componentDidUpdate(prevProps: ThemeProps) {
		if (this.props.locale === prevProps.locale) {
			return;
		}
	}

	render() {
		let theme: Theme;
		if (this.props.locale === 'hi') {
			theme = hiTheme;
		} else {
			theme = enUsTheme;
		}

		return (
			// <StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
			// </StyledEngineProvider>
		);
	}
}

function mapStateToProps(state: ApplicationState, props) {
	let { locale } = state.uxState;

	if (props && props.locale) {
		return { locale: props.locale };
	}

	return {
		locale: locale,
	};
}

export default connect(mapStateToProps)(StatefulThemeProvider);
