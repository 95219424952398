import { LinearProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import Page from './Page';
const ManageAllContent = React.lazy<any>(() => import('components/admin/ManageAllContent'));
const SRCM = React.lazy<any>(() => import('components/admin/SRCM'));

export default function AdminPage() {
	let { collection } = useParams();

	collection = collection === 'notices' ? 'notifications' : collection;

	return (
		<Page title="Administration" includeBottomNavigation fullscreen dark>
			<Suspense fallback={<LinearProgress />}>{collection === 'srcm' ? <SRCM /> : <ManageAllContent collection={collection} />}</Suspense>
		</Page>
	);
}
