import Skeleton from '@mui/material/Skeleton';
import RecordList from 'components/RecordList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Article } from 'types';
import PlayListTile from './PlayListTile';

interface PlayListProps {
	records?: Article[];
	currentIndex: number;
	ref?: any;
}

class PlayList extends React.Component<PlayListProps, any> {
	private listRef = React.createRef<any>();

	private getItemSize(index) {
		return 85;
	}

	public scrollToItem = (index: number) => {
		this.listRef.current && (this.listRef.current as any).scrollToItem(index, 'auto');
	};

	shouldComponentUpdate(newProps: PlayListProps) {
		if (this.props.currentIndex !== newProps.currentIndex) {
			// this.scrollToItem(newProps.currentIndex);
		}

		if (this.props.records !== newProps.records) {
			setTimeout(() => {
				this.scrollToItem(this.props.currentIndex);
			}, 0);
		}

		return this.props.records !== newProps.records;
	}

	componentDidMount() {
		setTimeout(() => {
			this.scrollToItem(this.props.currentIndex);
		}, 0);
	}

	render() {
		let { records, currentIndex = 0 } = this.props;

		return !records ? (
			<Skeleton
				animation='wave'
				height={12}
				width='40%'
				style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}
			/>
		) : (
			<RecordList
				ref={this.listRef}
				records={records}
				fetching={false}
				RecordTile={PlayListTile}
				getItemSize={this.getItemSize.bind(this)}
				initialScrollOffset={currentIndex * this.getItemSize(0)}
			></RecordList>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	let { audioPlaybackDetails } = state.tempState;

	let records = audioPlaybackDetails?.articleList;

	return {
		records,
		currentIndex: audioPlaybackDetails?.currentIndex || 0,
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(PlayList);
