import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Icons } from 'config/icons';
import React, { Fragment } from 'react';

export interface BottomDrawerOption {
	icon?: JSX.Element;
	title?: string;
	element?: JSX.Element;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
}

export interface BottomDrawerOptionProps {
	options: BottomDrawerOption[];
	element?: JSX.Element;
	actionIcon?: JSX.Element;
	actionTitle?: string;
	drawerTitle?: string;
	sx?: any;
	actionHandler?: (e: React.MouseEvent) => void;
}

export function BottomDrawerOptions(props: BottomDrawerOptionProps) {
	const optionsList = (
		<Fragment>
			{props.element ? (
				props.element
			) : (
				<List sx={props.sx}>
					{props.drawerTitle ? (
						<Fragment>
							<ListItem>
								<ListItemText primary={props.drawerTitle} />
							</ListItem>
							<Divider />
						</Fragment>
					) : null}
					{props.options.map((option, index) => {
						return (
							<ListItem
								key={option.title || (option.element?.key ?? index)}
								button
								onClick={option.onClick}
								disabled={option.disabled === true}
							>
								{option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
								{option.title ? <ListItemText primary={option.title} /> : null}
								{option.element}
							</ListItem>
						);
					})}
				</List>
			)}
			<Divider />
			<List>
				<ListItem button onClick={props.actionHandler}>
					<ListItemIcon>{props.actionIcon || Icons.Close}</ListItemIcon>
					<ListItemText primary={props.actionTitle || 'Cancel'} />
				</ListItem>
			</List>
		</Fragment>
	);

	return optionsList;
}
