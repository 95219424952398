import { Card, CardContent } from '@mui/material';
import { EndContent } from 'components/EndContent';
import TextContent from 'components/TextContent';
import { isAdmin } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, Rhyme } from 'types';
import { GoldenRhyme } from './GoldenRhyme';

interface Props {
	rhyme?: Rhyme;
	locale: string;
	isEmbed?: boolean;
}

export const Component = (props: Props) => {
	let { rhyme, locale, isEmbed } = props;

	let text = rhyme?.text[locale] || rhyme?.text.hi || rhyme?.text.en;
	let textLocale = rhyme?.text[locale] ? locale : rhyme?.text.hi ? 'hi' : 'en';

	let context;
	let contextLocale;
	if (rhyme?.context) {
		context = rhyme?.context[locale];
		contextLocale = rhyme?.context[locale] ? locale : rhyme?.context.hi ? 'hi' : 'en';
	}

	let loading = !rhyme ? (
		<Card style={{ marginTop: 8 }} elevation={0}>
			<CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>Content doesn not exist!!</CardContent>
		</Card>
	) : null;

	return rhyme ? (
		<Card style={{ marginTop: 8 }} elevation={0}>
			{context ? (
				<CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
					<TextContent alignRhymes={true} locale={contextLocale}>
						<span
							dangerouslySetInnerHTML={{
								__html: context,
							}}
						/>
					</TextContent>
				</CardContent>
			) : null}
			<GoldenRhyme rhyme={rhyme?.rhyme ?? ''} />
			<CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
				<TextContent alignRhymes={true} locale={textLocale}>
					<span
						dangerouslySetInnerHTML={{
							__html: text ?? '',
						}}
					/>
				</TextContent>
			</CardContent>
			{isEmbed ? null : <EndContent />}
		</Card>
	) : (
		loading
	);
};

function mapStateToProps(state: ApplicationState, props: { id: string }) {
	let rhyme: Rhyme | undefined = state.dataState.rhymes.byId[props.id];

	let admin = isAdmin(state.userState.userStore.user, state.userState.userStore.userData);
	let showAll =
		admin &&
		state.userState.userStore.userData?.settings &&
		state.userState.userStore.userData?.settings['admin.records'];

	if (
		!showAll &&
		rhyme &&
		(rhyme.active === false ||
			rhyme.publishTime.seconds > new Date().getTime() / 1000 ||
			rhyme.expireTime.seconds <= new Date().getTime() / 1000)
	) {
		rhyme = undefined;
	}

	return {
		rhyme,
		locale: state.uxState.locale,
	};
}

export const RhymePageContent = connect(mapStateToProps)(Component);
