import ArticleList from 'components/articles/ArticleList';
import React from 'react';
import Page from './Page';

export default function BhajanPage() {
	return (
		<Page title='Bhajan' includeBottomNavigation fullscreen dark>
			<ArticleList recordType='bhajan' />
		</Page>
	);
}
